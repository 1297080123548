import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as St from "./styles";
import { logout } from "~/services/authentication";
import { getUserPicture } from "~/services/profile";
import themes from "~/themes";
import { useHistory } from "react-router-dom";

const UserProfile = (props) => {
  const history = useHistory();
  const [userPicture, setUserPicture] = useState();
  const subMenuDatas = [
    { label: "Ver perfil", path: "/perfil" },
    { label: "Fale conosco", path: "/fale-conosco" },
    { label: "Segurança", path: "/safety" },
    { label: "Cancelar Conta", path: "/Cancelar-conta" },
    { label: "Sair", path: "" },
  ];
  const linkStyle = {
    height: "10px",
    margin: "5px 10px 10px 0",
    color: "#000000",
  };
  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    handleGetUserPicture();
  }, []);

  const handleGetUserPicture = async () => {
    getUserPicture().then((response) => {
      if (response.data?.usuarioFotoPerfil) {
        setUserPicture(
          `data:image/png;base64,${response.data?.usuarioFotoPerfil}`
        );
      }
    });
  };

  return (
    <St.Container>
      {/* <St.Notification>
                <img src={assets.images.notificationBell} />
            </St.Notification> */}
      <St.User>
        <St.Data>
          <St.Name onClick={() => history.push("/perfil")}>
            {`Bem vindo(a), ${user?.nomeTitular?.split(" ").at(0)} !`}
          </St.Name>
          <St.PerfilImage onClick={() => history.push("/perfil")}>
            <img
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "10px",
                marginLeft: "10px",
              }}
              src={userPicture ? userPicture : themes.components.images.avatar}
              alt="Imagem do perfil"
            />
          </St.PerfilImage>
        </St.Data>
        <St.Menu>
          {subMenuDatas.map((subMenu) =>
            subMenu.label !== "Sair" ? (
              <St.SubMenu key={Math.random()}>
                <Link to={subMenu.path} style={linkStyle}>
                  {subMenu.label}
                </Link>
              </St.SubMenu>
            ) : (
              <St.SubMenu key={Math.random()} onClick={handleLogout}>
                <Link style={linkStyle}>{subMenu.label}</Link>
              </St.SubMenu>
            )
          )}
        </St.Menu>
      </St.User>
    </St.Container>
  );
};
export default UserProfile;
