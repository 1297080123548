/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Layout from "~/components/Layout";
import ContainerBox from "~/components/ContainerBox";
import Button, { BUTTON_TYPES } from "~/components/Button";
import Loader, { LOADER_TYPES } from "~/components/Loader";
import Input from "~/components/Input";

import * as S from "./styles";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import themes from "~/themes";
import { ImClock } from "react-icons/im";
import { IoIosWarning } from "react-icons/io";

const DuplicateInvoice = () => {
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceBase64, setInvoiceBase64] = useState("");
  const [invoiceCodigoDeBarras, setInvoiceCodigoDeBarras] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [invoiceID, setInvoiceID] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [invoiceDescription, setInvoiceDescription] = useState("");
  const [url, setUrl] = useState(null);

  const { state } = useLocation();
  const history = useHistory();

  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));

  const GetInvoiceList = useApi(
    Endpoints.relatorio.getInvoiceList.replace("PARAM_CPF", user?.cpf),
    ApiMethod.GET
  );

  const GetInvoicePDF = useApi(
    Endpoints.relatorio.getInvoicePDF.replace("PARAM", url),
    ApiMethod.GET
  );

  useEffect(() => {
    if (state) {
      const { month, year, digitableLine, invoiceStatus, invoiceDescription } =
        state;
      if (month && year) {
        setUrl(`${user.cpf}/${month}/${year}`);
      }
      if (digitableLine) {
        setInvoiceCodigoDeBarras(state.digitableLine);
      }

      if (invoiceStatus) {
        setInvoiceStatus(invoiceStatus);
      }

      if (invoiceDescription) {
        setInvoiceDescription(invoiceDescription);
      }
    }

    if (currentMonth && currentYear) {
      setUrl(`${user.cpf}/${currentMonth}/${currentYear}`);
    }
  }, [user, currentMonth, currentYear, state]);

  useEffect(() => {
    (async () => {
      if (url) {
        const response = await GetInvoicePDF.callApi();
        setInvoiceBase64(response.data.documentFatura);
      }
    })();
  }, [url]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      if (!state?.month && !state?.year && !state?.digitableLine) {
        const data = await GetInvoiceList.callApi();
        setInvoiceCodigoDeBarras(
          data.data.listaFaturas[data.data.listaFaturas.length - 1]
            .linhaDigitavel
        );
        setCurrentMonth(
          data.data.listaFaturas[data.data.listaFaturas.length - 1]
            .mesReferencia
        );
        setCurrentYear(
          data.data.listaFaturas[data.data.listaFaturas.length - 1]
            .anoReferencia
        );

        setInvoiceID(
          data.data.listaFaturas[data.data.listaFaturas.length - 1].id
        );

        setInvoiceStatus(
          data.data.listaFaturas[data.data.listaFaturas.length - 1].status
        );

        setInvoiceDescription(
          data.data.listaFaturas[data.data.listaFaturas.length - 1]
            .statusDescricao
        );

        if (!data) {
          throw new Error("Fatura não encontrada");
        }
      } else {
        try {
          const data = await GetInvoiceList.callApi();
          if (data.status >= 200 && data.status <= 300)
            setInvoiceID(
              data.data.listaFaturas[data.data.listaFaturas.length - 1].id
            );
          setInvoiceStatus(
            data.data.listaFaturas[data.data.listaFaturas.length - 1].status
          );
          setInvoiceDescription(
            data.data.listaFaturas[data.data.listaFaturas.length - 1]
              .statusDescricao
          );
        } catch (err) {
          throw new Error("Fatura não encontrada");
        }
      }

      setIsLoading(false);
    })();
  }, [state.digitableLine, state.month, state.year]);

  const printInvoice = () => {
    const binary = atob(invoiceBase64.replace(/\s/g, ""));
    const buffer = new ArrayBuffer(binary.length);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < binary.length; i += 1) {
      view[i] = binary.charCodeAt(i);
    }

    const blob = new Blob([view], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(blob);
    return window.open(fileURL, "_blank");
  };

  const copyInvoice = () => {
    navigator.clipboard.writeText(invoiceCodigoDeBarras);
    setCopySuccess("Copiado!");
  };

  return (
    <Layout showBackGroundBar={false}>
      <ContainerBox>
        {GetInvoicePDF.loading || GetInvoiceList.loading ? (
          <S.WrapperLoader>
            <Loader size={LOADER_TYPES.size.medium} />
          </S.WrapperLoader>
        ) : invoiceStatus === "COBRANCA" ? (
          <S.ErrorInvoice>
            <IoIosWarning size={40} color="#F1CB0B" alt="warning" />
            <h2>Existe faturas em cobrança</h2>
          </S.ErrorInvoice>
        ) : (
          <S.Wrapper>
            {!errorMessage ? (
              <>
                <h6>2ª via da fatura</h6>
                <S.InvoiceStatus>
                  <t>Status da fatura:</t>
                  <b>{invoiceDescription}</b>
                  {invoiceStatus === "PAGA" ||
                  invoiceStatus === "PARCIAL" ||
                  invoiceStatus === "PARCELADA" ||
                  invoiceStatus === "PAGA_COBMAIS" ? (
                    <span>{themes.components.icons.check}</span>
                  ) : invoiceStatus === "GERADA" ? (
                    <span>{themes.components.icons.information}</span>
                  ) : invoiceStatus === "ATRASO" ? (
                    <span>{themes.components.icons.alert}</span>
                  ) : invoiceStatus === "ROTATIVO" ? (
                    <span>{themes.components.icons.rotate}</span>
                  ) : invoiceStatus === "COBRANÇA" ||
                    invoiceStatus === "COBRANCA" ? (
                    <IoIosWarning
                      size={17}
                      color={themes.colors.yellow.n2}
                      alt="warning"
                    />
                  ) : invoiceStatus === "PARCIAL_INVALIDO" ? (
                    <ImClock
                      size={17}
                      color={themes.colors.orange.n2}
                      alt="clock"
                    />
                  ) : (
                    <ImClock
                      size={17}
                      color={themes.colors.orange.n2}
                      alt="clock"
                    />
                  )}
                </S.InvoiceStatus>
                <embed
                  src={`data:application/pdf;base64, ${invoiceBase64}`}
                  type="application/pdf"
                  height="500px"
                  width="1000px"
                ></embed>
                <div style={{ width: "600px", marginLeft: "20%" }}>
                  <S.ContainerInvoice>
                    <S.InputCodigoDeBarras>
                      <Input
                        label="Linha digitável"
                        value={invoiceCodigoDeBarras}
                        readOnly
                      />
                    </S.InputCodigoDeBarras>
                    <S.CopyButton>
                      <Button onClick={copyInvoice}>Copiar</Button>
                    </S.CopyButton>
                  </S.ContainerInvoice>
                  {copySuccess}

                  <Button onClick={printInvoice}>Baixar imagem</Button>
                  <Button
                    onClick={() =>
                      history.push("/cartao-enviar-email", {
                        month: state?.month || currentMonth,
                        year: state?.year || currentYear,
                      })
                    }
                    type={BUTTON_TYPES.outlined}
                  >
                    Enviar no e-mail
                  </Button>
                  <Button
                    type={BUTTON_TYPES.outlined}
                    onClick={() =>
                      history.push("/qrcode-pix", {
                        id: invoiceID,
                      })
                    }
                  >
                    Pague pelo pix
                  </Button>
                </div>
              </>
            ) : (
              <h6>{errorMessage}</h6>
            )}
          </S.Wrapper>
        )}
      </ContainerBox>
    </Layout>
  );
};

export default DuplicateInvoice;
