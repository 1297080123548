/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import useWebSocket from "react-use-websocket";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import ReactLoading from "react-loading";

import { GlobalContext } from "~/contexts/GlobalState";

import * as St from "../../styles";
import themes from "~/themes";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

const WithDrawKYCValidation = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { lending } = useContext(GlobalContext);

  const history = useHistory();
  const [count, setCount] = useState(0);
  let timeoutId;

  const GetStatusKYC = useApi(
    Endpoints.lendings.getStatusClientKYC.replace("PARAM_CPF", lending.cpf),
    ApiMethod.GET
  );

  const PostCreateSolicitationKYC = useApi(
    Endpoints.lendings.postCreateSolicitationKYC,
    ApiMethod.POST
  );

  const PostCreateTimeOut = useApi(
    Endpoints.lendings.postCreateTimeout,
    ApiMethod.POST
  );

  const postContractedWithdraw = useApi(
    Endpoints.lendings.postContractWithdraw.replace(
      "PARAM_ID",
      lending?.idSimulacao
    ),
    ApiMethod.POST
  );

  const createSolicitationKYC = async () => {
    const response = await PostCreateSolicitationKYC.callApi({
      cpf: lending?.cpf,
      nome: lending?.nome,
    });
    try {
      if (response.status >= 200 && response.status < 300) {
        await getStatus();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const createTimeOutLog = async (createdTime) => {
    const response = await PostCreateTimeOut.callApi({
      cpf: lending?.cpf,
      dataCriacao: createdTime + "Z",
    });
    try {
      if (response.status >= 200 && response.status < 300) {
        console.log("TimeOut");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getStatus = async () => {
    const payload = {
      origem: lending?.origem,
    };

    const response = await GetStatusKYC.callApi();
    try {
      if (response.status >= 200 && response.status < 300) {
        if (response.data.status === "REJEITADO") {
          setLoading(false);
          setError(true);
          setErrorMessage(response.data.descricao);
        }
        if (response.data.status === "APROVADO") {
          const responseData = await postContractedWithdraw.callApi(payload);
          if (responseData.status >= 200 && responseData.status < 300) {
            history.push("/emprestimo/termos");
          }

          if (responseData.status >= 400) {
            setError(true);
            setErrorMessage(
              responseData.mensagem
                ? responseData.mensagem
                : "Erro na contratação!"
            );
            setLoading(false);
          }
        }

        if (response.data.status === "PROCESSANDO") {
          if (count < 2) {
            setCount(count + 1);
          } else {
            setError(true);
            setErrorMessage(response.data.descricao);
            await createTimeOutLog(response.data.dataCriacao);
            setCount(0);
          }
        }
      }
      if (response.status === 404) {
        setCount(count + 1);
        await createSolicitationKYC();
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getStatus();
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (count) {
      timeoutId = setTimeout(() => {
        getStatus();
      }, 20000);
      return () => clearTimeout(timeoutId);
    }
  }, [count]);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        icon={themes.components.images.warningLending}
        text={errorMessage}
        buttonText="Entendi"
        visibility={error}
        setVisibility={setError}
        imgIcon={true}
        onClick={() => history.push("/home")}
        slug={"/home"}
      />
      <St.Content>
        <St.BoxContent>
          <St.Title>Liberação do empréstimo</St.Title>
          <St.Subtitle>
            Estamos consultando a liberação para o teu empréstimo!
          </St.Subtitle>
          <St.Steps>Passo 6 de 11</St.Steps>
          {loading && (
            <St.WrapperLoader minHeight="500px">
              <span>
                Aguarde alguns segundos. Estamos processando teus dados!
              </span>
              <ReactLoading
                type="spinningBubbles"
                color="rgba(241, 147, 52, 0.80)"
                width={"20%"}
                height={"20%"}
              />
            </St.WrapperLoader>
          )}
        </St.BoxContent>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default WithDrawKYCValidation;
