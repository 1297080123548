/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";

import Layout from "~/components/Layout";
import ContainerBox from "~/components/ContainerBox";
import Loader, { LOADER_TYPES } from "~/components/Loader";

import * as S from "./styles";
import { useHistory } from "react-router";
import CardClient from "~/components/CardClient";
import { GlobalContext } from "~/contexts/GlobalState";
import { formatCardNumber } from "~/utils/mask";
import themes from "~/themes";
import { ImClock } from "react-icons/im";
import { IoIosWarning } from "react-icons/io";

import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

const ConsultInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [formattedCardNumber, setFormattedCardNumber] = useState(null);
  const { user } = useContext(GlobalContext);

  const history = useHistory();

  const GetInvoiceList = useApi(
    Endpoints.relatorio.getInvoiceList.replace("PARAM_CPF", user?.cpf),
    ApiMethod.GET
  );

  const hiddeCCNumber = (value) => {
    const valueSplited = value.split("");

    return valueSplited.map((el, index) => {
      if (index < 9) return "*";
      else return el;
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await GetInvoiceList.callApi();

        if (data.status >= 200 && data.status < 300) {
          setInvoices(data.data?.listaFaturas.reverse());
        } else {
          setErrorMessage("Nenhuma fatura encontrada");
        }
      } catch (err) {
        setErrorMessage("Nenhuma fatura encontrada");
      }
    })();
  }, []);

  useEffect(() => {
    setFormattedCardNumber(
      hiddeCCNumber(formatCardNumber(user?.numeroCartao || ""))
    );
  }, [user.numeroCartao]);

  const goToInvoice = (
    month,
    year,
    digitableLine,
    invoiceStatus,
    invoiceDescription
  ) =>
    history.push("/cartao-fatura", {
      month,
      year,
      digitableLine,
      invoiceStatus,
      invoiceDescription,
    });

  return (
    <Layout showBackGroundBar={false}>
      <ContainerBox>
        <S.Wrapper>
          <header>
            <h4>Faturas</h4>
          </header>
          <S.CardClientInfo>
            <CardClient number={formattedCardNumber} userName={user.nome} />
          </S.CardClientInfo>
          {GetInvoiceList.loading ? (
            <S.WrapperLoader>
              <Loader size={LOADER_TYPES.size.medium} />
            </S.WrapperLoader>
          ) : (
            <>
              {errorMessage ? (
                <h6>{errorMessage}</h6>
              ) : (
                <S.Table>
                  <h6>Lista de faturas</h6>
                  {invoices.map((fatura) => (
                    <S.InvoiceDescription>
                      <strong
                        onClick={() =>
                          goToInvoice(
                            fatura.mesReferencia,
                            fatura.anoReferencia,
                            fatura.linhaDigitavel,
                            fatura.status,
                            fatura.statusDescricao
                          )
                        }
                      >
                        {fatura.status === "PAGA" ||
                        fatura.status === "PARCIAL" ||
                        fatura.status === "PARCELADA" ||
                        fatura.status === "PAGA_COBMAIS" ? (
                          <span>{themes.components.icons.check}</span>
                        ) : fatura.status === "GERADA" ? (
                          <span>{themes.components.icons.information}</span>
                        ) : fatura.status === "ATRASO" ? (
                          <span>{themes.components.icons.alert}</span>
                        ) : fatura.status === "ROTATIVO" ? (
                          <span>{themes.components.icons.rotate}</span>
                        ) : fatura.status === "COBRANÇA" ||
                          fatura.status === "COBRANCA" ? (
                          <IoIosWarning
                            size={14.17}
                            color={themes.colors.yellow.n2}
                            alt="warning"
                          />
                        ) : fatura.status === "PARCIAL_INVALIDO" ? (
                          <ImClock
                            size={14.17}
                            color={themes.colors.orange.n2}
                            alt="clock"
                          />
                        ) : (
                          <ImClock
                            size={14.17}
                            color={themes.colors.orange.n2}
                            alt="clock"
                          />
                        )}
                        {fatura.mesReferencia + "/" + fatura.anoReferencia}
                        <p>{fatura.statusDescricao}</p>
                        <a>
                          {"R$ " +
                            Number.parseFloat(fatura.saldoFaturaAtual)
                              .toFixed(2)
                              .replace(".", ",")}
                        </a>
                      </strong>
                    </S.InvoiceDescription>
                  ))}
                </S.Table>
              )}
            </>
          )}
        </S.Wrapper>
      </ContainerBox>
    </Layout>
  );
};

export default ConsultInvoices;
