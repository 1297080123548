/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Loader from "~/components/Loader";
import InputBanking from "~/views/Lending/components/InputBanking";
import InputSelectSimple from "~/views/Lending/components/InputSelectSimple";
import InputOnboarding from "~/components/InputOnboarding";
import Button from "~/components/Button";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";

import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import {
  cpfCnpjMask,
  phoneMask,
  cpfUnmask,
  cpfMask,
  phoneUnmask,
  accountBankMask,
} from "~/utils/masksNew";
import {
  phoneValidator,
  cpfCnpjValidator,
  emailValidator,
  randomValidator,
  accountBankValidator,
  documentCPFValidator,
  selectValidator,
} from "~/utils/validators";
import { GlobalContext } from "~/contexts/GlobalState";

import * as St from "../styles";
import themes from "~/themes";
import InputSelectLoan from "~/views/Lending/components/InputSelectLoan";
import SelectDropdown from "../../components/SelectDropdown";

const INPUT_NAMES = {
  inputSearch: "inputSearch",
  typeAccount: "typeAccount",
  agency: "agency",
  account: "account",
  digit: "digit",
};

const BankingDataByLendingSearch = () => {
  const [loading, setLoading] = useState(false);
  const [bankings, setBankings] = useState([]);
  const [allBankings, setAllBankings] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [messageErrorPopup, setMessageErrorPopup] = useState("");
  const [errorsInputs, setErrorsInputs] = useState({});
  const [openSelect, setOpenSelect] = useState(false);
  const [openSelectTypeAccount, setOpenSelectTypeAccount] = useState(false);
  const [openSelectKey, setOpenSelectKey] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [pixKey, setPixKey] = useState("");
  const [input, setInput] = useState({
    key: "",
  });
  const [form, setForm] = useState({
    account: "",
    agency: "",
    digit: "",
  });
  const { lending, addLending } = useContext(GlobalContext);

  const GetBanksList = useApi(
    Endpoints.lendings.getBanksByEasyCredito,
    ApiMethod.GET
  );

  const postContractedWithdraw = useApi(
    Endpoints.lendings.postContractWithdraw.replace(
      "PARAM_ID",
      lending?.idSimulacao
    ),
    ApiMethod.POST
  );

  const putUpdateBankingData = useApi(
    Endpoints.lendings.putUpdateBankingDataWithdraw.replace(
      "PARAM_ID",
      lending?.idSimulacao
    ),
    ApiMethod.PUT
  );

  const history = useHistory();

  const FetchBankingList = async () => {
    setLoading(true);

    try {
      const response = await GetBanksList.callApi();
      const { data } = response;

      if (data) {
        setBankings(response.data);
        setAllBankings(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBankingCode = (value) => {
    updateFormField(value, INPUT_NAMES.inputSearch);

    if (value?.length > 0) {
      setOpenSelect(true);
      const filter = allBankings.filter((banking) => {
        const r = `${banking.name}-${banking.code}`;
        return r.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });
      setBankings(filter);
    } else {
      setBankings(allBankings);
      setOpenSelect(false);
    }
  };

  const activateButton = useMemo(
    () =>
      !form.account ||
      !form.agency ||
      !form?.typeAccount?.value ||
      !input?.inputSearch ||
      !form?.digit,
    [
      form.account?.length > 0,
      form.agency?.length > 0,
      form?.typeAccount?.value?.length > 0,
      form?.inputSearch?.value?.length > 0,
      form?.digit?.length > 0,
    ]
  );

  async function handleNext() {
    addLending({
      agency: form?.agency,
      codeBank: input?.inputSearch,
      account: form?.account + form?.digit,
      typeAccount: form?.typeAccount?.code,
    });

    history.push("/buscador/simular");
  }

  const updateFormField = (value, id) => {
    setInput((prev) => ({ ...prev, [id]: value }));
  };


  const updateFormSelectType = (code, id, value) => {
    setForm((prev) => ({ ...prev, [id]: { code, value } }));
  };

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/");
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, [verifyReloadPage]);
  

  useEffect(() => {
    FetchBankingList();
  }, []);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={messageErrorPopup}
        buttonText="Entendi"
        visibility={showPopup}
        setVisibility={setShowPopup}
      />
      <St.Content>
        <St.BoxContent>
          {loading ? (
            <St.WrapperLoader>
              <Loader size="large" />
            </St.WrapperLoader>
          ) : (
            <>
              <St.Title>Dados bancários</St.Title>
              <St.Subtitle>
                Agora precisamos de informaçoes bancárias (para onde o teu
                dinheiro vai!):
              </St.Subtitle>
              <St.Form>
                {/* <InputSelectLoan
                  label="Código e Descrição do Banco*"
                  placeholder="-"
                  type="bank"
                  onChange={(e) => handleBankingCode(e.target.value)}
                  open={openSelect}
                  setOpen={setOpenSelect}
                  options={bankings}
                  id={INPUT_NAMES.inputSearch}
                  inputTextValue={form?.inputSearch?.value}
                  onClickSelect={updateFormSelectType}
                  defaultValue={form?.inputSearch?.value}
                /> */}
                <SelectDropdown
                  label="Código e Descrição do Banco*"
                  placeholder="Selecione o banco"
                  options={bankings}
                  onChange={(name) =>
                    updateFormField(name, INPUT_NAMES.inputSearch)
                  }
                  id={INPUT_NAMES.inputSearch}
                  open={openSelect}
                  setOpen={setOpenSelect}
                  selectedKey={input?.inputSearch}
                />
                <SelectDropdown
                  label="Tipo de conta*"
                  placeholder="Selecione o tipo de conta"
                  id={INPUT_NAMES.typeAccount}
                  open={openSelectTypeAccount}
                  setOpen={setOpenSelectTypeAccount}
                  options={[
                    {
                      name: "Corrente Individual",
                      code: "CONTA_CORRENTE_INDIVIDUAL",
                    },
                    {
                      name: "Corrente Conjunta",
                      code: "CONTA_CORRENTE_CONJUNTA",
                    },
                    {
                      name: "Poupança Individual",
                      code: "CONTA_POUPANCA_INDIVIDUAL",
                    },
                    {
                      name: "Poupança Conjunta",
                      code: "CONTA_POUPANCA_CONJUNTA",
                    },
                  ]}
                  onChange={(text, name) =>
                    updateFormSelectType(text, INPUT_NAMES.typeAccount, name)
                  }
                  selectedKey={form?.typeAccount?.code}
                />
                {/* <InputSelectSimple
                  label="Tipo de conta*"
                  largeLabel
                  placeholder="Selecione o tipo de conta"
                  open={openSelectTypeAccount}
                  setOpen={setOpenSelectTypeAccount}
                  options={[
                    {
                      code: "Corrente Individual",
                      value: "CONTA_CORRENTE_INDIVIDUAL",
                    },
                    {
                      code: "Corrente Conjunta",
                      value: "CONTA_CORRENTE_CONJUNTA",
                    },
                    {
                      code: "Poupança Individual",
                      value: "CONTA_POUPANCA_INDIVIDUAL",
                    },
                    {
                      code: "Poupança Conjunta",
                      value: "CONTA_POUPANCA_CONJUNTA",
                    },
                  ]}
                  inputId={INPUT_NAMES.typeAccount}
                  onClickSelect={updateFormSelectType}
                  inputTextValue={form?.typeAccount?.code}
                  type="account"
                  error={errorsInputs.typeAccount}
                /> */}

                <InputOnboarding
                  type="text"
                  label="Agência*"
                  largeLabel
                  largeBorder
                  id={INPUT_NAMES.agency}
                  maxLength={4}
                  pattern="[0-9]*"
                  placeholder="0000"
                  value={form?.agency}
                  onChange={(e) => setForm({ ...form, agency: e.target.value })}
                  error={errorsInputs.agency}
                />
                <div style={{ display: "flex" }}>
                  <InputOnboarding
                    type="text"
                    label="Conta sem dígito*"
                    largeBorder
                    largeLabel
                    id={INPUT_NAMES.account}
                    maxLength={20}
                    pattern="[0-9]*"
                    placeholder="00000000"
                    value={form?.account}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        account: e.target.value,
                      })
                    }
                    error={errorsInputs.account}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <InputOnboarding
                      type="text"
                      label="Dígito*"
                      largeBorder
                      largeLabel
                      id={INPUT_NAMES.digit}
                      placeholder={"0"}
                      pattern="[0-9]*"
                      maxLength={1}
                      value={form?.digit}
                      error={errorsInputs.digit}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          digit: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </St.Form>
              <St.Footer align="right">
                <div>
                  <Button
                    onClick={handleNext}
                    disabled={
                      activateButton ||
                      postContractedWithdraw.loading ||
                      putUpdateBankingData.loading
                    }
                    isLoading={buttonIsLoading}
                  >
                    Avançar
                  </Button>
                </div>
              </St.Footer>
            </>
          )}
        </St.BoxContent>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default BankingDataByLendingSearch;
