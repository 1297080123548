/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useMemo, useRef, useEffect } from "react";

import Button from "~/components/Button";
import { OneButtonPopup } from "~/components/Modals/OneButtonPopup";

import * as S from "../styles";
import { dateMask, numericMask, cepMask } from "~/utils/mask";
import themes from "~/themes";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import { useHistory } from "react-router-dom";
import InputOnboarding from "~/components/InputOnboarding";
import ContainerBox from "~/components/ContainerBox";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import {
  bairroValidator,
  cepValidator,
  cidadeValidator,
  dateBirthValidator,
  enderecoValidator,
  generoValidator,
  numeroValidator,
  ufValidator,
} from "~/utils/validators";
import { cepUnmask, transformInGlobalDate } from "~/utils/masksNew";
import Camera, { FACING_MODES } from "components/NewCamera";
import PictureModal from "components/PictureCard";
import constants from "~/utils/constants";
import { api } from "~/services/api";
import { GlobalContext } from "~/contexts/GlobalState";
import TwoButtonPopup from "~/components/Modals/TwoButtonPopup";
import { groups, optionsUfs } from "./constants";
import InputSelect from "~/components/InputSelect";
import { MdPhotoCamera } from "react-icons/md";
import Spinner from "components/Spinner";
import FaceCaptchaService, {
  getCertifaceResultMessage,
  getImageEnc,
} from "~/services/facecaptcha/facecaptcha";

const UserPersonalDataMain = () => {
  const IMAGE_TYPES = {
    CERTIFACE: "CERTIFACE",
    FRENTE: "FRENTE",
    VERSO: "VERSO",
  };

  const history = useHistory();
  const [errorsInputs, setErrorsInputs] = useState({});
  const [cep, setCep] = useState("");
  const [cidade, setCidade] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [uf, setUf] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [genero, setGenero] = useState("");
  const retCep = cepUnmask(cep);
  const retDataNascimento = transformInGlobalDate(dataNascimento);
  const [disableEndereco, setDisableEndereco] = useState(false);
  const [documentsPicture1, setDocumentsPicture1] = useState(null);
  const [documentsPicture2, setDocumentsPicture2] = useState(null);
  const [cam1DocumentsIsOpen, setCam1DocumentsIsOpen] = useState(false);
  const [cam2DocumentsIsOpen, setCam2DocumentsIsOpen] = useState(false);
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const [auxImage, setAuxImage] = useState("");
  const [userPicture, setUserPicture] = useState();
  const [errorPopUpUpload, setErrorPopUpUpload] = useState(false);
  const [lastPictureUploaded, setLastPictureUploaded] = useState();
  const [certiface, setCertiface] = useState({});

  const [certifaceErrorMessage, setCertifaceErrorMessage] = useState("");
  const [certifaceImages, setCertifaceImages] = useState([]);
  const [camUserPictureIsOpen, setCamUserPictureIsOpen] = useState(false);
  const [currentChallenge, setCurrentChallenge] = useState(null);
  const [certifaceMessagebase64, setCertifaceMessagebase64] = useState(null);
  const [errorUserPicture] = useState(null);
  const [livenessActive] = useState(
    process.env.REACT_APP_LIVENESS_ACTIVE === "true" ? true : false
  );

  const livenessRef = useRef();

  const [firstPictureCertiface, setFirstPictureCertiface] = useState(null);
  const [imageUp, setImageUp] = useState("");
  const { onboarding, addOnboarding } = useContext(GlobalContext);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);
  const [messageMissingPicture, setMessageMissingPicture] = useState(false);

  const PostSaveClientIdentification = useApi(
    Endpoints.proposta.saveClientIdentification,
    ApiMethod.POST
  );

  const handleValidateFields = () => {
    setButtonIsLoading(true);
    const ufValues = ["AM", "RO", "RR"];
    const isValidDataUf = uf && ufValues.includes(uf);

    try {
      if (!isValidDataUf) {
        setShowPopupError(true);
      } else {
        const fieldsForm = {
          cep: cepValidator(cep).message || "",
          cidade: cidadeValidator(cidade).message || "",
          endereco: enderecoValidator(endereco).message || "",
          numero: numeroValidator(numero).message || "",
          bairro: bairroValidator(bairro).message || "",
          uf: ufValidator(uf).message || "",
          genero: generoValidator(genero).message || "",
          dataNascimento: dateBirthValidator(dataNascimento).message || "",
        };

        if (!documentsPicture1 || !documentsPicture2 || !userPicture) {
          if (!userPicture) {
            setMessageMissingPicture("É obrigatório a selfie do usuário");
          } else if (!documentsPicture1) {
            setMessageMissingPicture(
              "A parte verso do documento é obrigatório"
            );
          } else if (!documentsPicture2) {
            setMessageMissingPicture(
              "A parte frontal do documento é obrigatório"
            );
          }
          setButtonIsLoading(false);
          setShowPopup(true);
          return;
        }

        setErrorsInputs(fieldsForm);
        if (
          !fieldsForm.cep &&
          !fieldsForm.dataNascimento &&
          !fieldsForm.cidade &&
          !fieldsForm.endereco &&
          !fieldsForm.cep &&
          !fieldsForm.genero
        ) {
          setButtonIsLoading(false);
          history.push("/onboarding-criar-senha");
        }

        addOnboarding({
          cep: retCep,
          cidade: cidade,
          endereco: endereco,
          numero: numero,
          complemento: complemento,
          bairro: bairro,
          uf: uf,
          dataNascimento: retDataNascimento,
          genero: genero,
        });
      }
    } catch (err) {
      console.log(err);
    }
    setButtonIsLoading(false);
  };

  async function handleCep(e) {
    const valor = e?.target?.value;

    setCep(cepMask(valor));
    if (valor?.length === 9 || valor?.length === 8) {
      setDisableEndereco(true);
      api
        .get(constants.endpoints.findAddressByCep.replace("PARAM_CEP", valor))
        .then((response) => {
          setDisableEndereco(false);
          setEndereco(response.data?.logradouro);
          setBairro(response.data?.bairro);
          setCidade(response.data?.localidade);
          setUf(response.data?.uf);
        });
    }
  }

  const handleUploadPicture = async (image, type) => {
    setImageIsLoading(type);
    setAuxImage(image);
    try {
      const data = await PostSaveClientIdentification.callApi({
        file: image.split(",")[1],
        tipo: type,
        idProposta: onboarding.cpf,
      });
      console.log(data);

      switch (type) {
        case IMAGE_TYPES.CERTIFACE:
          setUserPicture(image);
          break;
        case IMAGE_TYPES.FRENTE:
          setDocumentsPicture1(image);
          break;
        case IMAGE_TYPES.VERSO:
          setDocumentsPicture2(image);
          break;
        default:
          return;
      }
    } catch (err) {
      switch (type) {
        case IMAGE_TYPES.CERTIFACE:
          break;
        case IMAGE_TYPES.FRENTE:
          break;
        case IMAGE_TYPES.VERSO:
          break;
        default:
          return;
      }

      setErrorPopUpUpload(true);
    } finally {
      setImageIsLoading(null);
    }
  };

  const toggleCameraDocuments = (index) => {
    if (index === 1) {
      setCam1DocumentsIsOpen((prev) => !prev);
    } else {
      setCam2DocumentsIsOpen((prev) => !prev);
    }
  };

  const onClickDeleteDocumentPicture = (index) => {
    if (index === 1) {
      setDocumentsPicture1(null);
    } else {
      setDocumentsPicture2(null);
    }
  };

  const onTakeDocumentPicture = async (imageBASE64, index) => {
    if (index === 1) {
      setLastPictureUploaded(IMAGE_TYPES.FRENTE);
      await handleUploadPicture(imageBASE64, IMAGE_TYPES.FRENTE);
    } else {
      setLastPictureUploaded(IMAGE_TYPES.VERSO);
      await handleUploadPicture(imageBASE64, IMAGE_TYPES.VERSO);
    }
  };

  const buttonIsDisabled = () => {
    if (!livenessActive) {
      return (
        (certiface?.codID === 1.1 ||
          certiface?.codID === 200.1 ||
          certiface?.codID === 200.2 ||
          certiface?.codID === 1.2) &&
        cep.length === 9 &&
        genero.length &&
        numero.length &&
        dataNascimento.length === 10
      );
    } else {
      return (
        (certiface?.codID === 1.1 || certiface?.codID === 1.2) &&
        cep.length === 9 &&
        genero.length &&
        numero.length &&
        dataNascimento.length === 10
      );
    }
  };

  const handleReUploadPicture = async () => {
    setErrorPopUpUpload(false);

    switch (lastPictureUploaded) {
      case IMAGE_TYPES.CERTIFACE:
        await handleUploadPicture(auxImage, IMAGE_TYPES.CERTIFACE);
        break;
      case IMAGE_TYPES.FRENTE:
        await handleUploadPicture(auxImage, IMAGE_TYPES.FRENTE);
        break;
      case IMAGE_TYPES.VERSO:
        await handleUploadPicture(auxImage, IMAGE_TYPES.VERSO);
        break;
      default:
        return;
    }
  };

  const toggleCameraUserPicture = async () => {
    setUserPicture();
    setCertifaceErrorMessage("");
    const isFormValid = checkIfLivenessFormValid();
    if (!isFormValid.valid) {
      setCertifaceErrorMessage(
        isFormValid.field + " obrigatório para iniciar a Prova de Vida"
      );
      return;
    }

    startLiveness();
  };

  const startLiveness = async () => {
    setImageIsLoading(IMAGE_TYPES.CERTIFACE);
    const service = FaceCaptchaService();
    setCertifaceImages([]);

    const startResponse = await service.start(
      onboarding.nome || "",
      onboarding.dataNascimento?.masked || "00/00/0000",
      onboarding.cpf || ""
    );

    setCamUserPictureIsOpen((prev) => !prev);

    if (startResponse.status === 200) {
      const challenges = startResponse.data?.challenge?.challenges || [];

      if (challenges.length > 0) {
        challenges.forEach((ch, index) => {
          const code = ch.tipoFace.codigo;
          const time = ch.tempoEmSegundos * 1000;
          const size = challenges.length - 1;

          forceCameraTakePicture(time, {
            code,
            time,
            index,
            size,
            message: ch.mensagem,
            startResponse,
          });
        });
      }
    }
    setImageIsLoading(null);
  };

  const invokeCameraHandle = (image) => {
    if (currentChallenge === null) return;

    const { code, index, size, message, startResponse } = currentChallenge;
    if (index === 0) {
      setFirstPictureCertiface(image);
    }

    setImageUp(image);

    const imageBase64 = image;

    const currentImages = certifaceImages;

    const newImage = getImageEnc(imageBase64, code);

    setCertifaceMessagebase64(message);

    currentImages.push(newImage);
    setCertifaceImages(currentImages);

    if (index === size) {
      if (livenessRef.current) {
        livenessRef.current.stopCamera();
      }

      if (index === 0) {
        sendChallenges(startResponse, currentImages, imageBase64);
      } else {
        sendChallenges(startResponse, currentImages);
      }
      setCurrentChallenge(null);
      setCertifaceMessagebase64(null);
    }
  };

  const sendChallenges = async (startResponse, images, firstImage) => {
    setImageIsLoading(IMAGE_TYPES.CERTIFACE);

    const service = FaceCaptchaService();

    const challengeResponse = await service.captcha(startResponse, images);
    setImageIsLoading(null);

    setCertiface(challengeResponse.data?.result);

    setCertifaceErrorMessage("");

    if (challengeResponse.success === true) {
      const codeResult = challengeResponse.data.result.codID;
      if ([1.1, 1.2].includes(codeResult)) {
        setUserPicture(firstPictureCertiface);
        handleUploadPicture(
          firstImage || firstPictureCertiface,
          IMAGE_TYPES.CERTIFACE
        );
      } else if ([200.1, 200.2].includes(codeResult)) {
        !livenessActive && setUserPicture(firstImage || firstPictureCertiface);
        setCertifaceErrorMessage(
          "Ops! No momento não podemos lhe atender. Tente novamente mais tarde!"
        );
      } else {
        setCertifaceErrorMessage(
          getCertifaceResultMessage(challengeResponse.data.result.codID || 0)
        );
      }
    }
  };

  const checkIfLivenessFormValid = () => {
    if (!onboarding.nome) return { field: "Nome Completo ", valid: false };
    if (!dataNascimento) return { field: "Data de Nascimento ", valid: false };

    return { field: "", valid: true };
  };

  // Isso e necessario para validar a imagem junto ao CERTIFACE!!!
  const cameraButtonIsDisabled = useMemo(
    () =>
      !onboarding.nome?.trim().length ||
      dataNascimento.length === 10 ||
      certiface?.codID === 1.1 ||
      certiface?.codID === 1.2 ||
      certiface?.codID === 200.1 ||
      certiface?.codID === 200.2 ||
      imageIsLoading === IMAGE_TYPES.CERTIFACE,
    [
      onboarding.nome,
      dataNascimento.length,
      certiface.codID,
      imageIsLoading,
      IMAGE_TYPES.CERTIFACE,
    ]
  );

  const forceCameraTakePicture = (time, obj) =>
    setTimeout(() => {
      setCurrentChallenge(obj);
      setCertifaceMessagebase64(obj.message);

      setTimeout(() => {
        if (livenessRef.current) {
          livenessRef.current.takePicture();
        }
      }, time);
    }, (time + 1000) * obj.index);

  const verifyReloadPage = () => {
    if (onboarding.cpf === "") {
      history.push("/");
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, [verifyReloadPage]);

  const isLivenessError = () => {
    if (!livenessActive) {
      if (
        certiface?.codID !== 1.1 &&
        certiface?.codID !== 1.2 &&
        certiface?.codID !== 200.1 &&
        certiface?.codID !== 200.2
      ) {
        return true;
      }
    } else {
      if (certiface?.codID !== 1.1 && certiface?.codID !== 1.2) {
        return true;
      }
    }
  };

  return (
    <LayoutOnboarding showBackGroundBar={false}>
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={messageMissingPicture}
        buttonText="Entendi"
        visibility={showPopup}
        setVisibility={setShowPopup}
      />
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={"Estado não permitido"}
        buttonText="Entendi"
        visibility={showPopupError}
        setVisibility={setShowPopupError}
      />
      <S.Container>
        <ContainerBox withoutBoxShadow={true}>
          <S.ContentMain>
            <S.MainText>
              <p>Criar carteira digital</p>
            </S.MainText>
            <S.MiddleText>
              <p>
                Preencha os campos abaixo com os teus dados pessoais, por favor:
              </p>
              <S.Picture wrapperCamera={camUserPictureIsOpen}>
                {userPicture && (
                  <div>
                    <PictureModal
                      id="picture-modal"
                      source={imageUp}
                      width="150"
                      height="150"
                      expand
                    />
                  </div>
                )}

                <Button
                  buttonType="primary"
                  onClick={toggleCameraUserPicture}
                  disabled={!cameraButtonIsDisabled}
                >
                  Tirar selfie &nbsp;
                  <MdPhotoCamera />
                  {imageIsLoading === IMAGE_TYPES.CERTIFACE && (
                    <Spinner color="#FFF" />
                  )}
                </Button>

                <S.WrapperCamera show={camUserPictureIsOpen}>
                  <Camera
                    open={camUserPictureIsOpen}
                    facingMode={FACING_MODES.USER}
                    onTakePhoto={invokeCameraHandle}
                    certifaceMessagebase64={certifaceMessagebase64}
                    stopWatch
                    doNotCloseAfterTakePicture
                    disableCloseButton
                    ref={livenessRef}
                    handleClose={() => setCamUserPictureIsOpen(false)}
                    disableTakePictureButton
                    mask
                  />
                </S.WrapperCamera>

                {errorUserPicture && (
                  <S.ErrorMessage>{errorUserPicture}</S.ErrorMessage>
                )}
              </S.Picture>

              {isLivenessError() && (
                <S.ErrorMessage>{certifaceErrorMessage}</S.ErrorMessage>
              )}
            </S.MiddleText>
            <S.MiddleText>
              <p>
                {" "}
                Agora, tire uma foto do teu documento de identificação. Exemplo:
                RG ou CNH (frente/verso).
              </p>
            </S.MiddleText>
            <S.InputSideBySide style={{ flexDirection: "row" }}>
              <p> Frente </p>
              <a> Verso </a>
            </S.InputSideBySide>
            <S.InputSideBySide>
              <S.Picture>
                <div>
                  <PictureModal
                    id="picture-first-modal"
                    source={
                      documentsPicture1 || themes.components.images.rgFront
                    }
                    width="171"
                    height="111"
                    onDelete={
                      documentsPicture1
                        ? () => onClickDeleteDocumentPicture(1)
                        : null
                    }
                    expand={!!documentsPicture1}
                    onClickCamera={() => toggleCameraDocuments(1)}
                    showOptions={!documentsPicture1}
                    isLoading={imageIsLoading === IMAGE_TYPES.FRENTE}
                  />

                  <S.WrapperCamera show={cam1DocumentsIsOpen}>
                    <Camera
                      open={cam1DocumentsIsOpen}
                      onTakePhoto={(img) => onTakeDocumentPicture(img, 1)}
                      handleClose={() => toggleCameraDocuments(1)}
                    />
                  </S.WrapperCamera>
                </div>

                <div>
                  <PictureModal
                    id="picture-second-modal"
                    source={
                      documentsPicture2 || themes.components.images.rgBack
                    }
                    width="171"
                    height="111"
                    onDelete={
                      documentsPicture2
                        ? () => onClickDeleteDocumentPicture(2)
                        : null
                    }
                    expand={!!documentsPicture2}
                    onClickCamera={() => toggleCameraDocuments(2)}
                    showOptions={!documentsPicture2}
                    isLoading={imageIsLoading === IMAGE_TYPES.VERSO}
                  />
                  <S.WrapperCamera show={cam2DocumentsIsOpen}>
                    <Camera
                      open={cam2DocumentsIsOpen}
                      onTakePhoto={(img) => onTakeDocumentPicture(img, 2)}
                      handleClose={() => toggleCameraDocuments(2)}
                    />
                  </S.WrapperCamera>
                </div>
              </S.Picture>
            </S.InputSideBySide>
          </S.ContentMain>
        </ContainerBox>
        <ContainerBox withoutBoxShadow={true}>
          <S.ContentMain>
            <S.InputMain>
              <S.InputSideBySide>
                <InputOnboarding
                  type="text"
                  label="Data de nascimento"
                  maxLength={10}
                  value={dataNascimento}
                  onChange={(e) => setDataNascimento(dateMask(e.target.value))}
                  error={errorsInputs.dataNascimento}
                />
                <InputSelect
                  className="select"
                  value={genero}
                  defaultValue={genero}
                  placeholder="Selecione"
                  label="Gênero"
                  values={groups}
                  type="select"
                  onChange={(e) => setGenero(e.target.value)}
                  error={errorsInputs.genero}
                />
              </S.InputSideBySide>
              <S.InputSideBySide>
                <InputOnboarding
                  name="zipCode"
                  id="zipCode"
                  label="CEP"
                  defaultValue={cepMask(cep)}
                  value={cepMask(cep)}
                  maxLength={9}
                  onChange={handleCep}
                  pattern="[0-9]*"
                  inputmode="numeric"
                  error={errorsInputs.cep}
                />
                <InputOnboarding
                  type="text"
                  label="Cidade"
                  maxLength={14}
                  value={cidade}
                  disabled={disableEndereco}
                  onChange={(e) => setCidade(e.target.value)}
                  error={errorsInputs.cidade}
                />
              </S.InputSideBySide>
              <InputOnboarding
                type="text"
                label="Endereço"
                maxLength={100}
                value={endereco}
                disabled={disableEndereco}
                onChange={(e) => setEndereco(e.target.value)}
                error={errorsInputs.endereco}
              />
              <S.InputSideBySide>
                <InputOnboarding
                  type="text"
                  label="Número"
                  maxLength={14}
                  value={numero}
                  onChange={(e) => setNumero(numericMask(e.target.value))}
                  error={errorsInputs.numero}
                />
                <InputOnboarding
                  type="text"
                  label="Complemento (opcional)"
                  maxLength={14}
                  value={complemento}
                  onChange={(e) => setComplemento(e.target.value)}
                  error={errorsInputs.complemento}
                />
              </S.InputSideBySide>
              <S.InputSideBySide>
                <InputOnboarding
                  type="text"
                  label="Bairro"
                  maxLength={14}
                  value={bairro}
                  disabled={disableEndereco}
                  onChange={(e) => setBairro(e.target.value)}
                  error={errorsInputs.bairro}
                />
                <InputSelect
                  className="select"
                  type="select"
                  value={uf}
                  defaultValue={uf}
                  values={optionsUfs}
                  placeholder="Selecione"
                  disabled={disableEndereco}
                  onChange={(e) => setUf(e.target.value)}
                  label="UF"
                  error={errorsInputs.uf}
                />
              </S.InputSideBySide>
            </S.InputMain>
            <S.Footer id="footer-button">
              <Button
                onClick={() => handleValidateFields()}
                isLoading={buttonIsLoading}
                disabled={!buttonIsDisabled()}
              >
                Continuar
              </Button>
            </S.Footer>
            <TwoButtonPopup
              text="Erro ao fazer upload das imagens!"
              visibility={errorPopUpUpload}
              buttonText="Tentar novamente"
              onClick={handleReUploadPicture}
              buttonCancelText="Voltar"
              setVisibility={(val) => {
                setErrorPopUpUpload(val);
              }}
            />
          </S.ContentMain>
        </ContainerBox>
      </S.Container>
    </LayoutOnboarding>
  );
};

export default UserPersonalDataMain;
