import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 55%;
  max-width: 800px;
  margin: 0 auto;

  > header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8%;

    h6 {
      flex: 1;
      color: #4A4B4A;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -0.86px;
      line-height: 27px;
      margin-left: -30%;
    }

    button {
      background: transparent;
      border: 0;
      height: 21px;
      width: 21px;
      margin-right: 40px;

      svg {
        height: 21px;
        width: 21px;
      }
    }
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;

  width: 380px;
`;

export const FooterModal = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;

  padding-right: 34px;
  padding-bottom: 24px;
  margin-top: 12px;

  button {
    text-align: center;
    color: #E71037;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.57px;
    line-height: 22px;
    background: transparent;
    border: 0;

    & + button {
      margin-left: 30px;
    }
  }
`;

export const WrapperLoader = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height: 100px;
`


export const CardClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-self:center;
  margin-top: -1%;
`;