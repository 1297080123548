import styled from "styled-components";

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: #E4003A;
  font-weight: 400;
  
`;
export const Icons = styled.div`
  width: 42.87px;
  height: 43.2px;
  border-radius: 10.8006px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 1px 3px 8px 0 rgba(201, 201, 201, 0.5);
`;