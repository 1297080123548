import React from 'react';

import * as S from './styles';

const ChatBox = ({ isUser, text, avatar }) => {
  return (
    <S.Container isUser={isUser}>
      <img src={avatar} alt='Avatar do usuário' />
      <p>{text}</p>
    </S.Container>
  );
};

export default ChatBox;
