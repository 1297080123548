/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Layout from "~/components/Layout";
import Loader, { LOADER_TYPES } from "~/components/Loader";

import { useLocation } from "react-router-dom";
import useApi from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

import * as St from "./styles";

const ViewWithdraw = () => {
  const [loading, setLoading] = useState();
  const [fileBase64, setFileBase64] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const location = useLocation();

  const RequestGetPDFContractWithdraw = useApi(
    Endpoints.lendings.getPDFContractWithdraw.replace(
      "PARAM_ID",
      location.state.idEmprestimo
    ),
    "GET"
  );

  const RequestGetPDFVoucherWithdraw = useApi(
    Endpoints.lendings.getPDFVoucherWithdraw.replace(
      "PARAM_ID",
      location.state.idEmprestimo
    ),
    "GET"
  );

  const GetContractPDFWithdraw = async () => {
    setLoading(true);
    try {
      const response = await RequestGetPDFContractWithdraw.callApi();
      if (response.status >= 200 && response.status < 300) {
        setFileBase64(response.data.contentPdf);
        setLoading(false);
      }

      if (response.status >= 400) {
        setErrorMessage("Não existe contratos");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetVoucherPdfWithdraw = async () => {
    setLoading(true);
    try {
      const response = await RequestGetPDFVoucherWithdraw.callApi();
      if (response.status >= 200 && response.status < 300) {
        setFileBase64(response.data.contentPdf);
        setLoading(false);
      }

      if (response.status >= 400) {
        setErrorMessage("Não existe comprovantes");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state.idEmprestimo) {
      if (location.state.type === "voucher") {
        GetVoucherPdfWithdraw();
      } else {
        GetContractPDFWithdraw();
      }
    }
  }, [location.state.idEmprestimo]);

  return (
    <Layout showBackGroundBar={false}>
      <St.Container>
        <h1>Visualizar empréstimo</h1>
        {loading ? (
          <St.WrapperLoader>
            <Loader size={LOADER_TYPES.size.medium} />
          </St.WrapperLoader>
        ) : RequestGetPDFContractWithdraw.status === 404 ||
          RequestGetPDFVoucherWithdraw.status === 404 ? (
          <St.WrapperLoader>
            <h1>{errorMessage}</h1>
          </St.WrapperLoader>
        ) : (
          <iframe
            src={`data:application/pdf;base64, ${fileBase64}`}
            type="application/pdf"
            style={{ border: "none" }}
            title="contrato de emprestimo"
          />
        )}
      </St.Container>
    </Layout>
  );
};

export default ViewWithdraw;
