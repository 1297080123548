import React from "react";

import { FiChevronRight } from "react-icons/fi";
import * as S from "./styles";

const TypeLending = ({ title, icon, subtitle, onClick, disabled }) => {
  
  return (
    <S.Container>
      <S.TypeButton onClick={onClick} disabled={disabled}>
        <S.Icon src={icon} />
        <S.TextContainer disabled={disabled}>
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </S.TextContainer>
        <div>
          <FiChevronRight
            size="30"
            color={`${disabled ? "rgba(222, 222, 222, 1)" : "#E71037"}`}
          />
        </div>
      </S.TypeButton>
    </S.Container>
  );
};

export default TypeLending;
