/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useContext } from "react";
import Layout from "~/components/Layout";
import * as St from "./styles";
import LOAN_PRODUCTS from "../../assets/mock/loanProducts.json";
import LoanProducts from "~/components/LoanProducts";
import ContainerBox from "~/components/ContainerBox";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import OffersProposal from "~/components/OffersProposal";
import Loader, { LOADER_TYPES } from "~/components/Loader";

const LoanHome = () => {
  const [userInfo, setUserInfo] = useState([]);
  const [data, setData] = useState([]);

  const GetProposalsByEasyCredito = useApi(
    Endpoints.lendings.getLastProposalsByEasyCredito.replace(
      "PARAM_CPF",
      userInfo?.cpf
    ),
    ApiMethod.GET
  );

  useEffect(() => {
    (async () => {
      const userInfo = await JSON.parse(
        localStorage.getItem("@NovaEra:infoUsuario")
      );
      setUserInfo(userInfo);
    })();
  }, []);

  const getProposals = async () => {
    try {
      const response = await GetProposalsByEasyCredito.callApi();
      if (response.status >= 200 && response.status < 300) {
        setData(response.data);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    if (userInfo.cpf) getProposals();
  }, [userInfo.cpf]);

  return (
    <Layout showNavBar={false} showBackGroundBar={false}>
      <St.Container>
        <ContainerBox>
          <St.TitleStyle>Tuas opções de empréstimo</St.TitleStyle>
          <LoanProducts data={LOAN_PRODUCTS} />
        </ContainerBox>

        <ContainerBox styleContainer={{ marginTop: 20, marginBottom: 10 }}>
          <St.TitleStyle>
            Escolha a oferta que mais combina com você e aproveite!
          </St.TitleStyle>
          {GetProposalsByEasyCredito.loading ? (
            <St.WrapperLoader>
              <Loader size={LOADER_TYPES.size.medium} />
            </St.WrapperLoader>
          ) : data ? (
            <St.Flex>
              <OffersProposal data={data} />
            </St.Flex>
          ) : (
            []
          )}
        </ContainerBox>
      </St.Container>
    </Layout>
  );
};

export default LoanHome;
