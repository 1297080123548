
import { BIN_ELO, BIN_HIPERCARD } from "./modelsCard";
import assets from "../assets";
import { cardNumberMask } from "./mask";

export const buildCard = (numCard) => {

    return {
        numeroCartao: numCard,
        maskCartao: cardNumberMask(numCard),
        bandeira: verifyBinCard(numCard),
        iconBandeira: returnIcon(verifyBinCard(numCard)),
        binCard: numCard.substring(0, 6),
    }
}

const returnIcon = (bandeira) => {
    switch (bandeira.replace('_', ' ')) {
        case "visa":
            return assets.images.visa;
        case "mastercard":
            return assets.images.mastercard;
        case "elo":
            return assets.images.elo;
        case "hipercard":
            return assets.images.hipercard;
        case "american express":
            return assets.images.amex;
        case "diners club":
            return assets.images.diners;
        case "TeuCard":
            return assets.images.logoTeuCard;
        default:
            return assets.images.defaultCard;
    }
}

const verifyBinCard = (numCard) => {
    const binCard = numCard.substring(0, 6);

    if (binCard.length !== 6) {
        return false;
    }
    if (verifyBinElo(binCard)) {
        return 'elo';
    }

    if (verifyHiperCard(binCard)) {
        return 'hipercard';
    }

    if (binCard.substring(0, 1) === '4') {
        return 'visa';
    }

    if (binCard.substring(0, 1) === '5') {
        return 'mastercard';
    }

    if ([34, 37].includes(parseInt(binCard.substring(0, 2)))) {
        return 'american_express';
    }

    if ([36, 38].includes(parseInt(binCard.substring(0, 2)))) {
        return 'diners_club';
    }

    if ([305, 301].includes(parseInt(binCard.substring(0, 3)))) {
        return 'diners_club';
    }

    if (binCard.substring(0, 6) === '605552') {
        return 'TeuCard';
    }

    return 'default flag';
}

const verifyBinElo = (binCard) => {
    if (BIN_ELO.includes(binCard)) {
        return true;
    }

    return false;
}

const verifyHiperCard = (binCard) => {
    if (BIN_HIPERCARD.includes(binCard)) {
        return true;
    }

    return false;
}