/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "~/components/Button";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import Checkbox from "~/components/Checkbox";
import InputSelect from "~/components/InputSelect";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";

import * as S from "../../styles";
import themes from "~/themes";

const FirstScreenWelcomeLoan = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [bandeiraSelected, setBandeiraSelected] = useState("disabled");
  const [showPopup, setShowPopup] = useState(false);

  const history = useHistory();

  const handleClick = () => {
    if (bandeiraSelected === "teucard") {
      setShowPopup(true);
    } else {
      history.push("/emprestimo/dados-pessoais");
    }
  };

  const handleDisabledButton = useMemo(
    () => (bandeiraSelected !== "disabled" && termsAccepted ? false : true),
    [bandeiraSelected, termsAccepted]
  );

  const bandeiras = [
    {
      value: "disabled",
      label: "Selecione",
    },
    {
      value: "visa",
      label: "Visa",
    },
    {
      value: "mastercard",
      label: "Mastercard",
    },
    {
      value: "elo",
      label: "Elo",
    },
    {
      value: "hipercard",
      label: "Hipercard",
    },
    {
      value: "amex",
      label: "American Express",
    },
    {
      value: "teucard",
      label: "TeuCard",
    },
  ];

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        icon={themes.components.icons.warning}
        visibility={showPopup}
        text="Ops!! Empréstimo não disponível com TeuCard. Utilize um cartão bandeirado. Exemplo: Mastercard, Visa e Elo"
        buttonText="Entendi"
        setVisibility={setShowPopup}
      />
      <S.Content>
        <S.Box>
          <S.BoxBandeiras>
            <S.Bandeiras>
              <img
                src={themes.components.images.logoMasterCard}
                alt="mastercard"
              />
              <img src={themes.components.images.logoVisa} alt="visa" />
              <img
                src={themes.components.images.logoElo2}
                alt="elo"
                className="elo"
              />
              <img
                src={themes.components.images.logoHipercard}
                alt="hipercard"
                className="hipercard"
              />
              <img src={themes.components.images.logoAmex2} alt="amex" />
            </S.Bandeiras>
          </S.BoxBandeiras>
          <S.Title>
            Bem-vindo a tua área de empréstimo com cartão de crédito
          </S.Title>
          <S.MiddleText>
            <img src={themes.components.images.orangeCircle} alt="Indicador" />
            <p>
              É de extrema importância que você possua Limite de Crédito
              Disponível hoje para continuar a operação!
            </p>
          </S.MiddleText>

          <InputSelect
            type="select"
            label="Qual cartão você vai utilizar?"
            className="select"
            values={bandeiras}
            value={bandeiraSelected}
            defaultValue={bandeiraSelected}
            onChange={(e) => setBandeiraSelected(e.target.value)}
          />

          <S.Footer>
            <S.Terms>
              <Checkbox
                label="Declaro que possuo cartão de crédito com limite disponível para
                o dia de hoje."
                checked={termsAccepted}
                onChange={(e) => {
                  setTermsAccepted(e.target.checked);
                }}
              />
            </S.Terms>
            <S.Button>
              <Button disabled={handleDisabledButton} onClick={handleClick}>
                Avançar
              </Button>
            </S.Button>
          </S.Footer>
        </S.Box>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default FirstScreenWelcomeLoan;
