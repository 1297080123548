import React from 'react';

import Header from '~/components/Header';


import { Container, Content, SimpleContent } from './styles';

const LayoutLogin = ({ children, auth = true }) => (
  <Container>
    {auth && <Header />}

    {auth ? (
      <Content>
        <main>{children}</main>
      </Content>
    ) : (
      <SimpleContent>
        <main> {children} </main>
      </SimpleContent>
    )}
  </Container>
);

export default LayoutLogin;
