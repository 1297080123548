import React, { useContext, useCallback, useState, useEffect } from "react";

import Button from "~/components/Button";
import { OneButtonPopup } from "~/components/Modals/OneButtonPopup";

import * as S from "../styles";
import { cpfMask } from "~/utils/mask";
import themes from "~/themes";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import { useHistory } from "react-router-dom";
import InputOnboarding from "~/components/InputOnboarding";
import { Alert } from "antd";
import { getUserIdentification } from "~/services/user";
import { cpfUnmask } from "~/utils/masksNew";
import {
  sendingSMSByCPF,
  validatingCodeCPF,
} from "~/services/onboardingService";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { GlobalContext } from "~/contexts/GlobalState";

const VerificationCodeIfExists = () => {
  const history = useHistory();

  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [emailUser, setEmailUser] = useState([]);
  const [codeIsValid, setCodeIsValid] = useState(false);
  const [verifyCodeMessageError, setVerifyCodeMessageError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const { onboarding, addOnboarding } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [popupErrorMesssage, setPopupErrorMesssage] = useState("");
  const [confirmationPopupVisibility, setOneButtonPopupVisibility] =
    useState(false);
  const [textPopup, setTextPopup] = useState("");
  const [chronometer, setChronometer] = useState(60);
  const [chronometerInterval, setChronometerInterval] = useState(0);
  const [resendButtonIsDisabled, setResendButtonIsDisabled] = useState(false);
  const [countResendSMS, setCountResendSMS] = useState(0);
  const document = onboarding.cpf;

  const userData = async () => {
    try {
      const dadosCPF = sessionStorage.getItem("cpfSession");
      const dadosCelular = sessionStorage.getItem("celularSession");
      const retCpf = cpfUnmask(dadosCPF);
      const response = await getUserIdentification(retCpf);
      setEmailUser(response.data.email);
    } catch (err) {
      console.log(err);
    }
  };

  const codeInputHandler = (event) => {
    setCode(cpfMask(event.target.value));
    setCodeIsValid(true);
  };

  const PostAddClientProposal = useApi(
    Endpoints.proposta.postAddWalletProposal,
    ApiMethod.POST
  );

  const POSTSendSMSWithWhatsApp = useApi(
    Endpoints.wallet.postSendWhatsappCode,
    ApiMethod.POST
  );

  const validatingCode = async () => {
    const requestBody = {
      cpf: onboarding.cpf,
      numeroGerado: code?.replace(".", ""),
    };
    const payload = {
      cpf: onboarding.cpf,
      idProduto: onboarding.idProduto,
    };
    const response = await validatingCodeCPF(requestBody);
    if (response.status >= 200 && response.status < 300) {
      try {
        const response = await PostAddClientProposal.callApi(payload);
        if (response.status >= 200 && response.status < 300) {
          history.push("/onboarding-validacao-sucesso");
        } else {
          setTextPopup(
            response.mensagem
              ? response.mensagem
              : "Erro ao criar um novo usuário, tente novamente."
          );
          setOneButtonPopupVisibility(true);
        }
      } catch (error) {
        console.error(error.message);
      }
    } else {
      setShowPopup((prev) => !prev);
      setVerifyCodeMessageError(response.data.mensagem);
    }
  };

  const sendNotification = async () => {
    const response = await sendingSMSByCPF(
      document.replaceAll(".", "").replaceAll("-", "")
    );
  };
  const sendCodeByWhatsapp = async () => {
    const response = await POSTSendSMSWithWhatsApp.callApi({
      cpf: document.replaceAll(".", "").replaceAll("-", ""),
    });
  };

  useEffect(() => {
    async function SendPush() {
      try {
        await sendCodeByWhatsapp();
        handleDisableButtonResendSMS();
      } catch (error) {
        setPopupErrorMesssage("Falha ao enviar SMS!");
        setPopUpIsOpen(true);
      }
    }
    SendPush();
  }, []);

  useEffect(() => {
    if (countResendSMS === 4) {
      reSendSMS();
    }
  }, [countResendSMS]);

  const reSendSMS = useCallback(async () => {
    if (resendButtonIsDisabled) return;

    if (countResendSMS === 3) {
      setPopupErrorMesssage(
        "Não é possível reenviar mais de 3 vezes o SMS! Verifique se o celular está correto e tente novamente."
      );
      setPopUpIsOpen(true);
    }

    handleDisableButtonResendSMS();
    setIsLoading(true);

    try {
      const response = await sendNotification();

      if (response.status >= 200 && response.status < 300) {
        setPopupErrorMesssage("SMS re-enviado com sucesso!");
      } else {
        setPopupErrorMesssage("Falha ao re-enviar SMS!");
      }
    } catch (err) {
      setPopupErrorMesssage("Falha ao re-enviar SMS!");
    } finally {
      setIsLoading(false);
      setPopUpIsOpen(true);
      setCountResendSMS((prev) => ++prev);
    }
  });

  useEffect(() => {
    if (chronometer === 0) {
      clearInterval(chronometerInterval);
      setChronometer(60);
    }
  }, [chronometer, chronometerInterval]);

  const handleDisableButtonResendSMS = () => {
    setResendButtonIsDisabled(true);
    setTimeout(() => setResendButtonIsDisabled(false), 60000);
    const interval = setInterval(() => setChronometer((prev) => --prev), 1000);
    setChronometerInterval(interval);
  };

  useEffect(() => {
    userData();
  }, [emailUser]);

  return (
    <LayoutOnboarding showBackGroundBar={false}>
      <OneButtonPopup
        slug="/onboarding-validar-cpf"
        icon={themes.components.icons.warning}
        text={verifyCodeMessageError}
        buttonText="Entendi"
        visibility={showPopup}
        setVisibility={setShowPopup}
      />
      <OneButtonPopup
        slug="/onboarding-validar-cpf"
        icon={themes.components.icons.warning}
        text={textPopup}
        buttonText="Entendi"
        visibility={confirmationPopupVisibility}
        setVisibility={setOneButtonPopupVisibility}
      />
      <S.Content>
        <S.MainText>
          <p>Criar carteira digital</p>
        </S.MainText>
        <S.MiddleText>
          <p>Digite o código enviado por WhatsApp</p>
        </S.MiddleText>
        <S.Input>
          <InputOnboarding
            type="text"
            label="Código de verificação"
            maxLength={8}
            value={code}
            onChange={codeInputHandler}
            error={error}
          />
        </S.Input>
        <S.MiddleText>
          <S.ResendCode disabled={resendButtonIsDisabled}>
            <p className="help-message">
              {" "}
              Não recebeu o WhatsApp? Você pode{" "}
              <span onClick={reSendSMS}>reenviar</span>.{" "}
              {chronometer !== 60 && `Aguarde ${chronometer}.`}
            </p>
          </S.ResendCode>
        </S.MiddleText>
        <S.Alert>
          <Alert
            message="Caso não receba o código no teu WhatsApp, o reenvio será feito por SMS."
            type="info"
            showIcon
          />
        </S.Alert>
        <S.Footer>
          <Button
            style={{ cursor: `${!codeIsValid ? "default" : "pointer"}` }}
            disabled={!codeIsValid}
            onClick={validatingCode}
          >
            Continuar
          </Button>
        </S.Footer>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default VerificationCodeIfExists;
