import styled from "styled-components";

import themes from "../../themes";

export const Container = styled.header`
  width: 100%;
  height: 90px;
  background: #f9f2f4;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;

  div {
    img {
      width: 150px;
      height: auto;
      cursor: pointer;
    }

    span {
      color: #e71037;
      font-weight: 800;
      font-size: 0.7rem;
    }
  }
`;

export const HeaderLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    display: flex;
    align-items: center;

    color: ${themes.components.colors.link};
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    letter-spacing: 0;
    line-height: 23px;

    svg {
      margin-left: 8px;
      color: ${themes.components.colors.link};
    }
  }

  button {
    margin-left: 64px;
    color: ${themes.components.colors.link};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;

    background: transparent;
    border: 0;
  }
`;
