/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Layout from "~/components/Layout";
import TransactionsLending from "../../Lending/components/TransactionsLending";
import Button from "~/components/Button";
import Loader from "~/components/Loader";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

import * as S from "./styles";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import themes from "~/themes";

const LendingExtractByTeuCard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const userInfoArray = JSON.parse(
    localStorage.getItem("@NovaEra:infoUsuario")
  );

  const GetClientByCPF = useApi(
    Endpoints.cliente.getSearchClientWithdrawPersonalData.replace(
      "PARAM_CPF",
      userInfoArray.cpf
    ),
    ApiMethod.GET
  );

  const getLending = useApi(
    Endpoints.lendings.getLending
      .replace("PARAM_CPF", userInfoArray.cpf)
      .replace("PARAM_TIPO", "CELCOIN"),
    ApiMethod.GET
  );

  const GetLending = async () => {
    setLoading(true);
    try {
      const response = await getLending.callApi();
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getUser = async () => {
    setLoading(true);
    try {
      const response = await GetClientByCPF.callApi();
      setUser(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetLending();

    getUser();
  }, []);

  const handleNewLoan = () => {
    let hasPendingLoan = false;

    for (const loan of data) {
      if (loan.status !== "APROVADO" && loan.status !== "REJEITADO") {
        setErrorMessage(
          <div style={{justifyContent: "center", alignItems: "center", textAlign: "center"}}><strong>Ops...! Identificamos que existe um empréstimo em andamento.<br/></strong><p>Acompanhe na tua área de empréstimos."</p></div>
        );
        setOpen(true);
        hasPendingLoan = true;
        break;
      }
    }

    if (!hasPendingLoan) {
      history.push("/emprestimo/bem-vindo");
    }
  };

  return (
    <Layout
      showLendingIcon
      onlyLendingIcon
      showDocumentIcon={false}
      showBackGroundBar={false}
      productType="Emprestimo"
    >
        <OneButtonPopup
          icon={themes.components.images.warningLending}
          imgIcon={true}
          text={errorMessage}
          buttonText="Entendi"
          visibility={open}
          onClick={() => history.push("/loan-home")}
          setVisibility={open}
          slug="/loan-home"
        />
      <S.ContentScreens>
        <h1>Teus Empréstimos</h1>
        {loading ? (
          <S.WrapperLoader>
            <Loader size="large" />
          </S.WrapperLoader>
        ) : (
          <>
            <S.LendingBox>
              {data?.map((item, id) => (
                <TransactionsLending
                  key={id}
                  amount={item.valorLiberado}
                  id={item.id}
                  typeSupplier={item.tipoFornecedor}
                  user={user}
                  date={item.dataEnvio}
                  motivo={item.motivoStatusEmprestimo}
                  status={item.statusNovoSaque || item.status}
                  statusCelcoin={item.descricaoStatusEmprestimo}
                  title={
                    item.tipoFornecedor === "NOVO_SAQUE"
                      ? "Empréstimo com cartão de crédito"
                      : "Empréstimo com cartão TeuCard"
                  }
                  isSigned={item?.assinatura}
                />
              ))}
              {data.length < 0 && (
                <S.EmptyText>Nenhum empréstimo encontrado!</S.EmptyText>
              )}
            </S.LendingBox>
            <S.Footer>
              <div>
                <Button
                  isLoading={loading}
                  onClick={() => handleNewLoan()}
                >
                  Quero simular novamente!
                </Button>
              </div>
            </S.Footer>
          </>
        )}
      </S.ContentScreens>
    </Layout>
  );
};

export default LendingExtractByTeuCard;
