import React, { useState, useEffect, useCallback } from "react";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";

import useWebSocket from "react-use-websocket";
import { useHistory } from "react-router-dom";

import * as St from "./../styles";
import { GlobalContext } from "~/contexts/GlobalState";
import { useContext } from "react";
import themes from "~/themes";

const SearchProposalByLendingSearch = () => {
  const [status, setStatus] = useState("");
  const [time, setTime] = useState(59);
  const { lending } = useContext(GlobalContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [idEmprestimo, setIdEmprestimo] = useState(lending?.idSimulacao);
  const { user } = useContext(GlobalContext);

  const history = useHistory();

  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      resolve(
        `${process.env.REACT_APP_WEBSOCKET_PAYMENT}/v1/websockets/emprestimos/buscador/${idEmprestimo}`
      );
    });
  }, [idEmprestimo]);

  const { lastMessage } = useWebSocket(getSocketUrl, {
    onOpen: () => console.log("opened"),
    onClose: () => console.log("closed"),
  });

  useEffect(() => {
    (async () => {
      if (lastMessage && lastMessage.data !== "_ready_") {
        console.log(lastMessage);
        const jsonString = lastMessage?.data;
        const parsedObject = await JSON.parse(jsonString);
        setStatus(parsedObject?.status);
      }
    })();
  }, [lastMessage]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }

      if (time < 2) {
        setOpenDialog(true);
        setMessage(
          "Aguarde mais alguns minutos e consulte novamente! Estamos buscando as melhores ofertas para você."
        );
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  useEffect(() => {
    if (status === "PROPOSAL_CREATED") {
      history.push("/proposal-result-loan-seeker");
    }
  }, [status]);

  return (
      <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        icon={themes.components.images.successValidation}
        text={message}
        buttonText="Entendi"
        visibility={openDialog}
        setVisibility={setOpenDialog}
        imgIcon={true}
        onClick={() => history.push("/loanHome")}
        slug={"/loanHome"}
      />
      <St.Content>
        <St.BoxContent>
          <St.Title>Propostas de empréstimo</St.Title>
          <St.Subtitle>
            Estamos buscando as melhores ofertas de crédito.
          </St.Subtitle>
          <St.Column>
          <St.Title style={{textAlign: "center"}}>
            Olá,{user?.nome}.<br /> 
            Agora
            estamos analisando o teu perfil <br />e buscando as propostas disponíveis.
          </St.Title>
          <St.Spinner>
            <h2 className="text">{`${
              time < 10 ? `00:0${time}` : `00:${time}`
            }`}</h2>
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </St.Spinner>
        <St.MiddleContainer>
          <St.Subtitle>
            Atenção! <b>NÃO</b> solicitamos depósito antecipado para a liberação
            de empréstimos e outros produtos.
          </St.Subtitle>
        </St.MiddleContainer>
        </St.Column>
        </St.BoxContent>
      </St.Content>
      </LayoutOnboarding>
  );
};

export default SearchProposalByLendingSearch;
