import styled from "styled-components";
import themes from "~/themes";

export const Content = styled.div`
  padding: 30px 49px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1100px) {
    padding: 10px;
  }

  @media (max-width: 600px) {
    padding: 0px;
  }
`;

export const Box = styled.div`
  display: flex;
  border-radius: 5px;
  flex: 1;
  gap: 50px;
  flex-direction: column;
  align-items: center;
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
  padding: 40.5px 46.5px;
  height: auto;
  width: 100%;
  min-height: 650px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: #4a4b4a;
  text-align: ${(props) => props.align};
  width: 42%;
  line-height: 40.52px;
  font-family: ${themes.components.fonts.marineRoundedBold};
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Banner = styled.img`
  width: 45%;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  color: #4a4b4a;
  font-family: ${themes.components.fonts.marineRoundedRegular};
`;

export const Steps = styled.span`
  font-size: 14px;
  color: #4a4b4a;
  font-weight: bold;
  margin-top: 10px;
  font-family: ${themes.components.fonts.marineRoundedBold};
`;

export const MiddleText = styled.div`
  display: flex;
  justify-content: center;
  width: 20%;
  margin-top: -50px;

  p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  img {
    width: 8px;
    height: 8px;
    position: relative;
    top: 2px;
    right: 14px;
  }

  @media (max-width: 1200px) {
    p {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }
  }

  @media (max-width: 1100px) {
    p {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }
  }

  @media (max-width: 600px) {
    p {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }
  }
`;

export const BoxType = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: 20px;
  flex: 0.8;
  gap: 20px;

  @media (max-width: 600px) {
    margin-top: 10px;
    gap: 10px;
  }
`;

export const PDFContainer = styled.div`
  margin-top: 29px;
  height: 500px;
  box-shadow: 5px 6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
`;

export const Form = styled.form`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
  padding-bottom: 10px;
`;

export const BoxContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
  padding: 40.5px 46.5px;
  height: auto;
`;
export const WrapperCamera = styled.div`
  display: flex;
  align-items: center;
  min-height: 600px;
  width: 42.5vw;
  height: 81vh;
  z-index: 10000;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  text-align: center;
`;

export const Terms = styled.div`
  margin-top: 54px;
`;

export const WrapperLoader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  align-items: center;
  width: 100%;
  height: 100%;
  min-height: ${(props) => (props.minHeight ? props.minHeight : "650px")};

  span {
    width: 30%;
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
    line-height: 27px;
    word-wrap: break-word;
    font-family: ${themes.components.fonts.marineRoundedBold};
  }
`;
export const Column = styled.div`
  margin-top: 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    padding-right: 0px;
    padding-left: 0;
  }

  @media (max-width: 1100px) {
    margin-bottom: 50px;
  }
`;

export const ContentValue = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: ${(props) => props.borderBottom};
`;

export const Value = styled.div`
  h1 {
    font-size: 42px;
    word-wrap: break-word;
    color: #424242;
    line-height: 36px;
    font-family: ${themes.components.fonts.marineRoundedBold};
  }
`;

export const SliderContainer = styled.div`
  margin-top: 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Slider = styled.div`
  width: 50%;
  gap: 40px;
`;

export const Row = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justify};
  gap: 10px;

  span {
    font-size: 18px;
    line-height: 21px;
    word-wrap: break-word;
  }

  img {
    width: 8px;
    height: 8px;
    top: 10px;
    position: relative;
  }
`;

export const BoxSuccess = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  gap: 20px;
  margin-bottom: 40px;
`;

export const ImageLogo = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  width: 20%;
  height: 25%;

  > img {
    flex: 1;
    display: flex;
    align-self: center;
  }

  @media (max-width: 760px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  align-items: center;
  justify-content: center;
  width: 47%;
  text-align: center;

  span {
    font-size: 22px;
  }
`;

export const TypeContainer = styled.div`
  margin-top: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 20px;

  @media (max-width: 600px) {
    margin-top: 10px;
    gap: 10px;
  }
`;

export const ImageSelfie = styled.img`
  width: 220px;
  height: 220px;
`;

export const BoxDescription = styled.div`
  width: 40%;
  height: 161px;
  border-radius: 8px;
  background: #f7f7f7;
  display: grid;
  gap: 8px;
  padding: 16px;
`;

export const RowDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;

  span {
    font-weight: 400;
    line-height: 21px;
    color: #4a4b4a;
    font-family: ${themes.components.fonts.marineRoundedRegular};
  }
`;

export const Submit = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  align-items: center;
  justify-content: center;

  span {
    font-size: 16px;
    font-weight: 700;
    color: #e71037;
    line-height: 26px;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.align};
  align-items: ${(props) => props.align};
  margin-top: 20px;
  gap: 20px;

  div {
    width: 40%;
  }
`;
