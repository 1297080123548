import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 380px;

  .DayPicker {
    background: #FFF;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
  }
  
  .DayPicker-NavBar{
    position: absolute;
    right: 0;
    top: 4px;

    .DayPicker-NavButton{
      top: 0!important;
    }
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
    margin: 4px 0 0;
    padding: 0 24px;
  }

  .DayPicker-Month {
    border-spacing: 8px;
  }

  .DayPicker-Day {
    width: 60px;
    height: 50px;
    color: #727272;
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: #666360 !important;
    border-radius: 0px;
    background: rgba(0,0,0,0.06) !important;
  }

  .DayPicker-Day--selectedRange{
    color: #FFF !important;
    border-radius: 0px;
    background-color: rgba(255,130,47,0.4)!important;
  }

  .DayPicker-Day--selectedRangeStart, .DayPicker-Day--selectedRangeEnd{
    border-radius: 4px;
    background-color: #E71037!important;
  }

  .DayPicker-Day--selectedRangeStart{
    border-radius: 4px 0 0 4px;
  }
  
  .DayPicker-Day--selectedRangeEnd{
    border-radius: 0 4px 4px 0;
  }
`;

export const CalendarHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;
  height: 100px;

  margin-bottom: 5px;
  background-color: #E71037;

  h6 {
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 19px;
  }

  > div {
    display: flex;
    margin-top: 10px;

    div:first-child {
      margin-right: 20px;
    }
  }
`;


export const CalendarHeaderField = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: #FFFFFF;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }

  p {
    min-width: 98px;
    color: #FFFFFF;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;

    border-bottom: 1px solid #FFFFFF;
  }
`;