import styled from "styled-components";
import themes from "~/themes";

export const Container = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin-top: 22px;

  a {
    align-self: center;
    text-decoration: none;
    color: #4A4B4A;

    font-size: 16px;
    letter-spacing: 0;
    line-height: 23px;
  }
`;

export const DateStyle = styled.p`
  color: ${themes.components.colors.transaction.primary};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 23px;
`;

export const TransactionsDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const Transaction = styled.div`
cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    display: flex;
    width: 60px;

    svg {
      margin: 0 auto;
      
      box-shadow: 4px 3px 8px 0 rgba(127, 127, 127, 0.5);
      border-radius: 10px;
      padding: 10px;


      path {
        //fill: ${themes.components.colors.link};
      }
    }
  }
`;

export const TransactionDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: ${(props) => (props.decoration ? "line-through" : "")};
  strong {
    color: ${(props) =>
    props.isPayment = themes.components.colors.transaction.primary};
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 400;
    line-height: 23px;
  }

  span {
    color: ${(props) =>
    props.isPayment = themes.components.colors.transaction.secondary};
    font-size: 12px;
    letter-spacing: 0;
    line-height: 23px;
  }
`;

export const TransactionMoeda = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;

  > span {
    color: ${themes.components.colors.transaction.tertiary};
    font-size: 9px;
    letter-spacing: 0;
    line-height: 23px;
  }
`;

export const TransactionValue = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  height: 35px;
  text-decoration: ${(props) => (props.decoration ? "line-through" : "")};

  span,
  strong {
    color: ${(props) =>
    props.green ? "#32CD32" : themes.components.colors.transaction.tertiary};
    letter-spacing: 0;
    line-height: 23px;
  }

  span {
    font-size: 10px;
    align-self: flex-end;
    margin-right: 6px;
  }

  svg {
      margin: 0 auto;


      path {
        //fill: ${themes.components.colors.link};
      }
    }

  strong {
    font-size: 16px;
    font-weight: 00;
    margin-right: 1px;
    align-self: flex-end;
  }
`;

export const LineHorizontal = styled.div`
  width: 60px;
  height: 28px;

  span {
    display: block;
    width: 1px;
    height: inherit;
    background: ${themes.components.colors.transaction.line};
    margin: 0 auto;
  }
`;

export const DivAlign = styled.div`
  display: ${(props) => (props.displayMore ? "block" : "none")};
  text-align: center;
`;

export const CardClientInfo = styled.div`
  display: flex;
  flex-direction: column;

  height: auto;
  margin-top: 22px;
`;

export const KnowMore = styled.span`
  margin-left: 10px;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  border: .1px solid #B4B4B4;
  text-align: center;
  width: auto;
  border-radius: 5px;

  
`;
