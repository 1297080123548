/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import useWebSocket from "react-use-websocket";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import ReactLoading from "react-loading";

import { GlobalContext } from "~/contexts/GlobalState";

import * as St from "../../styles";
import themes from "~/themes";

const TermsLending = () => {
  const [idEmprestimo, setIdEmprestimo] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [callWebSocketTimeOut, setCallWebSocketTimeOut] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { addLending, lending } = useContext(GlobalContext);

  setTimeout(() => setCallWebSocketTimeOut(true), 20000);

  const userInfoArray = JSON.parse(
    localStorage.getItem("@NovaEra:infoUsuario")
  );

  const history = useHistory();

  const { state } = useLocation();

  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      if (lending?.idSimulacao) {
        resolve(
          `${process.env.REACT_APP_WEBSOCKET_PAYMENT}/v1/websockets/emprestimos/saque/coleta-assinatura/${lending?.idSimulacao}`
        );
      }
    });
  }, [lending?.idSimulacao]);

  const { lastMessage } = useWebSocket(getSocketUrl, {
    onOpen: () => console.log("opened"),
    onClose: () => console.log("closed"),
  });

  useEffect(() => {
    if (callWebSocketTimeOut) {
      setError(true);
      setErrorMessage(
        "O teu contrato está sendo preparado e logo ficará disponível! Aguarde alguns segundos e consulte a tua área de empréstimos. Ou procure atendimento em uma das lojas Nova Era."
      );
    }
  }, [callWebSocketTimeOut]);

  useEffect(() => {
    if (
      (status === "PENDING_SIGNATURE" && idEmprestimo) ||
      state?.status === "ASSINANDO"
    ) {
      if (state?.status === "ASSINANDO") {
        addLending({
          statusSignature: status,
          cpf: userInfoArray?.cpf,
          dataNascimento: state?.user?.dataNascimento,
          nome: state?.user?.nome,
          idSimulacao: state?.idEmprestimoState,
        });
        history.push("/emprestimo/aceite");
      }
      history.push("/emprestimo/aceite");
    }
  }, [status, idEmprestimo]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (lastMessage && lastMessage.data !== "_ready_") {
        const jsonString = lastMessage?.data;
        const parsedObject = await JSON.parse(jsonString);
        setStatus(parsedObject?.status);
        setIdEmprestimo(parsedObject?.idEmprestimo);
        setLoading(false);
      }
    })();
  }, [lastMessage]);

  // const verifyReloadPage = () => {
  //   if (lending?.cpf === "" && !state?.user) {
  //     history.push("/");
  //   }
  // };

  // useEffect(() => {
  //   verifyReloadPage();
  // }, [verifyReloadPage]);

  const errorMessageContract = () => {
    return (
      <div>
        <strong style={{ textAlign: "center" }}>
          O teu contrato está sendo preparado e logo ficará disponível!
        </strong>
        <br /> {/* Adicionando um salto de linha */}
        <p style={{ textAlign: "center" }}>
          Aguarde alguns segundos e consulte a tua área de empréstimos. Ou
          procure atendimento em uma das lojas Nova Era.
        </p>
      </div>
    );
  };

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        icon={themes.components.images.successValidation}
        text={errorMessageContract()}
        buttonText="Entendi"
        visibility={error}
        setVisibility={setError}
        imgIcon={true}
        onClick={() => history.push("/home")}
        slug={"/home"}
      />
      <St.Content>
        <St.BoxContent>
          <St.Title>Termos do empréstimo</St.Title>
          <St.Subtitle>
            Estamos preparando os termos do teu empréstimo.
          </St.Subtitle>
          <St.Steps>Passo 7 de 11</St.Steps>
          {loading && (
            <St.WrapperLoader minHeight="500px">
              <span>
                Aguarde alguns segundos. Estamos processando teus dados!
              </span>
              <ReactLoading
                type="spinningBubbles"
                color="rgba(241, 147, 52, 0.80)"
                width={"20%"}
                height={"20%"}
              />
            </St.WrapperLoader>
          )}
        </St.BoxContent>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default TermsLending;
