import colors from "./colors";
import assets from "~/assets";
import { login } from "~/services/authentication";

const components = {
  colors: {
    buttonText: colors.white.n1,
    button: colors.orange.n2,
    font: colors.black.n1,
    navItem: colors.gray.n3,
    link: colors.orange.n2,
    newLink: colors.black.n1,
    label: colors.gray.n5,
    input: colors.black.n1,
    messageError: colors.red.n1,
    cardText: colors.white.n2,
    transaction: {
      primary: colors.gray.n2,
      secondary: colors.gray.n4,
      tertiary: colors.red.n1,
      line: colors.white.n4,
    },
    limitBar: colors.green.n1,
  },
  images: {
    avatar: assets.images.avatar,
    logo: assets.images.logo,
    logoHorizontal: assets.images.logoHorizontal,
    logoHorizontalTeuCard: assets.images.logoHorizontalTeuCard,
    logoTeuCard: assets.images.logoTeuCard,
    logoTeuCred: assets.images.logoTeuCred,
    logoTeuCred2: assets.images.logoTeuCred2,
    logoTeuCardBranco: assets.images.logoTeuCardBranco,
    logoTeuCredTransparent: assets.images.logoTeuCredTransparent,
    logoTeuCashWhite: assets.images.logoTeuCashWhite,
    emojisLogin: assets.images.emojisLogin,
    defaultCard: assets.images.defaultCard,
    cardBackground: assets.images.cardBackground,
    cardBackgroundTeuCard: assets.images.cardBackgroundTeuCard,
    creditCardLogoBlue: assets.images.creditCardLogoBlue,
    creditCardLogoRose: assets.images.creditCardLogoRose,
    creditCardLogoWhite: assets.images.creditCardLogoWhite,
    logoCarteiraDigital: assets.images.logoCarteiraDigital,
    warningLending: assets.images.warningLending,
    banner_card: assets.images.banner_card,
    cardLogo: assets.images.cardLogo,
    warning: assets.images.warning,
    visible: assets.images.visible,
    invisible: assets.images.invisible,
    teuCardBlack: assets.images.teuCardBlack,
    selfieDesktop: assets.images.selfieDesktop,
    pesquisarFatura: assets.images.pesquisarFatura,
    successValidation: assets.images.successValidation,
    errorValidation: assets.images.errorValidation,
    orangeCircle: assets.images.orangeCircle,
    rgFront: assets.images.rgFront,
    rgBack: assets.images.rgBack,
    selfieCam: assets.images.selfieCam,
    selfieBlack: assets.images.selfieBlack,
    cameraSelfieDesktop: assets.images.cameraSelfieDesktop,
    creditCardBackGround: assets.images.creditCardBackGround,
    semCartao: assets.images.semCartao,
    banner2Web: assets.images.banner2Web,
    newInformative: assets.images.newInformative,
    linkViewBackground: assets.images.linkViewBackground,
    logoVisa: assets.images.visa,
    logoMasterCard: assets.images.mastercard,
    logoElo: assets.images.elo,
    logoElo2: assets.images.elo2,
    logoDiners: assets.images.diners,
    logoDiners2: assets.images.diners2,
    logoHipercard: assets.images.hipercard,
    logoAmex: assets.images.amex,
    logoAmex2: assets.images.amex2,
    parcela: assets.images.parcela,
    saque: assets.images.saque,
    limite: assets.images.limite,
    creditCardLending: assets.images.creditCardLending,
    teuCardLending: assets.images.teuCardLending,
    selfieLending: assets.images.selfieLending,
    checkOrange: assets.images.checkOrange,
    star: assets.images.star,
    ornament: assets.images.ornament,
    infoLogoEmprestimo: assets.images.infoLogoEmprestimo,
    eyeHome: assets.images.eyeHome,
    blindHome: assets.images.blindHome,
    searchProposal: assets.images.searchProposal,
    companysEC: assets.images.companysEC,
    loanSearch: assets.images.loanSearch,
    checkGreen: assets.images.checkGreen,
    onboardingWalletNoAuth: assets.images.onboardingWalletNoAuth,

  },

  icons: { ...assets.icons },

  fonts: {
    marineRoundedBlack: assets.fonts.marineRoundedBlack,
    marineRoundedBold: assets.fonts.marineRoundedBold,
    marineRoundedRegular: assets.fonts.marineRoundedRegular,
  },
};

export default components;
