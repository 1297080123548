import React from 'react';

import NavBar from '~/components/NavBar';
import Header from '~/components/Header';

import { Container, Content, SimpleContent } from './styles';

const LayoutHome = ({ children, auth = true }) => (
  <Container>
    {auth && <Header />}

    {auth ? (
      <Content>
        <NavBar />
        <main> {children} </main>
      </Content>
    ) : (
      <SimpleContent>
        <main> {children} </main>
      </SimpleContent>
    )}
  </Container>
);

export default LayoutHome;
