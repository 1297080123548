/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import Input from "~/components/Input";
import Button from "~/components/Button";
import Loader, { LOADER_TYPES } from "~/components/Loader";

import { cpfMask, dateMask, phoneMask } from "~/utils/masksNew";

import * as S from "./styles";
import themes from "~/themes";
import ContainerBox from "~/components/ContainerBox";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { CustomizedError } from "~/utils/errors";

const PersonalData = () => {
  const [contentData, setContentData] = useState([]);
  const [userPicture, setUserPicture] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [edit, setEdit] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));

  const inputImageRef = useRef();
  
  const infoUSer = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));

  const RequestPutDataUser = useApi(
    Endpoints.autorizador.dataUserPUT.replace("PARAM_DATA", infoUSer.id),
    ApiMethod.PUT
  );

  const RequestGetClientByCPF = useApi(
    Endpoints.cliente.getClientByCPF.replace("PARAM_CPF", user?.cpf),
    "GET"
  );

  const RequestGetUserPicture = useApi(
    Endpoints.usuario.getUserPicture.replace("PARAM_PICTURE", infoUSer.id),
    ApiMethod.GET
  );

  const RequestEditUserPicture = useApi(
    Endpoints.usuario.postEditUserPicture,
    ApiMethod.POST
  );

  useEffect(() => {
    getUserInfo();
    handleGetUserPicture();
  }, []);

  useEffect(() => {
    if (inputImageRef.current) {
      inputImageRef.current.addEventListener("change", convertToBASE64);
    }
  }, [edit]);

  useLayoutEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
    if (userInfo?.fotoPerfil) {
      setUserPicture(`data:image/png;base64,${userInfo.fotoPerfil}`);
    }
  }, []);

  const GetUserPicture = async () => {
    const response = await RequestGetUserPicture.callApi();

    switch (response.status) {
      case 200:
        const userInfo = JSON.parse(
          localStorage.getItem("@NovaEra:infoUsuario")
        );
        localStorage.setItem(
          "@NovaEra:infoUsuario",
          JSON.stringify({
            ...userInfo,
            fotoPerfil: response.data.usuarioFotoPerfil,
          })
        );

        return { status: 200, data: response.data };
      default:
        throw CustomizedError({ message: response.status });
    }
  };

  const handleGetUserPicture = async () => {
    GetUserPicture().then((response) => {
      if (response.data.usuarioFotoPerfil) {
        setUserPicture(
          `data:image/png;base64,${response.data.usuarioFotoPerfil}`
        );
      }
    });
  };

  async function getUserInfo() {
    setIsLoading(true);
    try {
      const response = await RequestGetClientByCPF.callApi();

      setUserInfo(response.data);

      const ret = cpfMask(response.data.cpf);
      const retPho = phoneMask(response.data.celular);
      const retDta = dateMask(
        moment(response.data.dataNascimento).format("DD/MM/YYYY")
      );
      setContentData([
        { id: 0, title: "Nome", value: response.data.nome, route: false },
        { id: 1, title: "CPF", value: ret.masked, route: false },
        {
          id: 2,
          title: "Celular",
          value: retPho.masked,
          route: false,
        },
        {
          id: 3,
          title: "E-mail",
          value: response.data.email,
          route: false,
        },
        {
          id: 4,
          title: "Data de nascimento",
          value: retDta.masked,
          route: false,
        },
        {
          id: 5,
          title: "Endereço",
          value: response.data.endereco
            ? `${response.data.endereco?.logradouro}, ${response.data.endereco?.numero} ${response.data.endereco?.bairro}, ${response.data.endereco?.cidade} - ${response.data.endereco?.uf}, ${response.data.endereco?.cep}`
            : "",
          route: false,
        },
      ]);
    } catch (err) {
      console.error(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  function handleClick() {
    setEdit(true);
  }

  async function handleSubmitEdit() {
    setButtonIsLoading(true);
    const body = {
      celular: userInfo.celular,
      email: userInfo.email,
      endereco: {
        bairro: userInfo.endereco?.bairro,
        cep: userInfo.endereco?.cep,
        cidade: userInfo.endereco?.cidade,
        complemento: userInfo.endereco?.complemento,
        logradouro: userInfo.endereco?.logradouro,
        numero: userInfo.endereco?.numero,
        pais: userInfo.endereco?.pais,
        uf: userInfo.endereco?.uf,
      },
    };
    await RequestPutDataUser.callApi(body);
    setButtonIsLoading(false);
  }

  const editUserPicture = async (usuarioFoto) => {
    const infoUser = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));

    const response = await RequestEditUserPicture.callApi({
      id: infoUser.id,
      usuarioFoto,
    });

    switch (response.status) {
      case 200:
        return { status: 200 };
      default:
        throw CustomizedError({ message: response.status });
    }
  };

  const handleUploadPicture = async (image) => {
    await editUserPicture(image);
  };

  const onClickEditPicture = () => inputImageRef.current.click();

  const convertToBASE64 = function () {
    if (this.files && this.files[0]) {
      var FR = new FileReader();
      FR.addEventListener("load", function (e) {
        setUserPicture(e.target.result);

        const onlyBASE64 = e.target.result.split(",")[1];
        handleUploadPicture(onlyBASE64);
      });
      FR.readAsDataURL(this.files[0]);
    }
  };

  const handleChange = ({ target: { name: key, value } }) =>
    setUserInfo((prev) => ({
      ...prev,
      endereco: { ...prev.endereco, [key]: value },
    }));

  return (
    <S.Container>
      {edit ? (
        <S.ContainerInput>
          <Input
            type="text"
            label="CEP"
            name="cep"
            value={userInfo.endereco?.cep}
            onChange={handleChange}
          />
          <Input
            type="text"
            name="logradouro"
            label="Logradouro"
            value={userInfo.endereco?.logradouro}
            onChange={handleChange}
          />
          <Input
            type="text"
            name="numero"
            label="Numero"
            value={userInfo.endereco?.numero}
            onChange={handleChange}
          />
          <Input
            type="text"
            name="bairro"
            label="Bairro"
            value={userInfo.endereco?.bairro}
            onChange={handleChange}
          />
          <Input
            type="text"
            name="complemento"
            label="Complemento"
            value={userInfo.endereco?.complemento}
            onChange={handleChange}
          />
          <Input
            type="text"
            name="cidade"
            label="Cidade"
            value={userInfo.endereco?.cidade}
            onChange={handleChange}
          />
          <Input
            type="text"
            name="uf"
            label="UF"
            value={userInfo.endereco?.uf}
            onChange={handleChange}
          />
          <Input
            type="text"
            label="Pais"
            name="pais"
            value={userInfo.endereco?.pais}
            onChange={handleChange}
          />

          <Button onClick={handleSubmitEdit} isLoading={buttonIsLoading}>
            Salvar
          </Button>
        </S.ContainerInput>
      ) : (
        <ContainerBox>
          <S.Container>
            <S.ImageUser>
              <input
                type="file"
                accept="image/*"
                id="input-image"
                ref={inputImageRef}
              />
              <img
                src={userPicture ? userPicture : themes.components.images.avatar}
                alt="Imagem do perfil"
              />
              <span onClick={onClickEditPicture}>Editar foto</span>
            </S.ImageUser>

            {isLoading ? (
              <S.WrapperLoader>
                <Loader size={LOADER_TYPES.size.medium} />
              </S.WrapperLoader>
            ) : (
              <>
                {contentData.map((itemData) => (
                  <S.RowInfo key={itemData.id}>
                    <div>
                      <span>{itemData.title}</span>
                      <p> {itemData.value} </p>
                    </div>
                    {itemData.route && (
                      <Link onClick={handleClick}>
                        {themes.components.icons.chevronRight}
                      </Link>
                    )}
                  </S.RowInfo>
                ))}
              </>
            )}
            <S.Info>
              Mantenha sempre seus dados atualizados, caso seja necessário
              atualizar, favor entrar em contato via Chat ou pela central de
              atendimento 4003-3203
            </S.Info>
          </S.Container>
        </ContainerBox>
      )}
    </S.Container>
  );
};

export default PersonalData;
