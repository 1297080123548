import axios from "axios";
import { logout } from "./authentication";

export const autorizadorAPI = axios.create({
  baseURL: process.env.REACT_APP_MS_AUTORIZADOR,
  validateStatus: false,
});

export const usuarioAPI = axios.create({
  baseURL: process.env.REACT_APP_MS_USUARIO,
  validateStatus: false,
});

export const clienteAPI = axios.create({
  baseURL: process.env.REACT_APP_MS_CLIENTE,
  validateStatus: false,
});

export const notificacaoAPI = axios.create({
  baseURL: process.env.REACT_APP_MS_NOTIFICACAO,
  validateStatus: false,
});

export const emailAPI = axios.create({
  baseURL: process.env.REACT_APP_MS_EMAIL,
  validateStatus: false,
});

export const propostaAPI = axios.create({
  baseURL: process.env.REACT_APP_MS_PROPOSTA,
  validateStatus: false,
});

export const relatoriosAPI = axios.create({
  baseURL: process.env.REACT_APP_MS_RELATORIOS,
  validateStatus: false,
});

export const produtosAPI = axios.create({
  baseURL: process.env.REACT_APP_MS_PRODUTO,
  validateStatus: false,
});

export const walletAPI = axios.create({
  baseURL: process.env.REACT_APP_MS_CARTEIRA,
  validateStatus: false,
});

export const paymentAPI = axios.create({
  baseURL: process.env.REACT_APP_MS_PAGAMENTOS,
  validateStatus: false,
});

export const integracaoFaceCaptchaAPI = axios.create({
  baseURL: process.env.REACT_APP_MS_INTEGRACAO,
  validateStatus: false,
});

autorizadorAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("@NovaEra:token");
    if (token) config.headers.apikey = `token ${token}`;

    const TOKEN_Bearer = localStorage.getItem("@NovaEra:tokenBearer");
    if (TOKEN_Bearer) config.headers.Authorization = `Bearer ${TOKEN_Bearer}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

autorizadorAPI.interceptors.response.use(
  (config) => {
    if (401 === config.status) {
      logout();
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

notificacaoAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("@NovaEra:token");
    if (token) config.headers.apikey = `token ${token}`;

    const TOKEN_Bearer = localStorage.getItem("@NovaEra:tokenBearer");
    if (TOKEN_Bearer) config.headers.Authorization = `Bearer ${TOKEN_Bearer}`;

    return config;
  },
  (error) => {
    // return Promise.reject(error);
  }
);

notificacaoAPI.interceptors.response.use(
  (config) => {
    if (401 === config.status) {
      logout();
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

usuarioAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("@NovaEra:token");
    if (token) config.headers.apikey = `token ${token}`;

    const TOKEN_Bearer = localStorage.getItem("@NovaEra:tokenBearer");
    if (TOKEN_Bearer) config.headers.Authorization = `Bearer ${TOKEN_Bearer}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

usuarioAPI.interceptors.response.use(
  (config) => {
    if (401 === config.status) {
      logout();
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

clienteAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("@NovaEra:token");
    if (token) config.headers.apikey = `token ${token}`;

    const TOKEN_Bearer = localStorage.getItem("@NovaEra:tokenBearer");
    if (TOKEN_Bearer) config.headers.Authorization = `Bearer ${TOKEN_Bearer}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

clienteAPI.interceptors.response.use(
  (config) => {
    if (401 === config.status) {
      logout();
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

propostaAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("@NovaEra:token");
    if (token) config.headers.apikey = `token ${token}`;


    const TOKEN_Bearer = localStorage.getItem("@NovaEra:tokenBearer");
    if (TOKEN_Bearer) config.headers.Authorization = `Bearer ${TOKEN_Bearer}`;


    const xCode = sessionStorage.getItem("verificationCodeSession");
    if (xCode) config.headers.xcode = xCode;

    return config;
  },
  (error) => {
    // return Promise.reject(error);
  }
);

propostaAPI.interceptors.response.use(
  (config) => {
    if (401 === config.status) {
      logout();
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

emailAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("@NovaEra:token");
    if (token) config.headers.apikey = `token ${token}`;

    const TOKEN_Bearer = localStorage.getItem("@NovaEra:tokenBearer");
    if (TOKEN_Bearer) config.headers.Authorization = `Bearer ${TOKEN_Bearer}`;

    return config;
  },
  (error) => {
    // return Promise.reject(error);
  }
);

emailAPI.interceptors.response.use(
  (config) => {
    if (401 === config.status) {
      logout();
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

relatoriosAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("@NovaEra:token");
    const xCode = sessionStorage.getItem("@NovaEra:xCode");
    const TOKEN_Bearer = localStorage.getItem("@NovaEra:tokenBearer");

    if (token) config.headers.apikey = `token ${token}`;
    if (TOKEN_Bearer) config.headers.Authorization = `Bearer ${TOKEN_Bearer}`;
    if (xCode) config.headers.XCODE = xCode;

    return config;
  },
  (error) => {
    // return Promise.reject(error);
  }
);

relatoriosAPI.interceptors.response.use(
  (config) => {
    if (401 === config.status) {
      logout();
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

produtosAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("@NovaEra:token");
    if (token) config.headers.apikey = `token ${token}`
    const TOKEN_Bearer = localStorage.getItem("@NovaEra:tokenBearer");
    if (TOKEN_Bearer) config.headers.Authorization = `Bearer ${TOKEN_Bearer}`;

    return config;
  },
  (error) => {
    // return Promise.reject(error);
  }
);

export const api = axios.create();

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("@NovaEra:token");
    const xCode = sessionStorage.getItem("@NovaEra:xCode");
    const TOKEN_Bearer = localStorage.getItem("@NovaEra:tokenBearer");
    
    if (token) config.headers.apikey = `token ${token}`;
    if (TOKEN_Bearer) config.headers.Authorization = `Bearer ${TOKEN_Bearer}`;
    if (xCode) config.headers.X_CODE = xCode;


  return config;
});

api.interceptors.response.use(
  (config) => {
    if (401 === config.status) {
      logout();
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  });

walletAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("@NovaEra:token");
    if (token) config.headers.apikey = `token ${token}`
    const TOKEN_Bearer = localStorage.getItem("@NovaEra:tokenBearer");
    if (TOKEN_Bearer) config.headers.Authorization = `Bearer ${TOKEN_Bearer}`;

    return config;
  },
  (error) => {
    // return Promise.reject(error);
  }
);
paymentAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("@NovaEra:token");
    if (token) config.headers.apikey = `token ${token}`
    const TOKEN_Bearer = localStorage.getItem("@NovaEra:tokenBearer");
    if (TOKEN_Bearer) config.headers.Authorization = `Bearer ${TOKEN_Bearer}`;

    return config;
  },
  (error) => {
    // return Promise.reject(error);
  }
);

paymentAPI.interceptors.response.use(
  (config) => {
    if (401 === config.status) {
      logout();
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

integracaoFaceCaptchaAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("@NovaEra:onboardingToken");
    if (token) config.headers.apikey = `token ${token}`;

    const TOKEN_Bearer = localStorage.getItem("@NovaEra:onboardingTokenBearer");
    if (TOKEN_Bearer) config.headers.Authorization = `Bearer ${TOKEN_Bearer}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

integracaoFaceCaptchaAPI.interceptors.response.use(
  (config) => {
    if (401 === config.status) {
      logout();
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
