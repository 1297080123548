import React from "react";

import HeaderOnboarding from "~/components/HeaderOnboarding";

import { Container, BackgroundBar, Content, SimpleContent } from "./styles";

const Layout = ({
  children,
  auth = true,
  showBackGroundBar = true,
  teuCred,
}) => (
  <Container>
    {auth && <HeaderOnboarding teuCred={teuCred} />}
    {showBackGroundBar && <BackgroundBar style={{ top: auth ? 87 : 0 }} />}

    {auth ? (
      <Content>
        <main>{children}</main>
      </Content>
    ) : (
      <SimpleContent>
        <main> {children}</main>
      </SimpleContent>
    )}
  </Container>
);

export default Layout;
