import styled from "styled-components";
import { AiOutlineCopy } from "react-icons/ai";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h6 {
    color: #4a4b4a;
    font-size: 20px;
    font-weight: 600;
  }

  p {
    font-size: 12px;
    color: #ed4848;
    margin-top: 2px;
  }
`;

export const Key = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  h6 {
    color: #4a4b4a;
    font-size: 16px;
  }

  p {
    font-size: 12px;
    color: #ed4848;
    text-indent: 2px;
    margin-top: 10px;
    margin-left: 2px;
  }
`;

export const NavBtnLink = styled.button`
  border-radius: 5px;
  width: 5%;
  margin-bottom: 5px;
  background: background: linear-gradient(to right, #E71037, #ED933A);
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  &:hover {
  transition: all 0.3s ease-out;
  background: #ED933A;
  background-color: #ED933A;
}
`;

export const CopyIcon = styled(AiOutlineCopy)`
  cursor: pointer;
`;

export const WrapperLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;
