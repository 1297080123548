import React from "react";
import Accordeon from "components/Accordeon";
import * as S from "./styles";

const Help = () => {
  const contentCartao = [
    {
      id: 0,
      title: "Perdi meu cartão, como peço um novo?",
      children:
        "Em caso de perda do cartão, entre em contato com o suporte pelo numero: 4003-3203.",
    },
  ];
  return (
    <S.Container>
      <S.Content>
        <h3>Sobre seu cartão</h3>
        {contentCartao.map((itemCartao) => (
          <Accordeon
            key={itemCartao.id}
            title={itemCartao.title}
            children={itemCartao.children}
          />
        ))}
      </S.Content>
    </S.Container>
  );
};

export default Help;
