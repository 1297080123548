import React from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import TypeLending from "~/views/Lending/components/TypeLending";
import themes from "~/themes";

import * as St from "../../styles";
const LendingType = () => {
  const history = useHistory();

  const handleClick = (route) => {
    history.push(route);
  };

  const userInfoArray = JSON.parse(
    localStorage.getItem("@NovaEra:infoUsuario")
  );

  const valid = userInfoArray?.perfis;

  const options = [
    {
      id: 1,
      title: "Empréstimo Cartão TeuCard",
      icon: themes.components.images.teuCardLending,
      type: "teucard",
      route: "/emprestimo/bem-vindo",
      disabled: false,
    },
    // {
    //   id: 2,
    //   title: "Empréstimo Cartão de Crédito",
    //   icon: themes.components.images.creditCardLending,
    //   type: "creditCard",
    //   route: "/emprestimo",
    // },
    {
      id: 3,
      title: "Buscador de empréstimos",
      icon: themes.components.images.loanSearch,
      type: "buscador",
      route: "/buscador",
    },
  ];

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <St.Content>
        <St.Box>
          <St.Header>
            <St.Title align="center">
              Bem-vindo à tua área de empréstimo{" "}
            </St.Title>
          </St.Header>
          <St.BoxType marginTop="150px">
            <St.Subtitle>Veja as opções que preparamos para você!</St.Subtitle>
            <St.TypeContainer>
              {options.map((option, index) => {
                return (
                  <TypeLending
                    title={option.title}
                    key={index}
                    icon={option.icon}
                    onClick={() => handleClick(option.route, option.type)}
                    valid={valid}
                    disabled={option.disabled}
                  />
                );
              })}
            </St.TypeContainer>
          </St.BoxType>
        </St.Box>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default LendingType;
