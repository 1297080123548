import React from "react";
import "./styles.css";

const Checkbox = ({ label, checked, onChange, disabled }) => {
  return (
    <label className="container">
      {label}
      <input
        type="checkbox"
        id="senha"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default Checkbox;
