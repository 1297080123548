import styled, { css } from "styled-components";
import themes from "~/themes";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: auto;
`;

export const Value = styled.div`
  flex: 1;
  cursor: pointer;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const IconContainer = styled.div`
  position: absolute;
  align-items: flex-end;
  justify-content: center;
  right: 5px;
  top: 43%;
  cursor: pointer;

  padding-right: 10px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #eeeeee;
`;

export const ContainerOptions = styled.div`
  position: absolute;
  background: #ffffff;
  top: 317px;
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
  z-index: 99;
  width: 80%;
  elevation: 10;
  border-radius: 10px;
  flex: 1;
  max-height: 250px;
  height: 100%;
  transition: height 0.6s;
  overflow-y: hidden;
  border: none;

  @media (max-width: 1100px) {
    top: 300px;
  }

  .option {
    height: 100%;
    overflow-y: scroll;
    cursor: pointer;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #ddd;
      margin-top: 0;
    }
    &::-webkit-scrollbar-thumb {
      background: #e4003a;
      border: 3px solid #e4003a;
    }
  }
`;

export const Option = styled.button`
  display: flex;
  width: 100%;
  background: #fff;
  color: ${themes.components.colors.input};
  font-size: 16px;

  border-bottom: 1px solid #eeeeee;
  border: none;

  :hover {
    background: #f1f1f1;
  }
`;

export const ErrorText = styled.span`
  font-size: 12px;
  color: ${themes.components.colors.messageError};
`;

export const ContainerItems = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px 16px;
`;

export const OptionLabel = styled.label`
  display: flex;
  justify-content: flex-start;
`;
