import styled from "styled-components";
import { NavLink } from "react-router-dom";

import themes from "~/themes";

export const Container = styled.div`
  height: auto;
  width: 100px;

  border-radius: 4px;
  background: #ffffff;
  width: 110px;
  padding-left: 21px;

  border-radius: 4px;
  background: #ffffff;
  border-right: 2px solid #ececec;

  nav {
    margin-top: -20%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 30px 39px 0;
    padding: 30px 22px 0;
    path {
      stroke: ${themes.components.colors.navItem};
    }
    /* Quando a rota for a mesma no NavLink o router-dom adiciona a class 'active' */
    a:hover,
    .active {
      color: ${themes.components.colors.link};
      path {
        stroke: ${themes.components.colors.link};
      }

      g {
        fill: ${themes.components.colors.link};
      }
    }
  }
`;

export const NavItem = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;

  color: ${themes.components.colors.navItem};
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0;
  line-height: 19px;

  div {
    display: flex;
    width: 38px;
  }

  > svg {
    margin-left: auto;
  }
`;
