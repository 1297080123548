import React, { useMemo } from "react";
import ContainerBox from "~/components/ContainerBox";
import "reactjs-popup/dist/index.css";

import * as St from "./styles";
import { moneyMask } from "~/utils/mask";

function LimitMeter({ limitTitle, usedLimit, availableLimit, balanceVisible, totalLimit }) {

  const limitBarPercentage = useMemo(
    () => {
      if (usedLimit && typeof availableLimit) {
        return (usedLimit * 100) / (usedLimit + availableLimit)
      }
      else {
        return 0;
      }
    },
    [usedLimit, availableLimit]
  );

  return (
    <St.Container>
      <St.ContainerHead>
        <St.HeaderBox>
        <div className="title-container">
        {limitTitle}: {balanceVisible ? moneyMask(totalLimit) : "●●●●●●"}
       </div>
        </St.HeaderBox>
      <St.LimitBar percentage={limitBarPercentage}>
        <span />
      </St.LimitBar>

      <St.CardClientLimits>
        <div>
          <span />
          <p>
            Limite utilizado <br />
            <strong>{balanceVisible ?
              moneyMask(usedLimit)
              : "●●●●●●"
            }</strong>
          </p>
        </div>
        <div>
          <span />
          <p>
            Limite disponível <br />
            {/* <strong>{handleBalance(usedLimit + availableLimit)}</strong> */}
            <strong>{balanceVisible ?
              moneyMask(availableLimit)
              : "●●●●●●"
            }</strong>
          </p>
        </div>
      </St.CardClientLimits>
      </St.ContainerHead>
    </St.Container>
  );
}

export default LimitMeter;
