import React, { useContext, useCallback, useState, useEffect } from "react";

import Input from "~/components/Input";
import Button from "~/components/Button";
import Modal from "~/components/Modals/OneButtonPopup";

import * as S from "../styles";
import { cpfMask } from "~/utils/mask";
import themes from "~/themes";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import InputOnboarding from "~/components/InputOnboarding";
import { useHistory } from "react-router-dom";
import { phoneMask } from "~/utils/mask";
import {
  emailValidator,
  nameValidator,
  phoneValidator,
} from "~/utils/validators";
import Checkbox from "~/components/Checkbox";
import { createUserWallet } from "~/services/user";
import Termos from "~/services/terms";
import { phoneUnmask } from "~/utils/masksNew";
import { GlobalContext } from "~/contexts/GlobalState";
import { notificacaoAPI } from "~/services/api";
import { sendingSMSByCellPhone } from "~/services/onboardingService";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

const UserPersonalData = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [errorsInputs, setErrorsInputs] = useState({});
  const [terms, setTerms] = useState(false);
  const [whatsApp, setWhatsApp] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");
  const retPhoneNumber = phoneUnmask(phoneNumber);
  const { onboarding, addOnboarding } = useContext(GlobalContext);

  const gctx = useContext(GlobalContext);

  const history = useHistory();

  /* if( window.location.reload(false)) {
    history.push("/home")
  }*/

  const checkUserEmail = useApi(
    Endpoints.cliente.postUserValidateEmail
      .replace("PARAM_EMAIL", email)
      .replace("PARAM_CPF", onboarding?.cpf),
    ApiMethod.POST
  );

  const handleValidateFields = async () => {
    const checkEmailResponse = await checkUserEmail.callApi();

    if (checkEmailResponse.status === 404) {
      setPopupText(checkEmailResponse?.mensagem || "Erro ao continuar!");
      setShowPopup(true);
      setEmail("");
      return;
    }

    try {
      gctx.addOnboarding({ nome: name, email: email, phone: retPhoneNumber });

      const fieldsForm = {
        name: nameValidator(name).message || "",
        phoneNumber: phoneValidator(phoneNumber).message || "",
        email: emailValidator(email).message || "",
        terms: terms.message || "",
      };

      setErrorsInputs(fieldsForm);
      if (!fieldsForm.name && !fieldsForm.phoneNumber && !fieldsForm.email) {
        const response = await sendingSMSByCellPhone({
          numeroCelular: retPhoneNumber,
        });
        history.push("/onboarding-validar-sms");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const verifyReloadPage = () => {
    if (onboarding.cpf === "") {
      history.push("/home-produtos");
    }
  };

  const handleTermsConditions = async () => {
    const filePath = `${process.env.REACT_APP_MS_TERMOS_CONDICOES}`;

    window.open(filePath);
  };

  const handleTermsPrivacy = async () => {
    const filePath = `${process.env.REACT_APP_MS_TERMOS_POLITICA_PRIVACIDADE}`;
    window.open(filePath);
  };

  useEffect(() => {
    verifyReloadPage();
  }, [verifyReloadPage]);

  const handleDisabledButton = () => !terms || !whatsApp;

  return (
    <LayoutOnboarding showBackGroundBar={false}>
      <S.Content>
        <S.MainText>
          <p>Criar carteira digital</p>
        </S.MainText>
        <S.MiddleText>
          <p>
            Preencha os campos abaixo com os teus dados pessoais, por favor.
          </p>
        </S.MiddleText>
        <S.Input>
          <InputOnboarding
            type="text"
            label="Nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
            maxLength={50}
            error={errorsInputs.name}
          />
          <InputOnboarding
            type="text"
            label="Celular"
            maxLength={15}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(phoneMask(e.target.value))}
            error={errorsInputs.phoneNumber}
          />
          <InputOnboarding
            type="text"
            label="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errorsInputs.email}
          />
        </S.Input>
        <S.Checkbox>
          <Checkbox
            label={
              <>
                Li e concordo com os
                <S.ViewTerms onClick={() => handleTermsConditions()}>
                  Termos e condições de uso
                </S.ViewTerms>
                e a
                <S.ViewTerms onClick={() => handleTermsPrivacy()}>
                  Política de privacidade
                </S.ViewTerms>
              </>
            }
            checked={terms}
            onChange={(e) => setTerms(e.target.checked)}
          />

          <Checkbox
            style={{ marginLeft: "5%", marginTop: "1%" }}
            label={
              <>Desejo receber comunicação do meu interesse por Whatsapp</>
            }
            checked={whatsApp}
            onChange={(e) => setWhatsApp(e.target.checked)}
          />
        </S.Checkbox>

        <S.Footer>
          <Button
            onClick={() => handleValidateFields()}
            disabled={handleDisabledButton()}
          >
            Continuar
          </Button>
        </S.Footer>
      </S.Content>
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={popupText}
        buttonText="Entendi"
        visibility={showPopup}
        setVisibility={setShowPopup}
      />
    </LayoutOnboarding>
  );
};

export default UserPersonalData;
