import React from "react";
import Button from "~/components/Button";

import LinkButton from "~/components/LinkButton";
import * as S from "./styles";

export const NewOneButtonPopup = ({
  defaultButton,
  imgIcon,
  visibility,
  title,
  setVisibility = () => { },
  icon,
  text,
  buttonText,
  slug,
  onClick,
  children,
  ishtml = false,
}) => {
  return (
    <S.Container open={visibility} onClose={() => setVisibility(false)} modal>
      {(close) => (
        <>
          <div className="content-container">
            {imgIcon ? <img src={imgIcon} alt="" style={{width: "100%"}} /> : icon}
            <h1>{title}</h1>
            {ishtml ? <div dangerouslySetInnerHTML={{ __html: text }} /> : <p>{text}</p>}

            {children}
          </div>

          <hr />

          {defaultButton ? (
            <div className="button-container">
              <button onClick={close} className="button-cancel">
                {buttonText || "Cancelar"}
              </button>
            </div>
          ) : (
            slug && <LinkButton onClick={onClick} slug={slug} text={buttonText} /> ||
            !slug && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingBottom: '15px' }}>
              <Button style={{ width: '180px' }} onClick={() => setVisibility(false)}>{buttonText}</Button>
            </div>
          )}
        </>
      )}
    </S.Container>
  );
};

export default NewOneButtonPopup;
