import styled from "styled-components";

import themes from "~/themes";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 1%;
  position: relative;

  .large-border {
    border: 2px solid
      ${(props) =>
        props.isFocus
          ? themes.colors.orange.n2
          : themes.components.colors.label};
  }

  label {
    font-size: ${(props) => (props.largeLabel ? "18px" : "12px")};
    font-weight: ${(props) => (props.largeLabel ? 700 : 400)};
    color: ${(props) =>
      props.isFocus ? themes.colors.orange.n2 : themes.components.colors.label};
  }

  input {
    font-size: 16px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) =>
      props.isFocus ? themes.colors.orange.n2 : "#444444"};

    padding: 6px;
    color: ${themes.components.colors.input};
    height: 40px;
    min-width: 258px;
    background: ${themes.colors.white.n1};
    border-radius: 7px;
  }

  .icon {
    position: absolute;
    max-width: 18px;
    right: 10px;
    top: 20px;
    cursor: pointer;
  }
`;

export const ErrorText = styled.span`
  font-size: 12px;
  color: ${themes.components.colors.messageError};
  margin-top: 2px;
`;
