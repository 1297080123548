/* eslint-disable no-const-assign */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Layout from "~/components/Layout";
import Button, { BUTTON_TYPES } from "~/components/Button";
import Input from "~/components/Input";
import ModalOneButton from "~/components/Modals/OneButtonPopup";
import {
  verificationCodeMask,
  verificationCodeUnmask,
  phoneMask,
  hidePhone,
} from "~/utils/mask";
import { CustomizedError } from "~/utils/errors";
import Loader, { LOADER_TYPES } from "~/components/Loader";

import themes from "~/themes";
import * as S from "../styles";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

const ValidadeCode = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [chronometer, setChronometer] = useState(60);
  const { state } = useLocation();
  const [count, setCount] = useState(2);

  const RequestGetNoAuthInvoice = useApi(
    Endpoints.cliente.getNoAuthInvoice
      .replace("PARAM_CPF", state?.cpf)
      .replace("PARAM_TENTATIVAS", count),
    ApiMethod.GET
  );

  useEffect(() => {
    if (chronometer > 0) {
      const interval = setInterval(() => {
        setChronometer(chronometer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [chronometer, state.phone]);

  const handleDisabledButton = () => verificationCode.length !== 7;

  const handleClickButton = async () => {
    try {
      sessionStorage.setItem(
        "@NovaEra:xCode",
        verificationCodeUnmask(verificationCode)
      );
      await getInvoicePdf();
    } catch (err) {
      setModalText(err.mensagem);
    }
  };

  const getNoAuthInvoice = async () => {
    const response = await RequestGetNoAuthInvoice.callApi();
    const data = response.data;

    if (response.status >= 200 && response.status < 300) {
      if (data.celular) {
        sessionStorage.setItem(
          "@NovaEra:payloadFaturaNoAuth",
          JSON.stringify({
            ano: data.anoReferencia,
            mes: data.mesReferencia,
            cpf: state?.cpf,
            validaEmail: count >= 4,
          })
        );
        setModalText(
          count >= 4
            ? `Ops! Agora enviaremos para o teu email cadastrado: ${state?.email}`
            : "SMS reenviado com sucesso!"
        );
        setModalIsOpen(true);
        setChronometer(60);
      }
    } else {
      setModalText(data.mensagem);
      setModalIsOpen(true);
    }
    setCount((prev) => prev + 1);
  };

  const RequestPostFaturaGeradaNoAuth = useApi(
    Endpoints.relatorio.postFaturaGeradaNoAuth,
    ApiMethod.POST
  );

  const PostFaturaGeradaNoAuth = async () => {
    const aux = sessionStorage.getItem("@NovaEra:payloadFaturaNoAuth");
    const payload = JSON.parse(aux);

    const request = await RequestPostFaturaGeradaNoAuth.callApi(payload);

    if (request.status >= 200 && request.status < 300) {
      return { status: request.status, data: request.data };
    } else {
      sessionStorage.removeItem("@NovaEra:xCode");
      throw CustomizedError({ message: request.data.mensagem });
    }
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceBase64, setInvoiceBase64] = useState("");
  const [statusError, setStatusError] = useState(false);

  async function getInvoicePdf() {
    setErrorMessage("");
    setIsLoading(true);
    try {
      const response = await PostFaturaGeradaNoAuth();

      if (response.status >= 200 && response.status < 300) {
        setChronometer(0);
        setInvoiceBase64(response.data.documentFatura);
        setModalText("");
        setIsLoading(false);
        setStatusError(false);
      }
    } catch (error) {
      setModalIsOpen(true);
      setModalText(error.message ? error.message : "Nenhuma fatura encontrada");
      setErrorMessage(
        error.message ? error.message : "Nenhuma fatura encontrada"
      );
      setIsLoading(false);
      setStatusError(true);
    }
  }
  const reSendSMS = () => {
    getNoAuthInvoice();
  };
  const expandScreen = () => {
    const binary = atob(invoiceBase64.replace(/\s/g, ""));
    const buffer = new ArrayBuffer(binary.length);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < binary.length; i += 1) {
      view[i] = binary.charCodeAt(i);
    }

    const blob = new Blob([view], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(blob);
    return window.open(fileURL, "_blank");
  };

  return (
    <Layout auth={false}>
      <ModalOneButton
        ishtml
        text={modalText}
        buttonText="Ok"
        icon={themes.components.icons.warning}
        visibility={modalIsOpen}
        setVisibility={(bool) => setModalIsOpen(bool)}
        onClick={() => {
          setModalIsOpen(false);
          setVerificationCode("");
        }}
      />
      <S.Content>
        <img src={themes.components.images.logoTeuCard} alt="Logo TeuCard" />
        <p>
          <strong>Bem-vindo</strong> ao Fatura TeuCard
        </p>
        {RequestPostFaturaGeradaNoAuth.loading ? (
          <S.WrapperLoader>
            <Loader size={LOADER_TYPES.size.medium} />
          </S.WrapperLoader>
        ) : (
          <>
            {!!invoiceBase64 ? (
              <>
                {!errorMessage ? (
                  <embed
                    src={`data:application/pdf;base64, ${invoiceBase64}`}
                    type="application/pdf"
                    height="480px"
                    width="448px"
                  ></embed>
                ) : (
                  <h6>{errorMessage}</h6>
                )}
                <S.ContentButton>
                  {!isLoading && !statusError && (
                    <Button
                      onClick={() => expandScreen()}
                      type={BUTTON_TYPES.outlined}
                    >
                      Expandir fatura
                    </Button>
                  )}
                </S.ContentButton>
              </>
            ) : (
              <S.Main>
                <span>
                  {count >= 4
                    ? `Insira o código enviado no email ${state?.email}`
                    : `Insira o código enviado por SMS no seu celular: ${
                        hidePhone(phoneMask(state?.phone)) || ""
                      }`}
                </span>
                <S.ContentInput>
                  <Input
                    type="text"
                    label="Código de verificação"
                    placeholder="000 000"
                    maxLength={7}
                    value={verificationCode}
                    onChange={(e) =>
                      setVerificationCode(verificationCodeMask(e.target.value))
                    }
                  />
                  <S.ResendCode opacity={chronometer !== 0}>
                    Não recebeu o SMS? Você pode
                    <button
                      type="button"
                      onClick={reSendSMS}
                      disabled={chronometer !== 0}
                    >
                      reenviar
                    </button>
                    .{chronometer !== 60 && ` Aguarde ${chronometer}.`}
                  </S.ResendCode>
                </S.ContentInput>
                <S.ContentButton>
                  <Button
                    disabled={handleDisabledButton()}
                    onClick={() => handleClickButton()}
                  >
                    Continuar
                  </Button>
                </S.ContentButton>
                <S.Message>
                  <p>
                    Caso não receba o código no teu celular, após 3 tentativas
                    será encaminhado para o e-mail cadastrado: {state?.email}
                  </p>
                </S.Message>
              </S.Main>
            )}
          </>
        )}
      </S.Content>
    </Layout>
  );
};

export default ValidadeCode;
