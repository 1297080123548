import React, { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Input from "~/components/Input";
import Layout from "~/components/Layout";
import Button from "~/components/Button";
import { verificationCodeMask, verificationCodeUnmask } from "~/utils/mask";
import ContainerBox from "~/components/ContainerBox";
import themes from "~/themes";

import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import * as S from "./styles";

import { logout } from "~/services/authentication";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { CustomizedError } from "~/utils/errors";

const CodeVerification = () => {
  const location = useLocation();

  const [confirmationPopupVisibility, setOneButtonPopupVisibility] =
    useState(false);

  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [error, setError] = useState(null);
  const [textPopup, setTextPopup] = useState("");

  const RequestPostCodeValidate = useApi(
    Endpoints.notificacao.putCodeValidate,
    ApiMethod.PUT
  );

  const RequestPutUnlblockCard = useApi(
    Endpoints.autorizador.putUnblockCard,
    ApiMethod.PUT
  );

  const RequestPutBlockCard = useApi(
    Endpoints.autorizador.putBlockCard,
    ApiMethod.PUT
  );

  const RequestPostSendToken = useApi(
    Endpoints.notificacao.postSendToken,
    ApiMethod.POST
  );

  useEffect(() => {
    SendToken();
  }, []);

  const SendToken = async () => {
    let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
    if (!tokenCartao) {
      tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
    }
    const tokenFcm = localStorage.getItem("@NovaEra:tokenFCM");

    const request = await RequestPostSendToken.callApi({
      tokenCartao,
      tokenFcm,
    });

    switch (request.status) {
      case 201:
        return { status: 201, data: request.data };
      default:
        console.error(request.status);
        break;
    }
  };

  const CodeValidate = async (code) => {
    let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
    if (!tokenCartao) {
      tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
    }

    const request = await RequestPostCodeValidate.callApi({
      tokenCartao,
      numeroGerado: code,
    });

    switch (request.status) {
      case 204:
        return { status: 204, data: request.data };
      default:
        throw CustomizedError({ message: request.data.mensagem });
    }
  };

  const handleValidation = async ({ code } = {}) => {
    try {
      setButtonIsLoading(true);
      if (
        !code &&
        (await CodeValidate(verificationCodeUnmask(cardNumber))).status == 204
      ) {
        handleSubmit();
      } else if (code && (await CodeValidate(code)).status == 204) {
        handleSubmit();
      }
    } catch (err) {
      setButtonIsLoading(false);
      setError(err.message);
    } finally {
      setError(null);
    }
  };

  const formattedCardNumber = useCallback((value) => {
    setCardNumber(verificationCodeMask(value));
  }, []);

  const handleReenviar = () => {
    SendToken();
  };

  const onClickPopup = () => {
    if (location.state.parentPath === "/home") {
      window.location = "/home";
    } else {
      logout();
      window.location = "/login";
    }
  };

  const UnblockCard = async (senha) => {
    let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
    let optionWhatsApp = localStorage.getItem("@NovaEra:optionWhatsApp");
    let optionTermoUso = localStorage.getItem("@NovaEra:optionTermos");
    if (!tokenCartao) {
      tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
    }

    const response = await RequestPutUnlblockCard({
      optionTermoUso: Boolean(optionTermoUso),
      optionWhatsApp: Boolean(optionWhatsApp),
      origemAcao: "SITE",
      senha,
      tokenCartao,
    });

    const status = response.status;

    if (status >= 200 && status < 300) {
      localStorage.setItem("@NovaEra:tokenCartao", tokenCartao);
      return;
    } else {
      throw CustomizedError({ message: response.data.mensagem || status });
    }
  };

  const BlockCard = async (idMotivo, senha) => {
    const tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
    const response = await RequestPutBlockCard({
      idMotivo,
      tokenCartao,
      senha,
    });

    switch (response.status) {
      case 200:
        return { status: 200, data: response.data };
      case 204:
        return { status: 204, data: response.data };
      default:
        throw CustomizedError({
          message: response.data.mensagem || response.status,
        });
    }
  };

  const handleSubmit = async () => {
    if (location.state.parentPath === "/home") {
      const isUnblocked = await UnblockCard(location.state.password);
      setTextPopup(
        isUnblocked
          ? "Senha do cartão desbloqueada com sucesso!"
          : "Erro inesperado, por favor tente novamente."
      );
      setOneButtonPopupVisibility(true);
    } else {
      const isBlocked = await BlockCard(
        location.state.blockReason,
        location.state.password
      );
      setTextPopup(
        isBlocked
          ? "Cartão bloqueado com sucesso! Caso deseje um novo cartão, por favor solicitar em nossa loja."
          : "Erro inesperado, por favor tente novamente."
      );
      setOneButtonPopupVisibility(true);
    }

    setButtonIsLoading(false);
  };

  return (
    <Layout>
      <ContainerBox>
        <S.Form
          onSubmit={(e) => {
            e.preventDefault();
            handleValidation();
          }}
        >
          <h6>Código de verificação</h6>
          <p className="instructions">
            Insira o código enviado por push notification no seu celular.
          </p>
          <Input
            type="text"
            label="Código de verificação"
            placeholder="0000 0000"
            value={cardNumber}
            maxLength={7}
            onChange={(e) => formattedCardNumber(e.target.value)}
            error={error}
          />
          <p className="help-message">
            Não recebeu o push notification? Você pode{" "}
            <span onClick={handleReenviar}>reenviar</span>
          </p>
          <Button type="submit">
            {buttonIsLoading
              ? `Validando código ${cardNumber}...`
              : "Validar código"}
          </Button>
        </S.Form>
        <OneButtonPopup
          onClick={onClickPopup}
          icon={themes.components.icons.cardBlocked}
          text={textPopup}
          buttonText="Entendi"
          visibility={confirmationPopupVisibility}
          setVisibility={setOneButtonPopupVisibility}
        />
      </ContainerBox>
    </Layout>
  );
};

export default CodeVerification;
