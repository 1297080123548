import styled from 'styled-components';

import themes from '~/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 25px;
`;

export const RowInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%; 
  padding: 11px 4px;
  

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #eaeaea;
    
    span {
      color: ${themes.components.colors.label};
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
    }

    p {
      color: ${themes.components.colors.input};
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      margin: 4px 0 0;
      width: 700px;
      height: 35px;
      padding-left: 10px;
      display: flex;
      align-items: center;      
    }
  }

  a {
    text-decoration: none;
    background: transparent;
    border: 0;
    margin-left: auto;

    svg {
      height: 15px;
      width: auto;
    }
  }
`;