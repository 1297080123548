// masksNew.js
const defaultReturn = (masked, unmasked) => ({
  masked: masked,
  unmasked: unmasked,
});

export const cpfUnmask = (value) => value.replace(/\D/gim, "");

export const phoneUnmask = (value) =>
  value.replace(/\D/gim, "").replace(/\D/g, "");

export const accountBankMask = (value) => {
  const maskedValue = value
    .replace(/\D/g, "")
    .replace(/^(\d{7})(\d{1})$/, "$1-$2");

  return defaultReturn(maskedValue, value.replace(/\D/g, ""));
};
export const agencyBankMask = (value) => {
  const maskedValue = value
    .replace(/\D/g, "")
    .replace(/^(\d{4})(\d{1})$/, "$1-$2");

  return defaultReturn(maskedValue, value.replace(/\D/g, ""));
};

export const dateUnmask = (value) => value.replace(/\D/gim, "");

export const creditCardUnmask = (value) => value.replace(/\D/g, "");

export const codeVerificationUnmask = (value) => value.replace(/\D/g, "");

export const cepUnmask = (value) => value.replace(/\D/g, "");

export const cpfMask = (value) => {
  const maskedValue = value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

  return defaultReturn(maskedValue, cpfUnmask(value));
};

export const cpfCnpjMask = (value) => {
  const str = value?.replace(/\D/g, "");

  if (str.length <= 11) {
    return str
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  } else if (str.length === 14) {
    return str.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  } else {
    return str;
  }
};

export const phoneMask = (value) => {
  const maskedValue = value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/g, "($1) $2")
    .replace(/(\d)(\d{4})$/, "$1-$2");

  return defaultReturn(maskedValue, phoneUnmask(value));
};

export const dateMask = (value) => {
  const newValue = value.replace(/\D/g, "").replace(/^(\d{2})(\d)/g, "$1/$2");

  if (newValue.length > 4)
    return defaultReturn(
      newValue.replace(/(\d{2})(\d{1,2})/, "$1/$2"),
      dateUnmask(value)
    );
  else return defaultReturn(newValue, dateUnmask(value));
};

export const creditCardMask = (value = "") => {
  const newValue = value.replace(/\D/g, "");
  const sizeValue = value.length;

  if (sizeValue <= 9)
    return defaultReturn(
      newValue.replace(/(\d{4})(\d{1,4})/g, "$1 $2"),
      creditCardUnmask(value)
    );
  else if (sizeValue <= 14)
    return defaultReturn(
      newValue.replace(/(\d{4})(\d{4})(\d{1,4})/g, "$1 $2 $3"),
      creditCardUnmask(value)
    );
  else
    return defaultReturn(
      newValue.replace(/(\d{4})(\d{4})(\d{4})(\d{1,4})/g, "$1 $2 $3 $4"),
      creditCardUnmask(value)
    );
};

export const codeVerificationMask = (value) => {
  const maskedValue = value
    .replace(/\D/g, "")
    .replace(/^(\d{3})(\d{3})/g, "$1 $2")
    .replace(/( \d{3})\d+?$/, "$1");

  return defaultReturn(maskedValue, codeVerificationUnmask(value));
};

export const transformInGlobalDate = (value = "") => {
  const [day, month, year] = value.split("/");

  return `${year}-${month}-${day}`;
};
