/* eslint-disable no-unused-vars */
import { CustomizedError } from "~/utils/errors";
import { autorizadorAPI, emailAPI, propostaAPI, clienteAPI, relatoriosAPI } from "./api";

export const blockReasonService = async () => {
  try {
    const res = await autorizadorAPI.get("/v1/autorizador/motivos-bloqueio");
    return res.data;
  } catch (err) {
    return err.response.data.message;
  }
};

export const validateCard = async ({ numeroCartao }) => {
  const responseToken = await autorizadorAPI.post(
    "/v1/autorizador/noauth/token-cartao",
    { numeroCartao }
  );

  const responseMotivoBloqueio = await clienteAPI.get(
    `/v1/clientes/retornar-motivoBloqueio-numeroCartao?numeroCartao=${numeroCartao}`
  );

  const motivoBloqueio = responseMotivoBloqueio.data.status;

  if (responseToken.status === 404) {
    throw CustomizedError({
      message: responseToken.data.mensagem,
      status: responseToken.status,
    });
  } else if (responseToken.status !== 201) {
    throw CustomizedError({ message: responseToken.status });
  }

  const { tokenCartao, token } = responseToken.data;
  localStorage.setItem("@NovaEra:token", token);

  const responseCard = await autorizadorAPI.post(
    "/v1/autorizador/validar-cartao",
    { tokenCartao }
  );

  switch (responseCard.status) {
    case 200:
      const { statusCliente, bloqueado, permiteLogin } = responseCard.data;

      if (statusCliente === "INADIMPLENTE") {
        sessionStorage.setItem("@NovaEra:statusCliente", statusCliente);
      }

      if (
        statusCliente !== "CANCELADO" &&
        statusCliente !== "COBRANCA" &&
        motivoBloqueio !== "CANCELAMENTO_SOLICITACAO" &&
        motivoBloqueio !== "CANCELAMENTO_EXPERIENCIA" &&
        motivoBloqueio !== "PERDA_EXTRAVIO" &&
        motivoBloqueio !== "FURTO_ROUBO" &&
        motivoBloqueio !== "ERRO_IMPRESSAO" &&
        motivoBloqueio !== "CANCELADO"
      ) {
        localStorage.setItem("@NovaEra:tokenCartao", tokenCartao);
      } else {
        sessionStorage.setItem("@NovaEra:tokenCartao", tokenCartao);
      }

      return {
        status: 200,
        data: responseCard.data,
        motivoBloqueio: motivoBloqueio,
      };
    default:
      throw CustomizedError({
        status: responseCard.status,
        message: responseCard.data.mensagem || responseCard.status,
      });
  }
};

export const validatePassword = async (senha) => {
  let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
  if (!tokenCartao) tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");

  const response = await autorizadorAPI.post(
    "/v1/autorizador/noauth/validar-senha",
    {
      senha: senha,
      tokenCartao: tokenCartao,
    }
  );

  switch (response.status) {
    case 204:
      return { status: 204 };
    default:
      throw CustomizedError({
        message: response.data.mensagem || response.status,
      });
  }
};

export const activeCard = async (senha) => {
  try {
    let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
    if (!tokenCartao)
      tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");

    await autorizadorAPI.put("/v1/autorizador/ativar-cartao", {
      senha,
      tokenCartao,
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const changePassword = async (senhaAtual, novaSenha) => {
  const tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
  const response = await autorizadorAPI.put("/v1/autorizador/alterar-senha", {
    novaSenha,
    tokenCartao,
    senhaAtual,
  });

  switch (response.status) {
    case 204:
      return { status: 204, message: "Senha do cartão alterada com sucesso!" };
    default:
      throw CustomizedError({
        message: response.data.mensagem || response.status,
      });
  }
};

export const unblockCard = async (senha) => {
  let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
  let optionWhatsApp = localStorage.getItem("@NovaEra:optionWhatsApp");
  let optionTermoUso = localStorage.getItem("@NovaEra:optionTermos");
  if (!tokenCartao) {
    tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
  }

  const response = await autorizadorAPI.put(
    "/v1/autorizador/ativar-desbloquear",
    {
      optionTermoUso: Boolean(optionTermoUso),
      optionWhatsApp: Boolean(optionWhatsApp),
      origemAcao: "SITE",
      senha,
      tokenCartao,
    }
  );

  const status = response.status;

  if (status >= 200 && status < 300) {
    localStorage.setItem("@NovaEra:tokenCartao", tokenCartao);
    return;
  } else {
    throw CustomizedError({ message: response.data.mensagem || status });
  }
};

export const blockCard = async (idMotivo, senha) => {
  const tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
  const response = await autorizadorAPI.put("/v1/autorizador/bloquear-cartao", {
    idMotivo,
    tokenCartao,
    senha,
  });

  switch (response.status) {
    case 200:
      return { status: 200, data: response.data };
    case 204:
      return { status: 204, data: response.data };
    default:
      throw CustomizedError({
        message: response.data.mensagem || response.status,
      });
  }
};

export const validateUserPersonalData = (dataForm, dataApi) => {
  const cpfApiValidate = dataForm.document.replace(/\D/g, "") !== dataApi.cpf;

  const apiNameSplit = dataApi.nome.trim().split(" ");
  const apiLastName = apiNameSplit[apiNameSplit.length - 1];
  // const userNameSplit = dataForm.name.trim().split(" ");
  // const userLastName = userNameSplit[userNameSplit.length - 1];
  // const nameApiValidate =
  //   userLastName.toLowerCase() !== apiLastName.toLowerCase();

  const [day, mount, year] = dataForm.birthdate.split("/");
  const birthdateFormatted = `${year}-${mount}-${day}`;
  const birthdateApiValidate = birthdateFormatted !== dataApi.dataNascimento;

  // Os validators foram comentados pois a api só retorna dados estáticos e esses dados são inválidos
  return {
    // name: nameApiValidate ? "Nome incorreto com o registrado" : "",
    // document: documentCPFValidator(document).message || cpfApiValidate || '',
    document: cpfApiValidate ? "CPF incorreto com o registrado" : "",
    // birthdate: dateBirthValidator(birthdate).message || birthdateApiValidate || '',
    birthdate: birthdateApiValidate
      ? "Data de nascimento incorreta com o registrado"
      : "",
  };
};

export const getUserBalance = async () => {
  const tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");

  const response = await autorizadorAPI.post("/v1/autorizador/extrato/saldo", {
    tokenCartao,
  });

  switch (response.status) {
    case 200:
      return { status: 200, data: response.data };
    default:
      throw CustomizedError({
        message: response.data.mensagem || response.status,
      });
  }
};

export const ccValidate = async () => {
  const tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");

  const response = await autorizadorAPI.post("/v1/autorizador/validar-cartao", {
    tokenCartao,
  });

  switch (response.status) {
    case 200:
      sessionStorage.setItem("@NovaEra:tokenCartao", tokenCartao);
      return { status: 200, data: response.data };
    default:
      throw CustomizedError({
        message: response.data.mensagem || response.status,
      });
  }
};

export const getInvoicePDF = async (month, year) => {
  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));

  const response = await relatoriosAPI.get(
    `/v1/relatorios/gerar-fatura/${user?.cpf}/${month}/${year}`
  );

  switch (response.status) {
    case 200:
      return { status: 200, data: response.data };
    default:
      throw new Error(
        response.data.mensagem || "Não conseguimos carregar sua fatura"
      );
  }
};

export const getInvoice = async (month, year) => {
  const tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
  const response = await propostaAPI.get(
    `/v1/ficha-proposta/obter-dados-fatura/${tokenCartao}/${month}/${year}`
  );

  switch (response.status) {
    case 200:
      return { status: 200, data: response.data };
    default:
      throw new Error(
        response.data.mensagem || "Não conseguimos carregar sua fatura"
      );
  }
};

export const sendInvoiceEmail = async (month, year, email) => {
  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
  const response = await emailAPI.post(
    `/v1/email/email-fatura/${user?.cpf}/${month}/${year}/${email}`
  );

  switch (response.status) {
    case 204:
      return { status: 204 };
    default:
      throw new Error(response.data.mensagem);
  }
};

export const getInvoiceList = async () => {
  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));

  const request = await relatoriosAPI(
    `/v1/relatorios/obter-lista-faturas?cpf=${user?.cpf}`
  );

  const STATUS = request.status;

  switch (STATUS) {
    case 200:
      return request.data.listaFaturas;
    default:
      throw CustomizedError({
        message: request.data.mensagem || STATUS,
      });
  }
};
