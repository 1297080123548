import React from "react";

import themes from "~/themes";
import Button, { BUTTON_TYPES } from "~/components/Button";
import * as S from "./styles";
import { useState } from "react";

export const NewTwoButtonPopup = ({
  HeaderText,
  inputValue,
  subtitle,
  bodyText,
  loading,
  footerText,
  leftButtonText,
  rightButtonText,
  visibility,
  setVisibility,
  confirm,
  onClickCancel,
  showIcon = true,
  sendValue,
}) => {
  const [formattedValue, setFormattedValue] = useState("");

  const mascaraMoeda = (event) => {
    const onlyDigits = event.target.value
      .split("")
      .filter(s => /\d/.test(s))
      .join("")
      .padStart(3, "0")
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
    setFormattedValue(realMask(digitsFloat));
    return event.target.value = realMask(digitsFloat);

  }
  
  const realMask = (valor, locale = 'pt-BR', currency = 'BRL') => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency
    }).format(valor)
  }

  return (
    <S.Container open={visibility} onClose={() => setVisibility(false)} modal>
      {(close) => (
        <>
          <div className="header">
            <h1>{HeaderText}</h1>
            <h2>{subtitle}</h2>
            <div>{bodyText}</div>
            <footer>{footerText}</footer>
          </div>

          <hr />
          {inputValue &&
          <div className="input-value">
          <input type="text" onChange={(e) => sendValue(mascaraMoeda(e))} style={{width: '100%', height: '35px', borderRadius: 8, borderWidth: 1, borderColor: "50", paddingLeft: '10px'}}/>
          </div> }

          <div className="button-container">
            <Button
              onClick={() => {
                close();
                onClickCancel && onClickCancel();
              }}
              type={BUTTON_TYPES.outlined}
            >
              {leftButtonText}
            </Button>
            <Button onClick={confirm} isLoading={loading}>
              {rightButtonText}
            </Button>
          </div>
        </>
      )}
    </S.Container>
  );
};

export default NewTwoButtonPopup;
