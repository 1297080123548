import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Button from "~/components/Button";
import { GlobalContext } from "~/contexts/GlobalState";

import * as St from "../../styles";
import themes from "~/themes";

const ErrorLending = () => {
  const history = useHistory();

  const { state } = useLocation();

  const { lending } = useContext(GlobalContext);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <St.Content>
        <St.Box>
          <St.BoxSuccess>
            <St.ImageLogo>
              <img
                id="sucess-validation"
                src={themes.components.images.warningLending}
              />
            </St.ImageLogo>
            <St.Title>Atenção!</St.Title>
            <St.Description>
              {state?.error === "noTeuCardActive" && (
                <span>
                  Ops...! No momento não podemos te oferecer o empréstimo com
                  cartão TeuCard. Procure uma de nossas lojas!
                </span>
              )}
              {state?.error === "noLimitAvailable" && (
                <span>
                  Ops...! Teu valor disponível para empréstimo é de R$ `$
                  {lending.limiteSaqueDisponivel}` e não atinge o mínimo de R$
                  100,00. Pague a tua fatura e reestabeleça teu limite de
                  empréstimo!
                </span>
              )}
            </St.Description>
          </St.BoxSuccess>
          <St.Footer align="center">
            <div>
              <Button onClick={() => history.push("/home")}>
                Voltar à página inicial
              </Button>
            </div>
          </St.Footer>
        </St.Box>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default ErrorLending;
