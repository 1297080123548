import styled from 'styled-components';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import themes from '~/themes'

export const Container = styled.div`
  width: 100%;
  flex: 1
  display: flex;
  margin-top: 10px;
`;

export const ContainerHead = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 15px;
  margin: 0 11px;
  border-radius: 16px;
  background: #ffffff;
  ${({ withoutBoxShadow }) =>
    !withoutBoxShadow
      ? "box-shadow: 1px 3px 8px 0 rgba(201, 201, 201, 0.5);"
      : ""}
`;

export const HeaderBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid;

  .title-container {
    width: 100%;
    display: flex;
    align-items: center;
    color: #4a4b4a;
    font-size: 14px;
    font-weight: 600;
  }

`;

export const ExclamationIcon = styled(AiOutlineExclamationCircle)`
  font-size: 1.8em;
  margin-right: 5px;
  cursor: pointer;
  color: black;
  z-index: 2;
`;

export const PopoverWraper = styled.div`
  margin-left: 5px;
`;


export const LimitBar = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  margin-top: 10px;

  background: ${(props) => props.percentage !== 0 ? themes.components.colors.limitBar: themes.components.colors.limitBar };
  border-radius: 3px;

  width: 100%;
  height: 9px;

  > span {
    display: flex;
    width: ${(props) => props.percentage}%;
    height: 9px;
    background: ${ themes.components.colors.button};
    border-radius: 2px;
  }
`;

export const CardClientLimits = styled.div`
  width: 100%;
  margin-top: 12px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    display: flex;

    span {
      margin-top: 8px;
      height: 9px;
      width: 9px;
      border-radius: 4.5px;
    }

    p {
      color: ${themes.components.colors.font};
      font-size: 14px;
      letter-spacing: 0;
      line-height: 23px;
      padding-left: 10px;

      strong {
        color: #727272;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  div:nth-child(1) span {
    background: ${themes.colors.orange.n2};
  }
  div:nth-child(2) span {
    background: #95c11f;
  }
  div:nth-child(3) span {
    background: ${themes.components.colors.limitBar};
  }
`;
