import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 55%;
  
  margin: 0 auto;
`;

export const WrapperLoader = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height: 100px;
`
export const TeuCardLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 55%;
  max-width: 800px;

  img{
    width: 100px;
    height: 27.8px;
  }
`;

export const Header = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 55%;
  max-width: 800px;

  h3{
    margin-bottom:0
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
  }
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #9E9E9E;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;  
`;

export const BodyItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  max-width: 800px;
  margin-top: 20px;
  h3 {
    margin-top: 15px;
    color: #424242;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
  }
  hr {
    border: none;
    background: #E0E0E0;
    height: 1px;
  }
`;

export const BodyItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  max-width: 800px;
  margin-top: 20px;
  color: #424242;
  font-size: 14px;
  p{
    font-weight: 400;      
    margin-bottom: 0;
  }
  span{
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
  }
`;