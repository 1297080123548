import React, { useCallback, useEffect, useState } from "react";
import * as St from "./styles";
import { buildCard } from "~/utils/utilsCard";
import { deleteWalletById } from "~/services/walletServices";
import TwoButtonPopup from "../Modals/TwoButtonPopup";
import OneButtonPopup from "../Modals/OneButtonPopup";
import themes from "~/themes";
import { cardNumberMask } from "~/utils/mask";
import assets from "~/assets";

const CreditCard = (props) => {
  const informationCard = buildCard(props.data.numeroCartao);
  const [showDeleteModal, setShowDelteModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [validateCard, setValidateCard] = useState(props.data.validado);

  const onClickSelectCard = (data) => {
    if (props.onClick) {
      props.onClick(data);
    }
  }

  const deletingWallet = async () => {
    try {
      const responseData = await deleteWalletById(props.data.id);

      if (responseData.status === 204) {
        setShowDelteModal(false);
        window.location.reload(false);
      } else {
        setShowDelteModal(false);
        setShowPopup(true);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  console.log(props.data)

  return (
    <St.SafeArea>
      <St.Container
        imageIcon={props.imageTheme}
        backgroundColor={props.backgroundColor}
      >
        <TwoButtonPopup
          bodyText="Você deseja deletar o cartão?"
          leftButtonText="Cancelar"
          rightButtonText="Confirmar"
          visibility={showDeleteModal}
          setVisibility={setShowDelteModal}
          confirm={deletingWallet}
          onClickCancel={() => setShowDelteModal(false)}
          showIcon={false}
        />
        <OneButtonPopup
          icon={themes.components.icons.warning}
          text="Erro ao deletar o cartão !"
          buttonText="Entendi"
          visibility={showPopup}
          setVisibility={setShowPopup}
        />
        <St.Header bandeira={informationCard.bandeira}>
          <div style={{ fontSize: "15px", padding: "0", marginTop: "10px" }}>
            <img
              style={{
                objectFit: "contain",
                width: "60px",
                height: `${
                  informationCard.bandeira === "elo" ||
                  informationCard.bandeira === "hipercard"
                    ? "25px"
                    : "60px"
                }`,
              }}
              src={informationCard.iconBandeira}
            />
          </div>
          {!props.withoutDelete && (
            <button
              style={{ width: "55px", style: "none" }}
              onClick={() => setShowDelteModal(!showDeleteModal)}
            >
              <span>Excluir</span>
            </button>
          )}
        </St.Header>
        <St.CreditNumber bandeira={informationCard.bandeira}>
          {"●●●● ●●●● ●●●● " +
            informationCard.maskCartao.slice(
              informationCard.maskCartao.length - 4
            )}
        </St.CreditNumber>
        <St.CvvValidThru>
          <St.CreditCvv>
            <p>{props.data.ccv}</p>
          </St.CreditCvv>
          <St.CreditValidThru>
            <span style={{ marginRight: "7px", fontSize: "7px" }}>
              VALID <br />
              THRU
            </span>
            <span style={{ fontSize: "19px" }}>
              {" "}
              {`${props.data.mesValidade}/${props.data.anoValidade}`}
            </span>
          </St.CreditValidThru>
        </St.CvvValidThru>
        <St.CreditName>{props.data.nomeTitular}</St.CreditName>
      </St.Container>
      <St.FooterCard onClick={() => !props.data.validado ? onClickSelectCard(props.data) : null}>
        {!validateCard ? (
          <>
            <p style={{color: "#E4003A"}}>Não validado</p>
            <img
              src={assets.images.warning}
              style={{ objectFit: "contain", width: 25 }}
            />
          </>
        ) : (
          <>
            <p style={{color: "#95C11F"}}>Validado</p>
            <img
              src={assets.images.checkGreen}
              style={{ objectFit: "contain", width: 25 }}
            />
          </>
        )}
      </St.FooterCard>
    </St.SafeArea>
  );
};

export default CreditCard;
