import React from 'react';

import { Container } from './styles';

const LinkButton = ({ text, slug, margin, onClick }) => {
  return (
    <Container onClick={onClick} to={slug} margin={margin}>
      {text}
    </Container>
  );
};

export default LinkButton;
