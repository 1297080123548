import styled from "styled-components";
import themes from "~/themes";

export const ResendCode = styled.p`
  color: ${themes.components.colors.font};
  font-size: 16px;
  font-weight: 600;

  > span {
    margin-left: 3px;

    color: ${(props) =>
      props.disabled ? themes.colors.orange.n3 : themes.components.colors.link};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
  }
`;

export const Alert = styled.span`
  text-align: center;
  font-weight: bold;
  color: ${themes.colors.red.n1};
`;
