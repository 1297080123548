import styled from "styled-components";

export const Area = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  width: 333px;
  height: 120px;
  background: linear-gradient(to right, #E71037 -30%, #ED933A 70%);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

export const Header = styled.div`
  font-weight: 400;
  color: #444444;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40%;
  margin-left: 10px;
  button {
    background: transparent;
    border: 0;
    left: 130px;
    margin-right: 15px;
  }
`;

export const HeaderImg = styled.img`
  
`;

export const HeaderTitle = styled.span`
  margin-left: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: white;
  display: flex;
`;

export const Body = styled.div`
  margin-bottom: 18px;
  margin-left: 25px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #444444;
  flex-direction: column;
`;

export const Description = styled.span`
  color: #444444;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
`;

export const OtherInformation = styled.div`
  color: white;
  font-weight: 400;
  font-size: 10px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 3px;
`;

export const LimiteDisponivel = styled.span`
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 5px;
  display: flex;
  margin-left: 5px;
`;

export const HolderName = styled.span`
  color: #444;
  font-weight: 650;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 24px;
  margin-bottom: 5px;
`;
export const Value = styled.span`
  color: white;
  font-weight: 400;
  font-size: 14px;
`;

export const ValueFooter = styled.span`
  color: white;
  font-weight: 600;
  font-size: 11px;
`;


export const Footer = styled.footer`
width: 333px;
height: 3vh;
background: #ba182d;
border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px;
flex-direction: row;
align-items: center;
justify-content: center;
`;

