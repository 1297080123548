import React, { useContext, useState, useEffect, useCallback } from "react";
import Button from "~/components/Button";

import * as S from "../styles";
import { verificationCodeMask, verificationCodeUnmask } from "~/utils/mask";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import InputOnboarding from "~/components/InputOnboarding";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import NewOneButtonPopup from "~/components/Modals/NewOneButtonPopup";
import NewTwoButtonPopup from "~/components/Modals/NewTwoButtonPopup";
import themes from "~/themes";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { useLocation } from "react-router-dom";
import assets from "~/assets";
import { Alert } from "antd";

const AddCreditCardVerificationCode = () => {
  const [verificactionCode, setVerificactionCode] = useState("");
  const [error, setError] = useState("");
  const retVerificationCode = verificationCodeUnmask(verificactionCode);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [popupErrorMesssage, setPopupErrorMesssage] = useState("");
  const [chronometer, setChronometer] = useState(60);
  const [chronometerInterval, setChronometerInterval] = useState(0);
  const [resendButtonIsDisabled, setResendButtonIsDisabled] = useState(false);
  const [countResendSMS, setCountResendSMS] = useState(0);
  const [popUpSuccess, setPopUpSuccess] = useState(false);
  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
  const [popUpCardError, setPopUpCardError] = useState(false);
  const [popUpSendError, setPopUpSendError] = useState(false);
  const [popUpCardNotValidate, setPopCardNotValidate] = useState(false);
  const [popUpCardValidate, setPopCardValidate] = useState(false);
  const [validationCard, setValidationCard] = useState(false);
  const [validationValue, setValidationValue] = useState(false);
  const [valueCharged, setValueCharged] = useState();
  const [cardId, setCardId] = useState("");
  const { state } = useLocation();

  console.log(state);

  useEffect(() => {
    SendPush();
  }, []);

  async function SendPush() {
    try {
      await POSTSendSMSWithWhatsApp.callApi({ cpf: user?.cpf });
      handleDisableButtonResendSMS();
    } catch (error) {
      setPopupErrorMesssage("Falha ao enviar SMS!");
      setPopUpIsOpen(true);
    }
  }

  const postSendSMSByCPF = useApi(
    Endpoints.notificacao.postSMSWithCPF.replace("PARAM_CPF", user?.cpf),
    ApiMethod.POST
  );
  const POSTSendSMSWithWhatsApp = useApi(
    Endpoints.wallet.postSendWhatsappCode,
    ApiMethod.POST
  );
  const postSendVerificationValue = useApi(
    Endpoints.payments.postSendTransactionValidationCard,
    ApiMethod.POST
  );

  const postValidateTransaction = useApi(
    Endpoints.payments.postValidateTransaction,
    ApiMethod.POST
  );

  const postReverseCharge = useApi(
    Endpoints.payments.postReverseValue.replace("PARAM_ID", cardId),
    ApiMethod.POST
  );

  const putCodeValidateCPF = useApi(
    Endpoints.notificacao.putCodeValidateCPF,
    ApiMethod.PUT
  );

  const createNewCreditCard = useApi(Endpoints.wallet.addCard, ApiMethod.POST);

  const handleValidateFields = async () => {
    const response = await putCodeValidateCPF.callApi({
      cpf: user?.cpf,
      numeroGerado: verificactionCode.replaceAll(" ", ""),
    });
    if (response.status >= 200 && response.status <= 300) {
      await createCreditCard();
    } else {
      setShowPopup(true);
      setIsLoading(false);
      setPopupErrorMesssage(response.data.mensagem);
    }
  };

  const createCreditCard = async () => {
    try {
      const response = await createNewCreditCard.callApi(state?.data);
      if (response.status >= 200 && response.status <= 300) {
        setCardId(response.data?.id);
        setValidationCard(true);
      } else {
        setPopUpCardError(true);
        setPopupErrorMesssage(response.mensagem ? response.mensagem : null);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    if (countResendSMS === 4) {
      reSendSMS();
    }
  }, [countResendSMS]);

  const reSendSMS = useCallback(async () => {
    if (resendButtonIsDisabled) return;

    if (countResendSMS === 3) {
      setPopupErrorMesssage(
        "Não é possível reenviar mais de 3 vezes o SMS! Verifique se o celular está correto e tente novamente."
      );
      setPopUpIsOpen(true);
    }

    handleDisableButtonResendSMS();
    setIsLoading(true);

    try {
      const response = await postSendSMSByCPF.callApi();

      if (response.status >= 200 && response.status < 300) {
        setPopupErrorMesssage("SMS re-enviado com sucesso!");
      } else {
        setPopupErrorMesssage("Falha ao re-enviar SMS!");
      }
    } catch (err) {
      setPopupErrorMesssage("Falha ao re-enviar SMS!");
    } finally {
      setIsLoading(false);
      setPopUpIsOpen(true);
      setCountResendSMS((prev) => ++prev);
    }
  });

  /*const handleReenviar = () => {
    sendNotification();
  };*/

  useEffect(() => {
    if (chronometer === 0) {
      clearInterval(chronometerInterval);
      setChronometer(60);
    }
  }, [chronometer, chronometerInterval]);

  const handleDisableButtonResendSMS = () => {
    setResendButtonIsDisabled(true);
    setTimeout(() => setResendButtonIsDisabled(false), 60000);
    const interval = setInterval(() => setChronometer((prev) => --prev), 1000);
    setChronometerInterval(interval);
  };

  const returnBodyTextStartValidation = () => {
    return (
      <div>
        <p style={{ textAlign: "center" }}>
          Faremos uma cobrança no teu cartão <br />
          entre R$0,01 e R$ 5,00.
        </p>
        <p style={{ textAlign: "center" }}>
          Verifique o valor cobrado no extrato e informe na <br />
          próxima tela.
        </p>
        <br />
      </div>
    );
  };

  const returnBodyTextInputValue = () => {
    return (
      <div>
        <br />
        <br />
        <p style={{ textAlign: "center" }}>
          Digite o valor cobrado no teu cartão final{" "}
          {state?.data?.numeroCartao.slice(-4)} com a <br />
          descrição teuapp nova era:
        </p>
      </div>
    );
  };

  const errorSendCode = (
    <div>
      Não foi possível validar o cartão de Crédito!
      <br />
      Tente novamente mais tarde!
    </div>
  );

  const sendTransaction = async () => {
    try {
      const response = await postSendVerificationValue.callApi({
        idCartao: cardId,
      });
      if (response.status >= 200 && response.status <= 300) {
        setValidationValue(true);
      } else {
        setPopUpSendError(true);
        setPopupErrorMesssage(response.mensagem ? response.mensagem : null);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const validateTransaction = async () => {
    try {
      const response = await postValidateTransaction.callApi({
        idCartao: cardId,
        valor: parseFloat(valueCharged?.replace("R$", "").replace(",", ".")),
      });
      if (response.status >= 200 && response.status <= 300) {
        setPopCardValidate(true);
      } else {
        setPopCardNotValidate(true);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const onValidationValue = () => {
    sendTransaction();
  };

  const reverseTransaction = async () => {
    try {
      const response = await postReverseCharge.callApi();
      if (response.status >= 200 && response.status <= 300) {
        setPopCardValidate(false);
        setPopCardNotValidate(true);
      } else {
        setPopCardValidate(false);
        setPopCardNotValidate(true);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <LayoutOnboarding showBackGroundBar={false}>
      <NewTwoButtonPopup
        HeaderText="Validação do cartão!"
        subtitle="Cartão cadastrado com sucesso!"
        bodyText={returnBodyTextStartValidation()}
        footerText="Não se preocupe, a cobrança será extornada!"
        leftButtonText="Validar depois"
        rightButtonText="Validar agora"
        visibility={validationCard}
        setVisibility={(val) => setValidationCard(val)}
        confirm={() => onValidationValue()}
        onClickCancel={() => setPopCardNotValidate(true)}
        loading={postSendVerificationValue.loading}
      />
      <NewTwoButtonPopup
        HeaderText="Validação do cartão!"
        bodyText={returnBodyTextInputValue()}
        leftButtonText="Cancelar"
        rightButtonText="Confirmar"
        visibility={validationValue}
        inputValue={true}
        sendValue={(value) => {
          setValueCharged(value);
        }}
        setVisibility={(val) => setValidationValue(val)}
        confirm={validateTransaction}
        onClickCancel={() => reverseTransaction()}
        loading={postValidateTransaction.loading}
      />
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={popupErrorMesssage}
        buttonText="Entendi"
        visibility={showPopup}
        setVisibility={setShowPopup}
      />
      <OneButtonPopup
        slug="/add-credit-card"
        icon={themes.components.icons.warning}
        text={`Erro ao criar cartão. ${popupErrorMesssage}`}
        buttonText="Entendi"
        visibility={popUpCardError}
        setVisibility={setPopUpCardError}
      />
      <NewOneButtonPopup
        slug="/carteira-digital-home"
        title={"O cartão não foi validado!"}
        text={`O Pagamento das compras só será permitido após concluir a validação do cartão.`}
        buttonText="Entendi"
        visibility={popUpCardNotValidate}
        setVisibility={setPopCardNotValidate}
        imgIcon={assets.images.warningLending}
      />
      <NewOneButtonPopup
        slug="/carteira-digital-home"
        title={"O cartão não foi validado!"}
        text={errorSendCode}
        buttonText="Entendi"
        visibility={popUpSendError}
        setVisibility={setPopUpSendError}
        onClick={() => setPopUpSendError(false)}
        imgIcon={assets.images.warningLending}
      />
      <NewOneButtonPopup
        slug="/carteira-digital-home"
        title={"Cartão validado com sucesso!"}
        text={`Tudo certo! Agora você pode utilizar a vontade para pagar tuas compras.`}
        buttonText="Entendi"
        visibility={popUpCardValidate}
        setVisibility={setPopCardValidate}
        imgIcon={assets.images.successValidation}
      />
      <OneButtonPopup
        slug="/carteira-digital-home"
        icon={themes.components.icons.warning}
        text={`Cartão cadastrado com sucesso!`}
        bodyText={`Pronto, teu cartão de crédito foi cadastrado com sucesso e você pode utilizá-lo para pagar tuas compras.`}
        buttonText="Entendi"
        visibility={popUpSuccess}
        setVisibility={setPopUpSuccess}
      />
      <S.Content>
        <S.MainText>
          <p>Validação de segurança</p>
        </S.MainText>
        <S.MiddleText>
          <p>
            Insira o código enviado por WhatsApp no teu celular para cadastrar
            um cartão.
          </p>
        </S.MiddleText>
        <S.Input>
          <InputOnboarding
            type="text"
            label="Código de verificação"
            maxLength={7}
            placeholder="000 000"
            value={verificactionCode}
            onChange={(e) =>
              setVerificactionCode(verificationCodeMask(e.target.value))
            }
            error={error}
          />
        </S.Input>
        <S.MiddleText>
          <S.ResendCode disabled={resendButtonIsDisabled}>
            <p className="help-message">
              {" "}
              Não recebeu o WhatsApp? Você pode{" "}
              <span onClick={reSendSMS}>reenviar</span>.{" "}
              {chronometer !== 60 && `Aguarde ${chronometer}.`}
            </p>
          </S.ResendCode>
        </S.MiddleText>
        <S.Alert>
          <Alert
            message="Caso não receba o código no teu WhatsApp, o reenvio será feito por SMS."
            type="info"
            showIcon
          />
        </S.Alert>
        <S.Footer>
          <Button
            onClick={() => handleValidateFields()}
            isLoading={putCodeValidateCPF.loading}
            disabled={!verificactionCode}
          >
            Continuar
          </Button>
        </S.Footer>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default AddCreditCardVerificationCode;
