const MONTHS = ["", "JAN", "FEV", "MAR", "ABR", "MAIO", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];

export const convertBalance = (value) => {
    if (value) {
        return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
        }).format(value);
    } else {
        return "0,00";
    }
};

export const convertDate = (value) => {
    const newDate = new Date(value)
    return {        
        mounted_date: `${newDate.getDate()} ${MONTHS[newDate.getMonth()+1]}, ${newDate.getFullYear()}`,
        month_in_extension: MONTHS[newDate.getMonth()+1],
        day: newDate.getDate(),
        month: newDate.getMonth()+1,
        year: newDate.getFullYear(),
        hour: newDate.getHours(),
        minutes: newDate.getMinutes(),
        seconds: newDate.getSeconds()
    };
}