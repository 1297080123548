export default {
  ID_ORIGEM: "@NovaEra:idOrigem",
  ID_PROPOSTA: "@NovaEra:idProposta",
  PROPOSTA: "@NovaEra:proposta",
  EMAIL_PROPOSTA: "@NovaEra:emailProposta",
  USER: "@NovaEra:user",
  TOKEN: "@NovaEra:token",
  TOKEN_USER: "@NovaEra:tokenUser",
  TOKEN_BEARER: "@NovaEra:tokenBearer",
  CODE_VERIFICATION: "@NovaEra:codeVerification",
  X_CODE: "@NovaEra:xCode",
  FACE_ID: "@NovaEra:faceID",
};
