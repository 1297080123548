export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'TEUCARD',
    headline: 'Crie agora a Tua carteira digital',
    description:
      'Com o app do TeuCard você pode utilizar cartões de outros bancos pela Tua Carteira digital e realizar compras utilizando o QR Code.',
    buttonLabel: 'Instale o app e aproveite as vantagens',
    imgStart: '',
    img: '',
    alt: '',
    start: ''
  };