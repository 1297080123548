import { CustomizedError } from "~/utils/errors";
import { usuarioAPI, emailAPI, clienteAPI } from "./api";

export const getUserByCPF = async (cpfClient) => {
  const response = await usuarioAPI.get(
    `/v1/usuarios/noauth/usuario-porCpf/${cpfClient}`
  );
  switch (response.status) {
    case 200:
      return { data: response.data };
    case 201:
      return { data: response.data };
    default:
      throw CustomizedError({
        message: response.data.mensagem || response.status,
      });
  }
};

export const getClientByCPF = async (cpfClient) => {
  if (!cpfClient) {
    let user = localStorage.getItem("@NovaEra:infoUsuario");
    if (user) {
      user = JSON.parse(user);
      cpfClient = user.cpf;
    }
  }
  const response = await clienteAPI.get(
    `/v1/clientes/buscar-cliente-cpf/${cpfClient}`
  );
  console.log(response)
  switch (response.status) {
    case 200:
      return { data: response.data };
    case 404:
      return { status: response.status };
    default:
      throw CustomizedError({
        message: response.data.mensagem || response.status,
      });
  }
};

export const forgotPassword = async (emailUsuario) => {
  const response = await emailAPI.post(
    `/v1/email/noauth/link-senha-email-cartao/${emailUsuario}`
  );

  switch (response.status) {
    case 204:
      return { data: response.data };
    default:
      throw CustomizedError({
        message: response.data.mensagem || response.status,
      });
  }
};

export const defineNewPassword = async (idUsuario, novaSenha) => {
  const response = await usuarioAPI.post(
    `/v1/usuarios/noauth/confirmar-alterar-senha-cartao/${idUsuario}`,
    {
      novaSenha: novaSenha,
    }
  );

  switch (response.status) {
    case 200:
      return { data: response.data };
    case 201:
      return { data: response.data };
    default:
      throw CustomizedError({
        message: response.data.mensagem || response.status,
      });
  }

};

export const validatePasswordUser = async (senha, cpf) => {
  const response = await usuarioAPI.post(
    "/v1/usuarios/noauth/validar-senha-usuario",
    {
      senha: senha,
      cpf: cpf
    }
  );

  switch (response.status) {
    case 204:
      return { status: 204 };
    default:
      throw CustomizedError({
        message: response.data.mensagem || response.status,
      });
  }
};

export const changePasswordUser = async (senha) => {
  let user = localStorage.getItem("@NovaEra:infoUsuario");
  user = JSON.parse(user);
  const response = await usuarioAPI.post(
    "/v1/usuarios/noauth/alterar-senha-usuario",
    {
      idUsuario: user.id,
      novaSenha: senha
    }
  );

  switch (response.status) {
    case 204:
      return { status: 204 };
    default:
      throw CustomizedError({
        message: response.data.mensagem || response.status,
      });
  }
};

export const getClientByCPFOnboarding = async (cpfClient) => {
  if (!cpfClient) {
    let user = localStorage.getItem("@NovaEra:infoUsuario");
    if (user) {
      user = JSON.parse(user);
      cpfClient = user.cpf;
    }
  }
  const response = await clienteAPI.get(
    `/v1/clientes/buscar-cliente-cpf/${cpfClient}`
  );

  switch (response.status) {
    case 200:
      return { data: response.data };
    default:
      throw CustomizedError({
        message: response.data.mensagem || response.status,
      });
  }
};

export const getUserIdentification = async (cpfClient, tipoProduto) => {
  tipoProduto = sessionStorage.getItem("tipoProdutoSession");
  if (tipoProduto !== "") {
    tipoProduto = "CARTEIRA_DIGITAL";
  }
  if (!cpfClient) {
    let user = localStorage.getItem("@NovaEra:infoUsuario");

    if (user) {
      user = JSON.parse(user);
      cpfClient = user.cpf;
    }
  }
  console.log(tipoProduto)

  const response = await clienteAPI.get(
    `/v1/clientes/noauth/buscar-dados-identificacao-carteira/${cpfClient}?tipoProduto=${tipoProduto}`
  );
  switch (response.status) {
    case 200:
      return { data: response.data };
    case 404:
      return { status: response.status };
    default:
      throw CustomizedError({
        message: response.data.mensagem || response.status,
      });
  }
};
