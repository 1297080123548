import styled from "styled-components";
import theme from "~/themes";

const height = "28px";
const thumbHeight = 20;
const trackHeight = "12px";

// colours
const upperColor = "#ccc";
const thumbColor = "#ddd";
const thumbHoverColor = "#ccc";
const upperBackground = `linear-gradient(to bottom, ${upperColor}, ${upperColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;
const lowerBackground = `linear-gradient(to left, #E71037, #E71037) 100% 50% / 100% ${trackHeight} no-repeat transparent`;

const makeLongShadow = (color, size) => {
  let i = 10;
  let shadow = `${i}px 0 0 ${size} ${color}`;

  for (; i < 706; i++) {
    shadow = `${shadow}, ${i}px 0 0 ${size} ${color}`;
  }

  return shadow;
};

export const Container = styled.div`
  display: grid;
  place-items: center;
`;

export const Slider = styled.input`
 overflow: hidden;
  display: block;
  appearance: none;
  max-width: 700px;
  width: 100%;
  margin: 0;
  height: ${height};
  cursor: pointer;
  border-radius: 40px;

&:focus {
  outline: none;
}

&::-webkit-slider-runnable-track {
  width: 100%;
  height: ${height};
  background: ${lowerBackground};
}

&::-webkit-slider-thumb {
  position: relative;
  appearance: none;
  height: ${thumbHeight}px;
  width: ${thumbHeight}px;
  background: linear-gradient(to right, #E71037, #E71037);
  border-radius: 100%;
  border: 0;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: ${makeLongShadow(upperColor, "-4px")};
  transition: background-color 150ms;
}

&::-moz-range-track,
&::-moz-range-progress {
  width: 100%;
  height: ${height};
  background: ${upperBackground};
}

&::-moz-range-progress {
  background: ${lowerBackground};
}

&::-moz-range-thumb {
  appearance: none;
  margin: 0;
  height: ${thumbHeight};
  width: ${thumbHeight};
  background: ${thumbColor};
  border-radius: 100%;
  border: 0;
  transition: background-color 150ms;
}

&::-ms-track {
  width: 100%;
  height: ${height};
  border: 0;
  /* color needed to hide track marks */
  color: transparent;
  background: transparent;
}

&::-ms-fill-lower {
  background: ${lowerBackground};
}

&::-ms-fill-upper {
  background: ${upperBackground};
}

&::-ms-thumb {
  appearance: none;
  height: ${thumbHeight};
  width: ${thumbHeight};
  background: ${thumbColor};
  border-radius: 100%;
  border: 0;
  transition: background-color 150ms;
  /* IE Edge thinks it can support -webkit prefixes */
  top: 0;
  margin: 0;
  box-shadow: none;
}

&:hover,
&:focus {
  &::-webkit-slider-thumb {
    background-color: ${thumbHoverColor};
  }
  &::-moz-range-thumb {
    background-color: ${thumbHoverColor};
  }
  &::-ms-thumb {
    background-color: ${thumbHoverColor};
  }
`;

export const Title = styled.h1`
  display: flex;
  justify-content: center;
  font-size: 31px;
  color: ${theme.colors.orange.n2};
  font-weight: 700;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  p {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
  }
`;
