/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import Layout from "~/components/Layout";
import ContainerBox from "~/components/ContainerBox";
import TwoButtonPopup from "~/components/Modals/TwoButtonPopup";
import PasswordPopup from "~/components/Modals/PasswordPopup";
import { Link, useHistory } from "react-router-dom";
import { validatePassword } from "~/services/card";
import useApi from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import Loader from "~/components/Loader";
import * as S from "./styles";
import themes from "~/themes";
import { openInNewTab } from "~/utils/functions";

const Document = () => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
  const [blockPopupVisibility, setTwoButtonPopupVisibility] = useState(false);
  const [passwordPopupVisibility, setPasswordPopupVisibility] = useState(false);
  const [proposalID, setProposalID] = useState(null);
  const [cardCategory, setCardCategory] = useState();
  const [benefits, setBenefits] = useState([]);
  const [passwordSlug, setPasswordSlug] = useState("");

  const contentDocument = [
    {
      id: "Terms",
      title: "",
      value: "Quero visualizar os termos de uso do cartão",
      route: "/terms",
    },
    {
      id: "privacyPolicy",
      title: "",
      value: "Quero visualizar a política de privacidade",
      route: "/privacy",
    },
    {
      id: "cardContract",
      title: "",
      value: "Quero visualizar o contrato do cartão",
      route: "/contract",
    },
    {
      id: "withdrawContract",
      title: "",
      value: "Quero visualizar o empréstimo TeuCard",
      route: "/contract-withdraw",
    },
  ];

  const GetPlatinumTerms = useApi(
    `${Endpoints.cliente.getPlatinumTerms.replace(
      "ID_PROPOSAL",
      proposalID
    )}`,
    "GET"
  )

  const getPlatinumTerms = async () => {
    try {
      const response = await GetPlatinumTerms.callApi();
      if (response.status >= 200 && response.status <= 300) {
        openInNewTab(response.response)
    }} catch (err) {
      console.error(err.message);
    }
  }

  if (benefits) {
    for (let benefit of benefits.filter(
      (e) => e.statusBeneficio !== "CANCELADO"
    )) {
      const isTeuCardOdonto = benefit.idBeneficioAutorizador === 1;
      const isTeuCardCuida = benefit.idBeneficioAutorizador === 5;

      if (isTeuCardOdonto && isTeuCardCuida && benefit.linkContrato) {
        contentDocument.push({
          title: "",
          id: `contract${benefit.id}`,
          value: `Quero visualizar o contrato ${benefit.nome}`,
          icon: themes.components.icons.consultGrey,
          link: benefit.linkContrato,
        });
      }
      if (isTeuCardOdonto && isTeuCardCuida && !benefit.linkContrato) {
        contentDocument.push({
          id: `contractOdontoOld`,
          value: `Quero visualizar o contrato ${benefit.nome}`,
          icon: themes.components.icons.consultGrey,
          route: "/contract",
        });
      }
      if (benefit.linkContrato && !isTeuCardOdonto) {
        contentDocument.push({
          id: `contract${benefit.id}`,
          value: `Quero visualizar o contrato seguro ${benefit.nome}`,
          icon: themes.components.icons.consultGrey,
          link: benefit.linkContrato,
        });
      }
      if (benefit.linkBilhete) {
        contentDocument.push({
          id: `contract${benefit.id}`,
          value: `Quero visualizar o Bilhete seguro ${benefit.nome}`,
          icon: themes.components.icons.consultGrey,
          link: benefit.linkBilhete,
        });
      }
    }
  }

  if(cardCategory){
    contentDocument.push({
      title: "",
      id: `platinum`,
      value: `Quero visualizar o termo de adesão ao plano Platinum`,
      icon: themes.components.icons.consultGrey,
      function: getPlatinumTerms,
    });
  }

  const RequestGetClientByCPF = useApi(
    Endpoints.cliente.getClientByCPF.replace("PARAM_CPF", user?.cpf),
    "GET"
  );

  const GetContractedBenefits = useApi(
    `${Endpoints.proposta.getContractedBenefits.replace(
      "PARAM_ID_PROPOSTA",
      proposalID
    )}`,
    "GET"
  );

  useEffect(() => {
    if (proposalID) {
      (async () => {
        const getBenefits = async () => {
          try {
            const response = await GetContractedBenefits.callApi();
            if (response.status >= 200 && response.status <= 300) {
              setBenefits(response?.data?.beneficios);
            }
          } catch (err) {
            console.error(err.message);
          }
        };
        getBenefits();
      })();
    }
  }, [proposalID]);

  useEffect(() => {
    (async () => {
      const response = await RequestGetClientByCPF.callApi();
      setProposalID(response?.data?.propostaId);
      if(response?.data?.categoriaCartao === "PLATINUM"){
        setCardCategory(response?.data?.categoriaCartao);
      }    
    })();
  }, []);

  function handleClick(click) {
    if (click === "block") {
      setTwoButtonPopupVisibility(!blockPopupVisibility);
    }
  }

  const handleConfirmBlockModal = () => {
    setTwoButtonPopupVisibility(false);
    setPasswordPopupVisibility(true);
    setPasswordSlug("/cartao-motivo-bloqueio");
  };

  const handlePasswordPopup = async (password) => {
    await validatePassword(password);
    const parentPath = history.location.pathname;
    history.push(passwordSlug, { password, parentPath });
  };

  return (
    <Layout showBackGroundBar={false}>
      <ContainerBox title="Documentos" styleContainer={{display: 'flex', height: 'auto'}}>
        {contentDocument.map((itemData) => (
          <S.RowInfo key={itemData.id}>
            <div>
              <span>{itemData.title}</span>
              <p>{itemData.value}</p>
            </div>
            {itemData.route && (
              <Link to={itemData.route}>
                {themes.components.icons.chevronRight}
              </Link>
            )}
            {itemData.link && (
              <a href={itemData.link} target="_blank">
                {themes.components.icons.chevronRight}
              </a>
            )}
            {itemData.function && (
              <a onClick={itemData.function} target="_blank">
                {themes.components.icons.chevronRight}
              </a>
            )}
            {itemData.click && (
              <Link onClick={() => handleClick(itemData.click)}>
                {themes.components.icons.chevronRight}
              </Link>
            )}
          </S.RowInfo>
        ))}
        {GetContractedBenefits.loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
              gap: "10px",
            }}
          >
            <Loader /> <span>Carregando documentos...</span>
          </div>
        )}
      </ContainerBox>

      <TwoButtonPopup
        bodyText="Você quer bloquear seu cartão?"
        leftButtonText="Cancelar"
        rightButtonText="Bloquear"
        visibility={blockPopupVisibility}
        setVisibility={setTwoButtonPopupVisibility}
        confirm={handleConfirmBlockModal}
      />
      <PasswordPopup
        onClick={handlePasswordPopup}
        text="Continuar"
        slug={passwordSlug}
        visibility={passwordPopupVisibility}
        setVisibility={setPasswordPopupVisibility}
      />
    </Layout>
  );
};

export default Document;
