import { CustomizedError } from "~/utils/errors";
import { produtosAPI, usuarioAPI } from "./api";

export const productsCustomerNotHave = async (cpf) => {
    const response = await produtosAPI.get(
      `produtos-cliente-nao-tem/${cpf}`
    );

    const status = response.status;

    const urlProducts = new Array();

    switch (status) {
    case 200:
        for(let i = 0; i < response.data.listaProdutos.length; i++){
            urlProducts[i] = response.data.listaProdutos[i].imagem;
        }
        return urlProducts;
    default:
      throw new CustomizedError({ message: response.data.mensagem || status });
  }
};


export const availableProducts  = async () => {
    const response = await produtosAPI.get(
      `v1/produtos/listar-produtos-ativos`
    );
    const status = response.status;

    const urlAvailableProducts = new Array();
  
    switch (status) {
      case 200:
          for(let i = 0; i < response.data.produtos.length; i++){
              urlAvailableProducts[i] = response.data.produtos[i];
          }
          return urlAvailableProducts;
      default:
        throw new CustomizedError({ message: response.data.mensagem || status });
    }
  
      };
      