import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TypeButton = styled.button`
  background: #fff;
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px 13px 20px;

  gap: 20px;
  justify-content: space-between;

  width: 380px;
  height: 107px;

  :last-child {
    gap: 10px;
  }

  :disabled {
    background: rgba(222, 222, 222, 0.5);
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 20px;
  align-items: flex-start;
  width: 255px;

  h1 {
    font-size: 16px;
    text-align: left;
    line-height: 17.64px;
    color: ${(props) => (props.disabled ? "rgba(38, 50, 56, 0.7)" : "#263238")};
    margin: 0;
  }

  p {
    color: #263238;
    font-size: 14px;
    margin: 0;
    text-align: left;
  }
`;

export const Icon = styled.img`
  width: 43px;
  height: 43px;
  align-content: center;

  > svg {
    margin-left: auto;
  }
`;
