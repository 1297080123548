import styled, { css } from 'styled-components';

const DefaultTabStyle = css`
  padding: 8px 14px;
  margin: 0 2px 20px 2px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 5px;
`;

export const Tabs = styled.div`
  ${DefaultTabStyle}
  border: 1px solid;
  color: ${({ selected }) => (selected ? 'white' : 'black')};
  background-color: ${({selected}) => (selected ? '#E71037' : 'white')};

  :hover {
    background-color: ${({ selected }) =>
      selected ? 'filter: brightness(3);' : '#e6e6e6'};
  }
`;

export const CustomTabs = styled.div`
  border: 1px solid #e6e6e6;
  ${DefaultTabStyle}

  :hover {
    background-color: '#E71037';
    color: #fff;
  }
`;
