// profile
import { CustomizedError } from "~/utils/errors";
import { clienteAPI, usuarioAPI, autorizadorAPI } from "./api";

export const dataUserGet = async () => {
  const idUsuario = localStorage.getItem("@NovaEra:idUsuario");
  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));

  try {
    const res = await clienteAPI.get(
      `/v1/clientes/retornar-cliente-cpf/${user?.cpf}`
    );

    return res;
  } catch (err) {
    return err.response.data.message;
  }
};

export const dataUserPUT = async (body) => {
  try {
    const idUsuario = localStorage.getItem("@NovaEra:idUsuario");
    await autorizadorAPI.put(
      `/v1/autorizador/perfil-cliente/${idUsuario}`,
      body
    );

    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getUserPicture = async () => {
  const infoUSer = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));

  const response = await usuarioAPI.get(
    `/v1/usuarios/usuario-perfil?id=${infoUSer.id}`
  );

  switch (response.status) {
    case 200:
      try {
        const userInfo = JSON.parse(
          localStorage.getItem("@NovaEra:infoUsuario")
        );
        localStorage.setItem(
          "@NovaEra:infoUsuario",
          JSON.stringify({
            ...userInfo,
            fotoPerfil: response.data.usuarioFotoPerfil,
          })
        );
        return { status: 200, data: response.data };
      } catch (err) {
        console.log(err);
        return false;
      }
    default:
      throw CustomizedError({ message: response.status });
  }
};

export const editUserPicture = async (usuarioFoto) => {
  const infoUser = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));

  const response = await usuarioAPI.post(`/v1/usuarios/editar-foto`, {
    id: infoUser.id,
    usuarioFoto,
  });

  switch (response.status) {
    case 200:
      return { status: 200 };
    default:
      throw CustomizedError({ message: response.status });
  }
};
