import styled from "styled-components";
import themes from "~/themes";

export const ContentScreens = styled.div`
  display: flex;
  flex-direction: column;
  padding: 53px;
  flex: 1;
  height: 100%;
  min-height: 700px;

  h1 {
    color: #4a4b4a;
    font-size: 27px;
    font-weight: 700;
    letter-spacing: -0.86px;
  }
`;

export const LendingBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 88px;
`;

export const TransactionsDate = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 70%;
`;

export const DateStyle = styled.p`
  color: ${themes.components.colors.transaction.primary};
  font-size: 15px;
  letter-spacing: 0;
  line-height: 23px;
`;

export const Transaction = styled.div`
  display: flex;
  flex-direction: row;
  align-item: center;
  width: 100%;
  gap: 20px;
  padding: 10px;

  span {
    display: flex;
    width: 60px;
    height: 60px;

    svg {
      margin: 0 auto;
      width: 50px;
      height: 50px;
      box-shadow: 4px 3px 8px 0 rgba(127, 127, 127, 0.5);
      border-radius: 10px;

      path {
        //fill: ${themes.components.colors.link};
      }
    }
  }
`;

export const Description = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;

  label {
    font-weight: 700;
    font-size: 17px;
    width: 100%;
    height: 50%;
    margin-bottom: 10px;
  }

  span {
    width: 100%;
    height: 50%;
  }
`;

export const TransactionValue = styled.span`
  width: 20%;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
`;

export const WrapperLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 350px;
`;

export const EmptyText = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 23px;
`;

export const Footer = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;

  div {
    width: 40%;
  }
`;
