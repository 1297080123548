import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 40px;
  margin-top: 25px;
  h4 {
    color: #4a4b4a;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 25px;
  }
`;

export const TitleStyle = styled.span`
  width: 200px;
  color: #BBBBBB;
  font-family: 'Marine Rounded';
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
`;

export const Carteiras = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 20px;
`;

export const CadastrarCartao = styled.button`
  width: 180px;
  height: 40px;
  background-color: #E4003A;
  border-style: none;
  border-radius: 8px;
  font-weight: 700;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
`;

export const CardCredit = styled.div`
  width: 380px;
  height: 200px; 
  background-color: black;
  border-radius: 10px;
  margin-right: 20px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
`;

export const NoContentContainer = styled.div`
  position: absolute;
  width: 514px;
  height: 600px;
  left: 38%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const NoContent = styled.div`
  width: 100%;
  height: 312.07px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #F5F5F5;
  box-shadow: 5px 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(54px);
  border-radius: 8px;
  margin-bottom: 25px;

 h3 {
  width: 350px;
  height: 73.43px;
  left: 494.21px;
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
  text-align: center;
  color: #4A4B4A;
 }

 p {
  width: 407.53px;
  height: 47.02px;
  left: 516.24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #9E9E9E;
 }
`;

export const WrapperLoader = styled.div`
  position: absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  height: 100px;
  left: 38%;
`;
