import styled from "styled-components";
import themes from "~/themes";

export const ExpandableBoxWrapper = styled.div`
  overflow: hidden;
  transition: width 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 90px;
  width: 90px;
  margin-left: 30px;
  margin-right: 30px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  background: #f6f6f6;
  box-shadow: 1px 3px 8px 0 rgba(201, 201, 201, 0.5);
  transition: width 0.7s;

  &:hover {
    width: 200px;
  }
  .circle {
    width: ${(props) => (props.expanded ? "100%" : "30px")};
    height: ${(props) => (props.expanded ? "100%" : "30px")};
    border-radius: ${(props) => (props.expanded ? "8px" : "50%")};
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(to bottom, #CDD5E0, #a9b7c7); 
    box-shadow: 1px 3px 8px 0 rgba(201, 201, 201, 0.5);
    transition: all 0.7s ease; /* Ajuste a propriedade 'ease' para obter uma animação mais suave */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: ${(props) => (props.expanded ? "0px" : "30px")};
  }

  .content {
    width: ${(props) => (props.expanded ? "0px" : "80px")};
    display: flex;
    box-sizing: border-box;
    align-items: ${(props) => (props.expanded ? "flex-start" : "center")};
    justify-content: ${(props) => (props.expanded ? "flex-start" : "center")};
    margin-top: 70px;
    position:absolute;
    
`;

export const Header = styled.div`
  width: 100%;
`;

export const Title = styled.p`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 2px;
  margin-left: 5px;
  margin-top: 10px;
`;

export const Trace = styled.p`
  width: 94%;
  height: 0.4px;
  background: black;
`;

export const Body = styled.div`
  width: 100%;
  align-items: flex-start;
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TitleBody = styled.p`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 2px;
  margin-left: 5px;
`;

export const TitleBalance = styled.p`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 2px;
  margin-right: 5px;
`;

export const BalanceValue = styled.p`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 2px;
  margin-right: 5px;
  margin-top: 5px;
`;

export const NotSigned = styled.p`
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: ${themes.colors.red.n1};
`;

export const PhoneNumber = styled.p`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 2px;
  margin-left: 5px;
  margin-top: 5px;
`;

export const HeaderIcon = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 90px;
  margin-left: 30px;
  margin-right: 30px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  background: #f6f6f6;
  box-shadow: 1px 3px 8px 0 rgba(201, 201, 201, 0.5);

  p {
    color: ${themes.components.colors.newLink};
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0;
    line-height: 13px;
    margin-top: 20px;
  }

  &:hover {
    cursor: pointer;
  }
`;
