const colors = {
  orange: {
    n1: "rgba(231,16,55,0.5)",
    n2: "#E71037",
    n3: "#f35875",
  },
  black: {
    n1: "#4A4B4A",
    n2: "#000",
  },
  gray: {
    n1: "#757575",
    n2: "#727272",
    n3: "#B4B4B4",
    n4: "#AFAFAF",
    n5: "#444444",
  },
  white: {
    n1: "#FFFFFF",
    n2: "#F9F9F9",
    n3: "#E7E7E7",
    n4: "#DEDEDE",
    n5: "#F6F6F6",
  },
  red: {
    n1: "#ED4848",
  },
  green: {
    n1: "#00A857",
    n2: "#7ba03b",
  },
  yellow: {
    n1: "#fece00",
  },
};

export default colors;
