import React from "react";

import { moneyMask } from "utils/mask";

import * as S from "./styles";

const Slider = ({ min, max, selected, handleChange, step, value }) => {
  return (
    <S.Container>
      <S.Title>{`R$ ${selected}`}</S.Title>
      <S.Slider
        type="range"
        value={value}
        min={min}
        max={max}
        onChange={handleChange}
        step={step}
      />
      <S.LabelContainer>
        <p>{moneyMask(min)}</p>
        <p>{moneyMask(max)}</p>
      </S.LabelContainer>
    </S.Container>
  );
};

export default Slider;

