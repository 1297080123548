import React from "react";
import { Link} from "react-router-dom";
import Layout from "~/components/Layout";
import ContainerBox from "~/components/ContainerBox";
import themes from "~/themes";
import * as St from "./styles";

const CarteiraDigitalDocuments = props => {

    const contentDocument = [
        {
            id: 0,
            title: "",
            value: "Quero visualizar os termos de uso da carteira",
            route: "/carteira-digita-terms",
        },
        { id: 1, title: "", value: "Quero visualizar o contrato da carteira", route: "/carteira-digital-contract", },
    ];

    return (
        <>
            <Layout               
                showBackGroundBar={false}
                productType='Carteira digital'
            >
                <ContainerBox title="Documentos">
                    {contentDocument.map((itemData) => (
                        <St.RowInfo key={itemData.id}>
                            <div>
                                <span>{itemData.title}</span>
                                <p>{itemData.value}</p>
                            </div>
                            {itemData.route && (
                                <Link to={itemData.route}>
                                    {themes.components.icons.chevronRight}
                                </Link>
                            )}
                        </St.RowInfo>
                    ))}
                </ContainerBox>
            </Layout>
        </>
    )
}
export default CarteiraDigitalDocuments