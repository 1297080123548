/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Button, { BUTTON_TYPES } from "~/components/Button";
import Loader from "~/components/Loader";

import { moneyMask } from "~/utils/mask";
import moment from "moment";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { GlobalContext } from "~/contexts/GlobalState";

import * as St from "../../styles";
import themes from "~/themes";

const CancelWithdraw = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const userInfoArray = JSON.parse(
    localStorage.getItem("@NovaEra:infoUsuario")
  );

  const history = useHistory();

  const { state } = useLocation();

  const { addLending } = useContext(GlobalContext);

  const RequestPendingLending = useApi(
    Endpoints.lendings.getPendingLending.replace(
      "PARAM_CPF",
      userInfoArray.cpf
    ),
    ApiMethod.GET
  );

  const RequestCancelWithdraw = useApi(
    Endpoints.lendings.putCancelWithdraw.replace("PARAM_ID", data?.id),
    ApiMethod.PUT
  );

  const handleCancelWithdraw = async () => {
    setButtonIsLoading(true);
    try {
      const response = await RequestCancelWithdraw.callApi();
      if (response.status >= 200 && response.status < 300) {
        history.push("/emprestimo/sucesso-saque", {
          onCancelSuccess: "CANCELADO",
        });
      }
      setButtonIsLoading(false);
    } catch (error) {
      console.log(error);
      setButtonIsLoading(false);
    }
  };

  useEffect(() => {
    const getPendingLending = async () => {
      setLoading(true);
      try {
        const response = await RequestPendingLending.callApi();
        const { data } = response;
        setData(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getPendingLending();
  }, [userInfoArray.cpf]);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <St.Content>
        <St.BoxContent>
          {loading ? (
            <St.WrapperLoader>
              <Loader size="large" />
            </St.WrapperLoader>
          ) : (
            <>
              <St.Title>Empréstimo contratado</St.Title>
              <St.Subtitle>
                Falta apenas a tua assinatura. Confira abaixo os valores para
                continuar:
              </St.Subtitle>
              <St.Column>
                <St.ContentValue borderBottom="0.5px #eeeeee solid">
                  <St.Value>
                    <h1>{moneyMask(data?.valorLiberado)}</h1>
                  </St.Value>
                </St.ContentValue>
                <St.ContentValue>
                  <St.Row justify="space-between">
                    <span>Valor da parcela</span>
                    <span>{`${data.numeroParcelas}x de ${moneyMask(
                      data?.valorParcela
                    )}`}</span>
                  </St.Row>
                  <St.Row justify="space-between">
                    <span>
                      <b>Valor cobrado na próxima Fatura</b>
                    </span>
                    <span>
                      <b>{moneyMask(data?.valorParcela)}</b>
                    </span>
                  </St.Row>
                  <St.Row justify="space-between">
                    <span>Vencimento</span>
                    <span>
                      {moment(data.dataPrimeiroPagamento, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </St.Row>
                  <St.Row justify="space-between">
                    <span>Taxa de juros</span>
                    <span>
                      {(data.taxaJuros * 100).toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                      })}
                      % a.m.
                    </span>
                  </St.Row>
                  <St.Row justify="space-between">
                    <span>CET</span>
                    <span>
                      {(parseFloat(data?.taxaJurosEfetivaMensal) * 100)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")}
                      % a.m.
                    </span>
                  </St.Row>
                </St.ContentValue>
                <St.ContentValue style={{ marginTop: "61px" }}>
                  <St.Row>
                    <img
                      src={themes.components.images.orangeCircle}
                      alt="Indicador"
                    />
                    <p>
                      Será cobrado o valor integral, em parcela única, na tua{" "}
                      {""}
                      <b>próxima fatura</b>.
                    </p>
                  </St.Row>
                </St.ContentValue>
                <St.Footer align="center" direction="column">
                  <div style={{ width: "50%" }}>
                    <Button
                      onClick={() => {
                        addLending({
                          idSimulacao: data.id,
                        });
                        history.push("/emprestimo/termos", {
                          idEmprestimoState: data?.id,
                          status: state?.status,
                        });
                      }}
                    >
                      <b>Quero contratar</b>!
                    </Button>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Button
                      type={BUTTON_TYPES.outlined}
                      onClick={handleCancelWithdraw}
                      isLoading={buttonIsLoading}
                    >
                      Quero cancelar
                    </Button>
                  </div>
                </St.Footer>
              </St.Column>
            </>
          )}
        </St.BoxContent>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default CancelWithdraw;
