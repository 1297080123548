import React from "react";

import Layout from "~/components/Layout";
import Button from "~/components/Button";

import * as S from "./styles";

const CancelAccount = () => {
  return (
    <Layout onlyHomeItem={true} showBackGroundBar={false}>
      <S.Container>
        <S.Title>Entre em contato para cancelar tua conta.</S.Title>
        <S.Description>
          Para cancelar tua conta, favor entrar em contato com a Central de
          Atendimento pelo nr. 4003-3203 ou pelo WhatsApp.
        </S.Description>
        <S.WrapperButton>
          <Button
            type="submit"
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send?phone=559240033203&text=Preciso de ajuda com meu TeuCard.",
                "_blank"
              )
            }
          >
            WhatsApp
          </Button>
        </S.WrapperButton>
      </S.Container>
    </Layout>
  );
};

export default CancelAccount;
