import styled from "styled-components";
import themes from "~/themes";

export const Content = styled.div`
  padding: 30px 49px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1100px) {
    padding: 10px;
  }

  @media (max-width: 600px) {
    padding: 0px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
  padding: 18px 150px;
  border-radius: 4px;
  align-items: center;
  gap: 20px;

  justify-content: ${(props) => (props.left ? "flex-start" : "center")};
  width: 100%;
  min-height: 650px;

  @media (max-width: 1100px) {
    gap: 10px;
    padding: 10px 0;

    p {
      font-size: 18px;
    }
  }

  @media (max-width: 800px) {
    gap: 5px;
    font-size: 8px;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 900;
  align-self: center;
  line-height: 22px;
  justify-content: center;
  color: #4a4b4a;
  text-align: center;
  width: 35%;

  @media (max-width: 1200px) {
    padding: 0px;
    font-size: 18px;
    width: 95%;
  }

  @media (max-width: 1100px) {
    padding: 0px;
    font-size: 18px;
    width: 90%;
  }

  @media (max-width: 600px) {
    padding: 0px;
    font-size: 16px;
    width: 100%;
  }
`;

export const BoxBandeiras = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media (max-width: 1200px) {
    gap: 13px;
    padding: 15px 0;
  }

  @media (max-width: 1100px) {
    gap: 13px;
    padding: 10px 0;
  }

  @media (max-width: 600px) {
    gap: 10px;
    padding: 0px 0;
  }
`;

export const Bandeiras = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 51px;
  width: 100%;

  @media (max-width: 1100px) {
    gap: 10px;

    img {
      width: 60px;
      height: 50px;
    }
  }

  @media (max-width: 600px) {
    gap: 10px;

    img {
      width: 40px;
      height: 48px;
    }
  }

  img {
    width: 67px;
    height: 58px;
    position: relative;
    top: 12px;
    justify-content: center;
    background-size: contain;
  }

  .diners {
    width: 175px;
    height: 42px;
  }

  .hipercard {
    width: 97px;
    height: 42px;
  }

  .elo {
    width: 90px;
    height: 40px;
  }

  img:last-child {
    width: 87px;
    height: 32px;
    position: relative;
    top: 18px;
  }
  :last-child {
    padding: 22px;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const MiddleText = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  width: 100%;

  p {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  }

  img {
    width: 8px;
    height: 8px;
    position: relative;
    top: 2px;
    right: 14px;
  }

  @media (max-width: 1200px) {
    p {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }
  }

  @media (max-width: 1100px) {
    p {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }
  }

  @media (max-width: 600px) {
    p {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }
  }
`;

export const Footer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.div`
  width: 100%;
`;

export const Terms = styled.div`
  width: 80%;
  min-height: 100px;
  gap: 20px;

  label {
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    margin-left: 30px;
    font-weight: 400;
  }

  @media (max-width: 1200px) {
    width: 100%;
    label {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      margin: 0;
    }
  }

  @media (max-width: 1100px) {
    width: 100%;
    label {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      margin: 0;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    label {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      margin: 0;
    }
  }
`;

export const BoxData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
  padding: 40.5px 46.5px;
  height: auto;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
  padding: 41px 0px 71px 48px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 600px) {
    padding-right: 10px;
    padding-left: 10px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 550px;
  padding-right: 72px;

  @media (max-width: 600px) {
    padding-right: 0px;
    padding-left: 0;
  }

  @media (max-width: 1100px) {
    margin-bottom: 50px;
  }

  :last-child {
    border-left: ${(props) =>
      props.noBorder ? "none" : "1px solid rgba(151, 151, 151, 0.52)"};
    padding-left: 30px;

    @media (max-width: 1100px) {
      border-top: ${(props) =>
        props.noBorder ? "none" : "1px solid rgba(151, 151, 151, 0.52)"};
      padding-top: 41px;
      border-left: none;
      padding-left: 0;
    }
  }
`;

export const SliderContainer = styled.div`
  margin-top: 62px;
  align-items: center;
  width: 100%;
`;

export const StarContainer = styled.div`
  display: flex;
  flex-directions: column;
  width: 100%;
  justify-content: center;
  margin-top: 62px;

  img {
    width: 86px;
    height: 86px;
  }
`;

export const DataContainer = styled.div`
  width: 100%;
  padding: 55px 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: space-between;

  .select {
    font-size: 16px;
    padding: 6px;
    border-color: ${(props) =>
      props.isFocus ? themes.colors.orange.n2 : "#444444"};
    color: ${themes.components.colors.input};
    height: 40px;
    width: 100%;
    background: ${themes.colors.white.n1};
    border-radius: 5px;
  }
`;

export const Data = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  span {
    font-size: 16px;
    font-weight: 400;
    color: #424242;
    line-height: 26px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    line-height: 21.77px;
    color: #424242;
    font-family: "IBM Plex Sans", sans-serif;
  }

  .value {
    font-size: 23px;
    color: #424242;
    font-weight: 700;
  }

  :last-child {
    border-bottom: ${(props) =>
      props.noBorder ? "none" : "1px solid rgba(151, 151, 151, 0.52)"};
  }
`;

export const FooterColumn = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

export const Submit = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  align-items: center;
  justify-content: center;

  span {
    font-size: 16px;
    font-weight: 400;
    color: #e71037;
    line-height: 26px;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

export const FooterSimulation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  align-items: center;

  margin-top: 60px;

  div {
    width: 50%;
  }
`;

export const TitlePersonalData = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: #4a4b4a;
  line-height: 40.52px;
  font-family: ${themes.components.fonts.marineRoundedBold};
`;

export const Subtitle = styled.div`
  fon-size: 14px;
  color: #4a4b4a;
`;

export const Form = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
  padding-bottom: 10px;
`;

export const WrapperLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 650px;
`;

export const BoxSimulation = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
  height: 90px;
  padding: 30px;
  border-radius: 30px;
  background-color: #739943;
  align-items: center;
  justify-content: space-between;
`;

export const BoxInfo = styled.div`
  border: 2px dashed #d4313e;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 40%;
  height: auto;
  justify-content: flex-start;
  border-radius: 20px;
  padding: 20px;
  gap: 30px;

  @media (max-width: 1100px) {
    gap: 40px;
    width: 50%;
  }

  @media (max-width: 600px) {
    gap: 20px;
    width: 70%;
  }
`;

export const Type = styled.div`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const TypeSimulation = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  label {
    font-weight: 400;
    font-size: 14px;
  }

  span {
    font-size: 14px;
    font-weight: 700;
  }

  :last-child {
    align-items: flex-end;
  }
`;

export const SimulationValue = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;

  color: #fff;
  gap: 3px;

  label {
    font-weight: 400;
    font-size: 14px;
  }

  span {
    font-size: 21px;
    font-weight: 700;
  }
`;

export const Ornament = styled.img`
  width: 60px;
  height: 60px;
`;

export const TypeContainer = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 20px;

  @media (max-width: 600px) {
    margin-top: 10px;
    gap: 10px;
  }
`;

export const Warning = styled.div`
  width: 50%;
  text-align: left;

  span {
    color: #e4003a;
    font-weight: 700;
  }
`;

export const ContentValue = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  flex: 1;
  gap: 20px;

  @media (max-width: 1100px) {
    margin-top: 10px;
    gap: 10px;
    padding: 0;
    width: 100%;
  }
  @media (max-width: 600px) {
    margin-top: 10px;
    gap: 10px;
  }
`;

export const BoxSuccess = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 20px;
  margin-bottom: 40px;
`;

export const ImageLogo = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  width: 20%;
  height: 25%;

  > img {
    flex: 1;
    display: flex;
    align-self: center;
  }

  @media (max-width: 760px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  align-items: center;
  justify-content: center;
  width: 27%;
  text-align: center;
  span {
    font-size: 22px;
  }
`;

export const IconType = styled.img`
  width: 43px;
  height: 43px;
`;

export const FooterData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: right;
  margin-top: 25px;

  div {
    width: 30%;
  }
`;
