import styled, { css } from 'styled-components';
import Popup from 'reactjs-popup';

const buttonConfig = css`
  padding: 7px 0px;
  font-size: 1.2em;
  border-radius: 5px;
  width: 48%;
`;

export const Container = styled(Popup)`
  &-content {
    width: 500px;
    padding: 0;
    border-radius: 10px;

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        font-weight: bold;
        font-size: 16px;
        margin-top: 40px;
      }

      h2 {
        font-weight: bold;
        font-size: 14px;
        color: #616161;
        margin-top: 10px;
      }

      footer {
        font-weight: bold;
        font-size: 14px;
        color: #616161;
        margin-top: 10px;
      }
    }

    hr {
      opacity: 0;
    }

    .input-value {
      padding: 30px;
      align-items: center;
      justify-content: center;
    }

    .button-container {
      margin: 0;
      padding: 25px;
      display: flex;
      justify-content: space-between;

      button {
        ${buttonConfig}
      }

      .left-button {
        border: 2px solid #E71037;
        background: #fff;
        color:#E71037;
      }

      .right-button {
        border: 2px solid #E71037;
        color: #fff;
      }
    }
  }
`;
