/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import * as St from "./styles";
import Chronometer from "./components/Chronometer";
import { WhiteFlash } from "./components/WhiteFlash";
import { getDataURI, playClickAudio } from "./helpers";
import themes from "~/themes";

export const FACING_MODES = {
  ENVIRONMENT: "environment",
  USER: "user",
};

if (navigator.mediaDevices.getUserMedia === undefined) {
  navigator.mediaDevices.getUserMedia = function (constraints) {
    var getUserMedia =
      navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

    if (!getUserMedia) {
      return Promise.reject(
        new Error("getUserMedia is not implemented in this browser")
      );
    }

    return new Promise(function (resolve, reject) {
      getUserMedia.call(navigator, constraints, resolve, reject);
    });
  };
}

function CameraComponent(
  {
    open,
    handleClose,
    onTakePhoto,
    mask,
    doNotCloseAfterTakePicture,
    certifaceMessagebase64,
    disableCloseButton,
    disableChangeCamButton,
    disableTakePictureButton,
    facingMode = FACING_MODES.ENVIRONMENT,
    stopWatch = false,
  },
  ref
) {
  const [cameraType, setCameraType] = useState(facingMode);
  const [isShowWhiteFlash, setIsShowWhiteFlash] = useState(false);
  const [messageImage, setMessageImage] = useState(
    certifaceMessagebase64 || null
  );

  useEffect(() => {
    if (certifaceMessagebase64) setMessageImage(certifaceMessagebase64);
  }, [certifaceMessagebase64]);

  useEffect(() => {
    if (open) {
      startCamera();
    }
  }, [open]);

  useImperativeHandle(ref, () => ({
    takePicture: () => {
      takePicture();
    },
    stopCamera: () => {
      onClickClose();
    },
  }));

  useEffect(() => {
    if (open) {
      startCamera();
    }
  }, [cameraType]);

  const startCamera = () => {
    const facingMode = getFacingMode();

    navigator.mediaDevices
      .getUserMedia(facingMode)
      .then(function (stream) {
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen();
        }

        window.localStream = stream;
        const video = document.getElementById("liveness");
        if ("srcObject" in video) {
          video.srcObject = stream;
        } else {
          video.src = window.URL.createObjectURL(stream);
        }

        video.onloadedmetadata = function (e) {
          video.play();
        };
      })
      .catch(function (err) {
        console.error(err);
      });
  };

  const stopCamera = () => {
    if (window.localStream) {
      window.localStream.getTracks().forEach((track) => track.stop());
    }

    const video = document.getElementById("liveness");
    if (video) {
      video.srcObject = null;
    }

    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const onClickClose = () => {
    stopCamera();
    handleClose();
  };

  const getFacingMode = () => {
    if (cameraType === FACING_MODES.ENVIRONMENT) {
      return {
        video: {
          facingMode: "environment",
          width: { min: 320, ideal: 1024, max: 1920 },
          height: { min: 480, ideal: 768, max: 1080 },
        },
      };
    } else {
      return {
        video: {
          facingMode: "user",
          width: { min: 320, ideal: 1024, max: 1920 },
          height: { min: 480, ideal: 768, max: 1080 },
        },
      };
    }
  };

  const takePicture = () => {
    var video = document.getElementById("liveness");

    setIsShowWhiteFlash(true);
    playClickAudio();
    onTakePhoto(getDataURI(video));

    setTimeout(() => {
      setIsShowWhiteFlash(false);
      if (!doNotCloseAfterTakePicture) {
        onClickClose();
      }
    }, 900);
  };

  const Mask = () => {
    const styles = {
      container: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: "0px",
        bottom: "0px",
        right: "0px",
        left: "0px",
        zIndex: 9999,
      },
      img: {
        height: "100%",
      },
    };

    return (
      <div style={styles.container}>
        <img style={styles.img} src={themes.components.images.cameraSelfieDesktop} alt="mask" />
      </div>
    );
  };

  const changeCamera = () => {
    stopCamera();

    if (cameraType === FACING_MODES.ENVIRONMENT) {
      setCameraType(FACING_MODES.USER);
    } else {
      setCameraType(FACING_MODES.ENVIRONMENT);
    }
  };

  return open ? (
    <>
      {mask && <Mask />}

      {!disableCloseButton && <St.IconClose size={50} onClick={onClickClose} />}

      {!disableChangeCamButton && (
       <St.ChangeCamera>
          <St.IconChangeCamera size={50} onClick={changeCamera} />
        </St.ChangeCamera> 
      )}

      {stopWatch && <Chronometer reset={certifaceMessagebase64} />}

      {messageImage && (
        <St.CertifaceMessageSteps
          src={"data:image/png;base64," + messageImage}
        />
      )}

      {!disableTakePictureButton && <St.Button onClick={takePicture} />}

      <WhiteFlash isShowWhiteFlash={isShowWhiteFlash} />

      <St.Camera id="liveness" mirroring={cameraType === FACING_MODES.USER} />
    </>
  ) : (
    <></>
  );
}

export default forwardRef(CameraComponent);
