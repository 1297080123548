import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 50%;
  margin: 0 auto;

  h6 {
    color: #4a4b4a;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
  }

  span {
    svg {
      margin: 35px 0 25px;
    }
  }

  .instructions {
    margin-top: 25px 0 25px;
  }

  ul {
    margin-left: 20px;

    li {
      margin-top: 25px;
    }
  }
`;
