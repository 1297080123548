import styled from 'styled-components';


const Container = styled.div`
  display: flex;
`;

const Box = styled.div`
  width: 150px;
  height: 120px;
  overflow: hidden;
  border: 1px #000; /* Substitua a cor pela cor desejada */
  margin: 10px 10px 10px 10px;
  margin-right: 20px;
  margin-top: 30px;
  border-radius: 20px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2); /* Adicione sombra se necessário */
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


const IconBox = styled.div`
  width: ${(props) => props.width * 0.14}px;
  height: ${(props) => props.width * 0.14}px;
  overflow: hidden;
  border: 0.5px solid #000; /* Substitua a cor pela cor desejada */
  margin: 0 10px;
  border-radius: ${(props) => props.width * 0.17 / 2}px;
  background-color: white;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Adicione sombra se necessário */
`;

const Icon = styled.img`
  width: 35px;
  height: 35px;
`;

const ItemIdentify = styled.p`
  font-size: 11px;
  font-weight: bold;
  color: black;
  margin: 10px;
`;

export { Container, Box, IconBox, Icon, ItemIdentify };