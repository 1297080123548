/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import CameraLending, { FACING_MODES } from "../../components/NewCameraLending";
import Loader from "~/components/Loader";
import { OneButtonPopup } from "~/components/Modals/OneButtonPopup";

import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import FaceCaptchaService, {
  getImageEnc,
  getCertifaceResultMessage,
} from "~/services/facecaptcha/facecaptcha";
import { GlobalContext } from "~/contexts/GlobalState";
import moment from "moment";

import * as St from "../../styles";
import themes from "~/themes";
const SelfieSignature = () => {
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const [certifaceImages, setCertifaceImages] = useState([]);
  const [camUserPictureIsOpen, setCamUserPictureIsOpen] = useState(false);
  const [firstPictureCertiface, setFirstPictureCertiface] = useState(null);
  const [currentChallenge, setCurrentChallenge] = useState(null);
  const [certifaceMessagebase64, setCertifaceMessagebase64] = useState(null);
  const [imageUp, setImageUp] = useState("");
  const [certiface, setCertiface] = useState({});
  const [certifaceErrorMessage, setCertifaceErrorMessage] = useState("");
  const [userPicture, setUserPicture] = useState();
  const [ipAddress, setIPAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);
  const [camUserPictureIsClose, setCamUserPictureIsClose] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorUserPicture] = useState(null);
  const [livenessActive] = useState(
    process.env.REACT_APP_LIVENESS_ACTIVE === "true" ? true : false
  );
  const [livenessOpen] = useState(
    process.env.REACT_APP_LIVENESS_OPEN === "true" ? true : false
  );

  let countrSnapEnd = 0;

  const { lending } = useContext(GlobalContext);

  const livenessRef = useRef();

  const IMAGE_TYPES = {
    CERTIFACE: "CERTIFACE",
  };

  sessionStorage.setItem("@NovaEra:faceID", lending.xCode);

  const PostDigitalSignature = useApi(
    Endpoints.lendings.postDigitalSignature,
    ApiMethod.POST
  );

  const history = useHistory();

  const invokeCameraHandle = (image) => {
    if (currentChallenge === null) return;

    const { code, index, size, message, startResponse } = currentChallenge;

    if (index === 0) {
      setFirstPictureCertiface(image);
    }

    const imageBase64 = image;

    const currentImages = certifaceImages;

    const newImage = getImageEnc(imageBase64, code);

    setCertifaceMessagebase64(message);

    currentImages.push(newImage);
    setCertifaceImages(currentImages);

    if (index === size && camUserPictureIsClose) {
      if (livenessRef.current) {
        livenessRef.current.stopCamera();
      }

      if (index === 0) {
        sendChallenges(startResponse, currentImages, imageBase64);
      } else {
        sendChallenges(startResponse, currentImages);
      }
      setCurrentChallenge(null);
      setCertifaceMessagebase64(null);
    }
  };

  const sendChallenges = async (startResponse, images, firstImage) => {
    setImageIsLoading(IMAGE_TYPES.CERTIFACE);

    const service = FaceCaptchaService();

    const challengeResponse = await service.captcha(startResponse, images);
    setImageIsLoading(null);

    setCertiface(challengeResponse.data?.result);

    setCertifaceErrorMessage("");

    setIsLoading(true);

    if (challengeResponse.success === true) {
      const codeResult = challengeResponse.data.result.codID;
      if ([1.1, 1.2].includes(codeResult)) {
        setIsLoading(false);
        setUserPicture(firstPictureCertiface);
        handleSignatureRequest(firstImage, IMAGE_TYPES.CERTIFACE);
      } else if ([200.1, 200.2].includes(codeResult)) {
        if (livenessOpen) {
          !livenessActive &&
            setUserPicture(firstImage || firstPictureCertiface);
          setShowPopup(true);
          setCertifaceErrorMessage(
            "Ops! No momento não podemos lhe atender. Tente novamente mais tarde!"
          );
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setCamUserPictureIsOpen(firstPictureCertiface);
          handleSignatureRequest(firstImage, IMAGE_TYPES.CERTIFACE);
        }
      } else {
        setShowPopup(true);
        setImageIsLoading(false);
        setIsLoading(false);
        setCertifaceErrorMessage(
          getCertifaceResultMessage(challengeResponse.data.result.codID || 0)
        );
      }
    }
  };

  const isLivenessError = () => {
    if (!livenessActive) {
      if (
        certiface?.codID !== 1.1 &&
        certiface?.codID !== 1.2 &&
        certiface?.codID !== 200.1 &&
        certiface?.codID !== 200.2
      ) {
        return true;
      }
    } else {
      if (certiface?.codID !== 1.1 && certiface?.codID !== 1.2) {
        return true;
      }
    }
  };

  const handleSignatureRequest = async (image, type) => {
    setImageIsLoading(type);
    setIsLoading(true);

    try {
      const payload = {
        geolocation: `${latitude}, ${longitude}` || "",
        idEmprestimo: lending.idSimulacao,
        ipAddress: ipAddress,
        selfieImage: image.split(",")[1],
        userAgent: navigator.userAgent,
      };
      const response = await PostDigitalSignature.callApi(payload);

      if (response.status >= 200 && response.status < 300) {
        setIsLoading(true);
        history.push("/emprestimo/sucesso-saque");
      }

      if (response.status >= 400) {
        setShowPopupError(true);
        setCertifaceErrorMessage(response.mensagem);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setShowPopupError(true);
      setCertifaceErrorMessage(error.message);
      setIsLoading(false);
    } finally {
      sessionStorage.removeItem("@NovaEra:faceID");
      setIsLoading(false);
    }
  };

  const toggleCameraUserPicture = () => {
    countrSnapEnd = 0;
    setCamUserPictureIsClose(false);
    setCamUserPictureIsOpen((prev) => !prev);
  };

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/");
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, [verifyReloadPage]);

  useEffect(() => {
    toggleCameraUserPicture();
  }, []);

  const forceCameraTakePicture = (time, obj) => {
    setTimeout(() => {
      let count = 0;
      setCurrentChallenge(obj);
      setCertifaceMessagebase64(obj.message);

      const image = setInterval(async () => {
        count++;

        if (
          count === obj.numberSnap &&
          obj.numberOfChallenges - 1 === countrSnapEnd
        ) {
          setCamUserPictureIsClose(true);
        }

        if (livenessRef.current) {
          await livenessRef.current.takePicture();
        }
      }, 4000);

      const challenge = setInterval(function () {
        clearInterval(image);
        clearInterval(challenge);
        countrSnapEnd++;
      }, time);
    }, (time + 1000) * obj.index);
  };

  const startLivenessSignature = async () => {
    setImageIsLoading(IMAGE_TYPES.CERTIFACE);
    const service = FaceCaptchaService();
    setCertifaceImages([]);

    const startResponse = await service.start(
      lending?.nome,
      moment(lending.dataNascimento).format("DD/MM/YYYY") || "00/00/0000",
      lending?.cpf
    );

    if (startResponse.status === 200) {
      const challenge = startResponse.data?.challenge?.challenges || [];

      if (challenge.length > 0) {
        challenge.forEach((ch, index) => {
          const code = ch.tipoFace.codigo;
          const time = ch.tempoEmSegundos * 1000;
          const size = challenge.length - 1;
          const totalTime = startResponse.data?.challenge?.totalTime;
          const snapInMillis =
            startResponse.data?.challenge?.snapFrequenceInMillis;
          const numberSnap = startResponse.data?.challenge?.snapNumber;
          const numberOfChallenges =
            startResponse.data?.challenge?.numberOfChallenges;

          forceCameraTakePicture(time, {
            code,
            time,
            index,
            size,
            message: ch.mensagem,
            startResponse,
            totalTime,
            snapInMillis,
            numberSnap,
            numberOfChallenges,
          });
        });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getIpAddress = async () => {
      try {
        const response = await fetch("https://api64.ipify.org?format=json");
        const data = await response.json();
        setIPAddress(data.ip);
      } catch (error) {
        console.log(error);
      }
    };

    getIpAddress();
  }, []);

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, []);

  return (
    <>
      <LayoutOnboarding showBackGroundBar={false} auth teuCred>
        <OneButtonPopup
          icon={themes.components.icons.warning}
          text={certifaceErrorMessage || errorUserPicture}
          buttonText={"Voltar"}
          visibility={showPopup}
          slug="/emprestimo/verificacao-foto"
        />
        <OneButtonPopup
          icon={themes.components.icons.warning}
          text={certifaceErrorMessage || errorUserPicture}
          buttonText={"Voltar"}
          visibility={showPopupError}
          slug="/"
        />
        <St.Content>
          <St.Steps>Passo 10 de 11</St.Steps>
          <St.BoxContent>
            {isLoading ? (
              <St.WrapperLoader>
                <Loader size="large" />
              </St.WrapperLoader>
            ) : (
              <St.BoxType>
                <St.WrapperCamera show={camUserPictureIsOpen}>
                  <CameraLending
                    open={camUserPictureIsOpen}
                    facingMode={FACING_MODES.USER}
                    isStartLiveness={startLivenessSignature}
                    isLiveness
                    onTakePhoto={invokeCameraHandle}
                    certifaceMessagebase64={certifaceMessagebase64}
                    doNotCloseAfterTakePicture
                    stopWatch
                    disableCloseButton
                    ref={livenessRef}
                    handleClose={() => setCamUserPictureIsOpen(false)}
                    disableTakePictureButton
                    mask
                  />
                </St.WrapperCamera>
                {errorUserPicture && (
                  <St.ErrorMessage>{errorUserPicture}</St.ErrorMessage>
                )}
                {isLivenessError() && (
                  <St.ErrorMessage>{certifaceErrorMessage}</St.ErrorMessage>
                )}
              </St.BoxType>
            )}
          </St.BoxContent>
        </St.Content>
      </LayoutOnboarding>
    </>
  );
};

export default SelfieSignature;
