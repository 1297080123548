import styled from "styled-components";

import themes from "~/themes";

export const Content = styled.div`
  z-index: 2;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: center;
  justify-content: center;

  background-color: #fff;
  border-radius: 4px;
  padding: 48px 10px;
  margin: 60px auto 60px;
  min-height: 600px;
  max-width: 1210px;
  width: 100%;
  margin-top: 1%;

  p {
    color: ${themes.components.colors.font};
    font-size: 18px;
    letter-spacing: 0;
    line-height: 25px;
    /* margin-bottom: min(10%,auto); */
    text-align: left;
  }

  @media (max-width: 960px) {
    padding: 60px 0px 0px 0px;
    min-height: calc(100vh - 140px);
  }
`;

export const ImageLogo = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  margin-top: -3%;
  width: 10%;

  > img {
    flex: 1;
    display: flex;
    align-self: center;
  }

  @media (max-width: 800px) {
    margin: 0px;
  }
`;

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;

  > p {
    font-size: 20px;
    position: absolute;
    top: 280px;
    font-weight: 900;
    align-self: center;
    justify-content: center;
    color: #4a4b4a;

    @media (max-width: 800px) {
      position: static;
    }
  }
`;

export const MainText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 22px;
  font-weight: 900;
  color: #4a4b4a;
`;

export const MiddleText = styled.div`
  display:flex;
  align-self:center;
  flex-direction: column;
  margin-top: 50px;

  font-weight: 200;
  font-size: 18px;
  line-height: 18px;
  color: #4a4b4a;

  img {
    width: 8px;
    height: 8px;
    position: relative;
    top: 17px;
    right: 14px;
  }
  
  @media(max-width: 800px) {
    margin: 0px;
    padding-left: 30px;
  }

  @media(max-width: 376px) {
    padding-left: 15px;

  p:last-child {
    font-weight: bold;
    font-size: 24px;
  }
`;

export const Footer = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 45%;
  height: 20%;

  @media (max-width: 960px) {
    width: 100%;
  }
`;
