import styled from 'styled-components';

import themes from '~/themes';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 670px;
  //grid-template-rows: auto auto;
  background-color: #FFF;
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
  border-radius: 4px;
  padding: 48px 10px;
  margin: 60px auto 60px;
  min-height: 600px;
  max-width: 1210px;
  width: 100%;
  margin-top: 4%;
  height: auto;

  @media (max-width: 960px) {
    grid-template-columns: auto 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
  background-color: #FFF;
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
  border-radius: 4px;
  padding: 48px 10px;
  margin: 60px auto 60px;
  min-height: 600px;
  max-width: 1210px;
  width: 100%;
  margin-top: 1%;


  @media (max-width: 960px) {
    min-height: 100%;
  }

`;


export const MainText = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;

  margin-top: 2%;
  font-size: 20px;
  font-weight: 800;
  color: #4A4B4A;

  @media (max-width: 960px) {
    #text-confirm {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
`;  


export const MiddleText = styled.span`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;

  font-weight: 400;
  font-size: 16px;
  color: #444444;

  @media (max-width: 960px) {
    font-size: 14px;
  }
`;

export const ImageLogo = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  width: 30%;
  height: 35%;


  >img {
    flex:1;
    display:flex;
    align-self: center;
  }

  @media (max-width: 760px) {
    width: 100%;
  }
`;


export const Footer = styled.span`
  flex:1;
  display:flex;
  align-items: center;
  align-self: center;
  justify-content: flex-end;

  margin-top: 4%;
  width: 40%;
  height: 20%;

  @media (max-width: 960px) {
    width: 100%;
  }
`;
