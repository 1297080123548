import React, { useState } from 'react';

import Layout from '~/components/Layout';
import ContainerBox from '~/components/ContainerBox';
import ChatBox from './components/ChatBox';
import { Link } from 'react-router-dom';

import * as S from './styles';
import themes from '~/themes';

const ContactUs = () => {
  const [chat, setChat] = useState(false);

  const contentData = [
    // { id: 0, title: '', value: 'Chat', route: '/chat-client', color: '#4A4B4A' },
    { id: 1, title: 'WhatsApp', value: '(92) 4003-3203', route: '/whatsApp', color: '#E71037' },
    {
      id: 2,
      title: 'Telefone Central de Atendimento',
      value: '4003-3203',
      route: '',
      color: '#E71037'
    },
    {
      id: 3,
      title: 'Loja Física',
      value: 'Av. Torquato Tapajós, 2871, CEP: 69.048-010 Manaus/AM',
      route: '',
      color: '#E71037'
    },
    {
      id: 4,
      title: 'Site',
      value: 'http://useteucard.com.br/',
      route: '/site',
      color: '#E71037'
    },
    {
      id: 5,
      title: 'e-mail',
      value: 'sac@useteucard.com.br',
      route: '',
      color: '#444444'
    },
  ];

  function handleClick(route) {
    if (route === '/chat-client') {
      setChat(true)
    } else if (route === '/whatsApp') {
      window.open(
        'https://api.whatsapp.com/send?phone=559240033203&text=Preciso de ajuda com meu TeuCard.',
        '_blank'
      );
    } else if (route === '/site') {
      window.open(
        'http://useteucard.com.br/',
        '_blank'
      );
    }
  }

  return (
    <Layout
      onlyHomeItem={true}
      showBackGroundBar={false}
    >
      <S.Container>
        <ContainerBox title='Fale conosco'>
          {chat ?
            <S.Wrapper>
              <ChatBox text='Olá, somos a equipe de atendimento. Como podemos te ajudar?' />
              <ChatBox text='Estou com dificuldade em acessar minha 2° via da fatura. Poderia verificar?' />
            </S.Wrapper> :
            <>
              {contentData.map((itemData) => (
                <S.RowInfo key={itemData.id}>
                  <div>
                    <span>{itemData.title}</span>
                    {itemData.route
                      ?
                      <p>
                        {itemData.value}
                        <Link onClick={() => handleClick(itemData.route)}>
                          {themes.components.icons.chevronRight}
                        </Link>
                      </p>
                      :
                      <p>{itemData.value}</p>
                    }
                  </div>
                </S.RowInfo>
              ))}
            </>
          }
        </ContainerBox>
      </S.Container>
    </Layout>
  );
};

export default ContactUs;
