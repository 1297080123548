/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from "react";
import * as St from "./styles";
import Layout from "~/components/Layout";
import { Link, useHistory } from "react-router-dom";
import CreditCard from "~/components/CreditCard";
import themes from "~/themes";
import {
  addExistingCardToWallet,
  getWalletDigitalByCpf,
} from "~/services/walletServices";
import Loader from "~/components/Loader";
import { clientProducts } from "~/services/productService";
import TwoButtonPopup from "~/components/Modals/TwoButtonPopup";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import NewOneButtonPopup from "~/components/Modals/NewOneButtonPopup";
import NewTwoButtonPopup from "~/components/Modals/NewTwoButtonPopup";
import Endpoints from "~/services/endpoints";
import useApi, { ApiMethod } from "~/hooks/useApi";
import assets from "~/assets";

const CarteiraDigitalHome = (props) => {
  const [wallets, setWallets] = useState([]);
  const [modalAddCard, setModalAddCard] = useState(false);
  const [textModalError, setTextModalError] = useState("");
  const [openModalError, setOpenModalError] = useState(false);
  const [modalAddCardConfirm, setModalAddCardConfirm] = useState(false);
  const [validateCard, setValidateCard] = useState(false);
  const [popUpCardError, setPopUpCardError] = useState(false);
  const [popUpCardNotValidate, setPopCardNotValidate] = useState(false);
  const [popUpCardValidate, setPopCardValidate] = useState(false);
  const [validationCard, setValidationCard] = useState(false);
  const [validationValue, setValidationValue] = useState(false);
  const [valueCharged, setValueCharged] = useState();
  const [selectedCard, setSelectedCard] = useState(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const postSendVerificationValue = useApi(
    Endpoints.payments.postSendTransactionValidationCard,
    ApiMethod.POST
  );

  const postValidateTransaction = useApi(
    Endpoints.payments.postValidateTransaction,
    ApiMethod.POST
  );

  const postReverseCharge = useApi(
    Endpoints.payments.postReverseValue.replace("PARAM_ID", selectedCard?.id),
    ApiMethod.POST
  );

  const checkIfUserHasCardInWallet = useCallback(async (wallet) => {
    const response = await clientProducts();
    const card = response?.data?.produtosCliente.find(
      (e) => e.tipoProduto === "TEUCARD"
    );
    const hasTeuCardInWallet = wallet.some(
      (card) => card.bandeira === "TEUCARD"
    );
    if (card && !hasTeuCardInWallet) {
      setModalAddCard(true);
    }
  }, []);

  const addCard = async () => {
    const response = await addExistingCardToWallet();
    if (response.status === 201) {
      fetchData();
      setModalAddCard(false);
      setModalAddCardConfirm(true);
    } else {
      setModalAddCard(false);
      setTextModalError(response?.data?.mensagem);
      setOpenModalError(true);
    }
    return;
  };
  const fetchData = async () => {
    setIsLoading(true);

    try {
      const responseData = await getWalletDigitalByCpf();
      setWallets(responseData);
      checkIfUserHasCardInWallet(responseData);
      setIsLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const returnBodyTextStartValidation = () => {
    return (
      <div>
        <p style={{ textAlign: "center" }}>
          Faremos uma cobrança no teu cartão <br />
          entre R$0,01 e R$ 5,00.
        </p>
        <p style={{ textAlign: "center" }}>
          Verifique o valor cobrado no extrato e informe na <br />
          próxima tela.
        </p>
        <br />
      </div>
    );
  };

  const returnBodyTextInputValue = () => {
    return (
      <div>
        <br />
        <br />
        <p style={{ textAlign: "center" }}>
          Digite o valor cobrado no teu cartão final{" "}
          {selectedCard?.numeroCartao.slice(-4)} com a <br />
          descrição teuapp nova era:
        </p>
      </div>
    );
  };

  const errorSendCode = (
    <div>
      Não foi possível validar o cartão de Crédito!
      <br />
      Tente novamente mais tarde!
    </div>
  );

  const sendTransaction = async () => {
    try {
      const response = await postSendVerificationValue.callApi({
        idCartao: selectedCard?.id,
      });
      if (response.status >= 200 && response.status <= 300) {
        setValidationValue(true);
      } else {
        setValidationValue(false);
        setPopUpCardError(true);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const validateTransaction = async () => {
    try {
      const response = await postValidateTransaction.callApi({
        idCartao: selectedCard?.id,
        valor: parseFloat(valueCharged?.replace("R$", "").replace(",", ".")),
      });
      if (response.status >= 200 && response.status <= 300) {
        setValidationValue(false);
        setPopCardValidate(true);
      } else {
        setValidationValue(false);
        setPopUpCardError(true);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const onValidationValue = () => {
    sendTransaction();
  };

  const reverseTransaction = async () => {
    try {
      const response = await postReverseCharge.callApi();
      if (response.status >= 200 && response.status <= 300) {
        setValidationValue(false);
        setPopUpCardError(true);
      } else {
        setValidationValue(false);
        setPopUpCardError(true);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <>
      <Layout showBackGroundBar={false} productType="Carteira digital">
        <St.Container>
          <NewTwoButtonPopup
            HeaderText="Validação do cartão!"
            subtitle="Cartão cadastrado com sucesso!"
            bodyText={returnBodyTextStartValidation()}
            footerText="Não se preocupe, a cobrança será extornada!"
            leftButtonText="Validar depois"
            rightButtonText="Validar agora"
            visibility={validationCard}
            setVisibility={(val) => setValidationCard(val)}
            confirm={() => onValidationValue()}
            onClickCancel={() => setPopCardNotValidate(true)}
            loading={postSendVerificationValue.loading}
          />
          <NewTwoButtonPopup
            HeaderText="Validação do cartão!"
            bodyText={returnBodyTextInputValue()}
            leftButtonText="Cancelar"
            rightButtonText="Confirmar"
            visibility={validationValue}
            inputValue={true}
            sendValue={(value) => setValueCharged(value)}
            setVisibility={(val) => setValidationValue(val)}
            confirm={validateTransaction}
            onClickCancel={() => reverseTransaction()}
            loading={postValidateTransaction.loading}
          />
          <NewOneButtonPopup
            slug="/carteira-digital-home"
            title={"Cartão validado com sucesso!"}
            text={`Tudo certo! Agora você pode utilizar a vontade para pagar tuas compras.`}
            buttonText="Entendi"
            visibility={popUpCardValidate}
            setVisibility={(val) => setPopCardValidate(val)}
            onClick={() => {
              setPopCardValidate(false);
              window.location.reload();
            }}
            imgIcon={assets.images.successValidation}
          />
          <NewOneButtonPopup
            slug="/carteira-digital-home"
            title={"O cartão não foi validado!"}
            text={`O Pagamento das compras só será permitido após concluir a validação do cartão.`}
            buttonText="Entendi"
            visibility={popUpCardNotValidate}
            setVisibility={setPopCardNotValidate}
            onClick={() => setPopCardNotValidate(false)}
            imgIcon={assets.images.warningLending}
          />
          <NewOneButtonPopup
            slug="/carteira-digital-home"
            title={"O cartão não foi validado!"}
            text={errorSendCode}
            buttonText="Entendi"
            visibility={popUpCardError}
            setVisibility={setPopUpCardError}
            onClick={() => setPopUpCardError(false)}
            imgIcon={assets.images.warningLending}
          />
          <OneButtonPopup
            icon={themes.components.icons.check}
            text="Cartão cadastrado com sucesso!"
            buttonText="Entendi"
            visibility={modalAddCardConfirm}
            setVisibility={setModalAddCardConfirm}
          />
          <TwoButtonPopup
            bodyText="Deseja cadastrar o cartão TeuCard?"
            leftButtonText="Não"
            rightButtonText="Sim"
            visibility={modalAddCard}
            setVisibility={setModalAddCard}
            confirm={addCard}
            onClickCancel={() => setModalAddCard(false)}
            showIcon={false}
          />
          <OneButtonPopup
            text={textModalError}
            buttonText="Entendi"
            visibility={openModalError}
            setVisibility={() => setOpenModalError(false)}
          />
          <h4>Tua Carteira</h4>
          <St.Carteiras>
            {!isLoading &&
              wallets.length > 0 &&
              wallets.map((wallet) => (
                <CreditCard
                  key={wallet.id}
                  data={wallet}
                  imageTheme={themes.components.images.creditCardBackGround}
                  onClick={(wallet) => {
                    setSelectedCard(wallet);
                    setValidationCard(true);
                  }}
                />
              ))}
            {isLoading && (
              <St.WrapperLoader>
                <Loader />
              </St.WrapperLoader>
            )}

            {!isLoading && wallets.length === 0 && (
              <St.NoContentContainer>
                <St.NoContent>
                  <img
                    style={{ width: "85px", height: "85px" }}
                    src={themes.components.images.semCartao}
                  />
                  <h3> Você ainda não tem nenhum cartão cadastrado!</h3>
                  <p>
                    Para aproveitar mais as vantagens da tua carteira é
                    necessário adicionar um cartão
                  </p>
                </St.NoContent>
                <St.CadastrarCartao
                  onClick={() => history.push("/add-credit-card")}
                >
                  Cadastrar Cartão
                </St.CadastrarCartao>
              </St.NoContentContainer>
            )}
          </St.Carteiras>
          {wallets.length != 0 && (
            <St.CadastrarCartao
              onClick={() => history.push("/add-credit-card")}
            >
              Cadastrar Cartão
            </St.CadastrarCartao>
          )}
        </St.Container>
      </Layout>
    </>
  );
};
export default CarteiraDigitalHome;
