import { produtosAPI } from "./api";

export const getAllProducts = async () => {
    try {
        const res = await produtosAPI.get("/v1/produtos/todos-produtos-do-sistema");
        return res.data;
    } catch (err) {
        return err.response.data.message;
    }
};

export const nonContractedProducts = async () => {
    try {
        const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
        const res = await produtosAPI.get(`/v1/produtos/produtos-cliente-nao-tem/${user?.cpf}`)
        return res;
    } catch (error) {
        return error.response.data.message;
    }
}

export const clientProducts = async () => {
    const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
    const tokenCard = localStorage.getItem("@NovaEra:tokenCartao");
    try {
        const res = await produtosAPI.get(`/v1/produtos/listar-produtos-cliente/${user?.cpf}?tokenCartao=${tokenCard}`);
        return res;
    } catch (err) {
        return err.response.data.message;
    }
};

export const addProductToClient = async (requestBody) => {
    const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
    try {
        const res = await produtosAPI.post('/v1/produtos/adicionar-produto-cliente', requestBody);
        return res;
    } catch (err) {
        
        return err.response.data.message;
    }
};