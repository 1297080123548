import React, { useEffect } from 'react';

import themes from '~/themes';
import { Container, NavItem } from './styles';

const NavBarDigitalWallet = (props) => {
  const navItens = [
    { id: 0, name: 'Início', link: '/home', icon: themes.components.icons.home },
    {
      id: 1,
      name: 'Carteira digital',
      link: '/carteira-digital-home',
      icon: themes.components.icons.card,
    },
    {
      id: 2,
      name: 'Extratos',
      link: '/carteira-digital-transacoes',
      icon: themes.components.icons.transition,
    },
    {
      id: 3,
      name: 'documents',
      link: '/carteira-digital-documents',
      icon: themes.components.icons.document,
    }
  ];


  let navItemExibition = navItens.map((navItem) => (
    <NavItem to={navItem.link} key={navItem.id} exact>
      <div>{navItem.icon}</div>
    </NavItem>
  ))

  if (props.onlyHomeItemInNavBar) {
    navItemExibition = navItens.map((navItem) => (
      navItem.link === '/home' &&
      <NavItem
        to={navItem.link}
        key={navItem.id}
        exact
      >
        <div style={{ alignContent: 'center' }}>{navItem.icon}</div>
      </NavItem>
    ))
  }

  return (
    <Container>
      <nav>
        {navItemExibition}
      </nav>
    </Container>
  );
};

export default NavBarDigitalWallet;
