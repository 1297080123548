import React from "react";

import * as St from "./styles";
import themes from "~/themes";
import Loader from "../Loader";
import { Icon } from "react-icons";

export const BUTTON_TYPES = {
  outlined: "outlined",
  default: "default",
};

function Button({
  children,
  isLoading = false,
  disabled,
  icon,
  color,
  type = BUTTON_TYPES.default,
  ...others
}) {
  return (
    <St.Button color={color} disabled={disabled} type={type} {...others}>
      {icon}
      {isLoading ? <Loader color={themes.colors.orange.n3} /> : children}
    </St.Button>
  );
}

export default Button;
