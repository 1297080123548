import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { GlobalContext } from "~/contexts/GlobalState";
import * as S from "./styles";
import themes from "~/themes";
import { logout } from "~/services/authentication";
import UserProfile from "../UserProfile";

const HeaderOnboarding = ({ teuCred = false }) => {
  const { user } = useContext(GlobalContext);
  const history = useHistory();
  const handleLogout = () => {
    logout();
  };

  return (
    <S.Container teuCred={teuCred}>
      <S.HeaderLinks onClick={() => history.push("/home")}>
        {themes.components.icons.chevronLeft}
      </S.HeaderLinks>
      {teuCred ? (
        <div>
          <img
            onClick={() => history.push("/home")}
            src={themes.components.images.logoTeuCred}
            alt="Logo da empresa"
          />
          <span>Versão: {process.env.REACT_APP_VERSAO_APP}</span>
        </div>
      ) : (
        <>
          <img
            onClick={() => history.push("/home")}
            src={themes.components.images.logoHorizontalTeuCard}
            alt="Logo da empresa"
          />
        </>
      )}
    </S.Container>
  );
};

export default HeaderOnboarding;
