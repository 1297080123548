import React, { useEffect, useState } from "react";
import ContainerBox from "~/components/ContainerBox";
import Layout from "~/components/Layout";
import Loader, { LOADER_TYPES } from "~/components/Loader";
import { getClientByCPF } from "~/services/user";
import * as S from "./styles";

const DigitalWalletContract = props => {

    const [proposalID, setProposalID] = useState();
    const [isLoading, seIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        (async () => {
            try {
                seIsLoading(true);
                const response = await getClientByCPF();
                setProposalID(response.data.propostaId);
            } catch (err) {
                setErrorMessage(err.message);
            } finally {
                seIsLoading(false);
            }
        })();
    }, []);

    const data = `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/noauth/proposta-assinatura?id=${proposalID}`;
    return (
        <Layout
            showBackGroundBar={false}
            productType='Carteira digital'
        >
            <ContainerBox>
                <S.Container>
                    <h1>Visualizar contrato</h1>
                    {isLoading ? (
                        <S.WrapperLoader>
                            <Loader size={LOADER_TYPES.size.medium} />
                        </S.WrapperLoader>
                    ) : (
                        <>
                            {errorMessage ? (
                                <h6> aasdasdasd</h6>
                            ) : (
                                <iframe
                                    src={data}
                                    type="application/pdf"
                                    style={{ border: "none" }}
                                    title="proposta"
                                ></iframe>
                            )}
                        </>
                    )}
                </S.Container>
            </ContainerBox>
        </Layout>
    )
}
export default DigitalWalletContract