import styled from "styled-components";

import themes from "~/themes";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const BackgroundBar = styled.div`
  position: absolute;
  left: 0;
  background: ${themes.colors.orange.n2};
  width: 100%;
  height: 118px;

  @media (max-width: 465px) {
    background: ${themes.colors.orange.n2};
  }
`;

export const Content = styled.div`
  z-index: 2;
  display: flex;

  flex-direction: row;
  width: 100%;
  height: auto;

  margin-top: 10px;

  > main {
    flex: 1;
    margin: 0 11px;
  }
`;

export const SimpleContent = styled.div`
  z-index: 2;
  display: flex;

  width: 80%;
  height: auto;
  max-width: 810px;
  border-radius: 12px;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  margin: 60px auto 0;
  padding: 48px 28px;

  background: #fff;

  > main {
    margin: 0 auto;
    width: 448px;
  }
`;
