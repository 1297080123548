import React from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Button from "~/components/Button";

import * as St from "../../styles";
import themes from "~/themes";

const VerificationPhoto = () => {
  const history = useHistory();

  const handleNext = () => {
    history.push("/emprestimo/assinatura");
  };
  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <St.Content>
        <St.BoxContent>
          <St.Title>Foto de verificação</St.Title>
          <St.Subtitle>
            Precisamos que você tire uma foto para garantir que é você mesmo que
            está solicitando o empréstimo
          </St.Subtitle>
          <St.Steps>Passo 9 de 11</St.Steps>
          <St.TypeContainer style={{ marginBottom: 83 }}>
            <St.ImageSelfie
              src={themes.components.images.selfieLending}
              alt="selfie"
            />
            <St.BoxDescription>
              <St.RowDescription>
                <img
                  src={themes.components.images.checkOrange}
                  alt="check"
                  width={18}
                  height={18}
                />
                <span>Escolha um local bem iluminado.</span>
              </St.RowDescription>
              <St.RowDescription>
                <img
                  src={themes.components.images.checkOrange}
                  alt="check"
                  width={18}
                  height={18}
                />
                <span>
                  Remova mascara, boné, acessórios que possam dificultar tua
                  identificação.
                </span>
              </St.RowDescription>
              <St.RowDescription>
                <img
                  src={themes.components.images.checkOrange}
                  alt="check"
                  width={18}
                  height={18}
                />
                <span>Posicione-se no local indicado pela câmera.</span>
              </St.RowDescription>
            </St.BoxDescription>
          </St.TypeContainer>
          <St.Footer align="right">
            <div>
              <Button onClick={handleNext}>
                <b>Tirar Selfie</b>
              </Button>
            </div>
          </St.Footer>
        </St.BoxContent>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default VerificationPhoto;
