import React, { useCallback, useState, useMemo } from "react";

import Layout from "~/components/Layout";

import CardNumber from "./Card/CardNumber";
import UserPersonalData from "./UserPersonalData/";
import AplicationPassword from "./AplicationPassword/";
import CardPassword from "./Card/CardPassword";
import CardPasswordConfirmation from "./Card/CardPasswordConfirmation";
import VerificationCode from "./VerificationCode/";

import * as S from "./styles";

const Register = () => {
  const [numberSectionForm, setNumberSectionForm] = useState(0);

  const [valuesFields, setValuesFields] = useState({
    card: {
      number: "0000 0000 0000 0000",
      password: "",
      passwordConfirmation: "",
    },
    user: {
      name: "Leonardo Luis de Vargas",
      document: "",
      birthdate: "",
    },
    password: "",
    passwordConfirmation: "",
    verificationCode: "",
    validateApi: {
      cpf: "",
      dataNascimento: "",
      nome: "",
    },
  });

  const sectionsTitle = [
    "Número do cartão",
    "Dados pessoais",
    "Senha do aplicativo",
    "Senha do cartão",
    "Confirmar senha do cartão",
    "Código de verificação",
  ];

  const handleNextSection = useCallback((data) => {
    setNumberSectionForm((state) => {
      if (state !== 5) {
        // Skip na seção 'Senha do aplicativo'
        if (state === 1) return 4;
        return state + 1;
      }
      return state;
    });

    setValuesFields((state) => ({ ...state, ...data }));
  }, []);

  const componentSectionForm = useMemo(() => {
    switch (numberSectionForm) {
      case 0:
        return <CardNumber handleNextSection={handleNextSection} />;
      case 1:
        return (
          <UserPersonalData
            handleNextSection={handleNextSection}
            dataApi={valuesFields.validateApi}
          />
        );
      case 2:
        return <AplicationPassword handleNextSection={handleNextSection} />;
      case 3:
        return (
          <CardPassword
            handleNextSection={handleNextSection}
            card={valuesFields.card}
            userName={valuesFields.user.name}
          />
        );
      case 4:
        return (
          <CardPasswordConfirmation
            handleNextSection={handleNextSection}
            card={valuesFields.card}
            cpf={valuesFields.validateApi.cpf}
          />
        );
      case 5:
        return (
          <VerificationCode
            cpf={valuesFields.user.document}
            card={valuesFields.card}
            userName={valuesFields.user.name}
          />
        );
      default:
        return <></>;
    }
  }, [
    numberSectionForm,
    handleNextSection,
    valuesFields.validateApi,
    valuesFields.card,
    valuesFields.user.name,
    valuesFields.user.document,
  ]);

  return (
    <Layout auth={false}>
      <S.Content>
        <h3>{sectionsTitle[numberSectionForm]}</h3>

        {componentSectionForm}
      </S.Content>
    </Layout>
  );
};

export default Register;
