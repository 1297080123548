import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  display: flex;
  justify-content: center;
  font-size: 31px;
  font-weight: 700;
`;

export const Description = styled.span`
  /* color: #444444; */
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
`;

export const WrapperButton = styled.div`
  width: 25%;
  margin-top: 20px;
`;
