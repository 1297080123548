import React, { useEffect, useState } from 'react';
import ContainerBox from '~/components/ContainerBox';
import Layout from "~/components/Layout";
import Loader, { LOADER_TYPES } from '~/components/Loader';

import * as S from './styles';

const Terms = () => {
  const [isLoading, seIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const data = `${process.env.REACT_APP_MS_TERMOS_CONDICOES}`;

  useEffect(() => {
    (async () => {
      try {
        seIsLoading(true);
      } catch (err) {
        setErrorMessage(err.message);
      } finally {
        seIsLoading(false);
      }
    })();
  }, []);
  return (
    <Layout
      showBackGroundBar={false}
    >
      <S.Container>
      <h1>Termos de uso</h1>
        {isLoading ? (
          <S.WrapperLoader>
            <Loader size={LOADER_TYPES.size.medium} />
          </S.WrapperLoader>
        ) : (
          <div style={{width: '900px'}}>
            {errorMessage ? (
              <h6> aasdasdasd</h6>
            ) : (
              <iframe
                src={data}
                type="application/pdf"
                style={{ border: "none" }}
                title="proposta"
              ></iframe>
            )}
          </div>
        )}
      </S.Container>

    </Layout>
  );
};

export default Terms;
