/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Loader from "~/components/Loader";
import PDFViewer from "../../components/PDFViewer";
import Checkbox from "~/components/Checkbox";
import Button from "~/components/Button";

import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { GlobalContext } from "~/contexts/GlobalState";

import * as St from "../../styles";
const AgreementLending = () => {
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [contractBase64, setContractBase64] = useState("");

  const history = useHistory();

  const { addLending, lending } = useContext(GlobalContext);

  const GetContractLending = useApi(
    Endpoints.lendings.getContractLending.replace(
      "PARAM_ID",
      `${lending?.idSimulacao}`
    ),
    ApiMethod.GET
  );

  useEffect(() => {
    getContractLendingPDF();
  }, []);

  async function getContractLendingPDF() {
    setLoading(true);
    try {
      const response = await GetContractLending.callApi();
      addLending({xCode: response.headers.get('x-code')})
      const { data } = response;
      setContractBase64(data?.contentPdf);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const handleNext = () => {
    history.push("/emprestimo/verificacao-foto");
  };

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/");
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, [verifyReloadPage]);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <St.Content>
        <St.BoxContent>
          <St.Title>Termos do empréstimo</St.Title>
          <St.Subtitle>
            Confira se teus dados estão corretos nos termos do empréstimo!
          </St.Subtitle>
          <St.Steps>Passo 8 de 11</St.Steps>
          {loading ? (
            <St.WrapperLoader>
              <Loader size="large" />
            </St.WrapperLoader>
          ) : (
            <>
              <St.PDFContainer>
                <PDFViewer base64={contractBase64} />
              </St.PDFContainer>
              <St.Terms>
                <Checkbox
                  label="Li e concordo com os termos do empréstimo!"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
              </St.Terms>
              <St.Footer align="right">
                <div>
                  <Button
                    disabled={termsAccepted ? false : true}
                    onClick={handleNext}
                  >
                    <b>Contratar</b>
                  </Button>
                </div>
              </St.Footer>
            </>
          )}
        </St.BoxContent>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default AgreementLending;
