/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import Checkbox from "~/components/Checkbox";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import Loader from "~/components/Loader";
import InputOnboarding from "~/components/InputOnboarding";
import InputSelectSimple from "~/views/Lending/components/InputSelectSimple";
import Button from "~/components/Button";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import moment from "moment";

import { dateMask, moneyBrazilian } from "~/utils/mask";
import { GlobalContext } from "~/contexts/GlobalState";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

import * as St from "../styles";
import themes from "~/themes";
import SCHOOLING from "../../../../assets/mock/schooling.json";
import CIVIL_STATUS from "../../../../assets/mock/civilStatus.json";
import GENDER from "../../../../assets/mock/gender.json";
import CREDIT_REASON from "../../../../assets/mock/creditReason.json";

import InputSelectLoan from "~/views/Lending/components/InputSelectLoan";
import SelectDropdown from "../../components/SelectDropdown";

const INPUT_NAMES = {
  mother: "mother",
  civilStatus: "civilStatus",
  schooling: "schooling",
  gender: "gender",
  creditReason: "creditReason",
};

const ComplementaryDataByLendingSearch = () => {
  const [loading, setLoading] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [openSelectTypeSchooling, setOpenSelectTypeSchooling] = useState(false);
  const [openSelectTypeCivilStatus, setOpenSelectTypeCivilStatus] =
    useState(false);
  const [openSelectTypeGender, setOpenSelectTypeGender] = useState(false);
  const [openSelectTypeCreditReason, setOpenSelectTypeCreditReason] =
    useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [input, setInput] = useState({
    formatted: "R$ 0,00",
    unFormatted: 0,
  });

  const [form, setForm] = useState({});

  const { addLending, lending } = useContext(GlobalContext);

  const history = useHistory();

  async function handleNext() {
    addLending({
      mother: form?.mother,
      civilStatus: form.civilStatus?.code,
      schooling: form.schooling?.code,
      gender: form.gender?.code,
      creditReason: form.creditReason?.code,
    });
    history.push("/buscador/dados-identificacao");
  }

  const verifyReloadPage = () => {
    if (lending?.cpf === "") {
      history.push("/");
    }
  };

  // useEffect(() => {
  //   verifyReloadPage();
  // }, [verifyReloadPage]);

  const activateButton = useMemo(
    () =>
      !form?.mother ||
      !form?.civilStatus?.code?.length ||
      !form?.schooling?.code?.length ||
      !form?.gender?.code?.length ||
      !form?.creditReason?.code?.length,
    [
      form?.mother,
      form?.civilStatus?.code,
      form?.schooling?.code,
      form?.gender?.code,
      form?.creditReason?.code,
    ]
  );

  console.log(form);

  const updateFormSelectType = (code, id, value) => {
    setForm((prev) => ({ ...prev, [id]: { code, value } }));
  };

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text="Por favor! selecione o tipo de documento"
        buttonText="Entendi"
        visibility={showPopup}
        setVisibility={setShowPopup}
      />
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={messageError}
        buttonText="Entendi"
        visibility={showPopupError}
        setVisibility={setShowPopupError}
      />
      <St.Content>
        <St.BoxContent>
          {loading ? (
            <St.WrapperLoader>
              <Loader size="large" />
            </St.WrapperLoader>
          ) : (
            <>
              <St.Title>Dados complementares</St.Title>
              <St.Subtitle>
                Precisamos de algumas informações complementares:
              </St.Subtitle>
              <St.Form>
                <InputOnboarding
                  type="text"
                  label="Nome da Mãe*"
                  largeBorder
                  largeLabel
                  value={form.mother}
                  maxLength={50}
                  id={INPUT_NAMES.mother}
                  onChange={(e) => setForm({ ...form, mother: e.target.value })}
                />
                {/* <InputSelectSimple
                  label="Estado civíl*"
                  placeholder="-"
                  open={openSelectTypeCivilStatus}
                  setOpen={setOpenSelectTypeCivilStatus}
                  type="civilStatus"
                  inputTextValue={form?.civilStatus?.code}
                  inputId={INPUT_NAMES.civilStatus}
                  options={CIVIL_STATUS}
                  onClickSelect={updateFormSelectType}
                /> */}
                <SelectDropdown
                  options={CIVIL_STATUS}
                  label="Estado Civil*"
                  placeholder="Selecione o estado cívil"
                  onChange={(text, name) => {
                    updateFormSelectType(text, INPUT_NAMES.civilStatus, name);
                  }}
                  open={openSelectTypeCivilStatus}
                  setOpen={setOpenSelectTypeCivilStatus}
                  selectedKey={form?.civilStatus?.code}
                />
                {/* <InputSelectSimple
                  label="Escolaridade*"
                  placeholder="-"
                  open={openSelectTypeSchooling}
                  setOpen={setOpenSelectTypeSchooling}
                  type="schooling"
                  inputTextValue={form?.schooling?.code}
                  inputId={INPUT_NAMES.schooling}
                  options={SCHOOLING}
                  onClickSelect={updateFormSelectType}
                /> */}
                <SelectDropdown
                  label="Escolaridade*"
                  placeholder="Selecione sua escolaridade"
                  onChange={(text, name) =>
                    updateFormSelectType(text, INPUT_NAMES.schooling, name)
                  }
                  options={SCHOOLING}
                  id={INPUT_NAMES.schooling}
                  open={openSelectTypeSchooling}
                  setOpen={setOpenSelectTypeSchooling}
                  selectedKey={form?.schooling?.code}
                />
                <SelectDropdown
                  label="Genero*"
                  placeholder="Selecione o gênero"
                  open={openSelectTypeGender}
                  setOpen={setOpenSelectTypeGender}
                  onChange={(text, name) =>
                    updateFormSelectType(text, INPUT_NAMES.gender, name)
                  }
                  id={INPUT_NAMES.gender}
                  selectedKey={form?.gender?.code}
                  options={GENDER.GD_BY_VALUES}
                />
                {/* <InputSelectSimple
                  label="Gênero*"
                  placeholder="-"
                  open={openSelectTypeGender}
                  setOpen={setOpenSelectTypeGender}
                  type="gender"
                  inputTextValue={form?.gender?.code}
                  inputId={INPUT_NAMES.gender}
                  options={GENDER.GD_BY_VALUES}
                  onClickSelect={updateFormSelectType}
                /> */}
                <SelectDropdown
                  label="Motivo do empréstimo*"
                  placeholder="selecione o motivo"
                  options={CREDIT_REASON}
                  id={INPUT_NAMES.creditReason}
                  onChange={(text, name) =>
                    updateFormSelectType(text, INPUT_NAMES.creditReason, name)
                  }
                  open={openSelectTypeCreditReason}
                  setOpen={setOpenSelectTypeCreditReason}
                  selectedKey={form?.creditReason?.name}
                />
                {/* <InputSelectSimple
                  label="Motivo do empréstimo*"
                  placeholder="-"
                  open={openSelectTypeCreditReason}
                  setOpen={setOpenSelectTypeCreditReason}
                  type="payday"
                  inputTextValue={form?.creditReason?.code}
                  inputId={INPUT_NAMES.creditReason}
                  options={CREDIT_REASON}
                  onClickSelect={updateFormSelectType}
                /> */}
              </St.Form>
              <St.Footer align="right">
                <div>
                  <Button
                    isLoading={buttonIsLoading}
                    onClick={handleNext}
                    disabled={activateButton}
                  >
                    Avançar
                  </Button>
                </div>
              </St.Footer>
            </>
          )}
        </St.BoxContent>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default ComplementaryDataByLendingSearch;
