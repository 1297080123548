import React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./styles";
import themes from "~/themes";
import UserProfile from "../UserProfile";

const Header = () => {
  const history = useHistory();

  return (
    <S.Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "0.3rem",
        }}
      >
        <img
          onClick={() => history.push("/home")}
          src={themes.components.images.logoHorizontalTeuCard}
          alt="Logo da empresa"
        />
        <span>Versão: {process.env.REACT_APP_VERSAO_APP}</span>
      </div>
      <S.HeaderLinks>
        <UserProfile />
      </S.HeaderLinks>
    </S.Container>
  );
};

export default Header;
