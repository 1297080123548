import React, { useMemo, useState } from "react";

import Layout from "~/components/Layout";
import ContainerBox from "~/components/ContainerBox";

import PersonalData from "./screens/PersonalData";
import Help from "./screens/Help";
import themes from "~/themes";

import * as S from "./styles";


const Transactions = () => {
  const [selectScreen, setSelectScreen] = useState("personal-data");

  const navItens = [
    {
      id: "personal-data",
      name: "Dados pessoais",
      icon: themes.components.icons.user,
    },
    { id: "help", name: "Ajuda", icon: themes.components.icons.question },
    // {
    //   id: "contract",
    //   name: "Visualizar contrato",
    //   icon: themes.components.icons.consultGrey,
    // },
    // { id: "terms", name: "Termos de uso", icon: themes.components.icons.paper },
  ];

  const screenActive = useMemo(() => {
    switch (selectScreen) {
      case "personal-data":
        return { title: "Alterar dados Pessoais", content: <PersonalData /> };
      case "help":
        return { title: "Ajuda", content: <Help /> };
      default:
        return <></>;
    }
  }, [selectScreen]);


  return (
    <Layout
      onlyHomeItem={true}
      showBackGroundBar={false}
    >
      <ContainerBox withoutBoxShadow>
        <S.Content>
          <S.NavLeft>
            {navItens.map((navItem) => (
              <S.NavItem
                key={navItem.id}
                onClick={() => {
                  navItem.link
                    ? window.open(navItem.link)
                    : setSelectScreen(navItem.id);
                }}
                active={navItem.id === selectScreen}
              >
                <div>{navItem.icon}</div>
                {navItem.name}
              </S.NavItem>
            ))}
          </S.NavLeft>
          <S.ContentScreens>
            <h1>{screenActive.title}</h1>
            {screenActive.content}

            {/* <h1>Alterar dados Pessoais</h1>
            <PersonalData /> */}
          </S.ContentScreens>
        </S.Content>
      </ContainerBox>
    </Layout>
  );
};

export default Transactions;
