import React, { useRef, useState } from "react";

import * as S from "./styles";
import Button from "~/components/Button";

export const PasswordPopup = ({
  onClick,
  text,
  slug,
  visibility,
  setVisibility,
  errorMessage,
}) => {
  const [textInput, setTextInput] = useState(Array.from({ length: 6 }));
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inputRefs, setInputRefs] = useState({
    n1: useRef(),
    n2: useRef(),
    n3: useRef(),
    n4: useRef(),
    n5: useRef(),
    n6: useRef(),
  });

  const onChangeInput = (value, id) => {
    const nextKey = `n${id + 1}`;
    const currentKey = `n${id}`;

    const newTextInput = [...textInput];
    newTextInput[id - 1] = value;

    setTextInput(newTextInput);

    if (value.length) {
      if (id < 6) {
        inputRefs[nextKey].current.focus();
      }
    }
  };

  const handleBack = (evt, id) => {
    const previousKey = `n${id - 1}`;
    const currentKey = `n${id}`;
    if (evt.key == "Backspace" && id <= 6) {
      if (id > 1) {
        inputRefs[previousKey].current.focus();
      }
    }
  };

  const handleClick = async () => {
    try {
      setButtonIsLoading(true);
      setError(null);
      await onClick(textInput.join(""));
    } catch (err) {
      setError(err.message);
    } finally {
      setButtonIsLoading(false);
    }
  };

  return (
    <S.Container open={visibility} onClose={() => setVisibility(false)} modal>
      {(close) => (
        <>
          <div className="content">
            <div className="top-content">
              <p>Senha do cartão</p>
              <S.CloseIcon onClick={close} />
            </div>

            <div className="input-container">
              <input
                onChange={(e) => {
                  onChangeInput(e.target.value, 1);
                }}
                className="input"
                type="text"
                maxLength="1"
                ref={inputRefs.n1}
                onKeyUp={(evt) => handleBack(evt, 1)}
              />
              <input
                onChange={(e) => {
                  onChangeInput(e.target.value, 2);
                }}
                className="input"
                type="text"
                maxLength="1"
                ref={inputRefs.n2}
                onKeyUp={(evt) => handleBack(evt, 2)}
              />
              <input
                onChange={(e) => {
                  onChangeInput(e.target.value, 3);
                }}
                className="input"
                type="text"
                maxLength="1"
                ref={inputRefs.n3}
                onKeyUp={(evt) => handleBack(evt, 3)}
              />
              <input
                onChange={(e) => {
                  onChangeInput(e.target.value, 4);
                }}
                className="input"
                type="text"
                maxLength="1"
                ref={inputRefs.n4}
                onKeyUp={(evt) => handleBack(evt, 4)}
              />
              <input
                onChange={(e) => {
                  onChangeInput(e.target.value, 5);
                }}
                className="input"
                type="text"
                maxLength="1"
                ref={inputRefs.n5}
                onKeyUp={(evt) => handleBack(evt, 5)}
              />
              <input
                onChange={(e) => {
                  setTextInput((textInput[5] = e.target.value));
                  onChangeInput(e.target.value, 6);
                }}
                className="input"
                type="text"
                maxLength="1"
                ref={inputRefs.n6}
                onKeyUp={(evt) => handleBack(evt, 6)}
              />
            </div>
          </div>

          {error && <S.ErrorMessage>{error}</S.ErrorMessage>}

          <hr />

          <div className="button-container">
            <Button onClick={handleClick} isLoading={buttonIsLoading}>
              {text}
            </Button>
          </div>
        </>
      )}
    </S.Container>
  );
};

export default PasswordPopup;
