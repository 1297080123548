import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 10px 0;
`;

export const NavLeft = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 250px;
  padding-top: 8px;
`;

export const NavItem = styled.li`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => (props.active ? "#E71037" : "#B4B4B4")};

  font-size: 20px;
  letter-spacing: -0.69px;
  line-height: 22px;

  div {
    display: flex;
    width: 50px;
    svg {
      margin: auto;
      height: 20px;
      width: 20px;

      path {
        fill: ${(props) => (props.active ? "#E71037" : "#B4B4B4")};
      }
    }
  }

  & + li {
    margin-top: 30px;
  }

  &:hover {
    cursor: pointer;
    color: #e71037;

    div svg path {
      fill: #e71037;
    }
  }
`;

export const ContentScreens = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;

  h1 {
    text-align: center;
    color: #4a4b4a;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.86px;
  }
`;
