import styled from "styled-components";
import themes from "~/themes";

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;

  @media (max-width: 960px) {
    padding: 0px 11px;
  }
`;

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > img {
    width: 20%;
  }

  #logoInfo {
    width: 20%;
  }

  > p {
    font-size: 23px;
    color: #739942;
    font-weight: bold;

    @media (max-width: 760px) {
      font-size: 16px;
    }
  }
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10%;

  > span {
    margin-top: 2px;
    align-self: flex-start;
    font-size: 20px;
    text-align: left;

    @media (max-width: 760px) {
      font-size: 14px;
      padding-right: 0%;
    }
  }

  #options {
    color: ${themes.colors.orange.n2};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Footer = styled.span`
  width: 500px;
  margin-top: 50px;

  @media (max-width: 760px) {
    width: 100%;
  }
`;
