/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

import { GlobalContext } from "~/contexts/GlobalState";
import ContainerBox from "~/components/ContainerBox";
import CardClient from "~/components/CardClient";
import TransactionsClient from "~/components/TransactionsClient";
import Layout from "~/components/Layout";
import TwoButtonPopup from "~/components/Modals/TwoButtonPopup";
import PasswordPopup from "~/components/Modals/PasswordPopup";
import Loader, { LOADER_TYPES } from "~/components/Loader";
import LimitMeter from "~/components/LimitMeter";
import { formatCardNumber, moneyMask } from "~/utils/mask";
import { Popover } from "antd";
import themes from "~/themes";

import "reactjs-popup/dist/index.css";

import MONTHS from "~/assets/mock/monthsPT_BR.json";
import * as S from "./styles";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { CustomizedError } from "~/utils/errors";
import DownloadFatura from "~/components/DownloadFatura";
import assets from "~/assets";
import ExpandableBox from "~/components/ExpandBox";

const CardMain = () => {
  const history = useHistory();

  const { user } = useContext(GlobalContext);
  const [transactions, setTransactions] = useState();
  const [formattedCardNumber, setFormattedCardNumber] = useState(null);
  const [passwordSlug, setPasswordSlug] = useState("");
  const [blockPopupVisibility, setTwoButtonPopupVisibility] = useState(false);
  const [passwordPopupVisibility, setPasswordPopupVisibility] = useState(false);
  const [userBalance, setUserBalance] = useState();
  const [limitWithDraw, setLimitWithDraw] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [popoverVisibility, setPopoverVisibility] = useState(false);
  const [zeussBalance, setZeussBalance] = useState(0);
  const [notSigned, setNotSigned] = useState("");
  const [balanceError, setBalanceError] = useState(false);
  const [zeussPhone, setZeussPhone] = useState("");
  const [categoryCard, setCategoryCard] = useState("");
  const [balanceVisibility, setBalanceVisibility] = useState(
    localStorage.getItem("@NovaEra:balanceVisibility") === "true"
  );

  useEffect(() => {
    (async () => {
      const userInfo = await JSON.parse(
        localStorage.getItem("@NovaEra:infoUsuario")
      );
      setUserInfo(userInfo);
    })();
  }, []);

  const PostTransactions = useApi(
    Endpoints.autorizador.postExtrato
      .replace("PARAM_LIMITE", "3")
      .replace("/PARAM_DATA", ""),
    ApiMethod.POST
  );

  const PostLimitBalance = useApi(
    Endpoints.autorizador.postSaldo,
    ApiMethod.POST
  );

  const RequestPostValidatePassword = useApi(
    Endpoints.autorizador.postValidatePassword,
    ApiMethod.POST
  );

  const RequestConsultCardCategory = useApi(
    Endpoints.autorizador.getClientCurrentCategory.replace(
      "PARAM_CPF",
      userInfo.cpf
    ),
    ApiMethod.GET
  );

  const RequestConsultZeussBalance = useApi(
    Endpoints.cliente.getConsultZeussBalance.replace("PARAM_CPF", userInfo.cpf),
    ApiMethod.GET
  );

  const RequestConsultZeussPhone = useApi(
    Endpoints.cliente.getConsultZeussPhone.replace("PARAM_CPF", userInfo.cpf),
    ApiMethod.GET
  );

  const PostCardValidation = useApi(
    Endpoints.autorizador.postCartao,
    ApiMethod.POST
  );

  const GetLimitAvailable = useApi(
    Endpoints.lendings.getLimitAvailable.replace("PARAM_CPF", userInfo.cpf),
    ApiMethod.GET
  );

  const getZeussBalance = async () => {
    try {
      const response = await RequestConsultZeussBalance.callApi();
      if (response.status >= 200 && response.status < 300) {
        setZeussBalance(response.data?.saldo);
      }
      else if(response.status === 404){
        setBalanceError(response.mensagem);
        setNotSigned(true);       
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getZeussPhone = async () => {
    try {
      const response = await RequestConsultZeussPhone.callApi();
      if (response.status >= 200 && response.status < 300) {
        setZeussPhone(response.data?.celularZeuss);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getClientCategory = async () => {
    try {
      const response = await RequestConsultCardCategory.callApi();
      if (response.status >= 200 && response.status < 300) {
        setCategoryCard(response.data.data[0]?.categoria);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getLimitWithDraw = async () => {
    try {
      const response = await GetLimitAvailable.callApi();

      if (response.status >= 200 && response.status < 300) {
        setLimitWithDraw(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (userInfo.cpf) {
      getLimitWithDraw();
      getClientCategory();
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo.cpf && categoryCard === "Platinum") {
      getZeussBalance();
      getZeussPhone();
    }
  }, [userInfo, categoryCard]);

  useEffect(() => {
    setFormattedCardNumber(formatCardNumber(user?.numeroCartao || ""));
  }, [user.numeroCartao]);

  const tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");

  const contentSecurity = [
    { id: 1, title: "", value: "Bloquear TeuCard", click: "block" },
  ];

  const handleGetTransactions = async () => {
    try {
      const transactions = await PostTransactions.callApi({ tokenCartao });
      setTransactions(transactions.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const getClientStatus = async () => {
    try {
      const response = await PostCardValidation.callApi({ tokenCartao });

      sessionStorage.setItem(
        "@NovaEra:statusCliente",
        response.data.statusCliente
      );
    } catch (err) {}
  };

  const hangleGetUserBalance = async () => {
    try {
      const userData = await PostLimitBalance.callApi({ tokenCartao });
      setUserBalance(userData.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    hangleGetUserBalance();
    handleGetTransactions();
    getClientStatus();
  }, []);

  const handleConfirmBlockModal = () => {
    setTwoButtonPopupVisibility(false);
    setPasswordPopupVisibility(true);
    setPasswordSlug("/cartao-motivo-bloqueio");
  };

  const ValidatePassword = async (senha) => {
    let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
    if (!tokenCartao)
      tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");

    const response = await RequestPostValidatePassword.callApi({
      senha: senha,
      tokenCartao: tokenCartao,
    });

    switch (response.status) {
      case 204:
        return { status: 204 };
      default:
        throw CustomizedError({
          message: response.data.mensagem || response.status,
        });
    }
  };

  const handlePasswordPopup = async (password) => {
    await ValidatePassword(password);
    const parentPath = history.location.pathname;
    console.log("parentPath == " + parentPath);
    console.log("passwordSlug == " + passwordSlug);
    history.push(passwordSlug, { password, parentPath });
  };

  const handleBalanceVisibility = useCallback(() => {
    setBalanceVisibility((state) => {
      localStorage.setItem("@NovaEra:balanceVisibility", !state);
      return !state;
    });
  }, []);

  const handleBalance = (value) => {
    if (value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
        .format(value)
        .replace("R$", "")
        .split(",");
    } else {
      return ["0", "00"];
    }
  };

  function handleClick(click) {
    if (click === "block") {
      setTwoButtonPopupVisibility(!blockPopupVisibility);
    }
  }

  const formatBestDay = (date = "") => {
    const [day, month] = date.split("/");
    return `${day} de ${MONTHS[Number(month) - 1]}`;
  };

  const formatExpirationDate = (date = "") => date.split("/")[0];
  const baixarFatura = () => {
    console.log("baixar fatura");
  };

  return (
    <Layout showBackGroundBar={false}>
      <TwoButtonPopup
        bodyText="Você quer bloquear seu cartão?"
        leftButtonText="Cancelar"
        rightButtonText="Bloquear"
        visibility={blockPopupVisibility}
        setVisibility={setTwoButtonPopupVisibility}
        confirm={handleConfirmBlockModal}
        onClickCancel={() => setTwoButtonPopupVisibility(false)}
      />
      <PasswordPopup
        onClick={handlePasswordPopup}
        text="Continuar"
        slug={passwordSlug}
        visibility={passwordPopupVisibility}
        setVisibility={setPasswordPopupVisibility}
      />
      <S.Container>
        <ContainerBox>
          <S.TransactionHeader>
            <h6>Transações recentes</h6>
            <Link to="/cartao-fatura">
              <DownloadFatura onClick={baixarFatura} />
            </Link>
          </S.TransactionHeader>
          <S.Content>
            {transactions ? (
              <TransactionsClient transactions={transactions.transacoes} />
            ) : (
              <S.WrapperLoader>
                <Loader size={LOADER_TYPES.size.medium} />
              </S.WrapperLoader>
            )}
          </S.Content>
        </ContainerBox>
        <ContainerBox
          position="relative"
          withoutBoxShadow
          gridRow="1/3"
          styleContainer={{ padding: "0" }}
        >
          <S.ContainerHead>
            <S.HeaderBox>
              <div className="title-container">Limites disponíveis</div>
              <div className="icons-container">
                <Popover
                  content={
                    <>
                      <p style={{ marginBottom: 0 }}>Melhor data de compra</p>
                      <p>
                        <strong>
                          {formatBestDay(transactions?.melhorDia)}
                        </strong>
                      </p>
                      <p style={{ marginBottom: 0 }}>Vencimento todo dia</p>
                      <p style={{ marginBottom: 0 }}>
                        <strong>
                          {formatExpirationDate(transactions?.dataVencimento)}
                        </strong>
                      </p>
                    </>
                  }
                  placement="bottomRight"
                  trigger="click"
                  visible={popoverVisibility}
                  onVisibleChange={() =>
                    setPopoverVisibility(!popoverVisibility)
                  }
                >
                  <S.ExclamationIcon />
                </Popover>
                <button type="button" onClick={handleBalanceVisibility}>
                  {balanceVisibility
                    ? assets.icons.openEye
                    : themes.components.icons.blind}
                </button>
              </div>
            </S.HeaderBox>
            <S.valuesTitle>
              <span>Compra</span>
              <span>Empréstimo</span>
            </S.valuesTitle>
            <S.BalanceContent>
              <S.Balance>
                <h5>
                  R${""}
                  <strong>
                    {balanceVisibility
                      ? moneyMask(transactions?.saldo).replace("R$", "")
                      : "●●●●●●"}
                  </strong>
                </h5>
              </S.Balance>
              <S.Balance>
                <h5>
                  R${""}
                  <strong>
                    {balanceVisibility
                      ? moneyMask(limitWithDraw?.limiteSaqueDisponivel).replace(
                          "R$",
                          ""
                        )
                      : "●●●●●●"}
                  </strong>
                </h5>
              </S.Balance>
            </S.BalanceContent>
            {sessionStorage.getItem("@NovaEra:statusCliente") ===
              "INADIMPLENTE" && (
              <small style={{ color: "red", fontSize: 14 }}>
                *Possui fatura em atraso!
              </small>
            )}
          </S.ContainerHead>
          <br />
          <ContainerBox>
            <S.CardClientInfo>
              <CardClient number={formattedCardNumber} userName={user.nome} />

              {userBalance ? (
                balanceVisibility ? (
                  <>
                    <LimitMeter
                      limitTitle={"Limite de compra"}
                      usedLimit={userBalance?.limiteUtilizado}
                      availableLimit={userBalance?.saldo}
                      balanceVisible={balanceVisibility}
                      totalLimit={userBalance?.limiteCartao}
                    />
                    <LimitMeter
                      limitTitle={"Limite de empréstimo"}
                      usedLimit={
                        limitWithDraw?.valorSaqueMaximo -
                        limitWithDraw?.limiteSaqueDisponivel
                      }
                      availableLimit={
                        limitWithDraw?.valorSaqueMaximo -
                        (limitWithDraw?.valorSaqueMaximo -
                          limitWithDraw?.limiteSaqueDisponivel)
                      }
                      totalLimit={limitWithDraw?.valorSaqueMaximo}
                      balanceVisible={balanceVisibility}
                    />
                  </>
                ) : (
                  <>
                    <LimitMeter limitTitle={"Limite de compra"} />
                    <LimitMeter limitTitle={"Limite de empréstimo"} />
                  </>
                )
              ) : (
                <S.WrapperLoader>
                  <Loader />
                </S.WrapperLoader>
              )}
            </S.CardClientInfo>
            {contentSecurity.map((itemData) => (
              <S.HeaderIcons
                key={itemData.id}
                styleContainer={{ gridArea: "ICONS" }}
              >
                {categoryCard === "Platinum" && (
                  <ExpandableBox notSigned={notSigned} errorMessage={balanceError} balance={zeussBalance} phone={zeussPhone} />
                )}
                {itemData.click && (
                  <div onClick={() => handleClick(itemData.click)}>
                    <S.HeaderIcon type="button">
                      {themes.components.icons.bloquearCartao}
                      <p>Bloquear cartão</p>
                    </S.HeaderIcon>
                  </div>
                )}
                <Link to="/consultar-faturas">
                  <S.HeaderIcon>
                    {themes.components.icons.pesquisarFatura}
                    <p>Consultar faturas</p>
                  </S.HeaderIcon>
                </Link>
              </S.HeaderIcons>
            ))}
          </ContainerBox>
        </ContainerBox>
      </S.Container>
    </Layout>
  );
};

export default CardMain;
