/* eslint-disable default-case */
import React from "react";
import { useHistory } from "react-router-dom";

import Button from "~/components/Button";

import * as S from "./styles";
import themes from "~/themes";

const InfoLending = () => {
  const history = useHistory();

  const handleClick = (type) => {
    switch (type) {
      case "WhatsApp":
        window.open(
          "https://api.whatsapp.com/send?phone=559240033203&text=Quero contratar um empréstimo.",
          "_blank"
        );
        break;
      case "Email":
        window.open(`mailto:${"sac@useteucard.com.br/"}`, "_blank");
        break;
      case "Site":
        window.open("http://useteucard.com.br/", "_blank");
        break;
      case "SAC":
        window.open(`tel:${"4003-3203"}`, "_blank");
        break;
    }
  };

  return (
    <S.Content>
      <S.Main>
        <img src={themes.components.images.logoTeuCred2} alt="Logo TeuCred" />

        <img
          id="logoInfo"
          src={themes.components.images.onboardingWalletNoAuth}
          alt="Logo TeuCred Info"
        />

        <p> Eba! Oferecemos várias opções de empréstimo.</p>
        <p>
          Procure uma de nossas lojas para realizar a simulação e contratar na
          hora!
        </p>

        <S.ContentText>
          <span>Para mais informações, acesse:</span>
          <span onClick={() => handleClick("WhatsApp")}>
            WhatsApp: <span id="options">(92) 4003-3203</span>
          </span>
          <span onClick={() => handleClick("Email")}>
            Email: <span id="options">sac@useteucard.com.br</span>
          </span>
          <span onClick={() => handleClick("Site")}>
            Site: <span id="options">http://useteucard.com.br</span>
          </span>
          <span onClick={() => handleClick("SAC")}>
            Central de atendimento (SAC): <span id="options">4003-3203</span>
          </span>
        </S.ContentText>
        <S.Footer>
          <Button onClick={() => history.push("/")}>Entendi</Button>
        </S.Footer>
      </S.Main>
    </S.Content>
  );
};

export default InfoLending;
