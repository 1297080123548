import React, { useCallback, useEffect, useReducer, useState } from "react";
import * as St from "./styles";
import Layout from "~/components/Layout";
import Input from "~/components/Input";
import ContainerBox from "~/components/ContainerBox";
import { cardNumberMask, dueDateMask } from "~/utils/mask";
import { useHistory } from "react-router-dom";
import Button from "~/components/Button";
import { buildCard } from "~/utils/utilsCard";

const nameTitularReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return { value: action.val, isValid: action.val.trim().length > 0 };
  }
  return { value: "", isValid: false };
};

const cardNumberReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return { value: action.val, isValid: action.val.trim().length > 0 };
  }
  return { value: "", isValid: false };
};

const validateDataReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return { value: action.val, isValid: action.val.trim().length > 0 };
  }
  return { value: "", isValid: false };
};

const cvvReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return { value: action.val, isValid: action.val.trim().length > 0 };
  }
  return { value: "", isValid: false };
};

const AddCreditCard = (props) => {
  const [nameTitularState, nameTitularDispatch] = useReducer(
    nameTitularReducer,
    { value: "", isValid: false }
  );
  const [numberCardState, numberCardDispatch] = useReducer(cardNumberReducer, {
    value: "",
    isValid: false,
  });
  const [validateDataState, validateDataDispatch] = useReducer(
    validateDataReducer,
    { value: "", isValid: false }
  );
  const [cvvState, cvvDispatch] = useReducer(cvvReducer, {
    value: "",
    isValid: false,
  });
  const [nameTitularError, setNameTitularError] = useState("");
  const [numberCardError, setNumberCardError] = useState("");
  const [cvvError, setCvvError] = useState("");
  const [validateDataError, setvalidateDataError] = useState("");
  const history = useHistory();

  const [formIsValid, setFormIsValid] = useState(false);
  const [isPrincipal, setIsPrincipal] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const { isValid: nameTitularValid } = nameTitularState;
  const { isValid: numberCardValid } = numberCardState;
  const { isValid: validateDataValid } = validateDataState;
  const { isValid: cvvValid } = cvvState;

  useEffect(() => {
    setFormIsValid(
      nameTitularValid && numberCardValid && validateDataValid && cvvValid
    );
  }, [nameTitularValid, numberCardValid, validateDataValid, cvvValid]);

  const formattedCardNumber = useCallback((value) => {
    numberCardDispatch({ type: "USER_INPUT", val: cardNumberMask(value) });
  }, []);

  const handleCardPrincipal = () => {
    if (isPrincipal) {
      setIsPrincipal(false);
    } else {
      setIsPrincipal(true);
    }
  };
  const nameTitularHandler = (event) => {
    nameTitularDispatch({ type: "USER_INPUT", val: event.target.value });
    setFormIsValid(
      nameTitularValid && numberCardValid && validateDataValid && cvvValid
    );
    setNameTitularError("");
  };
  const cardNumberHandler = (event) => {
    formattedCardNumber(event.target.value);
    setFormIsValid(
      nameTitularValid && numberCardValid && validateDataValid && cvvValid
    );
    setNumberCardError("");
  };
  const cvvHandler = (event) => {
    cvvDispatch({ type: "USER_INPUT", val: event.target.value });
    setFormIsValid(
      nameTitularValid && numberCardValid && validateDataValid && cvvValid
    );
    setCvvError("");
  };
  const validateDataHandler = (event) => {
    let validate = dueDateMask(event.target.value);
    validateDataDispatch({ type: "USER_INPUT", val: validate.masked });
    setFormIsValid(
      nameTitularValid && numberCardValid && validateDataValid && cvvValid
    );
    setvalidateDataError("");
  };

  const confirmFlag = () => {
    submitHandler();
  };

  const submitHandler = () => {
    const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
    const array = validateDataState.value.split("/");

    const dataRequest = {
      anoValidade: array.at(1),
      ccv: cvvState.value,
      cpf: user?.cpf,
      mesValidade: array.at(0),
      nomeTitular: nameTitularState.value,
      numeroCartao: numberCardState.value.replaceAll(" ", ""),
      principal: isPrincipal,
    };

    if (formIsValid) {
      setTimeout(() => {
        history.push("/add-credit-card-verification-code", {
          data: dataRequest,
        });
      }, 100);
    } else {
      if (!nameTitularValid) {
        setNameTitularError("Campo obrigatório");
      }
      if (!numberCardValid) {
        setNumberCardError("Campo obrigatório");
      }
      if (!cvvValid) {
        setCvvError("Campo obrigatório");
      }
      if (!validateDataValid) {
        setvalidateDataError("Campo obrigatório");
      }
    }
  };

  return (
    <>
      <Layout showBackGroundBar={false} productType="Carteira digital">
        <ContainerBox>
          <St.Container>
            <div>
              <St.DoubleColumn
                style={{ marginBottom: "55px", marginRight: "40px" }}
              >
                <h4>Adicionar cartão</h4>
                <Button
                  onClick={() => confirmFlag()}
                  style={{
                    width: "180px",
                    height: "40px",
                    fontWeight: "700",
                    cursor: !formIsValid ? "default" : "pointer",
                  }}
                  type="submit"
                  isLoading={buttonIsLoading}
                  disabled={!formIsValid}
                >
                  Salvar
                </Button>
              </St.DoubleColumn>
              <St.CreditData>
                <St.CreditCardModel
                  style={{ opacity: numberCardState.value ? 1 : 0.6 }}
                >
                  <St.CreditCardModelContent>
                    <h6>{numberCardState.value || "●●●● ●●●● ●●●● ●●●●"}</h6>
                  </St.CreditCardModelContent>
                </St.CreditCardModel>
                <St.Wrapper>
                  <Input
                    label="Número do cartão *"
                    value={numberCardState.value}
                    placeholder="0000 0000 0000 0000"
                    maxLength={19}
                    onChange={cardNumberHandler}
                    error={numberCardError}
                  />
                  <Input
                    label="Nome impresso no cartão *"
                    value={nameTitularState.value}
                    onChange={nameTitularHandler}
                    placeholder="Exemplo: Fernando João"
                    marginBottom={5}
                    error={nameTitularError}
                  />
                  <St.DoubleColumn>
                    <Input
                      style={{ with: "100%" }}
                      label="Validade *"
                      value={validateDataState.value}
                      maxLength={5}
                      placeholder="26/24"
                      onChange={validateDataHandler}
                      marginBottom={5}
                      error={validateDataError}
                    />
                    <Input
                      label="CVV *"
                      value={cvvState.value}
                      maxLength={4}
                      placeholder="123"
                      onChange={cvvHandler}
                      marginBottom={5}
                      error={cvvError}
                    />
                  </St.DoubleColumn>
                  <St.DoubleColumn>
                    <div> Cartão principal?</div>
                    <div
                      style={{
                        marginRight: "25px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginRight: "15px" }}>
                        <St.CheckBoxWrapper>
                          <St.CheckBox
                            id="checkbox"
                            type="checkbox"
                            onChange={handleCardPrincipal}
                          />
                          <St.CheckBoxLabel htmlFor="checkbox" />
                        </St.CheckBoxWrapper>
                      </div>
                    </div>
                  </St.DoubleColumn>
                </St.Wrapper>
              </St.CreditData>
            </div>
          </St.Container>
        </ContainerBox>
      </Layout>
    </>
  );
};
export default AddCreditCard;
