import styled, { css } from 'styled-components'
import Spinner from 'components/Spinner'
import themes from '~/themes'

export const SpinnerCustomized = styled(Spinner).attrs(() => ({
  color: themes.components.colors.n2
}))``

export const WrapperSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div`
  position: relative;

  :hover #options {
    display: flex;
  }

  > img {
    border: 2px solid;
    width: ${({ width }) => width || 100}px;
    height: ${({ height }) => height || 100}px;
    border: 2px solid
      ${themes.components.colors.n2};
  }

  ${WrapperSpinner} {
    width: ${({ width }) => width || 100}px;
    height: ${({ height }) => height || 100}px;
  }

  #icon {
    border-radius: 100px;
    padding: 5px;
    background-color: #ffff;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      background-color: #d3d3d3;
    }
  }

  #options {
    position: absolute;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgba(255, 240, 255, 0.3);
    width: 100%;
    height: 100%;
    display: none;
  }

  ${({ showOptions }) =>
    showOptions &&
    css`
      #options {
        display: flex;
      }
    `}
`

export const ModalImage = styled.img`
  width: 100%;
`
