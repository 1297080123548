import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  border-bottom: 1px solid #EAEAEA;

  .accordeonLabel {
		width: 100%;
		justify-content: space-between;
		display: flex;
		position: relative;
		color: #4A4B4A;
		padding: 18px 0 5px;

		@media print {
			-webkit-print-color-adjust: exact;
		}
	}

	.accordeonContent {
		max-height: 0;
		overflow: hidden;
		transition: max-height .4s ease;
    text-align: justify;

		@media print {
			max-height: 2000px;
		}
	}
	&.isOpen {
		.accordeonLabel {
			span {
				transform: rotate(180deg);
			}
		}
		.accordeonContent {
			max-height: 2000px;
		}
	}
`
