import React, { useState } from 'react';

import {Container } from './styles';
import themes from '~/themes';

const Accordeon = ({ title, children }) => {
	const [ accordeonOpen, setAccordeonOpen ] = useState(false);

	return (
		<Container className={`accordeon${accordeonOpen ? ' isOpen' : ''}`}>
			<p
				className='accordeonLabel'
				onClick={(e) => {
					e.stopPropagation();
					setAccordeonOpen(!accordeonOpen);
				}}
			>
				{title}
				<span>{themes.components.icons.chevronBottom}</span>
			</p>
			<div className='accordeonContent'>{children}</div>
			<br/>
		</Container>
	);
}

export default Accordeon