/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import InputOnboarding from "~/components/InputOnboarding";
import Button from "~/components/Button";
import Loader from "~/components/Loader";

import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { GlobalContext } from "~/contexts/GlobalState";
import { phoneUnmask, cpfUnmask } from "~/utils/masksNew";
import moment from "moment";

import { cpfMask, phoneMask } from "~/utils/mask";
import {
  nameValidator,
  phoneValidator,
  emailValidator,
  documentCPFValidator,
  dateBirthValidator,
} from "~/utils/validators";

import * as S from "../styles";

const ConfirmDataByLoanSearch = () => {
  const [cpf, setCpf] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [email, setEmail] = useState("");
  const [userInfo, setUserInfo] = useState([]);
  const [enabledButton, setEnableButton] = useState(false);
  const [errorsInputs, setErrorsInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const retPhoneNumber = phoneUnmask(phone);
  const retCpf = cpfUnmask(cpf);
  const [form, setForm] = useState({
    name: "",
    cpf: "",
    dataNascimento: "",
    email: "",
    cellPhone: "",
  });

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const userInfo = await JSON.parse(
        localStorage.getItem("@NovaEra:infoUsuario")
      );
      setUserInfo(userInfo);
    })();
  }, []);

  const GetClientByCPF = useApi(
    Endpoints.cliente.getClientCPFByLending.replace("PARAM_CPF", userInfo.cpf),
    ApiMethod.GET
  );

  const PostIncludeLeaders = useApi(
    Endpoints.eventos.postIncludeEvent,
    ApiMethod.POST
  );

  const { addLending, lending } = useContext(GlobalContext);

  const getPersonalData = async () => {
    setLoading(true);
    const response = await GetClientByCPF.callApi();
    if (response.status >= 200) {
      const data = response.data;

      addLending({
        idCliente: data?.id,
        nome: data?.nome,
        cpf: data?.cpf,
        email: data?.email,
        phone: data?.celular ? data?.celular : data?.celular2,
        dataNascimento: data?.dataNascimento,
        street: data?.endereco.logradouro,
        number: data?.endereco.numero,
        complement: data?.endereco.complemento,
        neighborhood: data?.endereco.bairro,
        city: data?.endereco.cidade,
        uf: data?.endereco.uf,
        cep: data?.endereco.cep,
        document: data?.rg,
        mother: data?.nomeMae,
        father: data?.nomePai,
      });
      setForm({
        name: data?.nome,
        cpf: cpfMask(data?.cpf),
        dataNascimento: data?.dataNascimento,
        email: data?.email,
        cellPhone: data?.celular
          ? phoneMask(data?.celular)
          : phoneMask(data?.celular2),
      });
      setLoading(false);
    }
  };

  const handleValidateFields = async () => {
    setButtonIsLoading(true);
    try {
      const fieldsForm = {
        name: nameValidator(form?.name || name).message || "",
        cpf: documentCPFValidator(form?.cpf || cpf).message || "",
        email: emailValidator(form?.email || email).message || "",
        cellPhone: phoneValidator(form?.cellPhone || phone).message || "",
      };

      addLending({
        nome: name,
        email: email,
        dataNascimento: dataNascimento,
        cpf: retCpf,
        phone: retPhoneNumber,
      });

      setErrorsInputs(fieldsForm);
      if (
        !fieldsForm.name &&
        !fieldsForm.cpf &&
        !fieldsForm.dataNascimento &&
        !fieldsForm.cellPhone &&
        !fieldsForm.email
      ) {
        const payload = {
          name: form?.name || name,
          cpf: cpfUnmask(form?.cpf || cpf),
          email: form?.email || email,
          celular: phoneUnmask(form?.cellPhone || phone),
          produto: lending?.idProduto ? lending?.idProduto : "EMPRESTIMO",
          tipoEvento: "LEAD",
          origemEmprestimo: "EASY_CREDITO",
        };
        const response = await PostIncludeLeaders.callApi(payload);
        if (response.data === null) {
          setButtonIsLoading(true);
          history.push("/buscador/dados-adicionais");
        } else if (response.status >= 400) {
          setButtonIsLoading(true);
          history.push("/buscador/dados-adicionais");
        } else {
          setButtonIsLoading(true);
          history.push("/buscador/dados-adicionais");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (userInfo.cpf) {
      getPersonalData();
    }

    if (form.cpf && form.nome && form.email && form.cellPhone > 13) {
      setEnableButton(true);
    }
  }, [userInfo.cpf]);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <S.Content>
        <S.BoxContent>
          {loading ? (
            <S.WrapperLoader>
              <Loader size="large" />
            </S.WrapperLoader>
          ) : (
            <>
              <S.Title>Confirme teus dados</S.Title>
              <S.Subtitle>
                Para buscar as melhores ofertas de empréstimo, precisamos que
                você confirme alguns dados:
              </S.Subtitle>
              <S.Form>
                <InputOnboarding
                  type="text"
                  largeBorder
                  label="Nome completo"
                  value={form.name}
                  onChange={(e) => setName(e.target.value)}
                  maxLength={50}
                  disabled
                  largeLabel
                  error={errorsInputs.name}
                />
                <InputOnboarding
                  type="text"
                  label="CPF"
                  largeBorder
                  defaultValue={cpfMask(form.cpf)}
                  value={form.cpf}
                  disabled
                  onChange={(e) => setCpf(cpfMask(e.target.value))}
                  maxLength={14}
                  largeLabel
                  error={errorsInputs.cpf}
                />
                <InputOnboarding
                  type="text"
                  label="Data de nascimento"
                  largeBorder
                  defaultValue={moment(
                    form.dataNascimento,
                    "YYYY-MM-DD"
                  ).format("DD/MM/YYYY")}
                  value={moment(form.dataNascimento, "YYYY-MM-DD").format(
                    "DD/MM/YYYY"
                  )}
                  disabled
                  onChange={(e) =>
                    setDataNascimento(
                      moment(e.target.value).format("DD/MM/YYYY")
                    )
                  }
                  maxLength={14}
                  largeLabel
                  error={errorsInputs.cpf}
                />
                <InputOnboarding
                  type="text"
                  label="E-mail*"
                  largeBorder
                  value={form.email}
                  onChange={(e) => setEmail(e.target.value)}
                  maxLength={50}
                  disabled
                  largeLabel
                  error={errorsInputs.email}
                />

                <InputOnboarding
                  type="text"
                  label="Celular*"
                  largeBorder
                  value={form.cellPhone}
                  onChange={(e) => setPhone(phoneMask(e.target.value))}
                  maxLength={50}
                  disabled
                  largeLabel
                  error={errorsInputs.cellPhone}
                />
              </S.Form>
              <S.Footer align="right">
                <div>
                  <Button
                    onClick={() => handleValidateFields()}
                    disabled={enabledButton}
                    isLoading={buttonIsLoading}
                  >
                    Avançar
                  </Button>
                </div>
              </S.Footer>
            </>
          )}
        </S.BoxContent>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default ConfirmDataByLoanSearch;
