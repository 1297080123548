import { Link } from 'react-router-dom';
import styled from 'styled-components';


export const Container = styled.div`
  z-index: 1;
  width: 100%;
  height: 100vh;
  padding-right: 50px;
  padding-left: 50px;
  
  background: linear-gradient(to right, #E71037, #ED933A);
  @media screen and (max-width: 991px) {
    width: 100%;
    height: 90vh;
  }

`;

export const Header = styled.span`
  width: 100%;
  height: 10vh;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(to right, #E71037, #ED933A);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  display: flex;
  > img {
    width: 160px;
    height: auto;
  }

`;


export const NavBtnLink = styled(Link)`
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;
padding: 8px 16px;
height: 100%;
width: 50%;
border: none;
outline: none;
`;

export const Button = styled.button`
  border-radius: 10px;
  background: background: linear-gradient(to right, #E71037, #ED933A);
  white-space: nowrap;
  padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
  color: white;
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  &:hover {
    transition: all 0.3s ease-out;
    background: #ED933A;
    background-color: #ED933A;
  }

  @media screen and (max-width: 960px) {
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  }
`;

export const Img = styled.img`
  padding-left: 145vh;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
  position: absolute;
  z-index: 1;
`;

export const Footer = styled.nav`
width: 100%;
height: 12vh;
position: fixed;
bottom: 0;
left: 0;
background: white;
display: flex;
justify-content: center;
align-items: center;

@media screen and (max-width: 960px) {
  width: 100%;
  height: 2vh;
  margin-bottom: 4vh;
}
`;