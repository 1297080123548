import { paymentAPI, walletAPI } from "./api";
import moment from "moment";

export const getWalletDigitalByCpf = async () => {
  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
  try {
    const res = await walletAPI.get(
      `/v1/carteiras/listar-cartoes/${user?.cpf}`
    );
    return res.data;
  } catch (err) {
    return err.response.data.message;
  }
};

export const addExistingCardToWallet = async () => {
  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
  try {
    const response = await walletAPI.post(
      `/v1/carteiras/cadastro-automatico-cartao-teucard/${user?.cpf}`
    );
    return response;
  } catch (err) {
    return err.response.data.message;
  }
};

export const deleteWalletById = async (idCartao) => {
  try {
    const res = await walletAPI.put(`/v1/carteiras/deletar-cartao/${idCartao}`);
    return res;
  } catch (err) {
    return err.response.data.message;
  }
};

export const createNewCreditCard = async (requestBody) => {
  try {
    const res = await walletAPI.post(
      "/v1/carteiras/cadastrar-cartao",
      requestBody
    );
    return res;
  } catch (err) {
    return err.response.data.message;
  }
};

export const getExtrato = async (requestBody) => {
  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
  try {
    const res = await walletAPI.get("/v1/carteiras/extrato", {
      params: {
        cpf: user?.cpf,
        dataInicial: "2021-08-27",
        dataFinal: moment().format("YYYY-MM-DD"),
        numeroCartao: requestBody.numeroCartao,
        pagina: 1,
        totalItens: 10,
      },
    });
    return res;
  } catch (err) {
    return err.response.data.message;
  }
};

export const getpayments = async (idTransacao) => {
  // const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
  try {
    const res = await paymentAPI.get(`/v1/pagamentos/${idTransacao}`);
    return res;
  } catch (err) {
    return err.response.data.message;
  }
};
