import cardValidator from "card-validator";
import { differenceInYears } from "date-fns";
import ddds from "../assets/mock/ddd.json";

export const messagesErrors = {
  cardNumber: "Cartão de crédito inválido.",
  name: "Informe seu nome completo.",
  nameMother: "Informe o nome da sua mãe completo",
  nameFather: "Informe o nome da sue pai completo",
  cpf: "CPF inválido.",
  cnpj: "CNPJ inválido",
  rg: "RG inválido",
  phone: "Número inválido",
  phoneDD: "DDD informador inválido.",
  email: "E-mail inválido.",
  date: "Data inválida.",
  dateMin: "Idade abaixo do necessário.",
  verificationCode: "Código inválido.",
  cep: "CEP inválido.",
  cidade: "Informe a cidade",
  endereco: "Informe o endereço",
  numero: "Informe o número",
  bairro: "Informe o bairro",
  uf: "Informe o estado",
  genero: "Informe o gênero",
  agency: "Agência inválida.",
  account: "Conta inválida.",
  random: "Informe uma chave aleatória válida",
  bank: "Informe o banco",
  typeAccount: "Informe o tipo de conta",
  typeKey: "Informe a chave pix",
  values: "Infome o valor",
  select: "Selecione uma opção.",
  cpfCnpj: "CPF ou CNPJ inválido",
};

const BANK_ERRORS_MESSAGES = {
  invalids: {
    agency: "Agência inválida.",
    account: "Conta inválida.",
    cpf: "Chave CPF inválida.",
    cnpj: "Chave CNPJ inválida.",
    random: "Chave inválida.",
    cep: "CEP inválido.",
    address: "Endereço inválido.",
  },
};

export const verifyHasLength = (value) => value?.length > 0;

export const verifyLengthIsEqual = (value = "", length) =>
  value?.length === length;

export const verifyLengthIsEqualRandom = (value, min, max) => {
  return value.length >= min && value.length <= max;
};

export const agencyBankValidator = (value = "") => {
  if (!verifyHasLength(value))
    return { error: true, message: messagesErrors.agency };
  if (verifyLengthIsEqual(value, 4)) {
    return { error: false };
  } else {
    return { error: true, message: BANK_ERRORS_MESSAGES.invalids.agency };
  }
};

export const accountBankValidator = (value = "") => {
  if (!verifyHasLength(value))
    return { error: true, message: messagesErrors.account };
  if (verifyLengthIsEqual(value, 9)) {
    return { error: false };
  } else {
    return { error: true, message: BANK_ERRORS_MESSAGES.invalids.account };
  }
};

export const selectValidator = (value) => {
  if (!verifyHasLength(value))
    return { error: true, message: messagesErrors.select };
  if (verifyLengthIsEqual(value)) {
    return { error: false, message: messagesErrors.select };
  }
};

export const randomValidator = (value) => {
  if (!verifyHasLength(value)) {
    return { error: true, message: messagesErrors.random };
  }
  if (!verifyLengthIsEqualRandom(value, 32, 36)) {
    return { error: true, message: messagesErrors.random };
  }
  return { error: false, message: "" };
};

export const documentValidator = (value) => {
  if (!verifyHasLength(value))
    return { error: true, message: messagesErrors.rg };
  if (verifyLengthIsEqual(value, 12)) {
    return { error: false };
  } else {
    return { error: true, message: messagesErrors.rg };
  }
};

export const cpfCnpjValidator = (value = "", length = 14) => {
  const max = length < 15 ? 14 : 18;

  if (!verifyHasLength(value)) {
    return { error: true, message: messagesErrors.cpfCnpj };
  }
  if (verifyLengthIsEqual(value, max)) {
    return { error: false };
  } else {
    const message = length < 12 ? "cpf" : "cnpj";
    return { error: true, message: BANK_ERRORS_MESSAGES.invalids[message] };
  }
};

export const defaultReturn = (field, status = false, message = null) => {
  return {
    [field]: {
      status: status,
      message: message,
    },
  };
};

export const cardNumberValidator = (value) => {
  const RegExpNumberCard = new RegExp(/[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}/g);
  // Valida o formato do número com a expressão regular
  if (!RegExpNumberCard.test(value))
    return { error: true, message: messagesErrors.cardNumber };
  // Verifica se é um número de cartão válido
  const validation = cardValidator.number(value);
  if (!validation.isPotentiallyValid)
    return { error: true, message: messagesErrors.cardNumber };

  return { error: false };
};

export const nameValidator = (value) => {
  const [firstName, lastName] = value.split(" ");
  // Valida se existe o nome e sobrenome
  if (firstName.length > 2 && lastName)
    if (lastName.length > 1)
      // Valida se existe ao menos dois caracteres no sobrenome
      return { error: false };

  return { error: true, message: messagesErrors.name };
};

export const nameMotherValidator = (value) => {
  const [firstName, lastName] = value.split(" ");

  if (firstName.length > 2 && lastName)
    if (lastName.length > 1)
      // Valida se existe ao menos dois caracteres no sobrenome
      return { error: false };

  return { error: true, message: messagesErrors.nameMother };
};

export const nameFatherValidator = (value) => {
  const [firstName, lastName] = value.split(" ");

  if (firstName.length > 2 && lastName)
    if (lastName.length > 1)
      // Valida se existe ao menos dois caracteres no sobrenome
      return { error: false };

  return { error: true, message: messagesErrors.nameFather };
};

export const documentCPFValidator = (value) => {
  const RegExpCPF = new RegExp(/[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/g);
  // Valida o formato do CPF com a expressão regular
  if (!RegExpCPF.test(value))
    return { error: true, message: messagesErrors.cpf };

  const firstDigitCPF = Number(value.split("-")[1][0]);
  const newValue = value.replace(/\D/g, "");

  let result = 0;

  for (let k = 0; k < newValue.length - 2; k++) {
    result += Number(newValue.charAt(k)) * (10 - k);
  }
  // Nível 1: Calcúlo com os nove primeiros números
  let isValid = (result * 10) % 11 === firstDigitCPF ? true : false;
  if (isValid) {
    result = 0;
    for (let k = 0; k < newValue.length - 1; k++) {
      result += Number(newValue.charAt(k)) * (10 - k);
    }
    // Nível 2: Calcúlo com os nove primeiros números + o primeiro dígito
    const isValid = (result * 10) % 11 === 0 ? true : false;
    if (isValid) return { error: false };
  }
  return { error: true, message: messagesErrors.cpf };
};

export const phoneValidator = (value) => {
  const RegExpPhone = new RegExp(/[0-9]{2}\)? [0-9]{5}-?[0-9]{4}/g);
  // Valida o formato do telefone com a expressão regular
  if (!RegExpPhone.test(value))
    return { error: true, message: messagesErrors.phone };
  // Isola o DDD do número
  const phoneDDD = Number(value.slice(1, 3));
  const isExistDDD = ddds.some((ddd) => ddd === phoneDDD);

  if (!isExistDDD) return { error: true, message: messagesErrors.phoneDD };

  return { error: false };
};

export const emailValidator = (value) => {
  const RegExpEmail = new RegExp(/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+/g);
  // Valida se a string não está vazia
  if (!RegExpEmail.test(value))
    return { error: true, message: messagesErrors.email };

  const [firstDomain, secondDomain] = value.split("@")[1].split(".");

  if (firstDomain.length > 2 && secondDomain)
    if (secondDomain.length > 1) return { error: false };

  return { error: true, message: messagesErrors.email };
};

export const cepValidator = (value) => {
  if (value.length === 9) {
    return { error: false };
  }
  return { error: true, message: messagesErrors.cep };
};

export const cidadeValidator = (value) => {
  if (value.length !== 0) {
    return { error: false };
  }
  return { error: true, message: messagesErrors.cidade };
};

export const enderecoValidator = (value) => {
  if (value.length !== 0) {
    return { error: false };
  }
  return { error: true, message: messagesErrors.endereco };
};

export const numeroValidator = (value) => {
  if (value.length !== 0) {
    return { error: false };
  }
  return { error: true, message: messagesErrors.numero };
};

export const bairroValidator = (value) => {
  if (value.length !== 0) {
    return { error: false };
  }
  return { error: true, message: messagesErrors.bairro };
};

export const ufValidator = (value) => {
  if (!value?.trim().length || value === "disabled") {
    return { error: true, message: messagesErrors.uf };
  }
  return { error: false };
};

export const generoValidator = (value) => {
  if (!value?.trim().length || value === "disabled") {
    return { error: true, message: messagesErrors.genero };
  }
  return { error: false };
};

export const dateBirthValidator = (value) => {
  const regularExpressionDate = new RegExp(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/g);
  if (!regularExpressionDate.test(value))
    return { error: true, message: messagesErrors.date, a: 1 };

  const [day, month, year] = value.split("/");
  if (Number(month) - 1 > 13 || day > 32)
    return { error: true, message: messagesErrors.date, a: 2 };

  const dateFormat = new Date(year, Number(month) - 1, day);
  const ageUser = differenceInYears(new Date(), dateFormat);
  if (ageUser < 18)
    return { error: true, message: messagesErrors.dateMin, a: 3 };

  return { error: false };
};

export const verificationCodeValidator = (value) => {
  const RegExpVerificationCode = new RegExp(/[0-9]{4} [0-9]{4}/g);
  // Valida o formato da string com uma expressão regular
  if (!RegExpVerificationCode.test(value))
    return { error: true, message: messagesErrors.verificationCode };

  return { error: false };
};

export const isSequencialNumbers = (value) => {
  const verifyAscending = (array, cont = 0) => {
    if (cont === 6 && cont === array.length) {
      return true;
    }

    if (cont === 0 || Number(array[cont]) === Number(array[cont - 1]) + 1) {
      return verifyAscending(array, cont + 1);
    } else {
      return false;
    }
  };

  const verifyDescending = (array, cont = 0) => {
    if (cont === 6 && cont === array.length) {
      return true;
    }

    if (cont === 0 || Number(array[cont]) + 1 === Number(array[cont - 1])) {
      return verifyDescending(array, cont + 1);
    } else {
      return false;
    }
  };

  const arrayNumbers = value.split("");
  return verifyDescending(arrayNumbers) || verifyAscending(arrayNumbers);
};

export const validateIdenticalNumber = (value) => {
  const regexIdenticalNumber = new RegExp(/(\d)\1{2}/);
  // Valida o formato do telefone com a expressão regular
  if (regexIdenticalNumber.test(value))
    return { error: true, message: messagesErrors.password };
};

export const passwordValidator = (value) => {
  // Validando o tamanho mínimo da senha
  if (value.length < 6) return { error: true, message: "Senha mínima" };
  // Validado se a string não é sequencial
  if (isSequencialNumbers(value)) return { message: "Números sequenciais" };

  if (validateIdenticalNumber(value)) return { message: "Números iguais" };
  return false;
};

export const passwordConfirmationValidator = (
  password,
  passwordConfirmation
) => {
  if (password === passwordConfirmation) return false;

  return { message: "Senhas não compatíveis" };
};

export const userPersonalDataValidator = (dataForm, dataApi) => {
  const cpfApiValidate =
    dataForm.document.replace(/\D/g, "") !== dataApi.cpf &&
    "CPF incorreto com o registrado";

  const nameApiValidate =
    dataForm.name.toLowerCase() !== dataApi.nome.toLowerCase() &&
    "Nome incorreto com o registrado";

  const [day, mount, year] = dataForm.birthdate.split("/");
  const birthdateFormatted = `${year}-${mount}-${day}`;
  const birthdateApiValidate =
    birthdateFormatted !== dataApi.dataNascimento &&
    "Data de nascimento incorreta com o registrado";

  // Os validators foram comentados pois a api só retorna dados estáticos e esses dados são inválidos
  return {
    name: nameApiValidate || "",

    // document: documentCPFValidator(document).message || cpfApiValidate || '',
    document: cpfApiValidate || "",

    // birthdate: dateBirthValidator(birthdate).message || birthdateApiValidate || '',
    birthdate: birthdateApiValidate || "",
  };
};
