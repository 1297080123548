import styled from "styled-components";
import Popup from "reactjs-popup";
import { AiOutlineExclamationCircle } from "react-icons/ai";

import themes from "~/themes";

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1200px;
  grid-template-rows: auto auto;
  gap: 22px 0;
  // margin-top: -2%;
  grid-template-areas:
    "BALANCE ICONS"
    "ACTIVITIES CARD";
  overflow: auto;
  height: 100%;
`;

export const ContainerHead = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 15px;
  margin: 0 11px;
  border-radius: 16px;
  background: #ffffff;
  ${({ withoutBoxShadow }) =>
    !withoutBoxShadow
      ? "box-shadow: 1px 3px 8px 0 rgba(201, 201, 201, 0.5);"
      : ""}
`;

export const HeaderBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid;

  .title-container {
    width: 100%;
    display: flex;
    align-items: center;
    color: #4a4b4a;
    font-size: 20px;
    font-weight: 600;
  }

  .icons-container {
    width: 12%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button {
    background: transparent;
    border: 0;
    margin-top: 5px;
  }
`;

export const valuesTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  span {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
    align-self: flex-start;
    margin-left: 1px;
  }
`;

export const CalendarPopup = styled(Popup)`
  &-content {
    width: auto;
    padding: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 30%;
  width: 40vw;
  max-width: 50vw;
  //margin: 0 auto;
`;

export const TransactionHeader = styled.div`
  min-width: 30%;
  width: 45vw;
  max-width: 40vw;
  display: flex;
  flex-direction: row;
  align-items: center;

  h6 {
    flex: 1;
    color: #4a4b4a;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.86px;
    line-height: 27px;
  }

  button {
    background: transparent;
    border: 0;
    height: 21px;
    width: 21px;

    svg {
      height: 21px;
      width: 21px;
    }
  }

`;

export const BalanceContent = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
`;

export const Balance = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  height: 38px;

  h5 {
    color: #4a4b4a;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;

    strong {
      color: #727272;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 23px;
    }
  }

  span {
    color: #727272;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
    align-self: flex-start;
    margin-left: 1px;
  }

  button {
    position: absolute;
    background: transparent;
    border: 0;
    top: 10px;
    left: 130px;
  }
`;

export const HeaderIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;

  a {
    text-decoration: none;
  }
`;

export const ExclamationIcon = styled(AiOutlineExclamationCircle)`
  font-size: 1.8em;
  color: #e71037;
  cursor: pointer;
  z-index: 2;
`;

export const HeaderIcon = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 90px;
  margin-left: 30px;
  margin-right: 30px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  background: #f6f6f6;
  box-shadow: 1px 3px 8px 0 rgba(201, 201, 201, 0.5);

  p {
    color: ${themes.components.colors.newLink};
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0;
    line-height: 13px;
    margin-top: 20px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const CardClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
`;

export const LimitBar = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  margin-top: 35px;

  background: ${themes.components.colors.limitBar};
  border-radius: 3px;

  width: 100%;
  height: 9px;

  > span {
    display: flex;
    width: ${(props) => props.percentage}%;
    height: 9px;
    background: ${themes.components.colors.button};
    border-radius: 2px;
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;

  width: 380px;
`;

export const FooterModal = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;

  padding-right: 34px;
  padding-bottom: 24px;
  margin-top: 12px;

  button {
    text-align: center;
    color: #e71037;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.57px;
    line-height: 22px;
    background: transparent;
    border: 0;

    & + button {
      margin-left: 30px;
    }
  }
`;

export const WrapperLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;
