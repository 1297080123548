import React from "react";

import { AuthProvider } from "./Authentication";
import { GlobalProvider } from "~/contexts/GlobalState";

const AppProvider = ({ children }) => (
  <GlobalProvider>
    <AuthProvider>{children}</AuthProvider>
  </GlobalProvider>
);

export default AppProvider;
