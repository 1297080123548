/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Slider from "~/views/Lending/components/Slider";
import InputOnboarding from "~/components/InputOnboarding";
import Button from "~/components/Button";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";

import { GlobalContext } from "~/contexts/GlobalState";
import { moneyMask, moneyBrazilian } from "~/utils/mask";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

import * as St from "../../styles";
import themes from "~/themes";

const SimulationWithdraw = () => {
  const [input, setInput] = useState({
    formatted: "",
    unFormatted: "",
  });
  const [values, setValues] = useState({
    min: "",
    max: "",
    selected: "0",
    indicator: "",
    default: "0",
  });
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [messageErrorPopup, setMessageErrorPopup] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const history = useHistory();

  const { lending, addLending } = useContext(GlobalContext);

  const PostSimulationWithdrawal = useApi(
    Endpoints.lendings.postSimulationWithdrawal,
    ApiMethod.POST
  );

  const getInitialValues = () => {
    setValues(property.saque);
    setInput({
      unFormatted: property.saque.min,
      formatted: moneyMask(property.saque.min),
    });
  };

  const updateFormField = (value) => {
    const valueFloat =
      value.unmasked.toString().slice(0, value.unmasked.length - 2) +
      "." +
      value.unmasked.toString().slice(value.unmasked.length - 2);
    setInput({
      formatted: value.masked,
      unFormatted: parseFloat(valueFloat).toFixed(2),
    });
  };

  const updateSliderField = (value) => {
    setValues({
      ...values,
      selected: value.masked,
    });
  };

  const property = {
    saque: {
      min: 100,
      max: lending?.limiteSaqueDisponivel,
      selected: 100,
      text: "Valor do Saque",
      indicator:
        "Lembre-se, essa simulação só funciona com valores dentro do teu limite de empréstimo disponível.",
    },
  };

  const simulationWithdrawal = async () => {
    setButtonIsLoading(true);

    if (input.unFormatted < values.min) {
      setButtonIsLoading(false);
      setShowPopup(true);
      setMessageErrorPopup("O valor inserido não corresponde ao valor mínimo.");
    } else if (input.unFormatted > values.max) {
      setButtonIsLoading(false);
      setShowPopup(true);
      setMessageErrorPopup("O valor inserido ultrapassou valor máximo.");
    } else {
      const payload = {
        cpf: lending?.cpf,
        valorSolicitado: Number(input.unFormatted),
      };

      try {
        const response = await PostSimulationWithdrawal.callApi(payload);
        if (response.status >= 200 && response.status < 300) {
          const { data } = response;

          addLending({
            taxaJuros: data?.interest_rate,
            payment_amount: data?.payment_amount,
            last_payment_date: data?.last_payment_date,
            num_periods: data?.num_periods,
            requested_amount: data?.requested_amount,
            idSimulacao: data?.idSimulacao,
            financeFee: data?.finance_fee,
            first_payment_date: data?.first_payment_date,
            tac_amount: data?.tac_amount,
            origem: "SITE",
            disbursement_date: data?.disbursement_date,
            monthly_effective_interest_rate:
              data?.monthly_effective_interest_rate,
          });

          history.push("/emprestimo/resultado-saque");
        } else {
          setShowPopup(true);
          setMessageErrorPopup(
            response.mensagem ? response.mensagem : "Erro ao simular"
          );
        }
        setButtonIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/");
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, [verifyReloadPage]);

  useEffect(() => {
    getInitialValues();
  }, []);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={messageErrorPopup}
        buttonText="Entendi"
        visibility={showPopup}
        setVisibility={setShowPopup}
      />
      <St.Content>
        <St.BoxContent>
          <St.Title>Valor da Simulação</St.Title>
          <St.Subtitle>
            Conta pra gente o <b>Valor do Saque</b> que você deseja simular:
          </St.Subtitle>
          <St.Steps>Passo 2 de 11</St.Steps>
          <St.SliderContainer>
            <St.Slider>
              <Slider
                min={values.min}
                max={values.max}
                selected={String(values.selected).replace("R$", "")}
                value={input.unFormatted}
                handleChange={(value) => {
                  updateSliderField(
                    moneyBrazilian(Number(value.target.value).toFixed(2))
                  );
                  updateFormField(
                    moneyBrazilian(Number(value.target.value).toFixed(2))
                  );
                }}
                step={1}
              />
              <InputOnboarding
                type="text"
                label="Valor"
                largeLabel
                placeholder="digite o valor"
                value={input.formatted}
                maxLength={15}
                defaultValue={0}
                step={1}
                onChange={(e) => {
                  updateSliderField(moneyBrazilian(e.target.value));
                  updateFormField(moneyBrazilian(e.target.value));
                }}
              />
            </St.Slider>
            <St.Slider>
              <St.Row>
                <img
                  src={themes.components.images.orangeCircle}
                  alt="Indicador"
                />
                <p>{values.indicator}</p>
              </St.Row>
            </St.Slider>
          </St.SliderContainer>
          <St.Footer align="center">
            <div style={{ width: "55%" }}>
              <Button
                isLoading={buttonIsLoading}
                onClick={simulationWithdrawal}
              >
                <b>Simular</b>
              </Button>
            </div>
          </St.Footer>
        </St.BoxContent>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default SimulationWithdraw;
