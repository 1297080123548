import React from "react";
import themes from "../../themes";
import { Link } from "react-router-dom";
import * as St from "./styles"; // Importe todos os estilos do styles.js

const LoanProducts = ({ data = [] }) => {
  const renderIcon = (type) => {
    if (type === "privateLabel") {
      return (
        <img
          style={{ width: 40, height: 40 }}
          src={themes.components.images.teuCardLending}
          alt="Private Label"
        />
      );
    }

    if (type === "loanSearch") {
      return (
        <img
          style={{ width: 40, height: 40, marginTop: 10 }}
          src={themes.components.images.loanSearch}
          alt="Loan Search"
        />
      );
    }
  };

  const renderItem = (item, index) => {
    return (
      <Link to={item.route} key={index}>
        <St.Box>
          {renderIcon(item.icon)}
          <St.ItemIdentify>{item.title}</St.ItemIdentify>
        </St.Box>
      </Link>
    );
  };

  return (
    <>
      <St.Container>
        {data.map((item, index) => renderItem(item, index))}
      </St.Container>
    </>
  );
};

export default LoanProducts;
