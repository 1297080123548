/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";

import Input from "~/components/Input";
import Button from "~/components/Button";
import { cpfMask, dateMask } from "~/utils/mask";
import themes from "~/themes";
import * as S from "../styles";

import { SectionForm } from "../styles";

const UserPersonalData = ({ handleNextSection, dataApi }) => {
  const [name, setName] = useState("");
  const [document, setDocument] = useState("");
  const [birthdate, setBirthdate] = useState("");
  console.log(dataApi)

  console.log(birthdate, "testando")

  const [errorsInputs, setErrorsInputs] = useState({});

  const validateUserPersonalData = (dataForm, dataApi) => {
    const cpfApiValidate = dataForm.document.replace(/\D/g, "") !== dataApi.cpf;

    const [day, mount, year] = dataForm.birthdate.split("/");
    const birthdateFormatted = `${year}-${mount}-${day}`;
    const birthdateApiValidate = birthdateFormatted !== dataApi.dataNascimento;

    // Os validators foram comentados pois a api só retorna dados estáticos e esses dados são inválidos
    return {
      document: cpfApiValidate ? "CPF incorreto com o registrado" : "",
      birthdate: birthdateApiValidate
        ? "Data de nascimento incorreta com o registrado"
        : "",
    };
  };

  const handleValidadeUserData = useCallback(
    (event) => {
      event.preventDefault();

      const dataUser = {
        document,
        birthdate,
      };

      console.log(dataUser)

      const errorsValidator = validateUserPersonalData(dataUser, dataApi);

      const isErrors = Object.entries(errorsValidator).findIndex(
        (input) => input[1] !== ""
      );

      // O valor é -1 quando não haver erros
      if (isErrors === -1) {
        dataUser.cpf = dataApi.cpf;
        handleNextSection({ user: dataUser });
      }

      setErrorsInputs(errorsValidator);
    },
    [name, document, birthdate, dataApi]
  );

  const handleDisabledButton = () =>
    document.length !== 14 || birthdate.length !== 10;

  return (
    <S.AlternativeMain>
      <img src={themes.components.images.logoTeuCard} alt="Logo TeuCard" />
      <p>Desbloqueio de cartão</p>
      <S.MiddleText>
        <SectionForm onSubmit={handleValidadeUserData}>
          <p>
            Para utilizar o cartão TeuCard, é necessário realizar o desbloqueio
            primeiro. Preencha os campos abaixo para prosseguir:
          </p>
          <Input
            type="text"
            label="CPF"
            placeholder="000.000.000-00"
            maxLength={14}
            value={document}
            onChange={(e) => setDocument(cpfMask(e.target.value))}
            error={errorsInputs.document}
          />

          <Input
            type="text"
            label="Data de nascimento"
            placeholder="dd/mm/aaaa"
            maxLength={10}
            value={birthdate}
            onChange={(e) => setBirthdate(dateMask(e.target.value))}
            error={errorsInputs.birthdate}
          />

          <Button type="submit" disabled={handleDisabledButton()}>
            Avançar
          </Button>
        </SectionForm>
      </S.MiddleText>
    </S.AlternativeMain>
  );
};

export default UserPersonalData;
