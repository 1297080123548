/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import NavLinkbar from "~/components/navLinkBar";
import InfoSection from "~/components/InfoSection/InfoSection";

import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

import * as St from "./styles";
import { homeObjOne } from "./data";

const LinkSMS = () => {
  const [footer, setFooter] = useState(true);
  const [platform, setPlatform] = useState(true);
  const [idUser, setIdUser] = useState("");

  const PUTAddReadLead = useApi(
    Endpoints.eventos.putReadLead.replace("PARAM_ID", idUser),
    ApiMethod.PUT
  );

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setFooter(false);
    } else {
      setFooter(true);
    }
  };

  useEffect(() => {
    function getIdUser() {
      if (window.location.search) {
        const paramOrigem = window.location.search.split("=");
        setIdUser(paramOrigem[1]);
        showButton();
        platformUse();
      }
    }
    getIdUser();
  }, []);

  window.addEventListener("resize", showButton);

  const platformUse = () => {
    let userAgent = navigator.userAgent;
    console.log(userAgent);
    if (userAgent.includes("Android")) {
      setPlatform("Android");
    } else if (userAgent.includes("iPhone")) {
      setPlatform("iPhone");
    } else if (userAgent.includes("Windows")) {
      setPlatform("Windows");
    }
  };

  useEffect(() => {
    function handleSetReadLead() {
      if (idUser) {
        PUTAddReadLead.callApi();
      }
    }
    handleSetReadLead();
  }, [idUser]);

  return (
    <St.Container>
      <NavLinkbar />
      <InfoSection platform={platform} {...homeObjOne} />
    </St.Container>
  );
};

export default LinkSMS;
