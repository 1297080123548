import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '~/views/hyperLink/globalStyles';
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
  Button
} from './InfoSection.elements';

function InfoSection({
  primary,
  lightBg,
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  platform,
  start
}) {

  const sendToStore = () => {
    if(platform === "Android"){
      window.open("https://play.google.com/store/apps/details?id=com.novaera", '_blank');
    }
    else if(platform === "iPhone"){
      window.open("https://apps.apple.com/br/app/teucard/id1547285337");
    }

    else if (platform === "Windows") {
      window.open("https://play.google.com/store/apps/details?id=com.novaera", '_blank')
    }
  }

  return (
    <>
      <InfoSec lightBg={lightBg}>
        <Container>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper>
                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                  <Button onClick={() => sendToStore()} big fontBig primary={primary}>
                    {buttonLabel}
                  </Button>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper start={start}>
                <Img src={img} alt={alt} />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );
}

export default InfoSection;
