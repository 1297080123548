import React, { useEffect } from "react";
import LayoutLogin from "~/components/LayoutLogin";
import Layout from "~/components/Layout";
import Button from "~/components/Button";
import themes from "~/themes";
import { useHistory } from "react-router-dom";
import { FaRegHandshake } from "react-icons/fa";

import * as S from "./styles";

const Welcome = () => {
  const history = useHistory();

  const handleNegotiate = () => {
    window.open("https://negocie.useteucard.com.br/");
  };
  return (
    <LayoutLogin auth={false}>
      <S.Content>
        <S.Main>
          <S.InformationDiv>
            <img
              src={themes.components.images.logoTeuCard}
              alt="Logo TeuCard"
            />
            <p> Bem-vindo ao Portal TeuCard </p>

            <p>Aproveite tudo que preparamos para você.</p>
          </S.InformationDiv>

          <S.WrapperButton>
            <Button onClick={() => (window.location = "/login")}>Entrar</Button>
            <Button
              style={{ marginTop: 30 }}
              type="submit"
              onClick={() => history.push("/home-produtos")}
            >
              Fazer parte
            </Button>
            <Button
              style={{ marginTop: 30 }}
              type="submit"
              onClick={() => history.push("/cadastro")}
            >
              Desbloquear/Validar cartão
            </Button>
            <Button
              style={{ marginTop: 30 }}
              onClick={handleNegotiate}
              icon={<FaRegHandshake size={30} style={{ marginRight: 10 }} />}
            >
              Negocie aqui
            </Button>
          </S.WrapperButton>
        </S.Main>
        <S.ContentVersion>
          <S.Version>{process.env.REACT_APP_VERSAO_APP}</S.Version>
        </S.ContentVersion>
      </S.Content>
      <S.BackgroundLogin />
    </LayoutLogin>
  );
};

export default Welcome;
