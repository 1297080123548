import styled from 'styled-components';

import themes from '~/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: ${({marginBottom}) => marginBottom ? marginBottom : '5%'};
  margin-bottom: 2%;
  position: relative;

  label {
    font-size: 12px;
    color: ${(props) =>
      props.isFocus ? themes.colors.orange.n2 : themes.components.colors.label};
  }

  input {
    padding-left: 10px;
    font-size: 16px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) =>
      props.isFocus ? themes.colors.orange.n2 : '#444444'};

    color: ${themes.components.colors.input};
    height: 45px;
    background: ${themes.colors.white.n5} ;
    border-radius: 7px;
  }

  .icon {
    position: absolute;
    max-width: 18px;
    right: 10px;
    top: 20px;
    cursor: pointer;
  }
`;

export const ErrorText = styled.span`
  font-size: 12px;
  color: ${themes.components.colors.messageError};
  margin-top: 2px;
`;
