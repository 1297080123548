/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import InputOnboarding from "~/components/InputOnboarding";
import InputSelect from "~/components/InputSelect";
import Button from "~/components/Button";

import { GlobalContext } from "~/contexts/GlobalState";
import { optionsUfs } from "./constants";
import { rgMask, cepUnmask, cepMask, numericMask } from "~/utils/mask";
import { dateMask } from "~/utils/masksNew";
import { api } from "~/services/api";
import constants from "~/utils/constants";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";

import {
  bairroValidator,
  cepValidator,
  cidadeValidator,
  dateBirthValidator,
  enderecoValidator,
  documentValidator,
  numeroValidator,
  ufValidator,
  nameFatherValidator,
  nameMotherValidator,
} from "~/utils/validators";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

import themes from "~/themes";

import moment from "moment";

import * as S from "../../styles";

const PersonalDataMain = () => {
  const [dataNascimento, setDataNascimento] = useState("");
  const [endereco, setEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [cep, setCep] = useState("");
  const [rg, setRg] = useState("");
  const [nameMother, setNameMother] = useState("");
  const [nameFather, setNameFather] = useState("");
  const [disableEndereco, setDisableEndereco] = useState(false);
  const [errorsInputs, setErrorsInputs] = useState({});
  const [messagePopup, setMessagePopup] = useState(false);
  const [messageErrorPopup, setMessageErrorPopup] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const retCep = cepUnmask(cep);

  const { addLending, lending } = useContext(GlobalContext);

  const history = useHistory();

  useEffect(() => {
    setRg(rgMask(lending.document).masked);
    setDataNascimento(lending.dataNascimento);
    setCep(cepMask(lending.cep));
    setEndereco(lending.street);
    setBairro(lending.neighborhood);
    setNumero(lending.number);
    setCidade(lending.city);
    setUf(lending.uf);
    setNameFather(lending.father);
    setNameMother(lending.mother);
  }, [lending]);

  // const putChangedAdress = useApi(
  //   Endpoints.cliente.putChangedAddress,
  //   ApiMethod.PUT
  // );

  // const putUpdateClientByLending = useApi(
  //   Endpoints.cliente.updateClientByLending,
  //   ApiMethod.PUT
  // );

  async function handleCep(e) {
    const valor = e?.target.value;

    setCep(cepMask(valor));
    if (valor?.length === 9 || valor?.length === 8) {
      setDisableEndereco(true);

      api
        .get(constants.endpoints.findAddressByCep.replace("PARAM_CEP", valor))
        .then((response) => {
          setDisableEndereco(false);
          setEndereco(response.data?.logradouro);
          setBairro(response.data?.bairro);
          setCidade(response.data?.localidade);
          setUf(response.data?.uf);
        });
    }
  }

  function isMinor(birthdateString) {
    const birthdate = new Date(birthdateString?.split("/").reverse().join("-"));

    var today = new Date();
    var age = today.getFullYear() - birthdate.getFullYear();

    if (
      today.getMonth() < birthdate.getMonth() ||
      (today.getMonth() === birthdate.getMonth() &&
        today.getDate() < birthdate.getDate())
    ) {
      age--;
    }
    return age < 18;
  }

  const handleNext = () => {
    if (isMinor(dataNascimento?.masked)) {
      setShowPopup(true);
      setMessagePopup("Menor de idade, verifique a data de nascimento");
    } else {
      handleValidateFields();
    }
  };

  const handleValidateFields = async () => {
    setButtonIsLoading(true);

    try {
      const fieldsForm = {
        cep: cepValidator(cep).message || "",
        cidade: cidadeValidator(cidade).message || "",
        uf: ufValidator(uf).message || "",
        endereco: enderecoValidator(endereco).message || "",
        bairro: bairroValidator(bairro).message || "",
        numero: numeroValidator(numero).message || "",
        dataNascimento:
          dateBirthValidator(
            moment(lending?.dataNascimento, "YYYY-MM-DD").format("DD/MM/YYYY")
          ).message || "",
        rg: rg.message || "",
        nameMother: nameMotherValidator(nameMother).message || "",
        nameFather: nameFather || "",
      };

      if (!rg || !nameMother) {
        if (!rg) {
          setMessagePopup("É obrigatório informar o RG");
        }

        if (!nameMother) {
          setMessagePopup("É obrigatório informar o nome da mãe");
        }

        if (!numero) {
          setMessagePopup("É obrigatório informar o número do endereço");
        }

        if (!cep || cep.length < 9) {
          setMessagePopup("É obrigatório informar o CEP");
        }

        if (!dataNascimento.length === 9) {
          setMessagePopup("É obrigatório informar a data nascimento");
        }
        if (isMinor(dataNascimento?.masked)) {
          setMessagePopup(
            "O Empréstimo não está disponível para menores de 18 anos"
          );
        }

        setButtonIsLoading(false);
        setShowPopup(true);

        return;
      }

      setErrorsInputs(fieldsForm);

      if (
        !fieldsForm.cep &&
        !fieldsForm.cidade &&
        !fieldsForm.uf &&
        !fieldsForm.endereco &&
        !fieldsForm.bairro &&
        !fieldsForm.numero &&
        !fieldsForm.dataNascimento &&
        !fieldsForm.rg &&
        !fieldsForm.nameMother
      ) {
        addLending({
          cep: retCep,
          cidade: cidade,
          uf: uf,
          endereco: endereco,
          bairro: bairro,
          numero: numero,
          dataNascimento: dataNascimento?.masked
            ?.split("/")
            .reverse()
            .join("-"),
          document: rg?.unmasked,
          mother: nameMother,
          father: nameFather,
        });

        setButtonIsLoading(false);
        history.push("/emprestimo/dados-bancarios");
      }
    } catch (error) {
      console.log(error);
    }

    setButtonIsLoading(false);
  };

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/home-produtos");
    }
  };

  const activateButtonNext = useMemo(
    () => rg?.length === 11 && nameMother,
    [rg, nameMother]
  );

  useEffect(() => {
    verifyReloadPage();
  }, []);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={messagePopup}
        buttonText="Entendi"
        visibility={showPopup}
        setVisibility={setShowPopup}
      />
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={messageErrorPopup}
        buttonText="Entendi"
        visibility={showErrorPopup}
        setVisibility={() => {
          setShowErrorPopup(false);
          history.push("/home");
        }}
      />
      <S.Content>
        <S.Grid>
          <S.Column noBorder>
            <S.TitlePersonalData>Dados adicionais</S.TitlePersonalData>
            <S.Subtitle>
              Para dar continuidade, precisamos de algumas informações
              adicionais Tuas:
            </S.Subtitle>
            <S.Form>
              <InputOnboarding
                type="text"
                label="Data de Nascimento"
                maxLength={12}
                largeLabel
                defaultValue={moment(
                  lending.dataNascimento,
                  "YYYY-MM-DD"
                ).format("DD/MM/YYYY")}
                value={dataNascimento?.masked}
                onChange={(e) => setDataNascimento(dateMask(e.target.value))}
                error={errorsInputs.dataNascimento}
              />
              <InputOnboarding
                type="text"
                label="RG*"
                largeLabel
                maxLength={11}
                onChange={(e) => setRg(e.target.value)}
                placeholder="00.000.000-0"
                defaultValue={lending.document}
                pattern="[0-9]*"
                // disabled={rg ? true : false}
                value={rg}
                error={errorsInputs.rg}
              />
              <InputOnboarding
                type="text"
                label="Nome da Mãe*"
                largeLabel
                placeholder="Digite o nome da Mãe"
                defaultValue={nameMother}
                value={nameMother}
                maxLength={50}
                onChange={(e) => setNameMother(e.target.value)}
                error={errorsInputs.nameMother}
              />

              <InputOnboarding
                type="text"
                label="Nome do Pai"
                placeholder="Digite o nome do Pai"
                largeLabel
                defaultValue={nameFather}
                value={nameFather}
                maxLength={50}
                onChange={(e) => setNameFather(e.target.value)}
                error={errorsInputs.nameFather}
              />
            </S.Form>
          </S.Column>
          <S.Column noBorder>
            <S.TitlePersonalData>Dados residenciais</S.TitlePersonalData>
            <S.Form style={{ marginTop: "47px" }}>
              <S.Row>
                <InputOnboarding
                  name="zipCode"
                  id="zipCode"
                  maxLength={9}
                  pattern="[0-9]*"
                  type="text"
                  label="CEP*"
                  largeLabel
                  defaultValue={cepMask(cep)}
                  placeholder="Digite seu CEP"
                  onChange={handleCep}
                  value={cepMask(cep)}
                  error={errorsInputs.cep}
                />
                <InputSelect
                  type="select"
                  label="UF*"
                  className="select"
                  largeLabel
                  defaultValue={uf}
                  value={uf}
                  placeholder="Selecione o estado"
                  values={optionsUfs}
                  onChange={(e) => setUf(e.target.value)}
                  error={errorsInputs.uf}
                />
              </S.Row>
              <InputOnboarding
                type="text"
                label="Endereço*"
                largeLabel
                maxLength={100}
                defaultValue={endereco}
                value={endereco}
                disabled={disableEndereco}
                error={errorsInputs.endereco}
              />
              <S.Row>
                <InputOnboarding
                  type="text"
                  label="Número"
                  largeLabel
                  maxLength={14}
                  defaultValue={numero}
                  placeholder="Digite o número do endereço"
                  value={numero}
                  onChange={(e) => setNumero(numericMask(e.target.value))}
                  error={errorsInputs.numero}
                  disabled={disableEndereco}
                />
                <InputOnboarding
                  type="text"
                  label="Complemento (opcional)"
                  placeholder="Digite o complemento"
                  largeLabel
                  maxLength={20}
                  defaultValue={complemento}
                  value={complemento}
                  onChange={(e) => setComplemento(e.target.value)}
                  error={errorsInputs.complemento}
                />
              </S.Row>
              <S.Row>
                <InputOnboarding
                  type="text"
                  label="Bairro"
                  maxLength={14}
                  largeLabel
                  value={bairro}
                  defaultValue={bairro}
                  onChange={(e) => setBairro(e.target.value)}
                  error={errorsInputs.bairro}
                  disabled={disableEndereco}
                />
                <InputOnboarding
                  type="text"
                  label="Cidade"
                  largeLabel
                  maxLength={30}
                  value={cidade}
                  defaultValue={cidade}
                  onChange={(e) => setCidade(e.target.value)}
                  error={errorsInputs.cidade}
                  disabled={disableEndereco}
                />
              </S.Row>
            </S.Form>
            <S.FooterData>
              <div>
                <Button
                  onClick={() => handleNext()}
                  isLoading={buttonIsLoading}
                  disabled={activateButtonNext}
                >
                  Avançar
                </Button>
              </div>
            </S.FooterData>
          </S.Column>
        </S.Grid>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default PersonalDataMain;
