import React, { useState, useEffect } from "react";
import themes from "~/themes";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavItemBtn,
  NavBtnLink,
  Button,
} from "./elements";

function NavLinkbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            <img
              src={themes.components.images.logoTeuCard}
              alt="Logo da empresa"
            />
          </NavLogo>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default NavLinkbar;
