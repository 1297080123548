import React, { useCallback, useState } from "react";

import Input from "~/components/Input";
import Button from "~/components/Button";
import CardClient from "~/components/CardClient";

import { SectionForm, WrapperCard } from "../styles";

const CardPassword = ({ handleNextSection, card, userName }) => {
  const [passwordCard, setPasswordCard] = useState("");

  const handleValidadePasswordCard = useCallback(
    (event) => {
      try {
        event.preventDefault();

        handleNextSection({ card: { ...card, password: passwordCard } });
      } catch (err) {
        console.log(err);
      }
    },
    [handleNextSection, card, passwordCard]
  );

  const handleDisabledButton = () => passwordCard.length !== 6;

  return (
    <SectionForm onSubmit={handleValidadePasswordCard}>
      <WrapperCard>
        <CardClient number={card.number} userName={userName} />
      </WrapperCard>

      <p>Agora, precisamos que preencha a senha do seu cartão.</p>

      <Input
        type="password"
        label="Senha do cartão"
        placeholder="******"
        maxLength={6}
        value={passwordCard}
        onChange={(e) => setPasswordCard(e.target.value)}
      />

      <Button type="submit" disabled={handleDisabledButton()}>
        Continuar
      </Button>
    </SectionForm>
  );
};

export default CardPassword;
