export const openInNewTab = async (readableStream) => {
    const blob = await readableStream.blob();
  
    // Criar um objeto URL para o Blob
    const blobUrl = URL.createObjectURL(blob);
  
    // Abrir uma nova aba com o URL do Blob
    const newTab = window.open(blobUrl, '_blank');
  
    // Revogar o URL do objeto quando a nova aba é fechada
    if (newTab) {
      newTab.addEventListener('beforeunload', () => {
        window.URL.revokeObjectURL(blobUrl);
      });
    }
  };
