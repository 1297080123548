/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loader, { LOADER_TYPES } from "~/components/Loader";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { CustomizedError } from "~/utils/errors";
import Layout from "~/components/Layout";

import * as S from "./styles";

const Contract = ({ props }) => {
  const [proposalID, setProposalID] = useState();
  const [isLoading, seIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));

  const RequestGetClientByCPF = useApi(
    Endpoints.cliente.getClientByCPF.replace("PARAM_CPF", user?.cpf),
    ApiMethod.GET
  );

  const GetClientByCPF = async (cpfClient) => {
    if (!cpfClient) {
      let user = localStorage.getItem("@NovaEra:infoUsuario");
      if (user) {
        user = JSON.parse(user);
        cpfClient = user.cpf;
      }
    }
    const response = await RequestGetClientByCPF.callApi();

    switch (response.status) {
      case 200:
        return { data: response.data };
      default:
        throw CustomizedError({
          message: response.data.mensagem || response.status,
        });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        seIsLoading(true);
        const response = await GetClientByCPF();
        setProposalID(response.data.propostaId);
      } catch (err) {
        setErrorMessage(err.message);
      } finally {
        seIsLoading(false);
      }
    })();
  }, []);

  const data = `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/noauth/proposta-assinatura?id=${proposalID}`;

  return props ? (
    <S.Container>
      {isLoading ? (
        <S.WrapperLoader>
          <Loader size={LOADER_TYPES.size.medium} />
        </S.WrapperLoader>
      ) : (
        <>
          {errorMessage ? (
            <h6> aasdasdasd</h6>
          ) : (
            <iframe
              src={data}
              type="application/pdf"
              style={{ border: "none" }}
              title="proposta"
            ></iframe>
          )}
        </>
      )}
    </S.Container>
  ) : (
    <Layout showBackGroundBar={false}>
      <S.Container>
        <h1>Visualizar contrato</h1>
        {isLoading ? (
          <S.WrapperLoader>
            <Loader size={LOADER_TYPES.size.medium} />
          </S.WrapperLoader>
        ) : (
          <>
            {errorMessage ? (
              <h6> aasdasdasd</h6>
            ) : (
              <iframe
                src={data}
                type="application/pdf"
                style={{ border: "none" }}
                title="proposta"
              ></iframe>
            )}
          </>
        )}
      </S.Container>
    </Layout>
  );
};

export default Contract;
