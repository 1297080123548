/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Layout from "~/components/Layout";
import TransactionsLending from "../../Lending/components/TransactionsLending";
import Button from "~/components/Button";
import Loader from "~/components/Loader";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

import * as S from "./styles";

const LendingExtractByLoanSearch = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);

  const history = useHistory();

  const userInfoArray = JSON.parse(
    localStorage.getItem("@NovaEra:infoUsuario")
  );

  const GetClientByCPF = useApi(
    Endpoints.cliente.getSearchClientWithdrawPersonalData.replace(
      "PARAM_CPF",
      userInfoArray.cpf
    ),
    ApiMethod.GET
  );

  const getLending = useApi(
    Endpoints.lendings.getLastProposalsByEasyCredito.replace(
      "PARAM_CPF",
      userInfoArray.cpf
    ),
    ApiMethod.GET
  );

  const GetLending = async () => {
    setLoading(true);
    try {
      const response = await getLending.callApi();
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getUser = async () => {
    setLoading(true);
    try {
      const response = await GetClientByCPF.callApi();
      setUser(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetLending();

    getUser();
  }, []);

  return (
    <Layout
      showLendingIcon
      onlyLendingIcon
      showDocumentIcon={false}
      showBackGroundBar={false}
      productType="Emprestimo"
    >
      <S.ContentScreens>
        <h1>Teus Empréstimos</h1>
        {loading ? (
          <S.WrapperLoader>
            <Loader size="large" />
          </S.WrapperLoader>
        ) : (
          <>
            <S.LendingBox>
              {data?.propostas?.map((item, id) => (
                <TransactionsLending
                  key={id}
                  amount={item.valor}
                  id={item.id}
                  idEmprestimo={data.idEmprestimo}
                  user={user}
                  date={item.dataCriacao}
                  status={item.descricaoUltimoStatus}
                  title={item.produto}
                  proposals={item}
                />
              ))}
              {data.propostas?.length < 0 && (
                <S.EmptyText>Nenhum empréstimo encontrado!</S.EmptyText>
              )}
            </S.LendingBox>
            <S.Footer>
              <div>
                <Button
                  isLoading={loading}
                  onClick={() => history.push("/buscador")}
                >
                  Quero simular novamente!
                </Button>
              </div>
            </S.Footer>
          </>
        )}
      </S.ContentScreens>
    </Layout>
  );
};

export default LendingExtractByLoanSearch;
