/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useCallback, useEffect, useState, useContext } from "react";
import Popup from "reactjs-popup";

import "reactjs-popup/dist/index.css";

import Layout from "~/components/Layout";
import InputCalendar from "~/components/Input/Calendar";
import ContainerBox from "~/components/ContainerBox";
import TransactionsClient from "~/components/TransactionsClient";

import * as S from "./styles";
import themes from "~/themes";
import Loader, { LOADER_TYPES } from "~/components/Loader";
import CardClient from "~/components/CardClient";
import { GlobalContext } from "~/contexts/GlobalState";
import { formatCardNumber } from "~/utils/mask";
import DownloadFatura from "~/components/DownloadFatura";
import { Link } from "react-router-dom";

import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";

const Transactions = () => {
  const { user } = useContext(GlobalContext);

  const [transactions, setTransactions] = useState();
  const [title, setTitle] = useState("Todas as transações");
  const [formattedCardNumber, setFormattedCardNumber] = useState(null);
  const [selectedRange, setSelectedRange] = useState({
    from: null,
    to: null,
  });
  const [consultDate, setConsultDate] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [confirmationPopupVisibility, setOneButtonPopupVisibility] =
    useState(false);
  const tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");

  const PostTransactions = useApi(
    selectedRange.from && selectedRange.to
      ? Endpoints.autorizador.postExtrato
          .replace("PARAM_LIMITE", "0")
          .replace(
            "/PARAM_DATA",
            `?dataInicio=${selectedRange.from.toLocaleDateString(
              "pt-br"
            )}&dataFim=${selectedRange.to.toLocaleDateString("pt-br")}`
          )
      : Endpoints.autorizador.postExtrato
          .replace("PARAM_LIMITE", "0")
          .replace("/PARAM_DATA", ""),
    ApiMethod.POST
  );

  useEffect(() => {
    handleGetTransactions();
  }, []);

  useEffect(() => {
    setFormattedCardNumber(formatCardNumber(user?.numeroCartao || ""));
  }, [user.numeroCartao]);

  const handleGetTransactions = async () => {
    try {
      setConsultDate(false);
      const transactions = await PostTransactions.callApi({ tokenCartao });

      if (transactions?.status >= 200 && transactions?.status < 300) {
        setTransactions(transactions.data);
      } else {
        setPopUpMessage(transactions?.mensagem);
        setOneButtonPopupVisibility(true);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleTransactionsSelectDays = useCallback(
    async (close) => {
      const dates = {
        start: selectedRange.from.toLocaleDateString("pt-br"),
        end: selectedRange.to.toLocaleDateString("pt-br"),
      };
      setTitle(`Transações no período de ${dates.start} até ${dates.end}`);
      handleGetTransactions();
      close();
    },
    [selectedRange]
  );

  const baixarFatura = () => {
    console.log("baixar fatura");
  };
  return (
    <Layout showBackGroundBar={false}>
      <ContainerBox>
        <S.Content>
          <header>
            <h6>{title}</h6>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Popup
                trigger={
                  <button type="button">
                    {themes.components.icons.calendar}
                  </button>
                }
                modal
                nested
                contentStyle={{ width: "auto", padding: 0 }}
              >
                {(close) => (
                  <S.Modal>
                    <InputCalendar
                      setSelectedRange={setSelectedRange}
                      selectedRange={selectedRange}
                    />
                    <S.FooterModal>
                      <button onClick={close}>Cancelar</button>
                      <button
                        onClick={() => handleTransactionsSelectDays(close)}
                      >
                        Ok
                      </button>
                    </S.FooterModal>
                  </S.Modal>
                )}
              </Popup>
              <Link to="/cartao-fatura">
                <DownloadFatura onClick={baixarFatura} />
              </Link>
            </div>
          </header>
          <S.CardClientInfo>
            <CardClient number={formattedCardNumber} userName={user.nome} />
          </S.CardClientInfo>
          {transactions ? (
            <TransactionsClient
              transactions={transactions.transacoes}
              displayMore={false}
            />
          ) : (
            <S.WrapperLoader>
              <Loader size={LOADER_TYPES.size.medium} />
            </S.WrapperLoader>
          )}
        </S.Content>
      </ContainerBox>
      <OneButtonPopup
        defaultButton
        icon={themes.components.icons.cardBlocked}
        text={popUpMessage}
        buttonText="Entendi"
        visibility={confirmationPopupVisibility}
        setVisibility={() => setOneButtonPopupVisibility(false)}
      />
    </Layout>
  );
};

export default Transactions;
