import React from "react";

import NavBar from "~/components/NavBar";
import Header from "~/components/Header";

import { Container, BackgroundBar, Content, SimpleContent } from "./styles";
import NavBarDigitalWallet from "../NavBar/NavBarDigitalWallet";
import NavBarLending from "../NavBar/NavBarLending";

const Layout = ({
  children,
  auth = true,
  showNavBar = true,
  showBackGroundBar = true,
  onlyHomeItem = false,
  showHelpIcon = false,
  showDocumentIcon = true,
  productType,
}) => (
  <Container>
    {auth && <Header />}
    {showBackGroundBar && <BackgroundBar style={{ top: auth ? 87 : 0 }} />}

    {auth ? (
      <Content>
        {productType === "Carteira digital" ? (
          <NavBarDigitalWallet />
        ) : productType === "Emprestimo" ? (
          <NavBarLending />
        ) : (
          showNavBar && (
            <NavBar
              showDocumentIcon={showDocumentIcon}
              showHelpIcon={showHelpIcon}
              onlyHomeItemInNavBar={onlyHomeItem}
            />
          )
        )}
        <main> {children} </main>
      </Content>
    ) : (
      <SimpleContent>
        <main> {children} </main>
      </SimpleContent>
    )}
  </Container>
);

export default Layout;
