import React, { useEffect, useState } from "react";

import Input from '~/components/Input';
import Checkbox from '~/components/Checkbox';
import Button from '~/components/Button';

import Layout from "~/components/Layout";
import ContainerBox from "~/components/ContainerBox";
import themes from "~/themes";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";

import * as S from './styles';
import { numericMask } from '~/utils/mask';
import { isSequencialNumbers } from '~/utils/validators';
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { CustomizedError } from "~/utils/errors";

const FormPasswordRedefinition = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [validCurrentPassword, setValidCurrentPassword] = useState(true);
  const [validConfirmNewPassword, setValidConfirmNewPassword] = useState(true);
  const [isSequential, setIsSequential] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const idUsuario = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));

  const PostValidatePassword = useApi(
    Endpoints.usuario.postValidarSenhaUsu,
    ApiMethod.POST
  );

  const RequestPutChangePass = useApi(
    Endpoints.usuario.postAlterarSenha,
    ApiMethod.POST
  );

  useEffect(() => {
    setIsSequential(isSequencialNumbers(newPassword));
  }, [newPassword]);

  useEffect(() => {
    setValidConfirmNewPassword(newPassword === confirmNewPassword);
  }, [confirmNewPassword, newPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
    const idUsuario = localStorage.getItem("@NovaEra:idUsuario");

    try {
      const response = await PostValidatePassword.callApi({ senha: currentPassword, cpf: user?.cpf });

      if (response.status >= 200 && response.status < 300) {
        const responseChange = await RequestPutChangePass.callApi({
          novaSenha: newPassword,
          idUsuario,
        });

        if (responseChange.status >= 200 && responseChange.status < 300) {
          setShowPopup(true);
          setValidCurrentPassword(true);
        } else {
          throw CustomizedError({
            message: responseChange.data.mensagem || responseChange.status,
          });
        }
      } else {
        throw CustomizedError({
          message: response.data.mensagem || response.status,
        });
      }
    } catch (err) {
      setValidCurrentPassword(false);
    }
  };

  return (
    <Layout
      onlyHomeItem={true}
      showBackGroundBar={false}
    >
      <ContainerBox>
        <S.Content>
          <S.Form onSubmit={handleSubmit}>
            <h6>Redefinir senha</h6>
            <Input
              icon
              label='Senha atual'
              value={currentPassword}
              maxLength={6}
              onChange={(e) => setCurrentPassword(numericMask(e.target.value))}
              // error={!validCurrentPassword ? 'Senha atual inválida' : null}
            />

            <Input
              icon
              label='Nova senha'
              value={newPassword}
              maxLength={6}
              onChange={(e) => setNewPassword(numericMask(e.target.value))}
            />

            <Input
              icon
              label='Confirme nova senha'
              value={confirmNewPassword}
              maxLength={6}
              onChange={(e) => setConfirmNewPassword(numericMask(e.target.value))}
              error={
                !validConfirmNewPassword ? 'Confirmação de senha inválida' : null
              }
            />

            <Checkbox
              label='Senha de até 6 dígitos'
              checked={newPassword.length === 6}
            />

            <Checkbox
              label='Números não sequenciais'
              checked={!isSequential && newPassword.length}
            />

            <Button
              disabled={
                newPassword.length !== 6 ||
                isSequential ||
                !validConfirmNewPassword ||
                currentPassword.length !== 6
              }
              type='submit'
              isLoading={PostValidatePassword.loading || RequestPutChangePass.loading}
            >
              Continuar
            </Button>

            <OneButtonPopup
              slug='/home'
              icon={themes.components.icons.tick}
              text='Senha alterada com sucesso!'
              buttonText='OK'
              visibility={showPopup}
              setVisibility={setShowPopup}
            />
          </S.Form>
        </S.Content>
      </ContainerBox>
    </Layout>
  );
};

export default FormPasswordRedefinition;
