import React, { useCallback, useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import CreditCard from "../CreditCard";
import "./styles.css";
import themes from "~/themes";
import { GlobalContext } from "~/contexts/GlobalState";

let INIT_RENDER = true;
function Carousel(props) {
    const { wallets } = props;
    const [cardNumberSelected, setCardNumberSelected] = useState();
    const [cardIdSelected, setCardIdSelected] = useState("");
    const [walletIndex, setWalletIndex] = useState(0);
    const { cardNumberSelectedHandler, cardIdSelectedHandler } = useContext(GlobalContext);

    const settings = {
        infinite: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: props.wallets.length > 2 ? 2 : 1,
        slidesToScroll: 1,
        dots: true,
        beforeChange: (current, next) => {
            //cardNumberSelectedHandler('')
            INIT_RENDER = false;
            setWalletIndex(next);
            setCardNumberSelected(wallets[next].numeroCartao);
            setCardIdSelected(wallets[next].id);
        },
    };

    const cardNumberHandler = useCallback(() => {
        let numberCard = INIT_RENDER ? wallets[0].numeroCartao : cardNumberSelected;
        let idCard = INIT_RENDER ? wallets[0].id : cardIdSelected
        cardNumberSelectedHandler(numberCard)
        cardIdSelectedHandler(idCard)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardNumberSelected]);

    useEffect(() => {
        cardNumberHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletIndex]);

    return (
        <div className="container1">
            <Slider {...settings}>
                {wallets?.map((wallet, idx) => (
                    <div className={idx === walletIndex ? `slide activeSlide ${props.wallets.length > 2 ? 'auxiliary_larger' : 'auxiliary_smaller'}` : "slide"} key={idx}>
                        <CreditCard
                            data={wallet}
                            imageTheme={themes.components.images.creditCardBackGround}
                            withoutDelete={true}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
}
export default Carousel;