import React, { useEffect } from "react";

import themes from "~/themes";
import { Container, NavItem } from "./styles";
import { GrDocument } from "react-icons/gr";

const NavBar = (props) => {
  const navItens = [
    {
      id: 0,
      name: "Início",
      link: "/home",
      icon: themes.components.icons.home,
    },
    {
      id: 1,
      name: "TeuCard",
      link: "/cartao-main",
      icon: themes.components.icons.card,
    },
    {
      id: 2,
      name: "Transações",
      link: "/transacoes",
      icon: themes.components.icons.transition,
    },
  ];

  if (props.showDocumentIcon) {
    navItens.push({
      id: 3,
      name: "document",
      link: "/document",
      icon: <GrDocument size={24} />,
    });
  }

  if (props.showHelpIcon) {
    navItens.push({
      id: 4,
      name: "ajuda",
      link: "/carteira-digital-ajuda",
      icon: themes.components.icons.ajuda,
    });
  }

  let navItemExibition = navItens.map((navItem) => (
    <NavItem to={navItem.link} key={navItem.id} exact>
      <div>{navItem.icon}</div>
    </NavItem>
  ));

  if (props.onlyHomeItemInNavBar) {
    navItemExibition = navItens.map(
      (navItem) =>
        navItem.link === "/home" && (
          <NavItem to={navItem.link} key={navItem.id} exact>
            <div style={{ alignContent: "center" }}>{navItem.icon}</div>
          </NavItem>
        )
    );
  }

  return (
    <Container>
      <nav>{navItemExibition}</nav>
    </Container>
  );
};

export default NavBar;
