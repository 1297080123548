/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import InputOnboarding from "~/components/InputOnboarding";
import Button from "~/components/Button";
import Loader from "~/components/Loader";

import { cpfMask, phoneMask } from "~/utils/mask";
import { cpfUnmask } from "~/utils/masksNew";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { GlobalContext } from "~/contexts/GlobalState";

import * as St from "../../styles";

const ConfirmData = () => {
  const [loading, setLoading] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [form, setForm] = useState({
    name: "",
    cpf: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    (async () => {
      const userInfo = await JSON.parse(
        localStorage.getItem("@NovaEra:infoUsuario")
      );
      setUserInfo(userInfo);
    })();
  }, []);

  const GetClientByCPF = useApi(
    Endpoints.cliente.getSearchClientWithdrawPersonalData.replace(
      "PARAM_CPF",
      userInfo.cpf
    ),
    ApiMethod.GET
  );

  const GetLimitAvailable = useApi(
    Endpoints.lendings.getLimitAvailable.replace("PARAM_CPF", userInfo.cpf),
    ApiMethod.GET
  );

  const PostIncludeLeaders = useApi(
    Endpoints.eventos.postIncludeEvent,
    ApiMethod.POST
  );

  const { addLending, lending } = useContext(GlobalContext);

  const history = useHistory();

  const getPersonalData = async () => {
    setLoading(true);
    const response = await GetClientByCPF.callApi();

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;

      setForm({
        name: data?.nome,
        cpf: userInfo?.cpf,
        email: data?.email,
        phone: data?.celular,
      });

      addLending({
        nome: data?.nome,
        cpf: userInfo?.cpf,
        email: data?.email,
        phone: data?.celular ? data.celular : data.celular2,
        dataNascimento: data?.dataNascimento,
        document: data?.rg,
        mother: data?.nomeMae,
      });
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setButtonIsLoading(true);

    try {
      const payload = {
        name: form?.name,
        cpf: form?.cpf,
        email: form?.email,
        produto: lending?.idProduto ? lending?.idProduto : "EMPRESTIMO",
        tipoEvento: "LEAD",
        origemEmprestimo: "CELCOIN",
      };

      const response = await PostIncludeLeaders.callApi(payload);

      if (response.data === null) {
        const responseData = await GetLimitAvailable.callApi();
        const { data } = responseData;

        addLending({
          statusCartao: data?.statusCartao,
          limiteSaqueDisponivel: data?.limiteSaqueDisponivel,
          valorSaqueMaximo: data?.valorSaqueMaximo,
          valorSaqueMinimo: data?.valorSaqueMinimo,
        });

        if (data.limiteSaqueDisponivel < 100) {
          history.push("/emprestimo/erro", {
            error: "noLimitAvailable",
          });
        } else if (data?.limiteSaqueDisponivel >= 100) {
          history.push("/emprestimo/simulacao-saque");
        } else {
          history.push("/emprestimo/erro", {
            error: "noTeuCardActive",
          });
        }
        setButtonIsLoading(false);
      } else if (response.status >= 400) {
        const responseData = await GetLimitAvailable.callApi();

        const { data } = responseData;

        addLending({
          statusCartao: data?.statusCartao,
          limiteSaqueDisponivel: data?.limiteSaqueDisponivel,
          valorSaqueMaximo: data?.valorSaqueMaximo,
          valorSaqueMinimo: data?.valorSaqueMinimo,
        });

        if (data.limiteSaqueDisponivel < 100) {
          history.push("/emprestimo/erro", {
            error: "noLimitAvailable",
          });
        } else if (data?.limiteSaqueDisponivel >= 100) {
          history.push("/emprestimo/simulacao-saque");
        } else {
          history.push("/emprestimo/erro", {
            error: "noTeuCardActive",
          });
        }
      } else {
        const responseLimit = await GetLimitAvailable.callApi();

        const { data } = responseLimit;

        addLending({
          statusCartao: data?.statusCartao,
          limiteSaqueDisponivel: data?.limiteSaqueDisponivel,
          valorSaqueMaximo: data?.valorSaqueMaximo,
          valorSaqueMinimo: data?.valorSaqueMinimo,
        });
        setButtonIsLoading(true);
        history.push("/emprestimo/leads-saque", {
          simulationData: response.data,
        });
      }

      setButtonIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const isDisabledButton =
    !form.nome && !form.cpf && !form.email && !form.phone > 13;

  useEffect(() => {
    if (userInfo.cpf) {
      getPersonalData();
    }
  }, [userInfo.cpf]);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <St.Content>
        <St.BoxContent>
          {loading ? (
            <St.WrapperLoader>
              <Loader size="large" />
            </St.WrapperLoader>
          ) : (
            <>
              <St.Title>Confirme teus dados</St.Title>
              <St.Subtitle>
                Confirme teus dados Para simular teu empréstimo Saque Rápido,
                precisamos que você confirme alguns dados antes:
              </St.Subtitle>
              <St.Steps>Passo 1 de 11</St.Steps>
              <St.Form>
                <InputOnboarding
                  type="text"
                  label="Nome completo*"
                  value={form?.name}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                  maxLength={50}
                  disabled
                  largeLabel
                />
                <InputOnboarding
                  type="text"
                  label="CPF*"
                  value={cpfMask(form?.cpf)}
                  onChange={(e) => setForm({ ...form, cpf: e.target.value })}
                  maxLength={50}
                  disabled
                  largeLabel
                />
                <InputOnboarding
                  type="text"
                  label="E-mail*"
                  value={form?.email}
                  onChange={(e) => setForm({ form, email: e.target.value })}
                  maxLength={50}
                  disabled
                  largeLabel
                />
                <InputOnboarding
                  type="text"
                  disabled
                  label="Celular*"
                  value={phoneMask(form?.phone)}
                  onChange={(e) =>
                    setForm({ ...form, cellPhone: phoneMask(e.target.value) })
                  }
                  maxLength={50}
                  largeLabel
                />
              </St.Form>
              <St.Footer align="right">
                <div>
                  <Button
                    disabled={isDisabledButton}
                    isLoading={buttonIsLoading}
                    onClick={handleSubmit}
                  >
                    <b>Avançar</b>
                  </Button>
                </div>
              </St.Footer>
            </>
          )}
        </St.BoxContent>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default ConfirmData;
