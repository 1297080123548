/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import assets from "~/assets";
import themes from "~/themes";
import * as St from "./styles";

const ContractedProduct = (props) => {
  const [balanceVisibility, setBalanceVisibility] = useState(
    localStorage.getItem("@NovaEra:balanceVisibility") === "true"
  );

  const handleBalanceVisibility = useCallback(() => {
    setBalanceVisibility((state) => {
      localStorage.setItem("@NovaEra:balanceVisibility", !state);
      return !state;
    });
    props.showBalanceVisibility(balanceVisibility);
  }, []);

  return (
    <St.Area>
      <St.Container>
        <St.Header>
          <img
            style={{
              objectFit: "contain",
              width: "100px",
              height: "36px",
            }}
            src={
              props.title === "Empréstimo"
                ? themes.components.images.logoTeuCredTransparent
                : props.title === "Carteira Digital"
                ? themes.components.images.logoTeuCashWhite
                : themes.components.images.logoTeuCardBranco
            }
          />
          {props.title === "Cartão TeuCard" && (
            <button type="button" onClick={handleBalanceVisibility}>
              {balanceVisibility ? (
                <img
                  src={assets.images.eyeHome}
                  style={{ objectFit: "contain", width: 25 }}
                />
              ) : (
                <img
                  src={assets.images.blindHome}
                  style={{ objectFit: "contain", width: 25 }}
                />
              )}
            </button>
          )}
        </St.Header>
        <Link to={props.path}>
          <St.Body>
            <St.HeaderTitle>{props.title}</St.HeaderTitle>
            <St.Description>
              {props.saldo && (
                <>
                  <St.LimiteDisponivel
                    style={{ marginTop: 10, marginBottom: 0 }}
                  >
                    Disp. compras:
                    <St.Value>
                      &nbsp;
                      <strong>
                        {balanceVisibility ? `  ${props.saldo}` : "  ●●●●●●"}
                      </strong>
                    </St.Value>
                  </St.LimiteDisponivel>
                  <St.LimiteDisponivel style={{ marginTop: 0 }}>
                    Disp. empréstimo:
                    <St.Value>
                      &nbsp;
                      <strong>
                        {balanceVisibility
                          ? `  ${props?.limiteSaqueDisponivel}`
                          : "  ●●●●●●"}
                      </strong>
                    </St.Value>
                  </St.LimiteDisponivel>
                </>
              )}
              {props.statusCliente === "INADIMPLENTE" && (
                <small style={{ color: "red", fontSize: 14 }}>
                  *Possui fatura em atraso!
                </small>
              )}
            </St.Description>
          </St.Body>
        </Link>
      </St.Container>
      <St.Footer>
        <St.OtherInformation>
          {props.descriptions?.map((discr) => (
            <div key={Math.random()}>
              {discr.title}
              <St.ValueFooter>{`:  ${
                balanceVisibility ? `${discr.value}` : `●●●●`
              }`}</St.ValueFooter>
            </div>
          ))}
        </St.OtherInformation>
      </St.Footer>
    </St.Area>
  );
};

export default ContractedProduct;
