import styled from "styled-components";
import themes from '~/themes';

export const SafeArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;

`

export const Container = styled.div`
  width: 300px;
  height: 200px; 
  display: flex;
  flex-direction: column;
  background-size: cover;
  ${(p) => p.imageIcon
    ? `background-image: url(${p.imageIcon});`
    : p.backgroundColor
      ? `background: ${p.backgroundColor}`
      : 'background: #AAAAAA;'};  
  border-radius: 10px;
  margin-right: 20px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
`;

export const Header = styled.div`
 width: 280px;
 color: white;
 margin: ${(p) => p.bandeira === 'elo' ||  p.bandeira === 'hipercard' ? '10px' : '0'} 0 18px 20px;
 display: flex;
 flex-direction: row;
 justify-content: space-between;

 button {
   width: 55px;
   border-style: none;
   border-radius: 7px;
   background-color: transparent;
   margin-right: 20px;   
   span {
    border-bottom: 1px solid white;
   }
 }
`;
export const CvvValidThru = styled.div`
 color: white;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin: 12px 0 10px 0;
`;

export const CreditNumber = styled.div`
 margin-left: 10px;
 display: flex;
 align-items: center;
 flex-direction: row;
 height: 22px;
 color: white;
 font-size: 26px;
 color: white;
 ${(p) => p.bandeira === 'elo' ||  p.bandeira === 'hipercard' ? 'margin-top: 20px;' : ''}
`;
export const CreditCvv = styled.div`
 visibility: hidden;
 margin-left: 10px;
 display: flex;
 flex-direction: row;
 height: 27px;
p {
    margin: none;
    color: white;
    font-size: 15px; 
}
`;

export const CreditValidThru = styled.div`
 height: 25px;
 color: white;
 margin-left: 20px;
 margin-right: 25px;
 display: flex;
 align-items: center;
 flex-direction: row;
 justify-content: right; 
`;

export const CreditName = styled.div`
 margin-left: 10px;
 display: flex;
 align-items: center;
 flex-direction: row;
 height: 22px;
 color: white;
 font-size: 16px;
`;

export const FooterCard = styled.div`
  width: 94%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  p {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
   }

`;