import React, { useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Button, { BUTTON_TYPES } from "~/components/Button";

import { moneyMask } from "~/utils/mask";
import { GlobalContext } from "~/contexts/GlobalState";
import moment from "moment";

import * as St from "../../../Lending/styles";
import themes from "~/themes";

const LeadsWithdraw = () => {
  const { state } = useLocation();
  const { simulationData } = state;

  const history = useHistory();

  const { addLending, lending } = useContext(GlobalContext);

  const handleNext = () => {
    addLending({
      idSimulacao: simulationData.id,
      origem: "SITE",
    });
    history.push("/emprestimo/dados-adicionais-saque");
  };

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/");
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, [verifyReloadPage]);

  console.log("Teste", simulationData);

  return (
    <LayoutOnboarding auth showBackGroundBar={false} teuCred>
      <St.Content>
        <St.BoxData>
          <St.TitlePersonalData>Simulação</St.TitlePersonalData>
          <St.Subtitle>
            Que legal, encontramos tua última simulação:
          </St.Subtitle>
          <St.TypeContainer>
            <St.BoxSimulation>
              <St.SimulationValue>
                <label>Valor simulado</label>
                <span>{moneyMask(simulationData?.valorLiberado)}</span>
              </St.SimulationValue>
              <St.Ornament src={themes.components.images.ornament} />
            </St.BoxSimulation>
            <St.BoxInfo>
              <St.Type>
                <St.TypeSimulation>
                  <label>Tipo de simulação</label>
                  <span>Valor do saque</span>
                </St.TypeSimulation>
                <St.IconType src={themes.components.images.saque} />
              </St.Type>
              <St.Type>
                <St.TypeSimulation>
                  <label>Parcela</label>
                  <span>{`${simulationData.numeroParcelas}x de ${moneyMask(
                    simulationData?.valorParcela
                  )}`}</span>
                </St.TypeSimulation>
                <St.TypeSimulation>
                  <label>Taxa de juros</label>
                  <span>
                    {`${(simulationData.taxaJuros * 100).toLocaleString(
                      "pt-br",
                      { minimumFractionDigits: 2 }
                    )}%`}
                  </span>
                </St.TypeSimulation>
              </St.Type>
              <St.Type>
                <St.TypeSimulation>
                  <label>CET</label>
                  <span>
                    {(parseFloat(simulationData.taxaJurosEfetivaMensal) * 100)
                      .toFixed(2)
                      .toString()
                      .replace(".", ",")}
                    % a.m.
                  </span>
                </St.TypeSimulation>
                <St.TypeSimulation>
                  <label>Data de vencimento</label>
                  <span>
                    {moment(
                      simulationData.dataPrimeiroPagamento,
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY")}
                  </span>
                </St.TypeSimulation>
              </St.Type>
            </St.BoxInfo>
          </St.TypeContainer>
          <St.FooterSimulation>
            <div>
              <Button
                type={BUTTON_TYPES.outlined}
                onClick={() => history.push("/emprestimo/simulacao-saque")}
              >
                <b>Quero Simular outro valor</b>
              </Button>
            </div>
            <div>
              <Button onClick={handleNext}>
                <b>Contratar</b>
              </Button>
            </div>
          </St.FooterSimulation>
        </St.BoxData>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default LeadsWithdraw;
