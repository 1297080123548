import styled from 'styled-components';
import themes from '~/themes';

export const Container = styled.div`
  padding-left: 40px;
  margin-top: 25px;
  height: auto;
`;

export const CreditCardModel = styled.aside`
display: flex;
flex-direction: column;
position: relative;
width: 422px;
height: 266px;
background: #AAAAAA;
box-shadow: 5px 6px 20px rgba(0, 0, 0, 0.15);
backdrop-filter: blur(54px);
border: 1.2px solid rgba(255, 255, 255, 0.5);

background-size: cover;
background-position: center;
border-radius: 20px;

img {
  position: absolute;
  top: 20px;
  right: 15px;
  width: auto;
  height: auto;
}
`;

export const CreditCardModelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin-top: 110px;
  padding-left: 23px;
  
  h6, p {
    color: ${props => props.temaCard ? props.temaCard : themes.components.colors.cardText};
    font-family: 'Bai Jamjuree', sans-serif;
    font-weight: 600;
  }

  h6 {
    margin-top: 66px;
    font-size: 18px;
    letter-spacing: 1.13px;
    line-height: 22px;
  }

  p {
    font-size: 14px;
    letter-spacing: 0.88px;
    line-height: 22px;
    padding-top: 20px;
  }
`;

export const CreditData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 50%;
  margin: 0 auto;

  h6 {
    color: #4a4b4a;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 50px;
  }

  p {
    font-size: 12px;
    color: #ED4848;
    margin-top: 2px;
  }

  button {
    margin-top: 70px;
    padding: 7px 0px;

    font-size: 1.2em;
    border-radius: 5px;
    width: 100%;
    border: 2px solid #E71037;
    color: #fff;
  }
`;

export const DoubleColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  h4 {
    color: #4a4b4a;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 25px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
`;
export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: red;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;