/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import Checkbox from "~/components/Checkbox";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import Loader from "~/components/Loader";
import InputOnboarding from "~/components/InputOnboarding";
import InputSelectSimple from "~/views/Lending/components/InputSelectSimple";
import Button from "~/components/Button";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";

import { moneyBrazilian } from "~/utils/mask";
import { GlobalContext } from "~/contexts/GlobalState";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

import * as St from "../styles";
import themes from "~/themes";
import OCCUPATION from "../../../../assets/mock/occupation.json";
import INCOME from "../../../../assets/mock/currentIncome.json";
import InputSelectLoan from "~/views/Lending/components/InputSelectLoan";
import SelectDropdown from "../../components/SelectDropdown";

const INPUT_NAMES = {
  occupation: "occupation",
  profession: "profession",
  currentIncome: "currentIncome",
};

const AdditionalDataByLendingSearch = () => {
  const [loading, setLoading] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [openSelectTypeOccupation, setOpenSelectTypeOccupation] =
    useState(false);
  const [openSelectTypeProfession, setOpenSelectTypeProfession] =
    useState(false);
  const [openSelectTypeCurrentIncome, setOpenSelectTypeCurrentIncome] =
    useState(false);
  const [professions, setProfessions] = useState([]);
  const [allProfessions, setAllProfessions] = useState(professions);
  const [showPopup, setShowPopup] = useState(false);
  const [input, setInput] = useState({
    formatted: "R$ 0,00",
    unFormatted: 0,
  });

  const [form, setForm] = useState({
    occupation: { code: "", value: "" },
    profession: { code: "", value: "" },
    currentIncome: { code: "", value: "" },
  });
  const [termAccept, setTermAccept] = useState({
    ownHome: false,
    ownVehicle: false,
    ownCreditCard: false,
    ownAndroidPhone: false,
    restrictionName: false,
  });

  const { addLending, lending } = useContext(GlobalContext);

  const history = useHistory();

  const PostIncludeClient = useApi(
    Endpoints.lendings.postIncludeClientByEasyCredito,
    ApiMethod.POST
  );

  const GetProfessions = useApi(
    Endpoints.lendings.getProfissionsByEasyCredito,
    ApiMethod.GET
  );

  const getProfessions = async () => {
    try {
      const response = await GetProfessions.callApi();
      if (response.status >= 200 && response.status < 300) {
        setProfessions(response.data);
        setAllProfessions(response.data);
      } else {
        setButtonIsLoading(false);
        setMessageError(response.mensagem);
        setShowPopupError(true);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  async function handleNext() {
    setButtonIsLoading(true);

    addLending({
      occupation: form.occupation?.code,
      profession: form.profession?.code,
      currentIncome: input.unFormatted,
      dayOfPayment: Number(form?.currentIncome?.value),
    });

    const payload = {
      celular: lending?.phone,
      cpf: lending?.cpf,
      dataNascimento: lending?.dataNascimento,
      email: lending?.email,
      nome: lending?.nome,
      temCartaoCredito: termAccept.ownCreditCard,
      temCasaPropria: termAccept.ownHome,
      temCelularAndroid: termAccept.ownAndroidPhone,
      temRestricoes: termAccept.restrictionName,
      temVeiculo: termAccept.ownVehicle,
    };
    try {
      const response = await PostIncludeClient.callApi(payload);
      if (response.status >= 200 && response.status < 300) {
        addLending({
          idEasyCredito: response.data?.idEasyCredito,
        });
        history.push("/buscador/dados-complementares");
      }
      if (response.status >= 500) {
        setShowPopupError(true);
        setMessageError(response.mensagem);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getProfessions();
  }, []);

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/");
    }
  };

  useEffect(() => {
     verifyReloadPage();
  }, [verifyReloadPage]);

  const activateButton = useMemo(
    () =>
      !form.profession?.code?.length ||
      !form.occupation?.code?.length ||
      !form.currentIncome?.code?.length ||
      !input.unFormatted,
    [
      form.profession?.code,
      form.occupation?.code,
      form.currentIncome?.code,
      input.unFormatted,
    ]
  );

  const updateFormSelectType = (code, id, value) => {
    console.log("CODEEE", value);
    setForm((prev) => ({ ...prev, [id]: { code, value } }));
  };

  const updateFormField = (value) => {
    const valueFloat =
      value.unmasked.toString().slice(0, value.unmasked.length - 2) +
      "." +
      value.unmasked.toString().slice(value.unmasked.length - 2);
    setInput({
      formatted: value.masked,
      unFormatted: parseFloat(valueFloat).toFixed(2),
    });
  };

  const updateFormSelectTypeProfession = (code, id, value) => {
    setForm((prev) => ({ ...prev, [id]: { code, value } }));
  };

  const onChangeProfessionCode = (value) => {
    updateFormSelectTypeProfession(value, INPUT_NAMES.profession);
    if (value?.length > 0) {
      setOpenSelectTypeProfession(true);

      const filter = allProfessions.filter((prf) => {
        const r = `${prf?.name}`;
        return r.toLowerCase().indexOf(value?.toLowerCase()) !== -1;
      });

      setProfessions(filter);
    } else {
      setProfessions(allProfessions);
      setOpenSelectTypeProfession(false);
    }
  };

  console.log(form);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text="Por favor! selecione o tipo de documento"
        buttonText="Entendi"
        visibility={showPopup}
        setVisibility={setShowPopup}
      />
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={messageError}
        buttonText="Entendi"
        visibility={showPopupError}
        setVisibility={setShowPopupError}
      />
      <St.Content>
        <St.BoxContent>
          {loading ? (
            <St.WrapperLoader>
              <Loader size="large" />
            </St.WrapperLoader>
          ) : (
            <>
              <St.Title>Dados adicionais</St.Title>
              <St.Subtitle>
                Para dar continuidade, precisamos de algumas informações
                adicionais
              </St.Subtitle>
              <St.Form>
                {/* <InputSelectSimple
                  label="Ocupação*"
                  placeholder="Selecione uma ocupação"
                  open={openSelectTypeOccupation}
                  setOpen={setOpenSelectTypeOccupation}
                  type="account"
                  inputTextValue={form?.occupation?.code}
                  inputId={INPUT_NAMES.occupation}
                  options={OCCUPATION}
                  onClickSelect={updateFormSelectType}
                /> */}
                {/* <InputSelectLoan
                  label="Profissão*"
                  placeholder="Selecione uma profissão"
                  onChange={(e) => onChangeProfessionCode(e.target.value)}
                  open={openSelectTypeProfession}
                  options={professions}
                  id={INPUT_NAMES.profession}
                  inputTextValue={form?.profession?.value}
                  setOpen={setOpenSelectTypeProfession}
                  onClickSelect={updateFormSelectTypeProfession}
                  defaultValue={form?.profession?.value}
                /> */}
                <SelectDropdown
                  label="Ocupação"
                  placeholder="Selecione uma ocupação"
                  options={OCCUPATION}
                  open={openSelectTypeOccupation}
                  setOpen={setOpenSelectTypeOccupation}
                  onChange={(text, name) => {
                    updateFormSelectType(text, INPUT_NAMES.occupation, name);
                  }}
                  selectedKey={form?.occupation?.value}
                />
                <SelectDropdown
                  options={professions}
                  label="Profissão*"
                  placeholder="Selecione uma profissão"
                  onChange={(text, name) => {
                    updateFormSelectTypeProfession(
                      text,
                      INPUT_NAMES.profession,
                      name
                    );
                  }}
                  id={INPUT_NAMES.profession}
                  open={openSelectTypeProfession}
                  setOpen={setOpenSelectTypeProfession}
                  selectedKey={form?.profession?.value}
                />
                <InputOnboarding
                  label="Renda mensal*"
                  maxLength={15}
                  largeBorder
                  value={input.formatted}
                  id={"value-income"}
                  largeLabel
                  onChange={(e) =>
                    updateFormField(moneyBrazilian(e.target.value))
                  }
                />
                {/* <InputSelectSimple
                  label="Dia do pagamento*"
                  placeholder="-"
                  open={openSelectTypeCurrentIncome}
                  setOpen={setOpenSelectTypeCurrentIncome}
                  type="payday"
                  inputTextValue={form?.currentIncome?.code}
                  inputId={INPUT_NAMES.currentIncome}
                  options={INCOME}
                  onClickSelect={updateFormSelectType}
                /> */}
                <SelectDropdown
                  label="Dia de pagamento*"
                  placeholder="Selecione o dia do pagamento"
                  open={openSelectTypeCurrentIncome}
                  setOpen={setOpenSelectTypeCurrentIncome}
                  options={INCOME}
                  selectedKey={form?.currentIncome?.value}
                  onChange={(text, name) =>
                    updateFormSelectType(name, INPUT_NAMES.currentIncome, text)
                  }
                />
                <St.TermsBox>
                  <St.Terms>
                    <Checkbox
                      checked={termAccept.ownHome}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setTermAccept((prev) => ({
                          ...prev,
                          ownHome: checked,
                        }));
                      }}
                    />
                    <span1>Possuo casa própria</span1>
                  </St.Terms>
                  <St.Terms>
                    <Checkbox
                      checked={termAccept.ownVehicle}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setTermAccept((prev) => ({
                          ...prev,
                          ownVehicle: checked,
                        }));
                      }}
                    />
                    <span1>Possuo veículo próprio</span1>
                  </St.Terms>
                  <St.Terms>
                    <Checkbox
                      checked={termAccept.ownCreditCard}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setTermAccept((prev) => ({
                          ...prev,
                          ownCreditCard: checked,
                        }));
                      }}
                    />
                    <span1>Possuo cartão de crédito</span1>
                  </St.Terms>
                  <St.Terms>
                    <Checkbox
                      checked={termAccept.ownAndroidPhone}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setTermAccept((prev) => ({
                          ...prev,
                          ownAndroidPhone: checked,
                        }));
                      }}
                    />
                    <span1>Possuo celular com sistema android</span1>
                  </St.Terms>
                  <St.Terms>
                    <Checkbox
                      checked={termAccept.restrictionName}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setTermAccept((prev) => ({
                          ...prev,
                          restrictionName: checked,
                        }));
                      }}
                    />
                    <span1>Possuo restrição no meu nome</span1>
                  </St.Terms>
                </St.TermsBox>
              </St.Form>
              <St.Footer align="right">
                <div>
                  <Button
                    isLoading={PostIncludeClient.loading}
                    onClick={handleNext}
                    disabled={activateButton || PostIncludeClient.loading}
                  >
                    Avançar
                  </Button>
                </div>
              </St.Footer>
            </>
          )}
        </St.BoxContent>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default AdditionalDataByLendingSearch;
