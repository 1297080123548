/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Carousel from "~/components/Carousel";
import ContainerBox from "~/components/ContainerBox";
import ContainerFlex from "~/components/ContainerFlex";
import Layout from "~/components/Layout";
import Loader, { LOADER_TYPES } from "~/components/Loader";
import Pagination from "~/components/Pagination";
import { GlobalContext } from "~/contexts/GlobalState";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import themes from "~/themes";
import DigitalWalletExtrato from "~/views/DigitalWallet/Transation/Extrato";
import * as St from "./styles";

const DigitalWalletTransations = (props) => {
  const [transactions, setTransactions] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [page, Setpage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { walletDigital } = useContext(GlobalContext);
  const [noCards, setNoCards] = useState(false);
  const { cardNumberSelected, cardId } = walletDigital;
  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));

  const GetCardsList = useApi(
    Endpoints.wallet.getCreditCards.replace("PARAM_CPF", user?.cpf),
    ApiMethod.GET
  );

  const GetWalletExtract = useApi(
    Endpoints.wallet.getExtract
      .replace("PARAM_CPF", user?.cpf)
      .replace("PARAM_DAF", moment().format("YYYY-MM-DD"))
      .replace("PARAM_DAI", "2022-08-27")
      .replace("PARAM_ID_CARD", cardId)
      .replace("PARAM_PAG", page)
      .replace("PARAM_ITENS", 10),
    ApiMethod.GET
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await GetCardsList.callApi();

        if (responseData.status >= 200 && responseData.status < 300) {
          setWallets(responseData?.data);
          if (wallets.length === 0) {
            setNoCards(true);
          }
        }
      } catch (err) {
        console.error(err.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    Setpage(1);
    if (page === 1) fetchDataExtract();
  }, [cardNumberSelected]);

  useEffect(() => {
    fetchDataExtract();
  }, [page]);

  const fetchDataExtract = async () => {
    try {
      if (cardNumberSelected) {
        const responseData = await GetWalletExtract.callApi();

        if (responseData.status >= 200 && responseData.status < 300) {
          setTransactions(responseData.data.data);
          setTotalItems(responseData.data.totalItens);
          setTotalPages(responseData.data.totalPaginas);
        }
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const changePage = async (page = 1, limit = 10, returnResponse = false) => {
    Setpage(page);
  };

  return (
    <Layout showBackGroundBar={false} productType="Carteira digital">
      <ContainerBox title="Extrato">
        <St.Content>
          <St.CardClientInfo>
            {wallets?.length > 0 ? (
              <Carousel wallets={wallets} />
            ) : (
              <St.NoContent>
                <img
                  style={{ width: "85px", height: "85px" }}
                  src={themes.components.images.semCartao}
                />
                <h3> Você ainda não tem nenhum cartão cadastrado!</h3>
                <p>
                  Para aproveitar mais as vantagens da tua carteira é necessário
                  adicionar um cartão
                </p>
              </St.NoContent>
            )}
          </St.CardClientInfo>
          {(!GetCardsList.loading || !GetWalletExtract.loading) &&
            transactions.length > 0 && (
              <>
                <DigitalWalletExtrato
                  transactions={transactions}
                  displayMore={false}
                />
                {totalPages > 1 && (
                  <ContainerFlex row horizontal center marginTop={20}>
                    <Pagination
                      totalPages={totalPages}
                      onCurrentPageChange={changePage}
                    />
                  </ContainerFlex>
                )}
              </>
            )}
          {(!GetCardsList.loading || !GetWalletExtract.loading) &&
            transactions.length === 0 && (
              <St.NoStracts>
                {!noCards ? <p>Nenhum extrato encontrado</p> : null}
              </St.NoStracts>
            )}
          {GetCardsList.loading ||
            (GetWalletExtract.loading && (
              <St.WrapperLoader>
                <Loader />
              </St.WrapperLoader>
            ))}
        </St.Content>
      </ContainerBox>
    </Layout>
  );
};
export default DigitalWalletTransations;
