import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 50%;
  margin: 0 auto;

  h6 {
    color: #4a4b4a;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 50px;
  }

  p {
    font-size: 12px;
    color: #ED4848;
    margin-top: 2px;
  }

  button {
    margin-top: 70px;
    padding: 7px 0px;

    font-size: 1.2em;
    border-radius: 5px;
    width: 100%;
    border: 2px solid #E71037;
    color: #fff;
  }
`;

export const WrapperLoader = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  min-height: 300px;
`;
