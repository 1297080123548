/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Checkbox from "~/components/Checkbox";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import Button from "~/components/Button";
import { GlobalContext } from "~/contexts/GlobalState";

import * as St from "../styles";
import themes from "~/themes";
import { useState } from "react";

const WelcomeByLendingSearch = () => {
  const history = useHistory();

  const { lending } = useContext(GlobalContext);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleClick = () => {
    history.push("/buscador/confirmar-dados");
  };

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <St.Content>
        <St.Box>
          <St.Header>
            <St.Banner
              src={themes.components.images.companysEC}
              alt="banner"
              onResize="cover"
            />
          </St.Header>

          <St.Title align="center">
          Bem-vindo ao teu buscador de empréstimos.
          </St.Title>
          <St.MiddleText>
            <p>
            TeuCred, em parceria com a EasyCrédito, oferece várias <br/>opções em um só lugar.
            </p>
          </St.MiddleText>

          <St.Footer align="center" direction="column">
          <St.Terms>
              <Checkbox
                checked={termsAccepted}
                onChange={(e) => {
                  setTermsAccepted(e.target.checked);
                }}
              />
              <span1>Aceito o&nbsp;</span1>
              <span2 onClick={() => window.open(process.env.REACT_APP_MS_TERMOS_EASY_CREDITO, '_blank')}>Termo de Autorização de Consulta (SCR)</span2>
            </St.Terms>
            <div style={{marginTop: 20}}>
              <Button disabled={!termsAccepted} onClick={handleClick}>Avançar</Button>
            </div>
          </St.Footer>
        </St.Box>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default WelcomeByLendingSearch;
