import React from "react";
import * as St from "./styles"; // Importe todos os estilos do styles.js
import { useHistory } from "react-router-dom";
import { convertDateMode3, moneyMask } from "~/utils/mask";
import Button from "~/components/Button";
import { useContext } from "react";
import { GlobalContext } from "~/contexts/GlobalState";
import Slider from "react-slick";
import "./styles.css";

let INIT_RENDER = true;
const OffersProposal = ({ data = [] }) => {
  const history = useHistory();
  const { addLending } = useContext(GlobalContext);

  const settings = {
    infinite: false,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    useCSS: true,
    dots: true,
    beforeChange: (current, next) => {
      INIT_RENDER = false;
    },
  };

  const upDocuments = () => {
    addLending({
      idSimulacao: data?.idEmprestimo,
    });
    history.push("/loan-home/submeter-documentos");
  };
  const returnColorOfComponent = (status) => {
    if (status === "REPROVADO") {
      return "#E57373";
    }

    if (status === "PRE_APROVADO" || status === "LIBERADO") {
      return "#7FBF7F";
    }

    if (status === "EM_ANALISE_MANUAL" || status === "EM_ANALISE_OPERACIONAL") {
      return "#BF9D7F";
    } else {
      return "#7FA3BF";
    }
  };
  const renderItem = (item, index) => {
    const linkProduto = item?.linkProduto;
    const itemColor = returnColorOfComponent(item.ultimoStatus);
    return (
      <St.ContainerBox>
        <St.Box style={{ borderColor: itemColor }}>
          <St.Header
            style={{
              backgroundColor: itemColor,
            }}
          >
            <St.TextHeader>{item.descricaoUltimoStatus}</St.TextHeader>
          </St.Header>
          <St.Body>
            <St.BodyValues>
              <St.TitleBody>{item.produto}</St.TitleBody>
              <St.ValueDescription>Valor máximo</St.ValueDescription>
              <St.Value>{moneyMask(item.valor)}</St.Value>
              <St.Installments>Em até {item.parcelas}x</St.Installments>
              <St.Trace />
            </St.BodyValues>
          </St.Body>
          <St.Footer>
            {item.documentosPendentes && !item.documentosEnviados ? (
              <St.ButtonView>
                <Button
                  onClick={() =>
                    item.documentosPendentes &&
                    !item.temDocumentos &&
                    !item.documentosEnviados
                      ? upDocuments()
                      : null
                  }
                  color={true}
                >
                  {"Continuar"}
                </Button>
              </St.ButtonView>
            ) : !!linkProduto ? (
              <Button
                style={{
                  padding: 8,
                  backgroundColor: itemColor,
                }}
                onClick={() => window.open(linkProduto, "_blank")}
              >
                Detalhes
              </Button>
            ) : (
              <St.FooterView>
                <St.MessageStatus>
                  {item?.mensagemUltimoStatus}
                </St.MessageStatus>
              </St.FooterView>
            )}
            <St.DateOfCreateProposal>
              <St.DateDescription style={{ fontWeight: "bold" }}>
                Criado em{" "}
              </St.DateDescription>
              {convertDateMode3(item.dataCriacao)}
            </St.DateOfCreateProposal>
          </St.Footer>
        </St.Box>
      </St.ContainerBox>
    );
  };

  return (
    <>
      <St.Container>
        <Slider {...settings}>
          {data?.propostas?.map((item, index) => renderItem(item, index))}
        </Slider>
      </St.Container>
    </>
  );
};

export default OffersProposal;
