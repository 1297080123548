import styled from "styled-components";
import { Link } from "react-router-dom";

import themes from "~/themes";

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const InformationDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    width: 60%;
    margin-bottom: 15%;
    align-self: left;
  }

  p,
  span {
    color: ${themes.components.colors.font};
  }

  p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  }

  span {
    padding: 0 60px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 10vh;
  }
`;

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: left;
  margin-top: 100px;

  button {
    margin: 0;
  }
`;
export const WrapperButton = styled.div`
  flex: 0 1;
  margin-top: 20px;
  & button:nth-child(2) {
    margin-top: 10px;
  }
`;

export const ButtonSignIn = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;

  font-size: 16px;
  text-decoration: none;
  color: ${themes.components.colors.buttonText};

  margin-top: 166px;
  padding: 16px 0;
  border: 0;
  border-radius: 8px;
  background: ${themes.components.colors.button};
`;

export const ContentVersion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;

  @media (max-width: 960px) {
    margin-top: 0px;
  }
`;

export const Version = styled.p`
  color: red;
  font-size: 12px;
  text-align: center;
`;

export const BackgroundLogin = styled.div`
  z-index: -1;
  background-image: url(${themes.components.images.emojisLogin});
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  position: absolute;
  padding-left: 85%;
  top: 50px;
  width: 80%;
  height: 80%;

  @media (max-width: 1360px) {
    width: 60%;
    height: 60%;
    top: 100px;
  }

  @media (max-width: 1200px) {
    padding-left: 88%;
  }

  @media (max-width: 1024px) {
    padding-left: 95%;
  }

  @media (max-width: 960px) {
    display: none;
  }
`;
