import React, { useEffect, useState } from "react";

import Input from "~/components/Input";
import Button from "~/components/Button";
import Layout from "~/components/Layout";
import ContainerBox from "~/components/ContainerBox";
import themes from "~/themes";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";

import * as S from "./styles";
import Loader, { LOADER_TYPES } from "~/components/Loader";

import { useLocation } from "react-router";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

const FormSendEmail = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [url, setUrl] = useState(null);

  const { state } = useLocation();

  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
  const [email, setEmail] = useState(user?.email);

  const RequestPostSendEmail = useApi(
    Endpoints.email.postSendInvoiceEmail.replace("PARAM_EMAIL", url),
    ApiMethod.POST
  );

  useEffect(() => {
    if (state && email && user) {
      setUrl(`${user?.cpf}/${state?.month}/${state.year}/${email}`);
    }
  }, [email, state, user]);

  const validateEmail = () => {
    const regex = /^$|[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (email.trim().length) {
      if (regex.test(email)) return true;
      else if (!regex.test(email)) return false;
    } else {
      return true;
    }
  };

  const validateConfirmEmail = () => {
    if (confirmEmail.length && email !== confirmEmail) {
      return false;
    } else {
      return true;
    }
  };

  const handleDisableButton = () => {
    if (
      confirmEmail.length &&
      email.length &&
      validateEmail() &&
      validateConfirmEmail()
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSendEmail = async () => {
    try {
      setIsLoading(true);
      await RequestPostSendEmail.callApi();
      setShowPopup(true);
    } catch (err) {
      setErrorMessage(err.message || "Não conseguimos carregar sua fatura");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout showBackGroundBar={false}>
      <ContainerBox>
        {isLoading ? (
          <S.WrapperLoader>
            <Loader size={LOADER_TYPES.size.medium} />
          </S.WrapperLoader>
        ) : (
          <S.Wrapper>
            {!errorMessage ? (
              <>
                <h6>Envio por e-mail</h6>
                <Input
                  label="Email"
                  placeholder="exemplo@exemplo.com.br"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={validateEmail() ? null : "E-mail inválido"}
                />

                <Input
                  marginBottom="0"
                  label="Confirmar e-mail"
                  placeholder="exemplo@exemplo.com.br"
                  onChange={(e) => setConfirmEmail(e.target.value)}
                  error={
                    !validateConfirmEmail() && "Os e-mails devem ser idênticos."
                  }
                />

                <Button
                  onClick={handleSendEmail}
                  disabled={handleDisableButton()}
                >
                  Continuar
                </Button>

                <OneButtonPopup
                  slug="/cartao-main"
                  icon={themes.components.icons.tick}
                  text="E-mail enviado com sucesso!"
                  buttonText="Entendi"
                  visibility={showPopup}
                  setVisibility={setShowPopup}
                />
              </>
            ) : (
              <h6>{errorMessage}</h6>
            )}
          </S.Wrapper>
        )}
      </ContainerBox>
    </Layout>
  );
};

export default FormSendEmail;
