import React, { useState } from "react";
import styled from "styled-components";
import * as St from "./styles";
import { moneyMask } from "~/utils/mask";

const ExpandableBox = ({ balance, phone, notSigned, errorMessage }) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleMouseEnter = () => {
    setExpanded(true);
  };

  const handleMouseLeave = () => {
    setExpanded(false);
  };

  return (
    <St.ExpandableBoxWrapper
      expanded={isExpanded}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="circle">
        <St.Header>
          {isExpanded && <St.Title>Cashback Zeuss</St.Title>}
        </St.Header>
        {isExpanded && <St.Trace />}
        {!notSigned ? (
          <St.Body>
            <St.Line>
              {isExpanded && <St.TitleBody>Celular vinculado</St.TitleBody>}
              {isExpanded && <St.TitleBalance>Saldo</St.TitleBalance>}
            </St.Line>
            <St.Line>
              {isExpanded && (
                <St.PhoneNumber>
                  {"(" +
                    phone?.substr(0, 2) +
                    ")" +
                    "*****" +
                    " - **" +
                    phone?.substr(phone.length - 2, phone.length)}
                </St.PhoneNumber>
              )}
              {isExpanded && (
                <St.BalanceValue>{moneyMask(balance)}</St.BalanceValue>
              )}
            </St.Line>
          </St.Body>
        ) : (
          isExpanded && <St.Body>
             <St.Line>
              {isExpanded && <St.TitleBody>Celular vinculado</St.TitleBody>}
              </St.Line>
              <St.Line>
              <St.PhoneNumber>
                  {"(" +
                    phone?.substr(0, 2) +
                    ")" +
                    "*****" +
                    " - **" +
                    phone?.substr(phone.length - 2, phone.length)}
                </St.PhoneNumber>
            </St.Line>
            <St.NotSigned>{errorMessage}</St.NotSigned></St.Body>
        )}
      </div>
      <div className="content">
        {!isExpanded && (
          <p style={{ fontWeight: "600", fontSize: "12px" }}>ZEUSS</p>
        )}
      </div>
    </St.ExpandableBoxWrapper>
  );
};

export default ExpandableBox;
