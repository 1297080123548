import styled from 'styled-components';

import themes from '~/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
`;




export const Content = styled.div`
  z-index: 2;
  display: block;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin-top: 48px;

  > main {
    flex: 1;
    margin: 0 11px;
  }

  @media (max-width: 960px) {
    > main {
      margin: 0px;
    }
  }
`;

export const SimpleContent = styled.div`
  z-index: 2;
  display: flex;
  width: 40%;
  max-width: auto;
  height: auto;
  min-height: 100%;
  padding-left: 20px;
  padding: 48px 10px;
  background: #F6F6F6;

  > main {
    margin: 0 auto;
    width: 448px;
  }

  @media(max-width: 960px) {
    width: 100%;
    padding: 0px;
    background: transparent;

    > main {
      width: 100%;
      padding: 11px;
    }
  }
`;

