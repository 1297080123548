import styled from "styled-components";

import themes from "~/themes";

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;

  @media (max-width: 960px) {
    padding: 0px 11px;
  }
`;

export const MiddleText = styled.span`
  > p {
    font-size: 21px;
    color: #e40e3c;
    font-weight: bold;
    margin-bottom: 10%;
  }
`;

export const Footer = styled.span`
  width: 500px;
  margin-top: 50px;

  @media (max-width: 760px) {
    width: 100%;
  }
`;

export const ImageProduct = styled.div`
  > img {
    cursor: pointer;
  }
`;

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > img {
    width: 30%;
  }

  > ul {
    margin-top: 30px;

    @media (max-width: 760px) {
      width: 100%;
    }
  }

  > p {
    margin-top: 80px;
    font-size: 23px;
    color: #e40e3c;
    font-weight: bold;

    @media (max-width: 760px) {
      font-size: 16px;
    }
  }
`;

export const WrapperLoader = styled.div``;
