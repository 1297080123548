/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Slider from "./components/sliderLoan";
import InputOnboarding from "components/InputOnboarding";
import Installment from "./components/installments";
import Button from "components/Button";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";

import { moneyBrazilian, moneyMask } from "utils/mask";
import constants from "utils/constants";
import moment from "moment";

import * as St from "./../styles";
import useApi, { ApiMethod } from "~/hooks/useApi";
import themes from "~/themes";
import ContainerBox from "~/components/ContainerBox";
import { GlobalContext } from "~/contexts/GlobalState";
import { useContext } from "react";
import Endpoints from "~/services/endpoints";
const SimulationByLendingSearch = () => {
  const [installments, setInstallments] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [operador, setOperador] = useState(null);
  const [values, setValues] = useState({
    min: 500,
    max: 50000,
    selected: 500,
  });
  const { addLending, lending } = useContext(GlobalContext);
  const [installmentValue, setInstallmentValue] = useState("");

  const [input, setInput] = useState({
    formatted: "",
    unFormatted: "",
  });

  useEffect(() => {
    const getOperador = () => {
      const operador = JSON.parse(localStorage.getItem("@NovaEra:user"));
      setOperador(operador);
    };
    getOperador();
  }, []);

  const history = useHistory();

  const updateSliderField = (value) => {
    setValues({ ...values, selected: value.masked });
  };

  const postSearchProposals = useApi(
    Endpoints.lendings.postSearchProposalsEasyCredito,
    ApiMethod.POST
  );

  useEffect(() => {
    setInstallmentValue(
      moneyBrazilian(calculateInstallment(input.unFormatted, installments))
    );
  }, [installments, input.unFormatted, values.selected]);

  useEffect(() => {
    setInput({
      unFormatted: 500,
      formatted: moneyMask(500),
    });
    setInstallmentValue(
      moneyMask(calculateInstallment(input.unFormatted, installments))
    );
  }, []);

  useEffect(() => {
    const getInitialValues = () => {
      setInput({
        formatted: moneyMask(500),
        unFormatted: "500",
      });
      setValues({
        min: 500,
        max: 50000,
        selected: 500,
      });
    };
    getInitialValues();
  }, []);

  const updateFormField = (value) => {
    const valueFloat =
      value.unmasked.toString().slice(0, value.unmasked.length - 2) +
      "." +
      value.unmasked.toString().slice(value.unmasked.length - 2);
    setInput({
      formatted: value.masked,
      unFormatted: parseFloat(valueFloat).toFixed(2),
    });
  };

  console.log(lending);

  const calculateInstallment = (selectedValue, numberInstallments) => {
    const interestRate = 4.49 / 100; // Taxa de juros de 4,49%

    const monthlyInterestFactor = Math.pow(
      1 + interestRate,
      numberInstallments
    );
    const monthlyPayment =
      (selectedValue * interestRate * monthlyInterestFactor) /
      (monthlyInterestFactor - 1);

    return monthlyPayment.toFixed(2);
  };

  const handleInstallments = (value) => {
    setInstallments(value);
    calculateInstallment(parseFloat(values.selected), value);
  };

  const handleNext = async () => {
    setButtonIsLoading(true);
    const payload = {
      banco: {
        agencia: lending?.agency,
        banco: lending?.codeBank,
        conta: lending?.account,
        tipo: lending?.typeAccount,
      },
      celular: lending?.phone,
      cpf: lending?.cpf,
      email: lending?.email,
      dataNascimento: lending?.dataNascimento,
      educacao: lending?.schooling,
      endereco: {
        bairro: lending?.neighborhood,
        cep: lending.cep,
        cidade: lending?.city,
        complemento: lending?.complement,
        endereco: lending?.street,
        estado: lending?.uf,
        numero: lending?.number,
        tempoResidencia: lending?.timeResidential,
        tipoResidencia: lending?.typeResidential,
      },
      estadoCivil: lending?.civilStatus,
      genero: lending?.gender,
      idEasyCredito: lending?.idEasyCredito,
      identidade: {
        dataEmissao: moment(lending?.dataEmissaoDocumento, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        emissor: lending?.issuingAgency,
        estado: lending?.emissionDocumentUf,
        numero: lending?.document,
        tipo: lending?.typeDoc,
      },
      nome: lending?.nome,
      nomeMae: lending?.mother,
      simulacao: {
        parcelas: installments,
        valor: parseFloat(Number(input.unFormatted).toFixed(2)),
      },
      trabalho: {
        diaPagamento: lending?.dayOfPayment,
        ocupacao: lending?.occupation,
        profissao: lending?.profession,
        salario: parseInt(lending?.currentIncome).toFixed(2),
      },
    };

    try {
      const response = await postSearchProposals.callApi(payload);
      if (response.status >= 200 && response.status < 300) {
        addLending({
          idSimulacao: response.data?.idEmprestimo,
        });
        history.push("/buscador/buscando-propostas");
      } else {
        setButtonIsLoading(true);
        setDialogOpen(true);
        setErrorMessage(response.mensagem);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        text={errorMessage}
        buttonText="Entendi"
        title="Que pena!"
        iconModal={themes.components.images.successValidation}
        visibility={dialogOpen}
        defaultButton
        onClose={() => history.push("/loan-seeker")}
        setVisibility={() => {
          setDialogOpen(false);
          history.push("/loan-seeker");
        }}
      />

      <St.BoxContent>
        <ContainerBox>
          <St.TopSpace>
            <St.Column noBorder style={{ alignItems: "center", width: "100%" }}>
              <St.ContainBody>
                <St.Title>Valor do empréstimo</St.Title>
                <St.Subtitle>
                  Conta pra gente o <strong>Valor do Empréstimo</strong> que
                  você deseja simular:
                </St.Subtitle>
                <St.Slider>
                  <Slider
                    min={values.min}
                    max={values.max}
                    selected={String(values.selected).replace("R$", "")}
                    value={input.unFormatted}
                    handleChange={(value) => {
                      updateSliderField(
                        moneyBrazilian(Number(value.target.value).toFixed(2))
                      );
                      updateFormField(
                        moneyBrazilian(Number(value.target.value).toFixed(2))
                      );
                    }}
                    step={1}
                  />
                </St.Slider>
                <InputOnboarding
                  type="text"
                  label="Valor"
                  placeholder="digite o valor"
                  largeLabel
                  value={input.formatted}
                  maxLength={15}
                  defaultValue={0}
                  style={{ height: "54px" }}
                  step={1}
                  onChange={(e) => {
                    updateSliderField(moneyBrazilian(e.target.value));
                    updateFormField(moneyBrazilian(e.target.value));
                  }}
                />
                <St.ParcelBox>
                  <div>
                    <InputOnboarding
                      type="text"
                      label="Valor da parcela"
                      largeLabel
                      style={{ height: "54px" }}
                      value={installmentValue.masked}
                      disabled
                    />
                  </div>
                  <div>
                    <Installment
                      installment={installments}
                      onChange={handleInstallments}
                      label="Parcelas"
                    />
                  </div>
                </St.ParcelBox>
                <St.Subtitle
                  justify="flex-start"
                  style={{ marginTop: 27, fontWeight: "bold" }}
                >
                  Taxa de juros a partir de 1,99% a.m.{" "}
                </St.Subtitle>
                <St.MiddleContainer>
                  <St.Rounded />
                  <p style={{ width: "100%" }}>
                    Lembre-se, essa estes valores podem variar de acordo com o
                    critério de cada instituição financeira!
                  </p>
                </St.MiddleContainer>
                <St.Footer>
                  <Button
                    buttonType="primary"
                    disabled={postSearchProposals.loading}
                    isLoading={postSearchProposals.loading}
                    onClick={handleNext}
                  >
                    Buscar empréstimos
                  </Button>
                </St.Footer>
              </St.ContainBody>
            </St.Column>
          </St.TopSpace>
        </ContainerBox>
      </St.BoxContent>
    </LayoutOnboarding>
  );
};

export default SimulationByLendingSearch;
