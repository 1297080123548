import styled from "styled-components";

import themes from "../../themes";

export const Container = styled.header`
  width: 100%;
  height: 80px;
  background: ${(props) => (props.teuCred ? "#f8fcf0" : "#F9F2F4")};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 40px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 150px;
      height: 40px;
      cursor: pointer;
      object-fit: cover;
    }

    span {
      color: #7ba03b;
      font-weight: 800;
      font-size: 0.8rem;
    }
  }
`;

export const HeaderLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-left: 8px;
    color: ${themes.components.colors.link};
    height: 50%;
    width: 50%;
  }

  button {
    margin-left: 64px;
    color: ${themes.components.colors.link};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;

    background: transparent;
    border: 0;
  }
`;
