import React from "react";
import Router from "./router";
import GlobalStyles from "./themes/global";
import AppProvider from "./contexts";

function App() {
  return (
    <>
      <AppProvider>
        <Router />
      </AppProvider>
      <GlobalStyles />
    </>
  );
}

export default App;
