import React from 'react';

import LinkButton from '~/components/LinkButton';
import Layout from '~/components/Layout';
import ContainerBox from '~/components/ContainerBox';
import themes from '~/themes';

import * as S from './styles';

const InfoPasswordRedefinition = () => {
  return (
    <Layout
      onlyHomeItem={true}
      showBackGroundBar={false}
    >
      <ContainerBox withoutBoxShadow={true}>
        <S.Wrapper>
          <h6>Redefinir senha do cartão</h6>

          <span>{themes.components.icons.lockCard}</span>

          <p className='instructions'>
            Lembre-se, sua senha do cartão pode ser usada em:
          </p>

          <ul>
            <li>Compras no cartao</li>
          </ul>

          <LinkButton
            slug='/cartao-form-redefinir-senha'
            text='Continuar'
            margin='50px 0 5px'
          />
        </S.Wrapper>
      </ContainerBox>
    </Layout>
  );
};

export default InfoPasswordRedefinition;
