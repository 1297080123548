import { notificacaoAPI } from "./api";

export const sendingSMSByCPF = async (cpf) => {
    try {
        const res = await notificacaoAPI.post(`/v1/notificacoes/sms/enviar-sms-notificacao-cpf/${cpf}`)
        return res;
    } catch (err) {
        return err.response;
    }
};

export const validatingCodeCPF = async (requestBody) => {
    try {
        const res = await notificacaoAPI.put("/v1/push/validar-cpf", requestBody)
        return res;
    } catch (err) {
        return err.response;
    }
};

export const sendingSMSByCellPhone = async (requestBody) => {
    try {
        const res = await notificacaoAPI.post('/v1/notificacoes/sms/enviar-sms-notificacao-celular', requestBody)
        return res;
    } catch (err) {
        return err.response;
    }
};

export const validatingCodeCellPhone = async (requestBody) => {
    try {
        const res = await notificacaoAPI.put("/v1/push/validar-celular", requestBody)
        return res;
    } catch (err) {
        return err.response;
    }
};