/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useEffect } from "react";

import InputOnboarding from "~/components/InputOnboarding";
import { FiChevronDown } from "react-icons/fi";

import * as S from "./styles";

const InputBanking = ({
  label,
  id,
  open,
  error,
  placeholder,
  onBlur,
  setOpen,
  onChange,
  defaultValue,
  onClickSelect,
  inputTextValue,
  options = [],
}) => {
  const onClickSelectOption = (text) => {
    onClickSelect?.(text, id);
    setOpen?.(false);
  };
  return (
    <S.Container>
      <S.InputContainer>
        <InputOnboarding
          label={label}
          id={id}
          largeLabel
          onBlur={onBlur}
          placeholder={placeholder}
          value={inputTextValue}
          defaultValue={defaultValue}
          onChange={(text, name) => onChange(text, name)}
        />

        <S.IconContainer onClick={() => setOpen(!open)}>
          <FiChevronDown
            color="#E4003A"
            size={26}
            style={{
              transform: [
                {
                  rotate: open ? "180deg" : "0deg",
                },
              ],
            }}
          />
        </S.IconContainer>
      </S.InputContainer>
      {open && (
        <S.ContainerOptions>
          <div className="option">
            {options?.map((option, index) => (
              <>
                <S.Option
                  key={option?.code}
                  onClick={() =>
                    onClickSelectOption(
                      `${
                        option?.code ? option?.code + "-" : ""
                      }${option?.name?.replace("-", "")}`
                    )
                  }
                >
                  <S.ContainerItems key={option?.code}>
                    <S.OptionLabel>
                      {option?.code ? option?.code + " - " : ""}
                      {option?.name?.replace("-", "")}
                    </S.OptionLabel>
                  </S.ContainerItems>
                </S.Option>
                {index !== options?.length - 1 && <S.Divider />}
              </>
            ))}
          </div>
        </S.ContainerOptions>
      )}
      {!!error && <S.ErrorText>{error}</S.ErrorText>}
    </S.Container>
  );
};

export default InputBanking;
