import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  displayflex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Menu = styled.div`
  position: absolute;
  width: 164px;
  right: 2.8%;
  top: 9%;
  z-index: 1000000;
  display: none;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  color: black !important;
  cursor: default;
`;

export const Data = styled.div`
  display: flex;
  displayflex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const PerfilImage = styled.div`
  width: 49.94px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubMenu = styled.div`
  margin-top: 25px;
  padding-left: 10px;
  width: 140px;
  color: #000000 !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: 'Marine Rounded';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;  
  cursor: pointer;
  text-align: left;
  :hover{
    background-color: #F7F7F7;
    border-radius: 10px;
  }
`;
export const User = styled.div`
  display: flex;
  displayflex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover ${Data} {
    height: 120px;
    }
  &:hover ${Menu} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0px 10px 10px;
    }
`;

export const Notification = styled.div`
  color: #E4003A;
  border-right: 2px solid #ECECEC;
  padding: 18px;
`;
export const Name = styled.div`
  margin-left: 10px;
  color: #E71037;
  display: flex;
  align-items: center;
  text-align: right;
  font-weight: 700;
`;

