import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Button, { BUTTON_TYPES } from "~/components/Button";

import { moneyMask } from "~/utils/mask";
import { GlobalContext } from "~/contexts/GlobalState";
import moment from "moment";

import themes from "~/themes";
import * as St from "../../styles";
const ResultSimulationWithdraw = () => {
  const { lending } = useContext(GlobalContext);
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/");
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, [verifyReloadPage]);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <St.Content>
        <St.BoxContent>
          <St.Title>Simulação</St.Title>
          <St.Subtitle>
            Tudo pronto! Libere agora esse valor na Tua conta bancária e
            aproveite.
          </St.Subtitle>
          <St.Steps>Passo 3 de 11</St.Steps>
          <St.Column>
            <St.ContentValue borderBottom="0.5px #eeeeee solid">
              <St.Value>
                <h1>{moneyMask(lending.requested_amount)}</h1>
              </St.Value>
            </St.ContentValue>
            <St.ContentValue>
              <St.Row justify="space-between">
                <span>Valor da parcela</span>
                <span>
                  {`${lending.num_periods}x de ${moneyMask(
                    lending.payment_amount
                  )}`}
                </span>
              </St.Row>
              <St.Row justify="space-between">
                <span>
                  <b>Valor cobrado na próxima Fatura</b>
                </span>
                <span>
                  <b>{moneyMask(lending.payment_amount)}</b>
                </span>
              </St.Row>
              <St.Row justify="space-between">
                <span>Vencimento</span>
                <span>
                  {moment(lending.last_payment_date, "YYYY-MM-DD").format(
                    "DD/MM/YYYY"
                  )}
                </span>
              </St.Row>
              <St.Row justify="space-between">
                <span>Taxa de juros</span>
                <span>
                  {(lending?.taxaJuros * 100).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                  % a.m.
                </span>
              </St.Row>
              <St.Row justify="space-between">
                <span>CET</span>
                <span>
                  {(parseFloat(lending?.monthly_effective_interest_rate) * 100)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")}
                  % a.m.
                </span>
              </St.Row>
            </St.ContentValue>
            <St.ContentValue style={{ marginTop: "61px" }}>
              <St.Row>
                <img
                  src={themes.components.images.orangeCircle}
                  alt="Indicador"
                />
                <p>
                  Será cobrado o valor integral, em parcela única, na tua {""}
                  <b>próxima fatura</b>.
                </p>
              </St.Row>
            </St.ContentValue>
            <St.Footer align="center" direction="column">
              <div style={{ width: "50%" }}>
                <Button
                  onClick={() =>
                    history.push("/emprestimo/dados-adicionais-saque")
                  }
                >
                  <b>Quero contratar</b>!
                </Button>
              </div>
              <St.Submit>
                <span onClick={goBack}>Quero simular novamente</span>
              </St.Submit>
            </St.Footer>
          </St.Column>
        </St.BoxContent>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default ResultSimulationWithdraw;
