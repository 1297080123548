import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  max-width: 800px;
  margin-top: 50px;

  h3 {
    font-size: 16px;
    line-height: 22px;
    color: #4a4b4a;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
  }
`;
