import React, { useState } from "react";

import { Container, ErrorText } from "./styles";

const Select = ({
  error,
  values,
  label,
  large,
  id,
  name,
  largeLabel,
  hide,
  ...rest
}) => {
  const [focusSelect, setFocusSelect] = useState(false);

  if (hide) return null;

  return (
    <Container
      isFocus={focusSelect}
      name={id}
      largeLabel={largeLabel}
      large={large}
    >
      <label>{label}</label>
      <div>
        <select
          name={name}
          id={name}
          onFocus={() => setFocusSelect(true)}
          onBlur={() => setFocusSelect(false)}
          {...rest}
        >
          {values?.map((value) => (
            <option key={value?.value} value={value?.value}>
              {value?.label}
            </option>
          ))}
        </select>
      </div>

      {!!error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

export default Select;
