/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import Button from "~/components/Button";

import * as S from "./styles";
import themes from "~/themes";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import { useHistory } from "react-router-dom";

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: "environment" },
};

const FirstScreenOnboarding = () => {
  const history = useHistory();
  const [mediaStream, setMediaStream] = useState(null);

  useEffect(() => {
    async function enableStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(
          CAPTURE_OPTIONS
        );
        setMediaStream(stream);
      } catch (err) {
        // Removed for brevity
      }
    }

    if (!mediaStream) {
      enableStream();
    } else {
      return function cleanup() {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      };
    }
  }, [mediaStream, CAPTURE_OPTIONS]);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth={true}>
      <S.Content>
        <S.ImageLogo>
          <img
            src={themes.components.images.logoCarteiraDigital}
            alt="Logo CarteiraDigital"
          />
        </S.ImageLogo>

        <S.Main>
          <p> Bem-vindo à tua carteira digital </p>
        </S.Main>
        <S.MiddleText>
          <img src={themes.components.images.orangeCircle} />
          <img src={themes.components.images.orangeCircle} alt="Indicador" />
          <p>
            Você pode utilizar cartões de outros bancos pela Tua Carteira
            digital
          </p>
          <img src={themes.components.images.orangeCircle} />
          <img src={themes.components.images.orangeCircle} alt="Indicador" />
          <p> Parcele as compras em várias vezes</p>
          <img src={themes.components.images.orangeCircle} alt="Indicador" />
          <p> Utilize o QR Code para realizar pagamentos</p>
          <img src={themes.components.images.orangeCircle} alt="Indicador" />
          <p>Total controle das compras na palma da mão</p>
          <img src={themes.components.images.orangeCircle} alt="Indicador" />
          <p>Solicite empréstimo no teu cartão de crédito</p>
        </S.MiddleText>
        <S.Footer>
          <Button onClick={() => history.push("/onboarding-validar-cpf")}>
            Avançar
          </Button>
        </S.Footer>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default FirstScreenOnboarding;
