import React from 'react';

import themes from '~/themes';
import Button,{BUTTON_TYPES} from '~/components/Button'
import * as S from './styles';

export const TwoButtonPopup = ({ bodyText, leftButtonText, rightButtonText, visibility, setVisibility, confirm, onClickCancel, showIcon = true }) => {
  return (
    <S.Container open={visibility} onClose={() => setVisibility(false)} modal>
      {(close) => (
        <>
          <div className='content-container'>
            {showIcon && themes.components.icons.cardBlocked}
            <p>{bodyText}</p>
          </div>

          <hr />

          <div className='button-container'>
            <Button 
              onClick={() => {
                close()
                onClickCancel && onClickCancel()
              }} 
              type={BUTTON_TYPES.outlined}
            >
              {leftButtonText}
            </Button>
            <Button onClick={confirm} >
              {rightButtonText}
            </Button>
          </div>
        </>
      )}
    </S.Container>
  );
};

export default TwoButtonPopup;
