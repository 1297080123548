import React, { useContext, useState, useEffect, useCallback } from "react";
import Button from "~/components/Button";

import * as S from "../styles";
import { verificationCodeMask, verificationCodeUnmask } from "~/utils/mask";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import { useHistory } from "react-router-dom";
import InputOnboarding from "~/components/InputOnboarding";
import { GlobalContext } from "~/contexts/GlobalState";
import {
  validatingCodeCellPhone,
  sendingSMSByCellPhone,
} from "~/services/onboardingService";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import themes from "~/themes";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

const VerificationCode = () => {
  const history = useHistory();

  const [verificactionCode, setVerificactionCode] = useState("");
  const [error, setError] = useState("");
  const retVerificationCode = verificationCodeUnmask(verificactionCode);
  const { onboarding, addOnboarding } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [popupErrorMesssage, setPopupErrorMesssage] = useState("");
  const [chronometer, setChronometer] = useState(60);
  const [chronometerInterval, setChronometerInterval] = useState(0);
  const [resendButtonIsDisabled, setResendButtonIsDisabled] = useState(false);
  const [countResendSMS, setCountResendSMS] = useState(0);
  const [goBack, setGoBack] = useState(false);
  const document = onboarding.cpf;

  const handleValidateFields = async () => {
    setIsLoading(true);
    const response = await validatingCodeCellPhone({
      numeroCelular: onboarding.phone,
      numeroGerado: verificactionCode.replaceAll(" ", ""),
    });

    if (response.status === 204) {
      addOnboarding({ verificationCode: retVerificationCode });
      setIsLoading(false);
      history.push("/onboarding-validar-dados-main");
    } else {
      setShowPopup(true);
      setIsLoading(false);
      setPopupErrorMesssage(response.data.mensagem);
    }
  };
  const sendCodeByWhatsapp = async () => {
    const response = await POSTSendSMSWithWhatsApp.callApi({
      cpf: document.replaceAll(".", "").replaceAll("-", ""),
    });
  };
  useEffect(() => {
    async function SendPush() {
      try {
        sendCodeByWhatsapp();
        handleDisableButtonResendSMS();
      } catch (error) {
        setPopupErrorMesssage("Falha ao enviar SMS!");
        setPopUpIsOpen(true);
        setGoBack(true);
      }
    }
    SendPush();
  }, []);

  useEffect(() => {
    if (countResendSMS === 4) {
      reSendSMS();
    }
  }, [countResendSMS]);

  const POSTSendSMSWithWhatsApp = useApi(
    Endpoints.wallet.postSendWhatsappCode,
    ApiMethod.POST
  );

  const reSendSMS = useCallback(async () => {
    if (resendButtonIsDisabled) return;

    if (countResendSMS === 3) {
      setPopupErrorMesssage(
        "Não é possível reenviar mais de 3 vezes o SMS! Verifique se o celular está correto e tente novamente."
      );
      setPopUpIsOpen(true);
      setGoBack(true);
    }

    handleDisableButtonResendSMS();
    setIsLoading(true);

    try {
      const response = await sendingSMSByCellPhone({
        numeroCelular: onboarding.phone,
      });

      if (response.status >= 201 && response.status < 300) {
        setPopupErrorMesssage("SMS re-enviado com sucesso!");
      } else {
        setPopupErrorMesssage("Falha ao re-enviar SMS!");
      }
    } catch (err) {
      setPopupErrorMesssage("Falha ao re-enviar SMS!");
    } finally {
      setIsLoading(false);
      setPopUpIsOpen(true);
      setCountResendSMS((prev) => ++prev);
    }
  });

  useEffect(() => {
    if (chronometer === 0) {
      clearInterval(chronometerInterval);
      setChronometer(60);
    }
  }, [chronometer, chronometerInterval]);

  const handleDisableButtonResendSMS = () => {
    setResendButtonIsDisabled(true);
    setTimeout(() => setResendButtonIsDisabled(false), 60000);
    const interval = setInterval(() => setChronometer((prev) => --prev), 1000);
    setChronometerInterval(interval);
  };

  const verifyReloadPage = () => {
    if (onboarding.cpf === "") {
      history.push("/");
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, []);

  return (
    <LayoutOnboarding showBackGroundBar={false}>
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={popupErrorMesssage}
        buttonText="Entendi"
        visibility={showPopup}
        setVisibility={setShowPopup}
      />
      <S.Content>
        <S.MainText>
          <p>Criar carteira digital</p>
        </S.MainText>
        <S.MiddleText>
          <p>Insira o código enviado por WhatsApp no teu celular.</p>
        </S.MiddleText>
        <S.Input>
          <InputOnboarding
            type="text"
            label="Código de verificação"
            maxLength={7}
            placeholder="000 000"
            value={verificactionCode}
            onChange={(e) =>
              setVerificactionCode(verificationCodeMask(e.target.value))
            }
            error={error}
          />
          <S.MiddleText>
            <S.ResendCode disabled={resendButtonIsDisabled}>
              <p className="help-message">
                {" "}
                Não recebeu o WhatsApp? Você pode{" "}
                <span onClick={reSendSMS}>reenviar</span>.{" "}
                {chronometer !== 60 && `Aguarde ${chronometer}.`}
              </p>
            </S.ResendCode>
          </S.MiddleText>
        </S.Input>
        <S.Footer>
          <Button
            onClick={() => handleValidateFields()}
            isLoading={isLoading}
            disabled={!verificactionCode}
          >
            Continuar
          </Button>
        </S.Footer>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default VerificationCode;
