import styled from 'styled-components';

import themes from '~/themes';

export const Container = styled.div`
  display: grid;

  grid-template-columns: auto 670px;
  //grid-template-rows: auto auto;
  background-color: #FFF;
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
  border-radius: 4px;
  padding: 48px 10px;
  margin: 60px auto 60px;
  min-height: 600px;
  max-width: 1210px;
  width: 100%;
  margin-top: 4%;

  height: auto;
`;

export const ContentMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  > header {
    display: flex;
    flex-direction: row;
    align-items: center;

    h6 {
      flex: 1;
      color: #4a4b4a;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.86px;
      line-height: 27px;
    }

    button {
      background: transparent;
      border: 0;
      height: 21px;
      width: 21px;

      svg {
        height: 21px;
        width: 21px;
      }
    }
  }
`;

export const Content = styled.div`
   z-index: 2;
  display: flex;
  flex:1;
  flex-direction: column;
  
  background-color: #FFF;
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
  border-radius: 4px;
  padding: 48px 10px;
  margin: 60px auto 60px;
  min-height: 600px;
  max-width: 1210px;
  width: 100%;
  margin-top: 1%;

  p {
    color: ${themes.components.colors.font};
    letter-spacing: 0;
    line-height: 20px;
    margin-left: 5%;
    /* margin-bottom: min(10%,auto); */
    text-align: left;
  }
  a {
    color: ${themes.components.colors.font};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-left: 5%;
    text-align: left;


  }
`;


export const MainText = styled.div`

  display: flex;
  flex-direction: column;

  font-size: 22px;
  font-weight: 700;
  color: #4A4B4A;
`;  


export const MiddleText = styled.span`
  display:flex;
  flex-direction: column;

  font-weight: 400;
  font-size: 18px;
  color: #4A4B4A;

  
`;

export const ImageLogo = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  margin-top: 3%;
  width: 30%;
  height: 35%;


  >img {
    flex:1;
    display:flex;
    align-self: center;
    width:80%;
    height: 80%;

  }
`;


export const Footer = styled.span`
  flex:1;
  display:flex;
  align-items: center;
  align-self: center;
  justify-content: flex-end;

  margin-top: 4%;
  width: 40%;
  height: 20%;
`;
