import styled from 'styled-components';

export const Form = styled.form`
  max-width: 500px;
  margin: 0 auto;

  h6 {
    color: #4a4b4a;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
  }

  .instructions {
    margin: 50px 0;
  }

  .help-message {
    margin-bottom: 70px;

    span {
      color: #E71037;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  button {
    padding: 7px 0px;
    font-size: 1.2em;
    border-radius: 5px;
    width: 100%;
    border: 2px solid #E71037;
    color: #fff;
  }
`;
