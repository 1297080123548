/*
  A função ToggleChatBot serve para exibir ou esconder o chat na tela.

  A função recebe um boolean (isLogged) que vai dizer se o usuário está ou não logado.
  Caso o usuário esteja logado, o chat será exibido e vice-versa.
  O nome das classes foram coletadas com "inspecionar elemento" do navegador no próprio chat.

  powerzap_open_button_standardType_iframe -> classe responsável por exibir o botão inicar do chat
  notificationAndImage -> classe responsável por exibir o chat
  powerzap_chat_canvas_footer -> classe responsável por exibir o rodapé do chat
*/

export const toggleChatbot = (isLogged) => {
  window.addEventListener('DOMNodeInserted', () => {
    if(document.getElementsByClassName('powerzap_open_button_standardType_iframe')[0]) {
      const el = document.getElementsByClassName('powerzap_open_button_standardType_iframe')[0]
      
      if(!isLogged) {
        el.style.display = 'none'

        if(document.getElementsByClassName('powerzap_chat_canvas_iframe')[0]) {
         document.getElementsByClassName('powerzap_chat_canvas_iframe')[0].style.display = 'none'
        }

        if(document.getElementsByClassName('notificationAndImage')[0]) {
          document.getElementsByClassName('notificationAndImage')[0].style.display = 'none'
        }

        if(document.getElementsByClassName('powerzap_chat_canvas_footer')[0]) {
         document.getElementsByClassName('powerzap_chat_canvas_footer')[0].style.display = 'none'
        }
      } else {
        el.style.display = 'block'

        if(document.getElementsByClassName('powerzap_chat_canvas_iframe')[0]) {
          document.getElementsByClassName('powerzap_chat_canvas_iframe')[0].style.display = 'block'
         }

         if(document.getElementsByClassName('notificationAndImage')[0]) {
          document.getElementsByClassName('notificationAndImage')[0].style.display = 'block'
         }

         if(document.getElementsByClassName('powerzap_chat_canvas_footer')[0]) {
          document.getElementsByClassName('powerzap_chat_canvas_footer')[0].style.display = 'block'
         }
      }
    }
  })
}