/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";

import Input from "~/components/Input";
import ModalOneButton from "~/components/Modals/OneButtonPopup";
import Button from "~/components/Button";
import * as S from "../styles";

import { GlobalContext } from "~/contexts/GlobalState";
import { verificationCodeMask, verificationCodeUnmask } from "~/utils/mask";

import { useAuth } from "~/hooks/auth";
import themes from "~/themes";
import { SectionForm } from "../styles";
import { ResendCode, Alert } from "./styles";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { CustomizedError } from "~/utils/errors";

export default function VerificationCode({ cpf, card, userName }) {
  const [modalBlockIsOpen, setModalBlockIsOpen] = useState(false);
  const [modalBlockText, setModalBlockText] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalSuccessText, setModalSuccessText] = useState("");
  const [modalFailed, setModalFailed] = useState(false);
  const [modalFailedText, setModalFailedText] = useState("");
  const [countResendSMS, setCountResendSMS] = useState(0);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState("");
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState(false);
  const [resendButtonIsDisabled, setResendButtonIsDisabled] = useState(false);
  const [chronometer, setChronometer] = useState(60);
  const [modalPass, setModalPass] = useState(true);
  const [chronometerInterval, setChronometerInterval] = useState(0);
  const { addLoggedUser, addTransaction, addUser } = useContext(GlobalContext);
  const { handleChangeAuth, isLogged } = useAuth();
  let countSMS = 1;

  const tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");

  const RequestLogin = useApi(Endpoints.usuario.postLogin, ApiMethod.POST);

  const RequestPostSendTokenSMSOne = useApi(
    Endpoints.notificacao.postSendTokenSMSOne,
    ApiMethod.POST
  );

  const RequestPostSendTokenSMS = useApi(
    Endpoints.notificacao.postSendTokenSMS,
    ApiMethod.POST
  );

  const RequestPutCodeValidate = useApi(
    Endpoints.notificacao.putCodeValidate,
    ApiMethod.PUT
  );

  const RequestPutUnblockCard = useApi(
    Endpoints.autorizador.putUnblockCard,
    ApiMethod.PUT
  );

  useEffect(() => {
    SendTokenSMS(countSMS).catch((err) => console.log(err.message));
    handleDisableButtonResendSMS();
  }, []);

  useEffect(() => {
    if (countResendSMS === 4) {
      reSendSMS();
    }
  }, [countResendSMS]);

  useEffect(() => {
    if (chronometer === 0) {
      clearInterval(chronometerInterval);
      setChronometer(60);
    }
  }, [chronometer]);

  const handleDisableButtonResendSMS = () => {
    setResendButtonIsDisabled(true);
    setTimeout(() => setResendButtonIsDisabled(false), 60000);
    const interval = setInterval(() => setChronometer((prev) => --prev), 1000);
    setChronometerInterval(interval);
  };

  const SendTokenSMS = async (countSMS) => {
    let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
    if (!tokenCartao) {
      tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
    }

    let request;

    if (countSMS === 1) {
      countSMS++;
      request = await RequestPostSendTokenSMSOne.callApi({ tokenCartao });
    } else {
      request = await RequestPostSendTokenSMS.callApi({ tokenCartao });
    }

    return request;
  };

  const reSendSMS = async () => {
    if (resendButtonIsDisabled) return;

    setButtonText("Re-enviando SMS...");
    handleDisableButtonResendSMS();
    setButtonIsLoading(true);
    var response;

    try {
      response = await SendTokenSMS();
      if (response.status >= 200 && response.status <= 300) {
        setModalSuccessText("SMS re-enviado com sucesso!");
        setModalSuccess(true);
      } else {
        setModalFailedText(response.mensagem || "Falha ao re-enviar SMS!");
        setModalFailed(true);
      }
    } catch (err) {
      setModalFailedText("Falha ao re-enviar SMS!");
      setModalFailed(true);
    } finally {
      setButtonIsLoading(false);
      setCountResendSMS((prev) => ++prev);
    }
  };

  const CodeValidate = async (code) => {
    let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
    if (!tokenCartao) {
      tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
    }

    const request = await RequestPutCodeValidate.callApi({
      tokenCartao,
      numeroGerado: code,
    });

    switch (request.status) {
      case 204:
        return { status: 204, data: request.data };
      default:
        throw CustomizedError({ message: request.data.mensagem });
    }
  };

  const UnblockCard = async (senha) => {
    let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
    let optionWhatsApp = localStorage.getItem("@NovaEra:optionWhatsApp");
    let optionTermoUso = localStorage.getItem("@NovaEra:optionTermos");
    if (!tokenCartao) {
      tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
    }

    const response = await RequestPutUnblockCard.callApi({
      optionTermoUso: Boolean(optionTermoUso),
      optionWhatsApp: Boolean(optionWhatsApp),
      origemAcao: "SITE",
      senha,
      tokenCartao,
    });

    const status = response.status;

    if (status >= 200 && status < 300) {
      localStorage.setItem("@NovaEra:tokenCartao", tokenCartao);
      return;
    } else {
      throw CustomizedError({ message: response.data.mensagem || status });
    }
  };

  const handleValidation = async () => {
    setButtonIsLoading(true);
    setButtonText(`Validando código ${verificationCode}...`);
    try {
      const response = await CodeValidate(
        verificationCodeUnmask(verificationCode)
      );
      if (response.status === 204) {
        setVerificationCodeError(null);
        handleAcceptUnblockCreditCard();
      } else {
        setModalBlockText(response.data.mensagem);
        setModalBlockIsOpen(true);
        setVerificationCode("");
        setButtonIsLoading(false);
        setVerificationCodeError(response.data.mensagem);
      }
    } catch (err) {
      setVerificationCode("");
      setButtonIsLoading(false);
      setVerificationCodeError(err.message);
    }
  };

  const handleAcceptUnblockCreditCard = async () => {
    try {
      await UnblockCard(card.password);
      setModalBlockText("O TeuCard foi desbloqueado com sucesso!");
      setModalBlockIsOpen(true);
      // await fetchUserData();
    } catch (err) {
      console.error(err.message);
      setModalBlockText(err.message);
      setModalBlockIsOpen(true);
    } finally {
      setButtonIsLoading(false);
    }
  };

  const handleDisabledButton = () => verificationCode.length !== 7;

  return (
    <S.AlternativeMain>
      <ModalOneButton
        text={"Essa é a senha de acesso para o teu cartão e do teu aplicativo"}
        buttonText="Ok"
        icon={themes.components.icons.tick}
        visibility={modalPass}
        setVisibility={(bool) => setModalPass(bool)}
      />
      <ModalOneButton
        slug="/"
        text={modalBlockText}
        buttonText="Ok"
        icon={themes.components.icons.tick}
        visibility={modalBlockIsOpen}
        setVisibility={(bool) => setModalBlockIsOpen(bool)}
      />
      <ModalOneButton
        text={modalSuccessText}
        buttonText="Ok"
        icon={themes.components.icons.tick}
        visibility={modalSuccess}
        setVisibility={(bool) => setModalSuccess(bool)}
        onClick={() => setModalSuccess(false)}
      />
      <ModalOneButton
        text={modalFailedText}
        buttonText="Ok"
        imgIcon
        icon={themes.components.images.warning}
        visibility={modalFailed}
        setVisibility={(bool) => setModalFailed(bool)}
        onClick={() => setModalFailed(false)}
      />

      <img src={themes.components.images.logoTeuCard} alt="Logo TeuCard" />
      <p>Desbloqueio de cartão</p>
      <S.MiddleText>
        <SectionForm
          onSubmit={(e) => {
            e.preventDefault();
            handleValidation();
          }}
        >
          <p>Insira o código enviado para o teu Whatsapp.</p>
          <Input
            type="text"
            label="Código de verificação"
            placeholder="000 000"
            maxLength={7}
            value={verificationCode}
            onChange={(e) =>
              setVerificationCode(verificationCodeMask(e.target.value))
            }
            error={verificationCodeError}
          />

          <ResendCode disabled={resendButtonIsDisabled}>
            Não recebeu o Whatsapp? Você pode
            <span onClick={reSendSMS}>reenviar</span>.{" "}
            {chronometer !== 60 && `Aguarde ${chronometer}.`}
          </ResendCode>

          <Alert>
            Caso não receba o código no teu WhatsApp, você pode solicitar o
            reenvio por SMS
          </Alert>

          <Button type="submit" disabled={handleDisabledButton()}>
            {buttonIsLoading ? buttonText : "Validar código"}
          </Button>
        </SectionForm>
      </S.MiddleText>
    </S.AlternativeMain>
  );
}
