import React, { useState } from 'react';

import Input from '~/components/Input';
import Button from '~/components/Button'
import { isSequencialNumbers, passwordConfirmationValidator } from '~/utils/validators';
import CheckBox from '~/components/Checkbox'


import * as S from '../styles';
import themes from '~/themes';
import { numericMask } from '~/utils/mask';

const CardPasswordConfirmation = ({ handleNextSection, card, cpf }) => {
  const [passwordConfirmationCard, setPasswordConfirmationCard] = useState('');
  const [passwordCard, setPasswordCard] = useState('');
  const [errorInput, setErrorInput] = useState('');

  function handleClick(event) {
    event.preventDefault();

    const isErrorPassword = passwordConfirmationValidator(passwordCard, passwordConfirmationCard).message || '';
    setErrorInput(isErrorPassword);

    if (!isErrorPassword) {
      handleNextSection({ card: { ...card, password: passwordConfirmationCard } });
    }
  }

  const verifyPasswordLength = () => passwordCard.length === 6

  const verifyIsSequentialNumbers = () => !isSequencialNumbers(passwordCard) && passwordCard.length

  return (

    <S.AlternativeMain>
      <img src={themes.components.images.logoTeuCard} alt="Logo TeuCard" />
      <p>Desbloqueio de cartão</p>
      <S.MiddleText>
        <S.SectionForm onSubmit={handleClick}>
          <p>A senha deve ter 6 dígitos para o teu cartão, acessar a tua conta e realizar transações.
            A senha não poderá conter números iguais, números sequenciais ou tua data de aniversário.</p>
          <Input
            type="password"
            label="Digite a senha"
            placeholder="******"
            maxLength={6}
            value={passwordCard}
            onChange={e => setPasswordCard(numericMask(e.target.value))}
          />

          <Input
            type="password"
            label="Confirme a senha"
            placeholder="******"
            maxLength={6}
            value={passwordConfirmationCard}
            onChange={e => setPasswordConfirmationCard(numericMask(e.target.value))}
            error={errorInput}
          />

          <S.WrapperCheckbox>
            <CheckBox
              label="Senha de 6 digitos"
              checked={verifyPasswordLength()}
            />
            <CheckBox
              label="Números não sequenciais"
              checked={verifyIsSequentialNumbers()}
            />
          </S.WrapperCheckbox>

          <Button
            type="submit"
          >
            Continuar
          </Button>
        </S.SectionForm>
      </S.MiddleText>


    </S.AlternativeMain>
  );
}

export default CardPasswordConfirmation;
