/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Input from "~/components/Input";
import Layout from "~/components/Layout";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import Button from "~/components/Button";
import ContainerBox from "~/components/ContainerBox";
import { verificationCodeMask, verificationCodeUnmask } from "~/utils/mask";

import * as S from "./styles";
import themes from "~/themes";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { CustomizedError } from "~/utils/errors";
import LayoutHome from "~/components/LayoutHome";

const pushSMSBlock = () => {
  const location = useLocation();
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState("");
  const [textPopup, setTextPopup] = useState("");
  const [confirmationPopupVisibility, setOneButtonPopupVisibility] =
    useState(false);
  const [sucessoBlock, setSucessoBlock] = useState(false);
  const history = useHistory();

  const RequestPutCodeValidate = useApi(
    Endpoints.notificacao.putCodeValidate,
    ApiMethod.PUT
  );

  const RequestPutUnblockCard = useApi(
    Endpoints.autorizador.putUnblockCard,
    ApiMethod.PUT
  );

  const RequestPutBlockCard = useApi(
    Endpoints.autorizador.putBlockCard,
    ApiMethod.PUT
  );

  const RequestSendTokenSMSOne = useApi(
    Endpoints.notificacao.postSendTokenSMSOne,
    ApiMethod.POST
  );
  const RequestSendTokenSMS = useApi(
    Endpoints.notificacao.postSendTokenSMS,
    ApiMethod.POST
  );

  useEffect(() => {
    SendTokenSMS();
  }, []);

  const SendTokenSMS = async (countSMS) => {
    let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
    if (!tokenCartao) {
      tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
    }

    let request;

    if (countSMS === 1) {
      request = await RequestSendTokenSMSOne.callApi({ tokenCartao });
    } else {
      request = await RequestSendTokenSMS.callApi({ tokenCartao });
    }

    switch (request.status) {
      case 201:
        return { status: 201, data: request.data };
      default:
        throw new Error();
    }
  };

  const CodeValidate = async (code) => {
    let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
    if (!tokenCartao) {
      tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
    }

    const request = await RequestPutCodeValidate.callApi({
      tokenCartao,
      numeroGerado: code,
    });

    switch (request.status) {
      case 204:
        return { status: 204, data: request.data };
      default:
        throw CustomizedError({ message: request.data.mensagem });
    }
  };

  const UnblockCard = async (senha) => {
    let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
    let optionWhatsApp = localStorage.getItem("@NovaEra:optionWhatsApp");
    let optionTermoUso = localStorage.getItem("@NovaEra:optionTermos");
    if (!tokenCartao) {
      tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
    }

    const response = await RequestPutUnblockCard.callApi({
      optionTermoUso: Boolean(optionTermoUso),
      optionWhatsApp: Boolean(optionWhatsApp),
      origemAcao: "SITE",
      senha,
      tokenCartao,
    });

    const status = response.status;

    if (status >= 200 && status < 300) {
      localStorage.setItem("@NovaEra:tokenCartao", tokenCartao);
      return;
    } else {
      throw CustomizedError({ message: response.data.mensagem || status });
    }
  };

  const BlockCard = async (idMotivo, senha) => {
    const tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
    const response = await RequestPutBlockCard.callApi({
      idMotivo,
      tokenCartao,
      senha,
    });

    switch (response.status) {
      case 200:
        return { status: 200, data: response.data };
      case 204:
        return { status: 204, data: response.data };
      default:
        throw CustomizedError({
          message: response.data.mensagem || response.status,
        });
    }
  };

  const handleValidation = async () => {
    try {
      setButtonIsLoading(true);
      const response = await CodeValidate(
        verificationCodeUnmask(verificationCode)
      );
      if (response.status === 204) {
        setVerificationCodeError(null);
        handleSubmitBlock();           
      } else {
        setButtonIsLoading(false);
        setVerificationCodeError(response.data.mensagem);
      }
    } catch (err) {
      setButtonIsLoading(false);
      setVerificationCodeError(err.message);
    }
  };

  const onClickPopup = () => {
    if (location.state.parentPath === "/home" || !sucessoBlock) {
      window.location = "/home";      
    } else {
      // logout();
      history.push('/home')
    }
  };

  const handleSubmitBlock = async () => {
    if (location.state.parentPath === "/home") {
      const isUnblocked = await UnblockCard(location.state.password);
      setTextPopup(
        isUnblocked
          ? "Senha do cartão desbloqueada com sucesso!"
          : "Erro inesperado, por favor tente novamente."
      );
      setOneButtonPopupVisibility(true);
    } else {
      const isBlocked = await BlockCard(
        location.state.blockReason,
        location.state.password
      );

      if (isBlocked.status === 200 || isBlocked.status === 204) {
        setSucessoBlock(true);
        setTextPopup("Cartão bloqueado com sucesso! Caso deseje um novo cartão, por favor solicitar em nossa loja.");
      } else {
        setSucessoBlock(false);
        setTextPopup(
          "Não é possivel bloquear o cartão. Para maiores informações entre em contato com a central Teucard: 4003-3203."
        );
      }
      setOneButtonPopupVisibility(true);
    }

    setButtonIsLoading(false);
  };

  const handleDisabledButton = () => verificationCode.length !== 7;

  return (
    <LayoutHome>
      <ContainerBox>
        <S.Form
          onSubmit={(e) => {
            e.preventDefault();
            handleValidation();
          }}
        >
          <h6>Código de verificação</h6>
          <p className="instructions">
            Insira o código enviado por push notification no seu celular.
          </p>
          <Input
            type="text"
            label="Código de verificação"
            placeholder="000 000"
            value={verificationCode}
            maxLength={7}
            onChange={(e) =>
              setVerificationCode(verificationCodeMask(e.target.value))
            }
            error={verificationCodeError}
          />
          <p className="help-message">
            Não recebeu o SMS? Você pode{" "}
            <span onClick={SendTokenSMS}>reenviar</span>
          </p>
          <Button type="submit" disabled={handleDisabledButton()}>
            {buttonIsLoading
              ? `Validando código ${verificationCode}...`
              : "Validar código"}
          </Button>
        </S.Form>
        <OneButtonPopup
          slug='/home'
          onClick={onClickPopup}
          icon={themes.components.icons.cardBlocked}
          text={textPopup}
          buttonText="Entendi"
          visibility={confirmationPopupVisibility}
          setVisibility={setOneButtonPopupVisibility}
        />
      </ContainerBox>
    </LayoutHome>
  );
};

export default pushSMSBlock;
