import styled, { css } from 'styled-components';
import Popup from 'reactjs-popup';

const buttonConfig = css`
  padding: 7px 0px;
  font-size: 1.2em;
  border-radius: 5px;
  width: 48%;
`;

export const Container = styled(Popup)`
  &-content {
    width: auto;
    padding: 0;
    border-radius: 10px;

    .content-container {
      padding: 25px 25px 15px;

      p {
        margin: 10px 0;
      }
    }

    hr {
      opacity: 0;
    }

    .button-container {
      margin: 0;
      padding: 25px;
      display: flex;
      justify-content: space-between;

      button {
        ${buttonConfig}
      }

      .left-button {
        border: 2px solid #E71037;
        background: #fff;
        color:#E71037;
      }

      .right-button {
        border: 2px solid #E71037;
        color: #fff;
      }
    }
  }
`;
