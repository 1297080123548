/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import * as St from "../styles";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import InputSelectSimple from "~/views/Lending/components/InputSelectSimple";
import InputOnboarding from "~/components/InputOnboarding";
import { dateMask } from "~/utils/mask";
import InputSelect from "~/components/InputSelect";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import STATES from "../../../../assets/mock/states.json";
import Button from "~/components/Button";
import { GlobalContext } from "~/contexts/GlobalState";
import InputSelectLoan from "~/views/Lending/components/InputSelectLoan";
import moment from "moment";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import SelectDropdown from "../../components/SelectDropdown";
import themes from "~/themes";

const INPUT_NAMES = {
  typeDocument: "typeDocument",
  document: "document",
  emissionDate: "emissionDate",
  issuer: "issuer",
  state: "state",
};

const IdentifyDataByLendingSearch = () => {
  const [form, setForm] = useState([]);
  const [showPopupErrorDate, setShowPopupErrorDate] = useState(false);
  const [openSelectTypeDocument, setOpenSelectTypeDocument] = useState(false);
  const [openSelectTypeIssuer, setOpenSelectTypeIssuer] = useState(false);
  const [openSelectTypeUf, setOpenSelectTypeUf] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [issues, setIssues] = useState([]);
  const { addLending } = useContext(GlobalContext);
  const history = useHistory();

  const updateFormSelectType = (code, id, value) => {
    setForm((prev) => ({ ...prev, [id]: { code, value } }));
  };

  const GetIssues = useApi(
    Endpoints.lendings.getIssuesByEasyCredito,
    ApiMethod.GET
  );

  const getIssue = async () => {
    try {
      const response = await GetIssues.callApi();
      if (response.status >= 200 && response.status < 300) {
        setIssues(response.data);
      } else {
        setButtonIsLoading(false);
        setMessageError(response.mensagem);
        setShowPopupError(true);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getIssue();
  }, []);

  const activateButton = () => {
    return (
      !form?.typeDocument?.code ||
      !form?.document ||
      form?.emissionDate?.length < 10 ||
      !form.issuer?.code ||
      !form.state?.code
    );
  };

  console.log(moment(form?.emissionDate).isValid(), form?.emissionDate);
  async function handleNext() {
    addLending({
      typeDoc: form.typeDocument?.code,
      document: form?.document,
      dataEmissaoDocumento: form?.emissionDate,
      issuingAgency: form.issuer?.code,
      emissionDocumentUf: form.state?.code,
    });

    history.push("/buscador/dados-residenciais");
  }

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={messageError}
        buttonText="Entendi"
        visibility={showPopupError}
        setVisibility={setShowPopupError}
      />
      {/* <OneButtonPopup
        icon={themes.components.icons.warning}
        text="A data é inválida"
        buttonText="Entendi"
        visibility={showPopupErrorDate}
        setVisibility={setShowPopupErrorDate}
      /> */}
      <St.Content>
        <St.BoxContent>
          <St.Title>Dados de identidade</St.Title>
          <St.Subtitle>
            Precisamos de algumas informações de identificação:
          </St.Subtitle>
          <St.Form>
            {/* <InputSelectSimple
              label="Tipo de documento*"
              placeholder="Selecione o tipo de documento"
              open={openSelectTypeDocument}
              setOpen={setOpenSelectTypeDocument}
              type="typeDocument"
              inputTextValue={form?.typeDocument?.code}
              inputId={INPUT_NAMES.typeDocument}
              options={[
                { code: "RG", value: "RG" },
                { code: "CNH", value: "CNH" },
                { code: "Passaporte", value: "PASSAPORTE" },
                { code: "Carteira - CRC", value: "CARTEIRA_CRC" },
                { code: "Carteira - CRE", value: "CARTEIRA_CRE" },
                { code: "Carteira - CREA", value: "CARTEIRA_CREA" },
                { code: "Carteira - OAB", value: "CARTEIRA_OAB" },
              ]}
              onClickSelect={updateFormSelectType}
            /> */}
            <SelectDropdown
              label="Tipo de documento*"
              placeholder="Selecione o tipo de documento"
              open={openSelectTypeDocument}
              setOpen={setOpenSelectTypeDocument}
              options={[
                { name: "RG", code: "RG" },
                { name: "CNH", code: "CNH" },
                { name: "Passaporte", code: "PASSAPORTE" },
                { name: "Carteira - CRC", code: "CARTEIRA_CRC" },
                { name: "Carteira - CRE", code: "CARTEIRA_CRE" },
                { name: "Carteira - CREA", code: "CARTEIRA_CREA" },
                { name: "Carteira - OAB", code: "CARTEIRA_OAB" },
              ]}
              onChange={(text, name) =>
                updateFormSelectType(text, INPUT_NAMES.typeDocument, name)
              }
              selectedKey={form?.typeDocument?.code}
              id={INPUT_NAMES.typeDocument}
            />
            <InputOnboarding
              type="text"
              largeBorder
              label="Número*"
              maxLength={15}
              value={form?.document}
              id={INPUT_NAMES.document}
              largeLabel
              onChange={(e) => setForm({ ...form, document: e.target.value })}
            />
            <InputOnboarding
              type="text"
              label="Data de emissão*"
              largeBorder
              id={INPUT_NAMES.emissionDate}
              maxLength={10}
              value={form.emissionDate}
              largeLabel
              onChange={(e) => {
                const inputValue = dateMask(e.target.value);
                const isDateValid = inputValue;

                setForm({
                  ...form,
                  emissionDate: inputValue,
                  isEmissionDateValid: isDateValid,
                });
              }}
            />
            {/* <InputSelectLoan
              label="Orgão expedidor*"
              placeholder="-"
              inputTextValue={form?.issuer?.value}
              id={INPUT_NAMES.issuer}
              options={issues}
              open={openSelectTypeIssuer}
              setOpen={setOpenSelectTypeIssuer}
              onClickSelect={updateFormSelectType}
              type="issuer"
            /> */}
            <SelectDropdown
              label="Orgão expedidor*"
              placeholder="Selecione o orgão expedidor"
              options={issues}
              open={openSelectTypeIssuer}
              setOpen={setOpenSelectTypeIssuer}
              onChange={(text, name) =>
                updateFormSelectType(text, INPUT_NAMES.issuer, name)
              }
              selectedKey={form?.issuer?.code}
              id={INPUT_NAMES.issuer}
            />
            <SelectDropdown
              label="UF*"
              placeholder="Selecione o estado"
              open={openSelectTypeUf}
              setOpen={setOpenSelectTypeUf}
              options={STATES.UF_BY_VALUES}
              onChange={(text, name) =>
                updateFormSelectType(text, INPUT_NAMES.state, name)
              }
              id={INPUT_NAMES.state}
              selectedKey={form?.state?.code}
              inputTextValue={form?.state?.name}
            />
            {/* <InputSelectSimple
              label="UF*"
              placeholder="-"
              open={openSelectTypeUf}
              setOpen={setOpenSelectTypeUf}
              type="payday"
              inputTextValue={form?.state?.code}
              inputId={INPUT_NAMES.state}
              options={STATES.UF_BY_VALUES}
              onClickSelect={updateFormSelectType}
            /> */}
          </St.Form>
          <St.Footer align="right">
            <div>
              <Button
                isLoading={buttonIsLoading}
                onClick={handleNext}
                disabled={activateButton()}
              >
                Avançar
              </Button>
            </div>
          </St.Footer>
        </St.BoxContent>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default IdentifyDataByLendingSearch;
