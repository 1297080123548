import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;

  @media screen and (max-width: 1366px) {
    max-width: 1150px;
  }
`;

const ContainerBox = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Box = styled.div`
  width: 240px;
  height: 280px;
  overflow: hidden;
  border: 1px #000; /* Substitua a cor pela cor desejada */
  border-radius: 8px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2); /* Adicione sombra se necessário */
  background-color: white;
  display: flex;
  border-width: 1;
  flex-direction: column;
`;

const Header = styled.header`
  width: 100%;
  height: 30%;
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextHeader = styled.span`
  font-size: 13px;
  font-color: white;
`;

const Body = styled.body`
  width: 100%;
  display: flex;
`;

const BodyValues = styled.body`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const TitleBody = styled.span`
  font-size: 10px;
  font-color: black;
  padding: 10px;
  text-align: center;
  font-weight: bold;
`;

const ValueDescription = styled.span`
  font-size: 10px;
  font-color: black;
`;

const Value = styled.span`
  font-size: 22px;
  font-color: black;
`;

const Installments = styled.span`
  font-size: 10px;
  font-color: black;
`;

const Trace = styled.div`
  width: 80%;
  height: 1px;
  border-width: 1px;
  margin-top: 10px;
  background: #739942;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding: 0.6rem;
`;

const ButtonView = styled.div`
  width: 80%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FooterView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageStatus = styled.span`
  font-size: 10px;
  padding: 20px;
  font-color: black;
  text-align: center;
  font-weight: 500;
`;

const DateOfCreateProposal = styled.div`
  font-size: 9px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const DateDescription = styled.span`
  font-size: 9px;
  font-color: black;
`;

export {
  Container,
  ContainerBox,
  Box,
  Header,
  TextHeader,
  Body,
  BodyValues,
  TitleBody,
  ValueDescription,
  Value,
  Installments,
  Trace,
  ButtonView,
  Footer,
  FooterView,
  MessageStatus,
  DateOfCreateProposal,
  DateDescription,
};
