import styled, { css } from "styled-components";

const buttonConfig = css`
  padding: 7px 0px;
  font-size: 1.2em;
  border-radius: 5px;
  width: 100%;
  margin-top: 25px;
`;

export const Wrapper = styled.div`
  // max-width: 500px;
  margin: 0 auto;

  h6 {
    color: #4a4b4a;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 25px;
  }

  button {
    ${buttonConfig}
  }
`;

export const InvoiceStatus = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
flex-direction: row;
margin-bottom: 3px;
  t {
    color: #4a4b4a;
    font-size: 16px;
    font-weight: 500;
    margin-right: 5px;
  }

  b {
    font-weight: 600;
    font-size: 16px;
    margin-top: 2px;
    margin-right: 5px;
  }

   span {
    display: flex;
    flex-direction: row
    margin-left: 10px;
  }

   > svg {      
      margin-left: 2%;
    }

  
`;

export const ErrorInvoice = styled.div`
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WrapperLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

export const ContainerInvoice = styled.div`
  display: flex;
`;

export const InputCodigoDeBarras = styled.div`
  flex: 3;
  margin-right: 10px;
  margin-top: -14px;
`;

export const CopyButton = styled.div`
  flex: 1;
`;
