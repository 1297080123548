export const get = (key) => {
  let item = sessionStorage.getItem(key);

  try {
    item = JSON.parse(item);
  } catch (err) {
  } finally {
    return item;
  }
};

export const set = (key, value) => {
  if (typeof value == "object") value = JSON.stringify(value);

  return sessionStorage.setItem(key, value);
};

export const remove = (key) => {
  return sessionStorage.removeItem(key);
};

export const removeAll = () => {
  return sessionStorage.clear();
};
