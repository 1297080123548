/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import "./styles.css";

function Dropdown({
  options,
  placeholder = "",
  onChange,
  selectedKey,
  inputTextValue = "",
  open,
  setOpen,
  label,
  error,
}) {
  const [inputValue, setInputValue] = useState(inputTextValue);
  const [selectedValue, setSelectedValue] = useState(
    options.find((option) => option.code === selectedKey)?.name || ""
  );

  const onInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // useEffect(() => {
  //   if (selectedKey) {
  //     setInputValue(
  //       options.find((option) => option?.code === selectedKey).value
  //     );
  //   }
  // }, [selectedKey, options]);

  useEffect(() => {
    if (
      !open &&
      options.findIndex((option) => option.name === inputValue) === -1
    ) {
      if (!inputValue) {
        onChange("");
      } else {
        if (selectedKey) {
          setInputValue(
            options.find((option) => option.code === inputValue)?.value
          );
        } else {
          setInputValue("");
        }
      }
    }
  }, [inputValue]);

  const onItemSelected = (option) => {
    onChange !== undefined && onChange(option.code, option.name);
    onChange !== undefined && setInputValue(option.name);
    setSelectedValue(option.name);
    setOpen(false);
  };

  const clearDropdown = () => {
    setInputValue("");
    setSelectedValue("");
    onChange("");
  };

  const onInputClick = () => {
    setOpen((prevValue) => !prevValue);
    setInputValue("");
  };

  return (
    <div className="dropdown-container">
      <label>{label}</label>
      <div className="input-container" onClick={onInputClick}>
        <input
          type="text"
          value={inputValue || inputTextValue || selectedValue}
          placeholder={placeholder}
          onChange={onInputChange}
          style={{ border: "1px solid black" }}
        />
        <div className="input-arrow-container">
          {!open ? (
            <FiChevronDown size={25} color="#e4003A" />
          ) : (
            <FiChevronUp size={25} color="#e4003A" />
          )}
        </div>
        {selectedKey || inputValue || selectedValue ? (
          <div className="input-clear-container" onClick={clearDropdown}>
            <AiOutlineClose size={20} />
          </div>
        ) : null}
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className={`dropdown ${open ? "visible" : ""}`}>
        {options
          .filter((item) => {
            const searchItem = inputValue?.toLocaleLowerCase();
            const v = item?.name?.toLocaleLowerCase();

            if (!searchItem) return true;
            return v ? v.startsWith(searchItem) : false;
          })
          ?.map((option) => {
            return (
              <div
                key={option.code}
                className="option"
                onClick={() => onItemSelected(option)}
                value={option.code}
              >
                {option.name}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Dropdown;
