import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import * as S from "../../screens/LendingExtract/styles";
import themes from "~/themes";

import { moneyMask } from "~/utils/mask";
import { convertDate } from "~/utils/converters";
import { GlobalContext } from "~/contexts/GlobalState";

import LendingStatus from "~/assets/mock/lendingStatus.json";

const TransactionsLending = ({
  amount,
  date,
  status,
  statusCelcoin,
  motivo,
  title,
  user,
  typeSupplier,
  id,
  isSigned,
  proposals,
  idEmprestimo,
}) => {
  const newDate = convertDate(date);

  const history = useHistory();

  const { addLending } = useContext(GlobalContext);

  const formatStatus = (status) => {
    for (let i = 0; i < 17; i++) {
      if (status === LendingStatus[i].status) {
        return LendingStatus[i].returnMessage;
      }
    }
  };

  const upDocuments = () => {
    addLending({
      idSimulacao: idEmprestimo,
    });
    history.push("/loan-home/submeter-documentos");
  };

  return (
    <S.TransactionsDate>
      <S.DateStyle style={{ borderBottom: "1.08006px solid #727272" }}>
        {newDate.mounted_date}
      </S.DateStyle>
      <S.Transaction>
        <span>{themes.components.icons.lending}</span>
        <S.Description>
          <label>{title}</label>

          <span>
            {status === "ASSINANDO" &&
            typeSupplier === "CELCOIN" &&
            !isSigned ? (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                {typeSupplier === "CELCOIN"
                  ? statusCelcoin.replace("Clique aqui!", "")
                  : status}
                <b
                  style={{
                    color: `${themes.colors.orange.n2}`,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    addLending({
                      idSimulacao: id,
                      dataNascimento: user.dataNascimento,
                      nome: user.nome,
                    });
                    history.push("/emprestimo/cancelar-saque", {
                      idEmprestimoState: id,
                      status: status,
                    });
                  }}
                >
                  Clique aqui
                </b>
              </div>
            ) : typeSupplier === "CELCOIN" ? (
              statusCelcoin
            ) : (
              formatStatus(status)
            )}
            {!proposals?.documentosEnviados &&
              proposals?.documentosPendentes?.length > 0 && (
                <div>
                  <span style={{ gap: "2rem" }}>
                    {status}{" "}
                    <b
                      style={{
                        color: `${themes.colors.orange.n2}`,
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        proposals.documentosPendentes &&
                        !proposals.temDocumentos &&
                        !proposals.documentosEnviados
                          ? upDocuments()
                          : null
                      }
                    >
                      Clique aqui para enviar os documentos
                    </b>
                  </span>
                </div>
              )}
          </span>
          <span>
            {!proposals?.documentosEnviados &&
            proposals?.documentosPendentes?.length > 0
              ? null
              : status}
          </span>

          {motivo && (
            <span>
              <div>Motivo: {motivo}</div>
            </span>
          )}
        </S.Description>
        <S.TransactionValue>{moneyMask(amount)}</S.TransactionValue>
      </S.Transaction>
    </S.TransactionsDate>
  );
};

export default TransactionsLending;
