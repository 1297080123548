import styled from 'styled-components';

export const Form = styled.form`
  width: 50%;

  h6 {
    color: #4a4b4a;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 50px;
  }

  p{
    font-size: 12px;
    line-height: 16px;
    color:#757575;
    margin: -22px 0 22px;
  }

  button {
    margin-top: 70px;
    padding: 7px 0px;

    font-size: 1.2em;
    border-radius: 5px;
    width: 100%;
    border: 2px solid #E71037;
    color: #fff;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
