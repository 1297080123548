import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 55%;
  max-width: 800px;
  margin: 0 auto;
`;

export const WrapperLoader = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height: 100px;
`

export const NoStracts = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height: 100px;
`

export const CardClientInfo = styled.div`
  display: flex;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
`;

export const NoContent = styled.div`
  width: 100%;
  height: 312.07px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #F5F5F5;
  box-shadow: 5px 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(54px);
  border-radius: 8px;
  margin-bottom: 25px;

 h3 {
  width: 350px;
  height: 73.43px;
  left: 494.21px;
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
  text-align: center;
  color: #4A4B4A;
 }

 p {
  width: 407.53px;
  height: 47.02px;
  left: 516.24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #9E9E9E;
 }
`;