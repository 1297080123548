import React from "react";
import Button from "~/components/Button";

import LinkButton from "~/components/LinkButton";
import * as S from "./styles";

export const OneButtonPopup = ({
  defaultButton,
  imgIcon,
  visibility,
  title,
  setVisibility = () => {},
  icon,
  text,
  buttonText,
  slug,
  onClick,
  children,
  ishtml = false,
  onClose,
}) => {
  return (
    <S.Container
      open={visibility}
      onClose={() => {
        setVisibility(false);
        !!onClose && onClose();
      }}
      modal
    >
      {(close) => (
        <>
          <div className="content-container">
            <p>{title}</p>
            {imgIcon ? (
              <img src={icon} alt="" style={{ width: "100%" }} />
            ) : (
              icon
            )}
            {ishtml ? (
              <div dangerouslySetInnerHTML={{ __html: text }} />
            ) : (
              <p>{text}</p>
            )}

            {children}
          </div>

          <hr />

          {defaultButton ? (
            <div className="button-container">
              <button onClick={close} className="button-cancel">
                {buttonText || "Cancelar"}
              </button>
            </div>
          ) : (
            (slug && (
              <LinkButton onClick={onClick} slug={slug} text={buttonText} />
            )) ||
            (!slug && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "15px",
                }}
              >
                <Button
                  style={{ width: "180px" }}
                  onClick={() => {
                    setVisibility(false);
                    !!onClick && onClick();
                  }}
                >
                  {buttonText}
                </Button>
              </div>
            ))
          )}
        </>
      )}
    </S.Container>
  );
};

export default OneButtonPopup;
