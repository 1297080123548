/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import * as St from "../styles";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import InputSelectSimple from "~/views/Lending/components/InputSelectSimple";
import InputOnboarding from "~/components/InputOnboarding";
import { cepMask, dateMask } from "~/utils/mask";
import RESIDENTIAL_TIME from "../../../../assets/mock/residentialTime.json";
import RESIDENTIAL_TYPE from "../../../../assets/mock/residentialType.json";
import Button from "~/components/Button";
import { api } from "~/services/api";
import constants from "~/utils/constants";
import STATES from "../../../../assets/mock/states.json";
import { GlobalContext } from "~/contexts/GlobalState";
import useApi, { ApiMethod } from "~/hooks/useApi";
import SelectDropdown from "../../components/SelectDropdown";

const INPUT_NAMES = {
  cep: "cep",
  address: "address",
  number: "number",
  complement: "complement",
  neighborhood: "neighborhood",
  state: "state",
  residentialType: "residentialType",
  residentialTime: "residentialTime",
  city: "city",
};

const cepInfo = {
  cep: "cep",
  neighborhood: "neighborhood",
  number: "",
  complement: "complement",
  address: "address",
  city: "city",
  state: "state",
};

const ResidentialDataByLendingSearch = () => {
  const [form, setForm] = useState({
    cep: null,
    neighborhood: null,
    number: null,
    complement: null,
    name: null,
    city: null,
    state: null,
  });
  const [openSelectTypeOfResidence, setOpenSelectTypeOfResidence] =
    useState(false);
  const [openSelectTimeOfResidence, setOpenSelectTimeOfResidence] =
    useState(false);
  const [disableEndereco, setDisableEndereco] = useState(false);
  const [openSelectTypeUf, setOpenSelectTypeUf] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const { addLending, lending } = useContext(GlobalContext);
  const history = useHistory();

  console.log(form.cep);

  console.log(form?.number?.length);

  const updateFormSelectType = (code, id, value) => {
    setForm((prev) => ({ ...prev, [id]: { code, value } }));
  };

  const GetCep = useApi(
    constants.endpoints.findAddressByCep.replace(
      "PARAM_CEP",
      form?.cep || lending?.cep
    ),
    ApiMethod.GET
  );

  useEffect(() => {
    setForm({
      cep: cepMask(lending?.cep),
      address: lending?.street,
      number: lending?.number,
      neighborhood: lending?.neighborhood,
      complement: lending?.complement,
      state: lending?.uf,
    });
  }, [lending]);

  console.log(
    form.cep,
    form.address,
    form.number,
    form.complement,
    form.neighborhood,
    form.state?.code,
    form.residentialType?.code,
    form.residentialTime?.code
  );

  const activateButton = () => {
    return (
      !form?.cep ||
      !form?.address ||
      form?.number?.length > 0 ||
      !form?.state?.code ||
      !form?.neighborhood ||
      !form.residentialType?.code ||
      !form.residentialTime?.code
    );
  };

  const getCepClient = async () => {
    try {
      const returnCep = await GetCep.callApi();
      if (returnCep.status >= 200 && returnCep.status < 300) {
        cepInfo.cep = returnCep.data.cep;
        cepInfo.address = returnCep.data.logradouro;
        cepInfo.complement = returnCep.data.complemento;
        cepInfo.neighborhood = returnCep.data.bairro;
        cepInfo.city = returnCep.data.localidade;
        cepInfo.state = returnCep.data.uf;
        setFormUpdate();
      } else {
        setButtonIsLoading(false);
        setMessageError(returnCep.mensagem);
        setShowPopupError(true);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const setFormUpdate = async () => {
    setForm((prev) => ({
      ...prev,
      [INPUT_NAMES.cep.unmasked]: cepInfo.cep,
    }));
    setForm((prev) => ({ ...prev, [INPUT_NAMES.number]: cepInfo.number }));
    setForm((prev) => ({ ...prev, [INPUT_NAMES.city]: cepInfo.city }));
    setForm((prev) => ({ ...prev, [INPUT_NAMES.address]: cepInfo.address }));
    setForm((prev) => ({
      ...prev,
      [INPUT_NAMES.neighborhood]: cepInfo.neighborhood,
    }));
    updateFormSelectType(cepInfo.state, INPUT_NAMES.state, cepInfo.state);
    setForm((prev) => ({
      ...prev,
      [INPUT_NAMES.complement]: cepInfo.complement,
    }));
  };

  useEffect(() => {
    if (
      form?.cep?.length === 9 ||
      form?.cep?.length === 8 ||
      lending?.cep === 9
    ) {
      getCepClient();
    }
  }, [form?.cep?.length || lending?.cep]);

  async function handleNext() {
    addLending({
      street: form?.address,
      neighborhood: form?.neighborhood,
      cep: form?.cep,
      complement: form?.complement,
      number: form.number,
      uf: form.state?.code,
      city: form?.city,
      typeResidential: form?.residentialType?.code,
      timeResidential: form?.residentialTime?.code,
    });

    history.push("/buscador/dados-bancarios");
  }

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <St.Content>
        <St.BoxContent>
          <St.Title>Dados residenciais</St.Title>
          <St.Subtitle>
            Para continuarmos, precisamos dos teus dados residenciais
          </St.Subtitle>
          <St.Form>
            <InputOnboarding
              name="zipCode"
              id="zipCode"
              maxLength={9}
              pattern="[0-9]*"
              type="text"
              label="CEP*"
              largeLabel
              largeBorder
              placeholder="Digite seu CEP"
              onChange={(e) =>
                setForm({ ...form, cep: cepMask(e.target.value) })
              }
              value={form?.cep ? form.cep : lending?.cep}
            />
            <InputOnboarding
              type="text"
              label="Endereço*"
              largeBorder
              largeLabel
              maxLength={100}
              value={form?.address}
              disabled={disableEndereco}
              onChange={(e) => setForm({ ...form, address: e.target.value })}
            />
            <St.Row>
              <InputOnboarding
                type="text"
                label="Número"
                largeBorder
                largeLabel
                maxLength={14}
                placeholder="Digite o número do endereço"
                value={form?.number ? form.number : lending?.number}
                onChange={(e) => setForm({ ...form, number: e.target.value })}
                disabled={disableEndereco}
              />
              <InputOnboarding
                type="text"
                label="Complemento (opcional)"
                largeBorder
                placeholder="Digite o complemento"
                largeLabel
                maxLength={20}
                value={form?.complement || lending.complement}
                onChange={(e) =>
                  setForm({ ...form, complement: e.target.value })
                }
              />
            </St.Row>
            <St.Row>
              <InputOnboarding
                type="text"
                label="Bairro"
                largeBorder
                maxLength={14}
                largeLabel
                value={form?.neighborhood}
                onChange={(e) =>
                  setForm({ ...form, neighborhood: e.target.value })
                }
                disabled={disableEndereco}
              />
              {/* <InputSelectSimple
                label="UF*"
                placeholder="-"
                open={openSelectTypeUf}
                setOpen={setOpenSelectTypeUf}
                type="payday"
                inputTextValue={form?.state?.code}
                inputId={INPUT_NAMES.state}
                options={STATES.UF_BY_VALUES}
                onClickSelect={updateFormSelectType}
              /> */}
              <SelectDropdown
                label="UF*"
                placeholder="Selecione o estado"
                open={openSelectTypeUf}
                setOpen={setOpenSelectTypeUf}
                id={INPUT_NAMES.uf}
                options={STATES.UF_BY_VALUES}
                onChange={(text, name) =>
                  updateFormSelectType(text, INPUT_NAMES.state, name)
                }
                selectedKey={form?.state?.code}
                inputTextValue={form?.state?.value}
              />
            </St.Row>
            <SelectDropdown
              label="Tipo de residência*"
              placeholder="Selecione o tipo de residência"
              open={openSelectTypeOfResidence}
              setOpen={setOpenSelectTypeOfResidence}
              id={INPUT_NAMES.residentialType}
              options={RESIDENTIAL_TYPE}
              onChange={(text, name) =>
                updateFormSelectType(text, INPUT_NAMES.residentialType, name)
              }
              selectedKey={form?.residentialType?.code}
              inputTextValue={form?.residentialType?.value}
            />
            {/* <InputSelectSimple
              label="Tipo de residência*"
              placeholder="-"
              open={openSelectTypeOfResidence}
              setOpen={setOpenSelectTypeOfResidence}
              inputTextValue={form?.residentialType?.code}
              inputId={INPUT_NAMES.residentialType}
              options={RESIDENTIAL_TYPE}
              type="payday"
              onClickSelect={updateFormSelectType}
            /> */}
            <SelectDropdown
              label="Tipo de residência*"
              placeholder="Selecione o tipo de residência"
              open={openSelectTimeOfResidence}
              setOpen={setOpenSelectTimeOfResidence}
              id={INPUT_NAMES.residentialTime}
              options={RESIDENTIAL_TIME}
              onChange={(text, name) =>
                updateFormSelectType(text, INPUT_NAMES.residentialTime, name)
              }
              selectedKey={form?.residentialTime?.code}
              inputTextValue={form?.residentialTime?.value}
            />
            {/* <InputSelectSimple
              label="Tempo de residência*"
              placeholder="-"
              open={openSelectTimeOfResidence}
              setOpen={setOpenSelectTimeOfResidence}
              type="payday"
              inputTextValue={form?.residentialTime?.code}
              inputId={INPUT_NAMES.residentialTime}
              options={RESIDENTIAL_TIME}
              onClickSelect={updateFormSelectType}
            /> */}
          </St.Form>
          <St.Footer align="right">
            <div>
              <Button
                isLoading={buttonIsLoading}
                onClick={handleNext}
                disabled={activateButton()}
              >
                Avançar
              </Button>
            </div>
          </St.Footer>
        </St.BoxContent>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default ResidentialDataByLendingSearch;
