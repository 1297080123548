import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Select from "~/components/Select";
import Layout from "~/components/Layout";
import ContainerBox from "~/components/ContainerBox";
import themes from "~/themes";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";

import * as S from './styles';
import LayoutHome from '~/components/LayoutHome';
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

const BlockReason = () => {
  const history = useHistory();

  const [data, setData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [blockReason, setBlockReason] = useState(1);

  const RequestGetBlockReason = useApi(
    Endpoints.autorizador.getBlockReason.replace("PARAM_ORIGIN", "WEB"),
    ApiMethod.GET
  );

  const handleBlockReason = () => {
    const parentPath = history.location.pathname;
    const password = history.location.state.password;
    history.push("/pushSMSBlock", {
      parentPath,
      blockReason,
      password,
    });
    // history.push('/cartao-codigo-verificacao', {
    //   parentPath,
    //   blockReason,
    //   password,
    // });
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await RequestGetBlockReason.callApi();

      const filteredData = data.data;
      setData(filteredData);
    };

    fetchData();
  }, []);

  return (
    <LayoutHome>
      <ContainerBox>
        <S.Wrapper>
          <h6>Bloqueio do cartão</h6>
          <p className="instructions">
            Informe o motivo da solicitação do bloqueio do cartão, por favor.
          </p>

          <Select
            type="text"
            label="Motivo da solicitação"
            placeholder="0000 0000"
            values={data}
            maxLength={9}
            onChange={(e) => setBlockReason(+e.target.value)}
          />

          <button onClick={handleBlockReason}>Continuar</button>

          <OneButtonPopup
            slug="/cartao-main"
            icon={themes.components.icons.tick}
            text="Informação enviada com sucesso!"
            buttonText="Entendi"
            visibility={showPopup}
            setVisibility={setShowPopup}
          />
        </S.Wrapper>
      </ContainerBox>
    </LayoutHome>
  );
};

export default BlockReason;
