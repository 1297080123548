export default (state, action) => {
  switch (action.type) {
    case "ADD_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "ADD_LOGGED_USER":
      return {
        ...state,
        loggedUser: action.payload,
      };
    case "ADD_TRANSACTION":
      return {
        ...state,
        transactions: [action.payload, ...state.transactions],
      };
    case "CARD_NUMBER_SELECTED_HANDLER":
      return {
        ...state,
        walletDigital: {
          cardNumberSelected: action.payload,
        },
      };
    case "ADD_ID_TRANSACAO":
      return {
        ...state,
        walletDigital: {
          idTransacao: action.payload,
        },
      };
    case "CARD_ID_SELECTED_HANDLER":
      return {
        ...state,
        walletDigital: {
          ...state.walletDigital,
          cardId: action.payload,
        },
      };
    case "ADD_ERROR_MESSAGE":
      return {
        ...state,
        errorMessage: action.payload,
      };
    case "ADD_ONBOARDING":
      const obj = {
        ...state.onboarding,
        cpf: action.payload.cpf ? action.payload.cpf : state.onboarding.cpf,
        nome: action.payload.nome ? action.payload.nome : state.onboarding.nome,
        phone: action.payload.phone
          ? action.payload.phone
          : state.onboarding.phone,
        email: action.payload.email
          ? action.payload.email
          : state.onboarding.email,
        verificationCode: action.payload.verificationCode
          ? action.payload.verificationCode
          : state.onboarding.verificationCode,
        cep: action.payload.cep ? action.payload.cep : state.onboarding.cep,
        cidade: action.payload.cidade
          ? action.payload.cidade
          : state.onboarding.cidade,
        endereco: action.payload.endereco
          ? action.payload.endereco
          : state.onboarding.endereco,
        numero: action.payload.numero
          ? action.payload.numero
          : state.onboarding.numero,
        bairro: action.payload.bairro
          ? action.payload.bairro
          : state.onboarding.bairro,
        uf: action.payload.uf ? action.payload.uf : state.onboarding.uf,
        dataNascimento: action.payload.dataNascimento
          ? action.payload.dataNascimento
          : state.onboarding.dataNascimento,
        genero: action.payload.genero
          ? action.payload.genero
          : state.onboarding.genero,
        idProduto: action.payload.idProduto
          ? action.payload.idProduto
          : state.onboarding.idProduto,
        tipoProduto: action.payload.tipoProduto
          ? action.payload.tipoProduto
          : state.onboarding.tipoProduto,
      };
      return {
        ...state,
        onboarding: obj,
      };
    case "ADD_LENDING":
      const object = {
        ...state.lending,
        idCliente: action.payload.idCliente
          ? action.payload.idCliente
          : state.lending.idCliente,
        cpf: action.payload.cpf
          ? action.payload.cpf
          : state.lending.cpf,
        nome: action.payload.nome
          ? action.payload.nome
          : state.lending.nome,
        phone: action.payload.phone
          ? action.payload.phone
          : state.lending.phone,
        email: action.payload.email
          ? action.payload.email
          : state.lending.email,
        dataNascimento: action.payload.dataNascimento
          ? action.payload.dataNascimento
          : state.lending.dataNascimento,
        cep: action.payload.cep
          ? action.payload.cep
          : state.lending.cep,
        city: action.payload.city
          ? action.payload.city
          : state.lending.city,
        street: action.payload.street
          ? action.payload.street
          : state.lending.street,
        number: action.payload.number
          ? action.payload.number
          : state.lending.number,
        complement: action.payload.complement
          ? action.payload.complement
          : state.lending.complement,
        neighborhood: action.payload.neighborhood
          ? action.payload.neighborhood
          : state.lending.neighborhood,
        uf: action.payload.uf
          ? action.payload.uf
          : state.lending.uf,
        document: action.payload.document
          ? action.payload.document
          : state.lending.document,
        mother: action.payload.mother
          ? action.payload.mother
          : state.lending.mother,
        father: action.payload.father
          ? action.payload.father
          : state.lending.father,
        codeBank: action.payload.codeBank
          ? action.payload.codeBank
          : state.lending.codeBank,
        agency: action.payload.agency
          ? action.payload.agency
          : state.lending.agency,
        account: action.payload.account
          ? action.payload.account
          : state.lending.account,
        value: action.payload.value
          ? action.payload.value
          : state.lending.value,
        installments: action.payload.installments
          ? action.payload.installments
          : state.lending.installments,
        credit_limit: action.payload.credit_limit
          ? action.payload.credit_limit
          : state.lending.credit_limit,
        typeSimulation: action.payload.typeSimulation
          ? action.payload.typeSimulation
          : state.lending.typeSimulation,
        value_iof: action.payload.value_iof
          ? action.payload.value_iof
          : state.lending.value_iof,
        value_installment: action.payload.value_installment
          ? action.payload.value_installment
          : state.lending.value_installment,
        taxaJuros: action.payload.taxaJuros
          ? action.payload.taxaJuros
          : state.lending.taxaJuros,
        statusCartao: action.payload.statusCartao
          ? action.payload.statusCartao
          : state.lending.statusCartao,
        limiteSaqueDisponivel: action.payload.limiteSaqueDisponivel
          ? action.payload.limiteSaqueDisponivel
          : state.lending.limiteSaqueDisponivel,
        valorSaqueMaximo: action.payload.valorSaqueMaximo
          ? action.payload.valorSaqueMaximo
          : state.lending.valorSaqueMaximo,
        valorSaqueMinimo: action.payload.valorSaqueMinimo
          ? action.payload.valorSaqueMinimo
          : state.lending.valorSaqueMinimo,
        payment_amount: action.payload.payment_amount
          ? action.payload.payment_amount
          : state.lending.payment_amount,
        requested_amount: action.payload.requested_amount
          ? action.payload.requested_amount
          : state.lending.requested_amount,
        iof_amount: action.payload.iof_amount
          ? action.payload.iof_amount
          : state.lending.iof_amount,
        last_payment_date: action.payload.last_payment_date
          ? action.payload.last_payment_date
          : state.lending.last_payment_date,
        financed_amount: action.payload.financed_amount
          ? action.payload.financed_amount
          : state.lending.financed_amount,
        num_periods: action.payload.num_periods
          ? action.payload.num_periods
          : state.lending.num_periods,
        idSimulacao: action.payload.idSimulacao
          ? action.payload.idSimulacao
          : state.lending.idSimulacao,
        origem: action.payload.origem
          ? action.payload.origem
          : state.lending.origem,
        tipoChavePix: action.payload.tipoChavePix
          ? action.payload.tipoChavePix
          : state.lending.tipoChavePix,
        chavePix: action.payload.chavePix
          ? action.payload.chavePix
          : state.lending.chavePix,
        disbursement_date: action.payload.disbursement_date
          ? action.payload.disbursement_date
          : state.lending.disbursement_date,
        financeFee: action.payload.financeFee
          ? action.payload.financeFee
          : state.lending.financeFee,
        first_payment_date: action.payload.first_payment_date
          ? action.payload.first_payment_date
          : state.lending.first_payment_date,
        monthly_effective_interest_rate: action.payload.monthly_effective_interest_rate
          ? action.payload.monthly_effective_interest_rate
          : state.lending.monthly_effective_interest_rate,
        tac_amount: action.payload.tac_amount
          ? action.payload.tac_amount
          : state.lending.tac_amount,
        dataEmissaoDocumento: action.payload.dataEmissaoDocumento
          ? action.payload.dataEmissaoDocumento
          : state.lending.dataEmissaoDocumento,
        issuingAgency: action.payload.issuingAgency
          ? action.payload.issuingAgency
          : state.lending.issuingAgency,
        typeDoc: action.payload.typeDoc
          ? action.payload.typeDoc
          : state.lending.typeDoc,
        statusSignature: action.payload.statusSignature
          ? action.payload.statusSignature
          : state.lending.statusSignature,
        selfieAgent: action.payload.selfieAgent
          ? action.payload.selfieAgent
          : state.lending.selfieAgent,
        geolocation: action.payload.geolocation
          ? action.payload.geolocation
          : state.lending.geolocation,
        xCode: action.payload.xCode
          ? action.payload.xCode
          : state.lending.xCode,
        occupation: action.payload.occupation
          ? action.payload.occupation
          : state.lending.occupation,
        profession: action.payload.profession
          ? action.payload.profession
          : state.lending.profession,
        currentIncome: action.payload.currentIncome
          ? action.payload.currentIncome
          : state.lending.currentIncome,
        dayOfPayment: action.payload.dayOfPayment
          ? action.payload.dayOfPayment
          : state.lending.dayOfPayment,
        civilStatus: action.payload.civilStatus
          ? action.payload.civilStatus
          : state.lending.civilStatus,
        schooling: action.payload.schooling
          ? action.payload.schooling
          : state.lending.schooling,
        gender: action.payload.gender
          ? action.payload.gender
          : state.lending.gender,
        creditReason: action.payload.creditReason
          ? action.payload.creditReason
          : state.lending.creditReason,
        emissionDocumentUf: action.payload.emissionDocumentUf
          ? action.payload.emissionDocumentUf
          : state.lending.emissionDocumentUf,
        typeResidential: action.payload.typeResidential
          ? action.payload.typeResidential
          : state.lending.typeResidential,
        timeResidential: action.payload.timeResidential
          ? action.payload.timeResidential
          : state.lending.timeResidential,
        typeAccount: action.payload.typeAccount
          ? action.payload.typeAccount
          : state.lending.typeAccount,
        idEasyCredito: action.payload.idEasyCredito
          ? action.payload.idEasyCredito
          : state.lending.idEasyCredito
      };      
      return {
        ...state,
        lending: object,
      };
    case "ADD_DOCUMENT_IMAGE":
      return {
        ...state,
        documentImage: action.payload,
      };
    default:
      return state;
  }
};
