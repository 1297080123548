import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: ${({ position }) => position};
  height: auto;
  padding: 22px 15px;
  margin: 0 20px;
  border-radius: 16px;
  background: #ffffff;
  ${({ withoutBoxShadow }) =>
    !withoutBoxShadow ? "box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);" : ""}
  //box-shadow: 1px 3px 8px 0 rgba(201, 201, 201, 0.5);
  grid-row: ${({ gridRow }) => gridRow};

  .title-container {
    display: flex;
    justify-content: space-between;

    > h4 {
      color: #4a4b4a;
      font-size: 25px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 23px;
      margin-left: 25px;
    }
  }
`;
