import styled from "styled-components";
import themes from "~/themes";

export const Container = styled.div`
  margin-top: 25px;
`;

export const TitleStyle = styled.span`
  color: #bbbbbb;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
`;
export const ContractedProdutos = styled.div`
  margin-top: 15px;
  margin-right: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  > div {
    margin-top: 15px;
  }
`;

export const AproveitaAdquira = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-size: cover;
  ${(p) =>
    p.bannerImage
      ? `
    background-image: url(${p.bannerImage});
    width: 100%;
    height: 38vh;
    border-radius: 10px;
    `
      : ""}
`;

export const WrapperLoader = styled.div`
  // position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  left: 38%;
  // top: 13.5%;
`;

export const InativeCard = styled.div`
  width: 333px;
  height: 155px;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;
export const InativeCardHeader = styled.div`
  margin-top: 8px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #444444;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  button {
    background: transparent;
    border: 0;
    top: 10px;
    left: 130px;
    margin-right: 15px;
  }
`;
export const InativeCardHeaderTitle = styled.span`
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  color: #000000;
  display: flex;
`;

export const InativeCardBody = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #444444;
  flex-direction: column;
  height: 10rem;
  width: 100%;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const InativeCardDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  p {
    color: red;
    font-weight: 650;
    font-size: 12.8px;
    text-align: center;
  }
  > span {
    margin-left: 3px;
    color: ${(props) =>
      props.disabled ? themes.colors.secondary : themes.colors.primary};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
  }
`;

export const BlockCardDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  p {
    color: red;
    font-weight: 650;
    font-size: 12.8px;
    text-align: center;
  }
  a {
    color: black;
    font-weight: 650;
    font-size: 12.8px;
    text-align: center;
    text-decoration: underline;
  }
  > span {
    margin-left: 3px;
    color: ${(props) =>
      props.disabled ? themes.colors.secondary : themes.colors.primary};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
  }
`;

export const HyperLink = styled.span`
  font-size: 16px;
  text-decoration: underline;
  color: black;
`;

export const TextModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-size: 15px;
  }
`;
