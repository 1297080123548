const externalAccess = {
  guestUser: process.env.REACT_APP_USUARIO_VISITANTE,
  guestPassword: process.env.REACT_APP_SENHA_VISITANTE,
  originMobile: process.env.REACT_APP_ID_ORIGEM_MOBILE,
  originSite: process.env.REACT_APP_ID_ORIGEM_SITE,
  originStore: process.env.REACT_APP_ID_ORIGEM_LOJA,
  originTotem: process.env.REACT_APP_ID_ORIGEM_TOTEM,
};

const endpoints = {
  login: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/auth/login`,
  sendProposalToEmail: `${process.env.REACT_APP_MS_EMAIL}/v1/email/enviar-email/PARAM_ID_PROPOSAL/PARAM_EMAIL`,
  validateProposal: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/validar-proposta/PARAM_CPF`,
  validateProposalNoAuth: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/noauth/validar-proposta/PARAM_CPF`,
  initialProposal: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/proposta-inicial`,
  initialProposalNoAuth: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/noauth/proposta-inicial`,
  spcProposalData: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/dados-proposta-spc/PARAM_ID_PROPOSAL`,
  getReasons: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/obter-motivo-refazer-proposta/PARAM_ID_PROPOSAL`,
  createProposal: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/complementar-proposta`,
  saveContact: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/salvar-contato/PARAM_ID_PROPOSAL`,
  findAddressByCep: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/cep/PARAM_CEP`,
  getClientByCpf: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/noauth/usuario-porCpf/PARAM_CPF`,
  sendLinkRedefinePassword: `${process.env.REACT_APP_MS_EMAIL}/v1/email/noauth/link-senha-email-proposta/PARAM_EMAIL`,
  saveClientIdentification: `${process.env.REACT_APP_MS_PROPOSTA}/v1/ficha-proposta/salvar-identificacao-cliente`,
  validateCertiface: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/certiface/validacao-certiface`,
  saveDesignCreditCard: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/salvar-tema-cartao/PARAM_CPF/PARAM_DESIGN`,
  getProposalStatus: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/obter-status-propostas?PARAM_QUERY_STRING`,
  getClient: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/buscar-cliente-ativo?PARAM_QUERY_STRING`,
  getClientDetail: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/buscar-cliente-cpf/PARAM_QUERY_STRING`,
  getClientDocument: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/obter-documentos-identificacao/PARAM_QUERY_STRING`,
  getSearchFatura: `${process.env.REACT_APP_MS_RELATORIOS}/v1/relatorios/obter-lista-faturas?PARAM_QUERY_STRING`,
  getGeraFatura: `${process.env.REACT_APP_MS_RELATORIOS}/v1/relatorios/gerar-fatura/PARAM_QUERY_STRING`,
  postDuplicateCard: `${process.env.REACT_APP_MS_FICHA_AUTORIZADOR}/v1/autorizador/reemissao`,
  postValidPassword: `${process.env.REACT_APP_MS_FICHA_AUTORIZADOR}/v1/autorizador/noauth/validar-senha`,
  postTokenCard: `${process.env.REACT_APP_MS_FICHA_AUTORIZADOR}/v1/autorizador/noauth/token-cartao`,
  postEmailFatura: `${process.env.REACT_APP_MS_EMAIL}/v1/email/email-fatura/PARAM_QUERY_STRING`,
  getProposalsByStatus: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/buscar-propostas-status-paginacao`,
  defineNewPassword: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/noauth/confirmar-alterar-senha-backoffice/PARAM_ID_USER`,
  signProposal: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/assinar-proposta`,
  signProposalBenefit: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/assinar-beneficio-proposta`,
  getProposalPdfById: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/noauth/proposta-assinatura?id=PARAM_ID_PROPOSAL`,
  getBenefit: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/obter-beneficios`,
  getBenefitDependents: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/obter-beneficio-dependentes/PARAM_ID_PROPOSAL`,
  getBenefitById: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/buscar-beneficio-proposta/PARAM_ID_PROPOSAL`,
  postBenefitById: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/salvar-beneficio-proposta`,
  postDependent: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/adicionar-dependente/PARAM_ID_PROPOSAL`,
  refuseProposal: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/recusar-proposta/PARAM_ID_PROPOSAL`,
  livenessCredential: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/certiface/credencial`,
  livenessAppKey: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/certiface/appkey`,
  livenessChallenge: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/certiface/challenge`,
  livenessCaptcha: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/certiface/captcha`,
  livenessResult: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/certiface/result`,
  deleteBenefit: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/noauth/beneficio/PARAM_ID_BENEFIT`,
  putCodeValidate: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/push/validar-celular`,
  postSendPushSMS: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/notificacoes/sms/enviar-sms-notificacao-celular`,
  deleteBenefitDependent: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/excluir-beneficio-dependente`,
};

export default {
  endpoints,
  externalAccess,
};
