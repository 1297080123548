import { CustomizedError } from "~/utils/errors";
import { usuarioAPI } from "./api";

export const logout = (path = '/') => {
  localStorage.removeItem("@NovaEra:idUsuario");
  localStorage.removeItem("@NovaEra:infoUsuario");
  localStorage.removeItem("@NovaEra:tokenCartao");
  localStorage.setItem("@NovaEra:balanceVisibility", false);
  localStorage.setItem(
    "@NovaEra:isLogged",
    JSON.stringify({ isLogged: false })
  );
  window.location = path;
};

export const resetApplication = () => {
  localStorage.clear();
  window.location = "/";
};

export const login = async (document, password) => {
  let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");

  if (!tokenCartao) {
    tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");
  }

  const response = await usuarioAPI.post("/v1/usuarios/auth/login-cliente", {
    cpf: document.replace(/\.|-/g, ""),
    senha: password,
    // tokenCartao,
  });
  if (response.data.tokenCartao) {
    localStorage.setItem("@NovaEra:tokenCartao", response.data.tokenCartao);
  }
  switch (response.status) {
    case 201:
      const { token, novoToken, perfis, idClienteAutorizador, modeloTemaCartao } =
        response.data;
      console.log(modeloTemaCartao);
      const isUser = perfis.some((el) => el === "USUARIO");
      if (!isUser)
        throw CustomizedError({
          message: "Você não se encontra como usuário atualmente.",
        });

      localStorage.setItem("@NovaEra:token", token);
      localStorage.setItem("@NovaEra:tokenBearer", novoToken);
      localStorage.setItem("@NovaEra:idUsuario", idClienteAutorizador);
      localStorage.setItem("@NovaEra:perfilUsuario", perfis);
      localStorage.setItem(
        "@NovaEra:infoUsuario",
        JSON.stringify(response.data)
      );
      localStorage.setItem("@NovaEra:temaDoCartao", modeloTemaCartao);

      return response.data;
    default:
      throw CustomizedError({
        status: response.status,
        message: response.data.mensagem || response.status,
      });
  }
};
