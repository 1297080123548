import styled from 'styled-components';

import themes from '~/themes';

export const ButtonShowPassword = styled.button`
    position: absolute;
    top: 23px;
    right: 10px;

    background: transparent;
    outline: none;
    border: 0;

    img {
        width: 18px;
        height: auto;
    }
`;

export const CheckForm = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: start;

    margin-bottom: 18px;

    div {
        display: flex;

        background: ${props => props.active ? themes.colors.orange.n2 : themes.colors.gray.n2};
        height: 18px;
        width: 18px;
        border-radius: 4px;

        svg {
            width: 12px;
            height: auto;
            color: #FFF;
            margin: auto;
        }
    }
    p {
        margin-left: 8px;
        color: #4A4B4A;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 19px;
    }
`;