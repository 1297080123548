import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "~/contexts/GlobalState";
import * as St from "./styles";

export default (props) => {
  const history = useHistory();
  const { addOnboarding } = useContext(GlobalContext);

  const openFileManger = (data) => {
    if (data === "TEUCARD") {
      window.open(
        `${process.env.REACT_APP_URL_PROPOSTA}/cadastro-minimo/?98FA1C264E`,
        "_self"
      );
    } else if (data === "CARTEIRA_DIGITAL") {
      sessionStorage.setItem("tipoProdutoSession", data);
      addOnboarding({ idProduto: props.produto.id });
      history.push("/onboarding-digital-wallet");
    } else if (data === "EMPRESTIMO") {
      sessionStorage.setItem("tipoProdutoSession", data);
      addOnboarding({ idProduto: props.produto.id });
      history.push("/emprestimo/info-onboard");
    }
  };

  return (
    <>
      <St.Container>
        <St.ProductImage
          imageUrl={props.produto.imagem}
          onClick={() => openFileManger(props.produto.tipoProduto)}
        >
          <St.ProductFuncoes>
            <St.ProductInformation>
              {props.produto.mensagem}
            </St.ProductInformation>
          </St.ProductFuncoes>
        </St.ProductImage>
      </St.Container>
    </>
  );
};
