import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  display: flex;
  justify-content: center;
  margin: ${({ margin }) => (margin ? margin : '25px')};
  padding: 7px 0px;

  text-decoration: none;
  font-size: 1.2em;
  border-radius: 5px;
  border: 2px solid #E71037;
  background: #E71037;
  color: #fff;
`;
