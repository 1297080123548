import styled from 'styled-components';

import themes from '~/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 25px;

  label {
    font-size: 12px;
    color: ${(props) =>
      props.isFocus ? themes.colors.orange.n2 : themes.components.colors.label};
  }

  select {
    border: 0;
    font-size: 16px;

    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${(props) =>
      props.isFocus ? themes.colors.orange.n2 : '#AFAFAF'};

    color: ${themes.components.colors.input};
    height: 30px;
  }
`;

export const ErrorText = styled.span`
  font-size: 12px;
  color: ${themes.components.colors.messageError};
  margin-top: 2px;
`;
