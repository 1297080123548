/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Loader from "~/components/Loader";
import InputOnboarding from "~/components/InputOnboarding";
import InputSelectSimple from "~/views/Lending/components/InputSelectSimple";
import Button from "~/components/Button";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import Dropdown from "~/views/LendingBySearch/components/SelectDropdown";
import moment from "moment";

import { dateMask } from "~/utils/mask";
import { GlobalContext } from "~/contexts/GlobalState";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

import * as St from "../../styles";
import themes from "~/themes";

const INPUT_NAMES = {
  typeDoc: "typeDoc",
  mother: "mather",
  document: "document",
  emission: "emission",
  issuingAgency: "issuingAgency",
};
const AdditionalData = () => {
  const [loading, setLoading] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [openSelectTypeDocument, setOpenSelectTypeDocument] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [input, setInput] = useState({
    key: "",
  });
  const [form, setForm] = useState({
    mother: "",
    document: "",
    emission: "",
    issuingAgency: "",
  });

  const { addLending, lending } = useContext(GlobalContext);

  const history = useHistory();

  const putAdditionalData = useApi(
    Endpoints.lendings.putUpdateAdditionalDataWithdraw.replace(
      "PARAM_ID",
      lending?.idSimulacao
    ),
    ApiMethod.PUT
  );

  async function handleNext() {
    setButtonIsLoading(true);

    const isDateValid = isValidDate(form.emission);

    if (!isDateValid) {
      setShowPopupError(true);
      setMessageError("Data de emissão é invalida");
      setButtonIsLoading(false);
      return;
    }
    if (!input?.typeDoc?.value) {
      setShowPopup(true);
      setButtonIsLoading(false);
    } else {
      setButtonIsLoading(true);
      addLending({
        mother: form.mother,
        document: form.document,
        dataEmissaoDocumento: moment(form.emission, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        typeDoc: input.typeDoc?.value,
        issuingAgency: form.issuingAgency,
      });

      const payload = {
        dataEmissaoDocumento: moment(form.emission, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        nomeMae: form.mother,
        numeroDocumento: form.document,
        orgaoExpedidorDocumento: form.issuingAgency,
        tipoDocumento: input?.typeDoc?.value,
      };

      const response = await putAdditionalData.callApi(payload);

      if (response.status >= 200 && response.status < 300) {
        setButtonIsLoading(false);
        history.push("/emprestimo/dados-bancarios-saque");
      }

      if (response.status > 400) {
        setButtonIsLoading(false);
        setMessageError(response.mensagem);
        setShowPopupError(true);
      }
    }
  }

  useEffect(() => {
    setForm({
      mother: lending?.mother,
      document: lending?.document,
      dataEmissaoDocumento: lending?.dataEmissaoDocumento,
      issuingAgency: lending?.issuingAgency,
    });
  }, []);

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/");
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, [verifyReloadPage]);

  function isValidDate(dateString) {
    const parsedDate = moment(dateString, "DD/MM/YYYY", true);

    if (!parsedDate.isValid()) {
      return false;
    }

    const minDate = moment().subtract(80, "years");
    const maxDate = moment().add(0, "day");

    return parsedDate.isBetween(minDate, maxDate, "day", "[]");
  }

  const activateButton = useMemo(
    () =>
      !form.mother ||
      !form.document?.length ||
      form.emission?.length < 6 ||
      !form.issuingAgency ||
      !input?.typeDoc?.value,

    [
      form.document,
      form.mother,
      form.emission,
      form.issuingAgency,
      input.typeDoc?.value,
    ]
  );

  const updateFormSelectType = (code, id, value) => {
    setInput((prev) => ({
      ...prev,
      [id]: {
        code,
        value,
      },
    }));
  };

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text="Por favor! selecione o tipo de documento"
        buttonText="Entendi"
        visibility={showPopup}
        setVisibility={setShowPopup}
      />{" "}
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={messageError}
        buttonText="Entendi"
        visibility={showPopupError}
        setVisibility={setShowPopupError}
      />{" "}
      <St.Content>
        <St.BoxContent>
          {" "}
          {loading ? (
            <St.WrapperLoader>
              <Loader size="large" />
            </St.WrapperLoader>
          ) : (
            <>
              <St.Title> Dados adicionais </St.Title>{" "}
              <St.Subtitle>
                Dados adicionais Para dar continuidade, precisamos de algumas
                informações adicionais{" "}
              </St.Subtitle>{" "}
              <St.Steps> Passo 4 de 11 </St.Steps>{" "}
              <St.Form>
                <InputOnboarding
                  type="text"
                  label="Nome da Mãe*"
                  largeLabel
                  value={form.mother}
                  maxLength={50}
                  id={INPUT_NAMES.mother}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      mother: e.target.value,
                    })
                  }
                />{" "}
                {/* <InputSelectSimple
                            label="Tipo de documento*"
                            placeholder="Selecione o tipo de documento"
                            open={openSelectTypeDocument}
                            setOpen={setOpenSelectTypeDocument}
                            type="account"
                            inputTextValue={input?.typeDoc?.code}
                            inputId={INPUT_NAMES.typeDoc}
                            options={[
                              { code: "RG", value: "RG" },
                              { code: "CNH", value: "CNH" },
                            ]}
                            onClickSelect={updateFormSelectType}
                          /> */}{" "}
                <Dropdown
                  label="Tipo de documento"
                  placeholder="Selecione o tipo de documento"
                  open={openSelectTypeDocument}
                  setOpen={setOpenSelectTypeDocument}
                  options={[
                    {
                      name: "RG",
                      code: "RG",
                    },
                    {
                      name: "CNH",
                      code: "CNH",
                    },
                  ]}
                  onChange={(text, name) =>
                    updateFormSelectType(text, INPUT_NAMES.typeDoc, name)
                  }
                  id={INPUT_NAMES.typeDoc}
                  selectedKey={form?.typeDoc?.code}
                />{" "}
                <InputOnboarding
                  type="text"
                  label="Número*"
                  maxLength={15}
                  value={form?.document}
                  id={INPUT_NAMES.document}
                  largeLabel
                  onChange={(e) =>
                    setForm({
                      ...form,
                      document: e.target.value,
                    })
                  }
                />{" "}
                <InputOnboarding
                  type="text"
                  label="Data de emissão*"
                  id={INPUT_NAMES.emission}
                  maxLength={10}
                  value={form.emission}
                  largeLabel
                  onChange={(e) => {
                    const inputValue = dateMask(e.target.value);
                    const isDateValid = isValidDate(inputValue);

                    setForm({
                      ...form,
                      emission: inputValue,
                      isEmissionDateValid: isDateValid,
                    });
                  }}
                />{" "}
                <InputOnboarding
                  type="text"
                  label="Orgão expedidor*"
                  id={INPUT_NAMES.issuingAgency}
                  value={form.issuingAgency}
                  maxLength={30}
                  largeLabel
                  onChange={(e) =>
                    setForm({
                      ...form,
                      issuingAgency: e.target.value,
                    })
                  }
                />{" "}
              </St.Form>{" "}
              <St.Footer align="right">
                <div>
                  <Button
                    isLoading={buttonIsLoading}
                    onClick={handleNext}
                    disabled={activateButton}
                  >
                    Avançar{" "}
                  </Button>{" "}
                </div>{" "}
              </St.Footer>{" "}
            </>
          )}{" "}
        </St.BoxContent>{" "}
      </St.Content>{" "}
    </LayoutOnboarding>
  );
};

export default AdditionalData;
