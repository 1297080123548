/* eslint-disable no-unused-vars */
import React, { useContext, useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Popover } from "antd";

import "reactjs-popup/dist/index.css";

import { GlobalContext } from "~/contexts/GlobalState";
import { formatCardNumber } from "~/utils/mask";
import themes from "~/themes";
import * as S from "./styles";
import MONTHS from "~/assets/mock/monthsPT_BR.json";

import Layout from "~/components/Layout";
import ContainerBox from "~/components/ContainerBox";
import CardClient from "~/components/CardClient";
import TransactionsClient from "~/components/TransactionsClient";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import TwoButtonPopup from "~/components/Modals/TwoButtonPopup";
import PasswordPopup from "~/components/Modals/PasswordPopup";
import LimitMeter from "~/components/LimitMeter";
import Loader, { LOADER_TYPES } from "~/components/Loader";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { CustomizedError } from "~/utils/errors";
import LayoutHome from "~/components/LayoutHome";

const Home = () => {
  const history = useHistory();

  const [transactions, setTransactions] = useState();
  const [passwordSlug, setPasswordSlug] = useState("");
  const [formattedCardNumber, setFormattedCardNumber] = useState(null);
  const [popoverVisibility, setPopoverVisibility] = useState(false);
  const [errorPopupVisibility, setErrorPopupVisibility] = useState(false);
  const [unblockPopupVisibility, setUnblockPopupVisibility] = useState(false);
  const [passwordPopupVisibility, setPasswordPopupVisibility] = useState(false);
  const [popUpNewsVisibility, setPopUpNewsVisibility] = useState(false);
  const [userBalance, setUserBalance] = useState();
  const [versionApp] = useState(process.env.REACT_APP_VERSAO_APP);
  const [balanceVisibility, setBalanceVisibility] = useState(
    localStorage.getItem("@NovaEra:balanceVisibility") === "true"
  );

  const { user } = useContext(GlobalContext);

  const tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");

  const PostTransactions = useApi(
    Endpoints.autorizador.postExtrato
      .replace("PARAM_LIMITE", "3")
      .replace("/PARAM_DATA", ""),
    ApiMethod.POST
  );

  const PostLimitBalance = useApi(
    Endpoints.autorizador.postSaldo,
    ApiMethod.POST
  );
  const PostCardValidation = useApi(
    Endpoints.autorizador.postCartao,
    ApiMethod.POST
  );

  const RequestPostValidatePassword = useApi(
    Endpoints.autorizador.postValidatePassword,
    ApiMethod.POST
  );

  useEffect(() => {
    if (user?.numeroCartao) {
      setFormattedCardNumber(formatCardNumber(user?.numeroCartao));
    }
  }, [user]);

  useEffect(() => {
    handleGetTransactions();
    hangleGetUserBalance();
    getClientStatus();
  }, []);

  // useEffect(() => {
  //   async function appVersion() {
  //     if (versionApp !== await localStorage.getItem('@NovaEra:VersionApp')){
  //       setPopUpNewsVisibility(true);
  //     }
  //   }
  //   appVersion();
  // });

  const cardClient = {
    name: "Antônio A de Souza",
    number: "●●●● ●●●● 5710 2642",
    limit: {
      max: 1000,
      used: 100,
    },
  };

  const handleGetTransactions = async () => {
    try {
      const transactions = await PostTransactions.callApi({ tokenCartao });

      setTransactions(transactions.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const hangleGetUserBalance = async () => {
    try {
      const userData = await PostLimitBalance.callApi({ tokenCartao });
      setUserBalance(userData.data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleBalanceVisibility = useCallback(() => {
    setBalanceVisibility((state) => {
      localStorage.setItem("@NovaEra:balanceVisibility", !state);
      return !state;
    });
  }, []);

  const getClientStatus = async () => {
    try {
      const tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");

      const response = await PostCardValidation.callApi({ tokenCartao });

      sessionStorage.setItem(
        "@NovaEra:statusCliente",
        response.data.statusCliente
      );
    } catch (err) {}
  };

  const handleConfirmUnblockModal = () => {
    setUnblockPopupVisibility(false);
    setPasswordPopupVisibility(true);
    setPasswordSlug("/cartao-codigo-verificacao");
  };

  const handleInvoice = () => {
    setPasswordPopupVisibility(true);
    setPasswordSlug("/cartao-fatura");
  };

  const ValidatePassword = async (senha) => {
    let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
    if (!tokenCartao)
      tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");

    const response = await RequestPostValidatePassword.callApi({
      senha: senha,
      tokenCartao: tokenCartao,
    });

    switch (response.status) {
      case 204:
        return { status: 204 };
      default:
        throw CustomizedError({
          message: response.data.mensagem || response.status,
        });
    }
  };

  const handlePasswordPopup = async (password) => {
    await ValidatePassword(password);
    const parentPath = history.location.pathname;
    history.push(passwordSlug, { password, parentPath });
  };

  const handleBalance = (value) => {
    if (value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
        .format(value)
        .replace("R$", "")
        .split(",");
    } else {
      return ["0", "00"];
    }
  };

  const formatBestDay = (date = "") => {
    const [day, month] = date.split("/");
    return `${day} de ${MONTHS[Number(month) - 1]}`;
  };

  const formatExpirationDate = (date = "") => date.split("/")[0];

  return (
    <>
      <LayoutHome>
        <S.Container>
          <ContainerBox
            title="Limite disponível"
            position="relative"
            styleContainer={{ gridArea: "BALANCE" }}
          >
            <Popover
              content={
                <>
                  <p style={{ marginBottom: 0 }}>Melhor data de compra</p>
                  <p>
                    <strong>{formatBestDay(transactions?.melhorDia)}</strong>
                  </p>
                  <p style={{ marginBottom: 0 }}>Vencimento todo dia</p>
                  <p style={{ marginBottom: 0 }}>
                    <strong>
                      {formatExpirationDate(transactions?.dataVencimento)}
                    </strong>
                  </p>
                </>
              }
              placement="bottomRight"
              trigger="click"
              visible={popoverVisibility}
              onVisibleChange={() => setPopoverVisibility(!popoverVisibility)}
            >
              <S.ExclamationIcon />
            </Popover>
            <S.Balance>
              <h5>
                R${" "}
                <strong>
                  {balanceVisibility
                    ? handleBalance(transactions?.saldo)[0]
                    : "●●●●●●"}
                </strong>
              </h5>
              {balanceVisibility && (
                <span>{handleBalance(transactions?.saldo)[1]}</span>
              )}
              <button type="button" onClick={handleBalanceVisibility}>
                {themes.components.icons.blind}
              </button>
            </S.Balance>
            {sessionStorage.getItem("@NovaEra:statusCliente") ===
              "INADIMPLENTE" && (
              <small style={{ color: "red" }}>Possui fatura em atraso</small>
            )}
          </ContainerBox>

          <S.HeaderIcons styleContainer={{ gridArea: "ICONS" }}>
            <S.HeaderIcon
              type="button"
              onClick={() => history.push("/transacoes")}
            >
              {themes.components.icons.money}
              <p>Extrato TeuCard</p>
            </S.HeaderIcon>

            {/* <S.HeaderIcon
              type='button'
              onClick={() => setUnblockPopupVisibility(true)}
            >
              {themes.components.icons.password}
              <p>Desbloquear cartão</p>
            </S.HeaderIcon> */}

            {/* <S.HeaderIcon type='button' onClick={handleInvoice}>
              {themes.components.icons.paperOrange}
              <p>2° via da fatura</p>
            </S.HeaderIcon> */}
          </S.HeaderIcons>

          <ContainerBox
            title="Atividades recentes"
            styleContainer={{ gridArea: "ACTIVITIES" }}
          >
            {transactions ? (
              <TransactionsClient transactions={transactions.transacoes} />
            ) : (
              <S.WrapperLoader>
                <Loader size={LOADER_TYPES.size.medium} />
              </S.WrapperLoader>
            )}
          </ContainerBox>

          <ContainerBox title="TeuCard" styleContainer={{ gridArea: "CARD" }}>
            <S.CardClientInfo>
              <CardClient
                number={formattedCardNumber}
                userName={user?.nomeTitular}
              />

              {userBalance ? (
                <LimitMeter
                  usedLimit={userBalance?.limiteUtilizado}
                  availableLimit={userBalance?.saldo}
                />
              ) : (
                <S.WrapperLoader>
                  <Loader />
                </S.WrapperLoader>
              )}
            </S.CardClientInfo>
          </ContainerBox>
        </S.Container>
      </LayoutHome>
      <TwoButtonPopup
        bodyText="Você quer desbloquear a senha do seu cartão?"
        leftButtonText="Cancelar"
        rightButtonText="Desbloquear"
        visibility={unblockPopupVisibility}
        setVisibility={setUnblockPopupVisibility}
        confirm={handleConfirmUnblockModal}
      />
      <PasswordPopup
        onClick={handlePasswordPopup}
        text="Continuar"
        slug={passwordSlug}
        visibility={passwordPopupVisibility}
        setVisibility={setPasswordPopupVisibility}
      />

      <OneButtonPopup
        onClick={() => {
          setPopUpNewsVisibility(false);
          localStorage.setItem(
            "@NovaEra:VersionApp",
            process.env.REACT_APP_VERSAO_APP
          );
        }}
        slug="/home"
        buttonText="Entendi"
        visibility={popUpNewsVisibility}
        setVisibility={setPopUpNewsVisibility}
        text="Novidades da versão"
      >
        <>
          <p>- Feature 1</p>
          <p>- Feature 2</p>
          <p>- Feature 3</p>
        </>
      </OneButtonPopup>
    </>
  );
};

export default Home;
