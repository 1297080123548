import React, { useState, useEffect, useContext } from "react";

import Button from "~/components/Button";
import * as S from "./styles";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import { useHistory } from "react-router-dom";
import themes from "~/themes";
import { GlobalContext } from "~/contexts/GlobalState";

const AlreadyClient = () => {
  const history = useHistory();
  const { onboarding } = useContext(GlobalContext);

  return (
    <LayoutOnboarding showBackGroundBar={false}>
      <S.Content>
        <S.MainText>
          <p>Criar carteira digital</p>
        </S.MainText>
        <S.MiddleText>
          <p>
            Olá, {onboarding?.nome}. Encontramos o teu cadastro! Na próxima
            tela, enviaremos um WhatsApp para o número: {onboarding?.phone}.
          </p>
        </S.MiddleText>
        <S.ImageLogo>
          <img
            src={themes.components.images.emojisLogin}
            alt="Logo CarteiraDigital"
          />
        </S.ImageLogo>
        <S.Footer>
          <Button
            onClick={() =>
              history.push("/onboarding-validar-sms-cliente-existente")
            }
          >
            Avançar
          </Button>
        </S.Footer>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default AlreadyClient;
