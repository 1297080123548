import styled from "styled-components";

import themes from "~/themes";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: 960px) {
    height: 100vh;
  }
`;

export const BackgroundBar = styled.div`
  position: absolute;
  /* top: 87px; */
  left: 5%;
  background: ${themes.colors.orange.n2};
  width: 90%;
  height: 60px;
  min-height: 40px;
  min-width: 600px;
  max-width: 1215px;
`;

export const Content = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;

  > main {
    flex: 1;
    margin: 0 11px;
  }

  @media (max-width: 960px) {
    height: 100% !important;

    > main {
      height: 100% !important;
      width: 100%;
      padding: 11px;
    }
  }
`;

export const SimpleContent = styled.div`
  z-index: 2;
  display: flex;
  width: auto;
  max-width: auto;
  height: 100vh;

  background: #fff;

  > main {
    margin: 0 auto;
    width: 448px;
  }

  @media (max-width: 960px) {
    > main {
      height: 100%;
      width: 100%;
    }
  }
`;
