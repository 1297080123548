// eslint-disable-next-line no-unused-vars
import React, { createContext, useState, useContext } from "react";
import { usuarioAPI } from "~/services/api";

const AuthContext = createContext({});

export const verifyUserIsLogged = () => {
  const auth = JSON.parse(localStorage.getItem("@NovaEra:isLogged"));

  if (auth) return auth?.isLogged;
  else return false;
};

const AuthProvider = ({ children }) => {
  const [isLogged, setIsLogged] = useState(verifyUserIsLogged());
  const [data, setData] = useState(() => {
    // Token de autenticação
    const token = localStorage.getItem("@NovaEra:token");
    // Token do número do cartão
    const tokenCard = localStorage.getItem("@NovaEra:tokenCartao");
    // Dados do usuário como: Nome, email, foto....
    const user = localStorage.getItem("@NovaEra:user");

    if (tokenCard && user) {
      // Configura o token no header das requisições
      // api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, tokenCard, user: JSON.parse(user) };
    }

    return {};
  });

  const handleChangeAuth = (value) => {
    setIsLogged(value);
    localStorage.setItem(
      "@NovaEra:isLogged",
      JSON.stringify({ isLogged: value })
    );
  };

  const signInGuest = async (cpf, password) => {
    const res = await usuarioAPI.post('/v1/usuarios/auth/login',
      { cpf: cpf.replace(/\.|-/g, ''), senha: password, }
    );

    const { token, novoToken } = res?.data;
    console.log(res?.data)
    localStorage.setItem("@NovaEra:onboardingToken", token);
    localStorage.setItem("@NovaEra:onboardingTokenBearer", novoToken);
    localStorage.setItem("@NovaEra:onboardingUser", res?.data);
  }

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        handleChangeAuth,
        isLogged,
        setIsLogged,
        signInGuest,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
