import styled, { css } from "styled-components";

import themes from "~/themes";

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 1%;
  }

  span {
    font-size: 15px;
    line-height: 30px;
  }

  img {
    width: 30%;
    margin-bottom: 5%;
  }
`;

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: baseline;
  justify-content: flex-start;
  width: 100%;
`;

export const ContentInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContentButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
`;

export const ResendCode = styled.div`
  width: 100%;
  display: flex;

  button {
    width: 14%;
    height: 100%;
    background: #0000;
    color: red;
    border: none;
    text-decoration: underline;
    opacity: ${(props) => (props.opacity ? "0.5" : "1")};
  }
`;

export const Message = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  max-width: 400px;

  p {
    font-size: 12px;
    font-weight: bold;
    line-height: 25px;
    color: #e71037;
    text-align: center;
  }
`;

export const HyperLink = styled.span`
  font-size: 16px;
  text-decoration: underline;
  color: red;
`;

export const WrapperLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;
