import React from "react";

import Button from "~/components/Button";
import * as S from "./styles";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import { useHistory } from "react-router-dom";
import themes from "~/themes";

const SuccessValidation = () => {
  const history = useHistory();

  return (
    <LayoutOnboarding showBackGroundBar={false}>
      <S.Content>
        <S.ImageLogo>
          <img id="sucess-validation" src={themes.components.images.successValidation} />
        </S.ImageLogo>
        <S.MainText>
          <p id="text-confirm">Validado com sucesso</p>
        </S.MainText>
        <S.MiddleText>
          Parabéns, agora você faz parte da carteira digital!
          <p>Faça o teu login e comece a cadastrar teus cartões!</p>
        </S.MiddleText>
        <S.Footer>
          <Button onClick={() => history.push("/login")}>Avançar</Button>
        </S.Footer>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default SuccessValidation;
