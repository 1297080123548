import styled from "styled-components";
import themes from "~/themes";

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  background-color: #fff;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: #4a4b4a;
  text-align: ${(props) => props.align};
  line-height: 40.52px;
  font-family: ${themes.components.fonts.marineRoundedBold};
  margin-top: 10px;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  color: #4a4b4a;
  font-family: ${themes.components.fonts.marineRoundedRegular};
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;
`;

export const Picture = styled.div`
  grid-area: camera;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0;

  @media screen and (max-width: 500px) {
    justify-content: center;
  }

  #picture-modal {
    margin-right: 20px;
  }

  button {
    display: flex;
    align-items: center;
    width: auto !important;
    height: 40px !important;
    margin: 0 20px 0 0 !important;
    svg {
      margin-right: 10px;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightRegular },
    },
  }) => fontWeightRegular};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};

`;

export const WrapperCamera = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;

  visibility: ${({ show }) => (show ? "visible" : "hidden")};
`;

export const ErrorMessage = styled.span`
  color: ${({
    theme: {
      colors: { red },
    },
  }) => red};
  font-size: 1.5rem;
`;

export const BoxCenter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    width: 200px;
    height: 200px;
  }
`;

export const BoxDescription = styled.div`
  width: 50%;
  background: #f7f7f7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 16px;

  div {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    gap: 1rem;

    span {
      font-size: 15px;
      align-items: center;
      line-height: 24px;
    }
  }
`;

export const Footer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.align};
  align-items: ${(props) => props.align};
  margin-top: 20px;
  div {
    width: 40%;
  }
`;

export const WrapperLoader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  align-items: center;
  width: 100%;
  height: 100%;
  min-height: ${(props) => (props.minHeight ? props.minHeight : "650px")};

  span {
    width: 30%;
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
    line-height: 27px;
    word-wrap: break-word;
    font-family: ${themes.components.fonts.marineRoundedBold};
    
  }
`;

