/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Layout from "~/components/Layout";
import ContainerBox from "~/components/ContainerBox";
import Loader, { LOADER_TYPES } from "~/components/Loader";
import { Link, useHistory } from "react-router-dom";
import useApi from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

import moment from "moment";

import * as St from "../../styles";
import themes from "~/themes";
const LoanContractedWithdraw = () => {
  const [contractWithdraw, setContractWithdraw] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));

  const RequestGetContractWithdraw = useApi(
    Endpoints.lendings.getContractWithdraw.replace("PARAM_CPF", user?.cpf),
    "GET"
  );

  const GetContractWithdraw = async () => {
    setIsLoading(true);
    try {
      const response = await RequestGetContractWithdraw.callApi();
      setContractWithdraw(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user?.cpf) {
      GetContractWithdraw();
    }
  }, [user.cpf]);

  return (
    <Layout showBackGroundBar={false}>
      <ContainerBox title="Contratos de empréstimos">
        {isLoading ? (
          <St.WrapperLoader>
            <Loader size={LOADER_TYPES.size.medium} />
          </St.WrapperLoader>
        ) : (
          <>
            {contractWithdraw.map((item) => (
              <St.RowInfo key={item.id}>
                <div>
                  <p>{`${moment(item.dataPagamento, "YYYY-MM-DD HH:mm").format(
                    "DD/MM/YYYY [as] HH:mm"
                  )}`}</p>
                </div>
                <Link
                  to={{
                    pathname: "/contract-voucher",
                    state: {
                      idEmprestimo: item.id,
                    },
                  }}
                >
                  {themes.components.icons.chevronRight}
                </Link>
              </St.RowInfo>
            ))}
            {contractWithdraw.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                }}
              >
                <St.Span>Não existe contratos!</St.Span>
              </div>
            )}
          </>
        )}
      </ContainerBox>
    </Layout>
  );
};

export default LoanContractedWithdraw;
