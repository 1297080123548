/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Button, { BUTTON_TYPES } from "~/components/Button";

import { moneyMask } from "~/utils/mask";
import { GlobalContext } from "~/contexts/GlobalState";
import themes from "~/themes";

import * as S from "../../styles";

const LeadsEmprestimo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const { simulationData } = state;
  const history = useHistory();

  const { addLending, lending } = useContext(GlobalContext);

  const Simulation = () => {
    setIsLoading(true);
    addLending({
      value: Number(simulationData?.valorLiberado),
      installments: 12,
      typeSimulation: simulationData?.tipoSimulacao,
      value_installment: simulationData?.valorParcela,
      credit_limit: simulationData?.limiteCredito,
      value_iof: simulationData?.valorIOF,
      taxaJuros: simulationData?.taxaJuros,
    });
    history.push("/emprestimo/dados-adicionais");
  };

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/home-produtos");
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, []);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <S.Content>
        <S.BoxData>
          <S.TitlePersonalData>Simulação</S.TitlePersonalData>
          <S.Subtitle>Que legal, encontramos tua última simulação:</S.Subtitle>
          <S.TypeContainer>
            <S.BoxSimulation>
              <S.SimulationValue>
                <label>Valor Simulado</label>
                <span>{moneyMask(simulationData?.valorLiberado)}</span>
              </S.SimulationValue>
              <S.Ornament src={themes.components.images.ornament} />
            </S.BoxSimulation>
            <S.BoxInfo>
              <S.Type>
                <S.TypeSimulation>
                  <label>Tipo de Simulação</label>
                  <span>
                    {simulationData?.tipoSimulacao === "VALOR_PARCELA"
                      ? "Valor da Parcela"
                      : simulationData?.tipoSimulacao === "CREDITO_LIMITE"
                      ? "Crédito de Limite"
                      : "Valor do empréstimo"}
                  </span>
                </S.TypeSimulation>
                {simulationData?.tipoSimulacao === "VALOR_PARCELA" ? (
                  <S.IconType src={themes.components.images.parcela} />
                ) : simulationData?.tipoSimulacao === "CREDITO_LIMITE" ? (
                  <S.IconType src={themes.components.images.limite} />
                ) : (
                  <S.IconType src={themes.components.images.saque} />
                )}
              </S.Type>
              <S.Type>
                <S.TypeSimulation>
                  <label>Limite de crédito</label>
                  <span>{moneyMask(simulationData?.limiteCredito)}</span>
                </S.TypeSimulation>
                <S.TypeSimulation>
                  <label>Parcela</label>
                  <span>{moneyMask(simulationData?.valorParcela)}</span>
                </S.TypeSimulation>
              </S.Type>
              <S.Type>
                <S.TypeSimulation style={{ alignItems: "flex-start" }}>
                  <label>Taxa de Juros</label>
                  <span>{`${simulationData?.taxaJuros}%`}</span>
                </S.TypeSimulation>
              </S.Type>
            </S.BoxInfo>
            <S.Warning>
              <span>
                ** O vencimento será de acordo com o vencimento da fatura do teu
                cartão de crédito
              </span>
            </S.Warning>
          </S.TypeContainer>
          <S.FooterSimulation>
            <div>
              <Button
                type={BUTTON_TYPES.outlined}
                onClick={() => history.push("/emprestimo/tipo-simulacao")}
              >
                Quero simular outro valor
              </Button>
            </div>

            <div>
              <Button isLoading={isLoading} onClick={Simulation}>
                Continuar
              </Button>
            </div>
          </S.FooterSimulation>
        </S.BoxData>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default LeadsEmprestimo;
