import React from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import { addDays, format, subDays } from "date-fns";
import "react-day-picker/lib/style.css";

import { Container, CalendarHeader, CalendarHeaderField } from "./styles";

const LIMIT_DAYS = 150;

const InputCalendar = ({ selectedRange, setSelectedRange }) => {
  const today = new Date();
  const disabledDays = { after: today, before: subDays(today, LIMIT_DAYS) };

  const handleDayClick = (date) => {
    const isOverDays = date > subDays(today, LIMIT_DAYS);
    const isNotFuture = date < addDays(today, 1);

    if (isOverDays && isNotFuture) {
      const range = DateUtils.addDayToRange(date, selectedRange);
      setSelectedRange(range);
    }
  };

  return (
    <Container>
      <CalendarHeader>
        <h6>Selecione a data</h6>
        <div>
          <CalendarHeaderField>
            <span>de</span>
            <p>
              {selectedRange.from
                ? format(selectedRange.from, "dd/MM/yyyy")
                : "selecione uma data"}
            </p>
          </CalendarHeaderField>
          <CalendarHeaderField>
            <span>até</span>
            <p>
              {selectedRange.to
                ? format(selectedRange.to, "dd/MM/yyyy")
                : "selecione uma data"}
            </p>
          </CalendarHeaderField>
        </div>
      </CalendarHeader>
      <DayPicker
        weekdaysShort={["D", "S", "T", "Q", "Q", "S", "S"]}
        fromMonth={subDays(today, LIMIT_DAYS)}
        toMonth={today}
        onDayClick={handleDayClick}
        modifiers={{
          selectedRangeStart: selectedRange.from,
          selectedRangeEnd: selectedRange.to,
          selectedRange: { ...selectedRange },
        }}
        disabledDays={disabledDays}
        months={[
          "Janeiro",
          "Feveiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ]}
      />
    </Container>
  );
};

export default InputCalendar;
