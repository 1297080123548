import React from 'react';

import { Container } from './styles';

const ContainerBox = ({
  title,
  styleContainer = {},
  children,
  gridRow = '',
  icon = '',
  position = 'static',
  withoutBoxShadow = false,
}) => (
  <Container style={styleContainer} position={position} gridRow={gridRow} withoutBoxShadow={withoutBoxShadow}>
    {title && (
      <div className='title-container'>
        <h4>{title}</h4>
        <div>{icon}</div>
      </div>
    )}
    {children}
  </Container>
);

export default ContainerBox;
