/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Layout from "~/components/Layout";
import { phoneMask, hidePhone } from "~/utils/mask";
import ModalOneButton from "~/components/Modals/OneButtonPopup";

import themes from "~/themes";
import * as S from "../styles";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

const LoadingCPF = () => {
  const [phone, setPhone] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const { state } = useLocation();
  const history = useHistory();

  const RequestGetNoAuthInvoice = useApi(
    Endpoints.cliente.getNoAuthInvoice
      .replace("PARAM_CPF", state?.cpf)
      .replace("PARAM_TENTATIVAS", 1),
    ApiMethod.GET
  );
  const getNoAuthInvoice = async () => {
    const response = await RequestGetNoAuthInvoice.callApi();
    const data = response.data;

    if (response.status >= 200 && response.status < 300) {
      if (data.celular) {
        setPhone(data.celular);
        sessionStorage.setItem(
          "@NovaEra:payloadFaturaNoAuth",
          JSON.stringify({
            ano: data.anoReferencia,
            mes: data.mesReferencia,
            cpf: state?.cpf,
            validaEmail: false,
          })
        );
        setTimeout(() => {
          history.push("/Fatura-Validate", {
            phone: data.celular,
            cpf: state?.cpf,
            email: data.emailMascarado,
          });
        }, 4000);
      }
    } else {
      setModalText(data.mensagem);
      setModalIsOpen(true);
    }
  };

  useEffect(() => {
    getNoAuthInvoice();
  }, []);

  return (
    <Layout auth={false}>
      <ModalOneButton
        ishtml
        text={modalText}
        buttonText="Ok"
        icon={themes.components.icons.tick}
        visibility={modalIsOpen}
        setVisibility={(bool) => setModalIsOpen(bool)}
        onClose={() => {
          history.push("/fatura-cpf");
        }}
        onClick={() => {
          history.push("/fatura-cpf");
        }}
      />
      <S.Content>
        <S.Main>
          <img src={themes.components.images.logoTeuCard} alt="Logo TeuCard" />
          <p>
            <strong>Enviado SMS</strong>...
          </p>
          {phone ? (
            <span>
              Aguarde! Estamos enviado um SMS para o número:{" "}
              <strong>{hidePhone(phoneMask(phone))}</strong>
            </span>
          ) : (
            <span>Aguarde! Estamos preparando o teu SMS...</span>
          )}
        </S.Main>
      </S.Content>
    </Layout>
  );
};

export default LoadingCPF;
