/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useLocation, useHistory } from "react-router-dom";

import Input from "~/components/Input";
import Button from "~/components/Button";
import Layout from "~/components/Layout";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";

import themes from "~/themes";
import * as S from "./styles";
import { hideEmailMask } from "~/utils/mask";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import ContainerBox from "~/components/ContainerBox";

export default function EmailPass() {
  const [email, setEmail] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [confirmationPopupVisibility, setOneButtonPopupVisibility] =
    useState(false);

  const { state } = useLocation();
  const history = useHistory();

  const RequestGetUserByCPF = useApi(
    Endpoints.usuario.getUserByCPF.replace("PARAM_CPF", state?.cpf),
    ApiMethod.GET
  );

  const RequestPostForgotPassword = useApi(
    Endpoints.email.forgotPassword.replace("PARAM_EMAIL", email),
    ApiMethod.POST
  );

  useEffect(() => {
    getEmail();
  }, []);

  async function getEmail() {
    try {
      const response = await RequestGetUserByCPF.callApi();
      setEmail(response.data.email);
    } catch (err) {
      setOneButtonPopupVisibility(true);
      setPopUpMessage(err.message);
    }
  }

  async function handleSubmit(evt) {
    evt.preventDefault();
    setButtonIsLoading(true);

    try {
      const response = await RequestPostForgotPassword.callApi();

      if (response.status >= 200 && response.status < 300) {
        setPopUpMessage('Email enviado com sucesso! Verifique sua caixa de entrada.');
        setModalIsOpen(true);
      }
    } catch (err) {
      setOneButtonPopupVisibility(true);
      setPopUpMessage(err.message);
    } finally {
      setButtonIsLoading(false);
    }
  }

  return (
    <Layout auth={false}>
        <S.Content>
          <h3>Esqueci minha senha</h3>
          <S.SectionForm onSubmit={handleSubmit}>
            <p>Orientações serão enviadas para o e-mail de cadastro abaixo:</p>

            <div style={{ position: "relative" }}>
              <Input
                type="text"
                label="E-mail"
                value={hideEmailMask(email)}
                onChange={(e) => setEmail(e.target.value)}
                disabled
              />
            </div>

            <Button
              type="submit"
              isLoading={buttonIsLoading}
            >
              Continuar
            </Button>

            <OneButtonPopup
              slug="/login"
              icon={themes.components.icons.tick}
              text="E-mail enviado com sucesso!"
              buttonText="Entendi"
              visibility={modalIsOpen}
              setVisibility={setModalIsOpen}
            />
            <OneButtonPopup
              defaultButton
              icon={themes.components.icons.cardBlocked}
              text={popUpMessage}
              buttonText="Entendi"
              visibility={confirmationPopupVisibility}
              setVisibility={(e) => {
                setOneButtonPopupVisibility(e);
                history.push("/login");
              }}
            />
          </S.SectionForm>
        </S.Content>
    </Layout>
  );
}
