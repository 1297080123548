/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import InputOnboarding from "~/components/InputOnboarding";
import Button, { BUTTON_TYPES } from "~/components/Button";
import Slider from "~/views/Lending/components/Slider";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import Loader from "~/components/Loader";

import { GlobalContext } from "~/contexts/GlobalState";
import { moneyMask, moneyBrazilian } from "~/utils/mask";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

import { messagesErrors } from "~/utils/validators";

import * as S from "../../styles";
import themes from "~/themes";

const Simulation = () => {
  const [values, setValues] = useState({
    min: "",
    max: "",
    selected: "0",
    title: "",
    text: "",
    indicator: "",
    default: "0",
  });
  const [input, setInput] = useState({
    formatted: "",
    unFormatted: "",
  });
  const [errorsInputs, setErrorsInputs] = useState({});
  const [messageErrorPopup, setMessageErrorPopup] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const history = useHistory();

  const { state } = useLocation();

  const { addLending, lending } = useContext(GlobalContext);

  const updateFormField = (value) => {
    const valueFloat =
      value.unmasked.toString().slice(0, value.unmasked.length - 2) +
      "." +
      value.unmasked.toString().slice(value.unmasked.length - 2);
    setInput({
      formatted: value.masked,
      unFormatted: parseFloat(valueFloat).toFixed(2),
    });
  };

  const updateSliderField = (value) => {
    setValues({
      ...values,
      selected: value.masked,
    });
  };

  const PostSimulationWithAmount = useApi(
    Endpoints.lendings.postAmountRelease,
    ApiMethod.POST
  );

  const PostSimulationWithInstallment = useApi(
    Endpoints.lendings.postInstallmentValue,
    ApiMethod.POST
  );

  const PostSimulationWithCreditLimit = useApi(
    Endpoints.lendings.postCreditLimit,
    ApiMethod.POST
  );

  const getInitialValues = () => {
    if (state.type === "SAQUE") {
      setValues(property.saque);
      setInput({
        unFormatted: property.saque.min,
        formatted: moneyMask(property.saque.min),
      });
    } else if (state.type === "LIMITE") {
      setValues(property.limite);
      setInput({
        unFormatted: property.limite.min,
        formatted: moneyMask(property.limite.min),
      });
    } else {
      setValues(property.parcela);
      setInput({
        unFormatted: property.parcela.min,
        formatted: moneyMask(property.parcela.min),
      });
    }
  };

  useEffect(() => {
    getInitialValues();
  }, []);

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/home-produtos");
    }
  };

  const lendingSimulation = async () => {
    setButtonIsLoading(true);
    if (input.unFormatted < values.min) {
      setButtonIsLoading(false);
      setShowPopup(true);
      setMessageErrorPopup("O valor inserido não corresponde ao valor mínimo.");
    } else if (
      input.unFormatted > values.min &&
      input.unFormatted > values.max
    ) {
      setButtonIsLoading(false);
      setShowPopup(true);
      setMessageErrorPopup("O valor inserido ultrapassou valor máximo.");
    } else {
      const payload = {
        cliente: {
          cpf: lending.cpf,
          nome: lending.nome,
          email: lending.email,
          celular: lending.phone,
        },
        simulacao: {
          installments: 12,
          value: Number(input.unFormatted),
        },
      };
      try {
        if (state.type === "SAQUE") {
          const response = await PostSimulationWithAmount.callApi(payload);

          if (response.status >= 200 && response.status < 300) {
            const data = response.data;
            setData(data);
            addLending({
              value: Number(input.unFormatted),
              installments: 12,
              credit_limit: data?.credit_limit,
              value_installment: data?.installment_value,
              value_iof: data?.iof_total,
              taxaJuros: data?.interest_rate,
            });
            history.push("/emprestimo/resultado-simulacao", {
              data: data,
            });
          } else {
            setShowPopup(true);
            setMessageErrorPopup(
              response.message ? response.message : "Erro ao simular"
            );
          }
        } else if (state.type === "LIMITE") {
          const response = await PostSimulationWithCreditLimit.callApi(payload);

          if (response.status >= 200 && response.status < 300) {
            const data = response.data;
            setData(data);
            addLending({
              value: Number(input.unFormatted),
              installments: 12,
              credit_limit: data?.credit_limit,
              value_installment: data?.installment_value,
              value_iof: data?.iof_total,
              taxaJuros: data?.interest_rate,
            });
            history.push("/emprestimo/resultado-simulacao", {
              data: data,
            });
          } else {
            setShowPopup(true);
            setMessageErrorPopup(
              response.message ? response.message : "Erro ao simular"
            );
          }
        } else {
          const response = await PostSimulationWithInstallment.callApi(payload);

          if (response.status >= 200 && response.status < 300) {
            const data = response.data;
            setData(data);

            addLending({
              value: Number(input.unFormatted),
              installments: 12,
              credit_limit: data?.credit_limit,
              value_installment: data?.installment_value,
              value_iof: data?.iof_total,
              taxaJuros: data?.interest_rate,
            });
            history.push("/emprestimo/resultado-simulacao", {
              data: data,
            });
          } else {
            setShowPopup(true);
            setMessageErrorPopup(
              response.message ? response.message : "Erro ao simular"
            );
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setButtonIsLoading(false);
      }
    }
  };

  const property = {
    saque: {
      min: 100,
      max: 50000,
      selected: 100,
      title: "Valor do Saque",
      text: "Quanto deseja sacar?",
      indicator:
        "Lembre-se, essa simulação só funciona com valores dentro do Teu limite de crédito disponível hoje.",
    },
    limite: {
      min: 100,
      max: 50000,
      selected: 100,
      title: "Valor do limite disponível",
      text: "Qual valor do limite do cartão deseja utilizar?",
      indicator:
        "Lembre-se, essa simulação só funciona com valores dentro do Teu limite de crédito disponível hoje.",
    },
    parcela: {
      min: 10,
      max: 5000,
      selected: 10,
      title: "Valor da parcela",
      text: "Qual valor de parcela mensal você deseja pagar?",
      indicator:
        "Lembre-se, essa simulação só funciona com valores dentro do Teu limite de crédito disponível hoje.",
    },
  };

  const resetSimulator = () => {
    if (state.type === "SAQUE") {
      setValues({
        ...values,
        selected: property.saque.min,
      });
      setData([]);
      setButtonIsDisabled(false);
      setInput({
        unFormatted: property.saque.min,
        formatted: moneyMask(property.saque.min),
      });
    } else if (state.type === "LIMITE") {
      setValues({
        ...values,
        selected: property.limite.min,
      });
      setData([]);
      setButtonIsDisabled(false);
      setInput({
        unFormatted: property.limite.min,
        formatted: moneyMask(property.limite.min),
      });
    } else {
      setValues({
        ...values,
        selected: property.parcela.min,
      });

      setData([]);
      setButtonIsDisabled(false);
      setInput({
        unFormatted: property.parcela.min,
        formatted: moneyMask(property.parcela.min),
      });
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, []);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={messageErrorPopup}
        buttonText="Entendi"
        visibility={showPopup}
        setVisibility={setShowPopup}
      />
      <S.Content>
        <S.BoxData>
          <S.TitlePersonalData>Valor da Simulação</S.TitlePersonalData>
          <S.Subtitle>
            Conta pra gente o <b>{values.title}</b> que você deseja simular:
          </S.Subtitle>

          <S.Column noBorder style={{ alignItems: "center", width: "100%" }}>
            <S.ContentValue style={{ alignItems: "center" }}>
              <S.SliderContainer>
                <Slider
                  min={values.min}
                  max={values.max}
                  selected={String(values.selected).replace("R$", "")}
                  value={input.unFormatted}
                  handleChange={(value) => {
                    updateSliderField(
                      moneyBrazilian(Number(value.target.value).toFixed(2))
                    );
                    updateFormField(
                      moneyBrazilian(Number(value.target.value).toFixed(2))
                    );
                  }}
                  step={10}
                />
              </S.SliderContainer>
              <S.FooterColumn>
                <S.InputContainer>
                  <InputOnboarding
                    type="text"
                    label="Valor"
                    placeholder="digite o valor"
                    largeLabel
                    value={input.formatted}
                    maxLength={15}
                    defaultValue={0}
                    error={errorsInputs.values}
                    step={1}
                    onChange={(e) => {
                      updateSliderField(moneyBrazilian(e.target.value));
                      updateFormField(moneyBrazilian(e.target.value));
                    }}
                  />
                </S.InputContainer>
                <S.MiddleText style={{ width: "100%" }}>
                  <img
                    src={themes.components.images.orangeCircle}
                    alt="Indicador"
                  />
                  <p>{values.indicator}</p>
                </S.MiddleText>
                <Button onClick={lendingSimulation} isLoading={buttonIsLoading}>
                  Simular
                </Button>
              </S.FooterColumn>
            </S.ContentValue>
          </S.Column>

          {/* <S.Column>
            <S.TitlePersonalData>Simulação</S.TitlePersonalData>
            <S.Subtitle>
              Tudo pronto! Libere agora esse valor na Tua conta bancária e
              aproveite.
            </S.Subtitle>
            <S.StarContainer>
              <img src={themes.components.images.star} alt="star" />
            </S.StarContainer>
            <S.DataContainer>
              <S.Data>
                <span>Valor</span>
                <p className="value">{moneyMask(data?.value_free)}</p>
              </S.Data>
              <S.Data>
                <span>Parcela</span>
                <p>{`12x de ${moneyMask(data?.installment_value)}`}</p>
              </S.Data>
              <S.Data>
                <span>Limite necessário no cartão</span>
                <p>{moneyMask(data?.credit_limit)}</p>
              </S.Data>
            </S.DataContainer>
            <S.FooterColumn>
              <Button
                type={BUTTON_TYPES.outlined}
                disabled={!buttonIsDisabled}
                onClick={resetSimulator}
              >
                Quero simular novamente
              </Button>
              <Button
                onClick={() => handleValidateField()}
                isLoading={loading}
                disabled={!buttonIsDisabled}
              >
                Quero contratar!
              </Button>
            </S.FooterColumn>
          </S.Column> */}
        </S.BoxData>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default Simulation;
