import React, { useEffect, useState } from "react";
import { availableProducts } from "../../services/products";
import Button from "~/components/Button";
import themes from "~/themes";
import * as S from "./styles";
import { useHistory } from "react-router-dom";
import ProductWithoutKnowMore from "~/components/ProductWithoutKnowMore";
import Loader from "~/components/Loader";

const HomeProdutos = () => {
  const history = useHistory();
  const [urlAvailableProducts, setUrlAvailableProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAllProducts = async () => {
    setIsLoading(true);
    const data = await availableProducts();
    if (data != null) {
      setIsLoading(false);
      setUrlAvailableProducts(data);
    } else {
      setUrlAvailableProducts([]);
    }
  };

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    getAllProducts();
  }, []);

  const listaProdutos = urlAvailableProducts.map((data) => (
    <S.ImageProduct>
      {/* {data.tipoProduto === "CARTEIRA_DIGITAL" && data.ativo === true && (
        <ProductWithoutKnowMore key={data.id} produto={data} />
      )} */}
      {data.tipoProduto === "TEUCARD" && data.ativo === true && (
        <ProductWithoutKnowMore key={data.id} produto={data} />
      )}
      {data.tipoProduto === "EMPRESTIMO" && data.ativo === true && (
        <ProductWithoutKnowMore key={data.id} produto={data} />
      )}
    </S.ImageProduct>
  ));

  return (
    <S.Content>
      <S.Main>
        <img src={themes.components.images.logoTeuCard} alt="Logo TeuCard" />

        <p>Por favor, escolha uma opção:</p>

        {isLoading && (
          <S.WrapperLoader>
            <Loader />
          </S.WrapperLoader>
        )}
        {!isLoading && urlAvailableProducts.length > 0 && (
          <ul>{listaProdutos}</ul>
        )}
        <S.Footer>
          {!isLoading && urlAvailableProducts.length > 0 && (
            <Button onClick={() => history.push("/")}>Voltar</Button>
          )}
        </S.Footer>
      </S.Main>
    </S.Content>
  );
};

export default HomeProdutos;
