import React, { useContext, useState, useMemo } from "react";

import Button from "~/components/Button";

import * as S from "../styles";
import themes from "~/themes";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import { useHistory } from "react-router-dom";
import InputOnboarding from "~/components/InputOnboarding";
import { GlobalContext } from "~/contexts/GlobalState";
import { cpfUnmask } from "~/utils/masksNew";
import { cpfMask } from "~/utils/mask";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import { useAuth } from "~/hooks/auth";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

const SearchCpf = () => {
  const history = useHistory();
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [confirmationPopupVisibility, setOneButtonPopupVisibility] =
    useState(false);
  const gctx = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const { addOnboarding } = useContext(GlobalContext);


  const user = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
  const { isLogged } = useAuth();

  const GetUserIdInfo = useApi(
    Endpoints.cliente.getUserIdentification.replace("PARAM_CPF", isLogged ? user?.cpf: cpfUnmask(document)).replace("PARAM_TIPO", sessionStorage.getItem("tipoProdutoSession")),
    ApiMethod.GET
  );

  async function getUserInfo() {
    setIsLoading(true);
    try {
      const retCpf = cpfUnmask(document);
      const dataAPI = await GetUserIdInfo.callApi();
      const data = dataAPI.data;
      const status = dataAPI.status;
      setUserInfo(data);

      if (status === 404) {
        setIsLoading(false);
        addOnboarding({ cpf: retCpf});
        history.push("/onboarding-validar-dados");
      } else if (data.produtoContratado === true) {
        setIsLoading(false);
        setOneButtonPopupVisibility(true);
      } else {
        addOnboarding({ cpf: isLogged ? user.cpf : retCpf, nome: data.nome, phone: data.celular });
        setIsLoading(false);
        history.push("/onboarding-cliente-existente");
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  const activeButtonLogin = useMemo(
    () => document.length === 14 || user?.cpf?.length === 11,
    [document, user]
  );

  return (
    <LayoutOnboarding showBackGroundBar={false}>
      <S.Content>
        <S.MainText>
          <p>Criar carteira digital</p>
        </S.MainText>
        <S.MiddleText>
          <p>Preencha o campo abaixo com o teu CPF, por favor.</p>
        </S.MiddleText>
        <S.Input>
          <InputOnboarding
            type="text"
            label="CPF"
            maxLength={14}
            value={isLogged ? cpfMask(user.cpf) : document}
            onChange={(e) => setDocument(cpfMask(e.target.value))}
            error={error}
            disabled={isLogged}
          />
        </S.Input>
        <S.Footer>
          <Button
            disabled={!activeButtonLogin}
            onClick={getUserInfo}
            isLoading={isLoading}
          >
            Continuar
          </Button>
        </S.Footer>
        <OneButtonPopup
          slug="/login"
          icon={themes.components.icons.warning}
          text={
            <span
              style={{ fontSize: "1em", fontWeight: 600, textAlign: "center" }}
            >
              Olá, vimos que você já é cliente da Carteira Digital! <br />
              Faça o login para acessar tua conta.
            </span>
          }
          buttonText="Entendi"
          visibility={confirmationPopupVisibility}
          setVisibility={setOneButtonPopupVisibility}
        />
      </S.Content>
    </LayoutOnboarding>
  );
};

export default SearchCpf;
