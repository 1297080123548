/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useState, useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Button, { BUTTON_TYPES } from "~/components/Button";
import { moneyMask } from "~/utils/mask";

import { GlobalContext } from "~/contexts/GlobalState";

import * as S from "../../styles";
import themes from "~/themes";
const ResultSimulation = () => {
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const { data } = state;

  const history = useHistory();

  const { lending } = useContext(GlobalContext);

  const goBack = () => {
    setLoading(true);
    history.goBack();
  };

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/home-produtos");
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, []);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <S.Content>
        <S.BoxData>
          <S.TitlePersonalData>Simulação</S.TitlePersonalData>
          <S.Subtitle>
            Tudo pronto! Libere agora esse valor na Tua conta bancária e
            aproveite.
          </S.Subtitle>
          <S.Column
            noBorder
            style={{
              alignItems: "center",
            }}
          >
            <S.ContentValue style={{ alignItems: "center" }}>
              <S.StarContainer>
                <img src={themes.components.images.star} alt="star" />
              </S.StarContainer>
              <S.DataContainer>
                <S.Data>
                  <span>Valor Liberado</span>
                  <p className="value">{moneyMask(data?.value_free)}</p>
                </S.Data>
                <S.Data>
                  <span>Parcela</span>
                  <p>{`12x de ${moneyMask(data?.installment_value)}`}</p>
                </S.Data>
                <S.Data>
                  <span>Taxa de Juros</span>
                  <p>{`${data?.interest_rate}%`}</p>
                </S.Data>
                <S.Data>
                  <span>Limite necessário no cartão</span>
                  <p>{moneyMask(data?.credit_limit)}</p>
                </S.Data>
                <S.Data>
                  <span style={{ color: "#E4003A", fontWeight: 700 }}>
                    ** O vencimento será de acordo com o vencimento da fatura do
                    teu cartão de crédito
                  </span>
                </S.Data>
              </S.DataContainer>
              <S.FooterColumn>
                <Button
                  onClick={() => history.push("/emprestimo/dados-adicionais")}
                >
                  Quero contratar!
                </Button>
                <S.Submit>
                  <span onClick={goBack}>Quero simular novamente</span>
                </S.Submit>
              </S.FooterColumn>
            </S.ContentValue>
          </S.Column>
        </S.BoxData>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default ResultSimulation;
