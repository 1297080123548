import styled from 'styled-components';
import Popup from 'reactjs-popup';

export const Container = styled(Popup)`
  &-content {
    width: 422px;
    padding: 0;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    .content-container {
      padding: 25px;
      align-items: center;
      justify-content: center;
      p {
        margin-top: 20px;
      }
    }

    hr {
      opacity: 0.5;
      margin-bottom: 20px;
    }

    .button-container {
      display: flex;
      justify-content: space-between;

      padding: 25px;

      button {
        width: 100%;
        font-size: 1.2em;
        background: #E71037;
        border: 2px solid #E71037;
        border-radius: 5px;
        color: #fff;

        padding: 7px 0px;
        margin-bottom: 20px;
      }
    }

    @media (max-width: 960px) {
      width: 100%;
      margin-left: 20px !important;
      margin-right: 20px !important;

      .content-container {
        padding: 20px;
      }
    }
  }
`;
