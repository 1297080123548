import React, { useEffect, useState } from "react";

import { Container, Content } from "./styles";
import themes from "~/themes";

const CardClient = ({ number, userName }) => {
  const [temaDoCartao, setTemaDoCartao] = useState(null);

  useEffect(() => {
    let tema = localStorage.getItem("@NovaEra:temaDoCartao");
    setTemaDoCartao(tema);
  }, []);

  return (
    <Container
      temaCard={
        temaDoCartao === "BLUE"
          ? themes.components.images.creditCardLogoBlue
          : temaDoCartao === "LUDICO"
          ? themes.components.images.creditCardLogoWhite
          : temaDoCartao === "ROSE"
          ? themes.components.images.creditCardLogoRose
          : themes.components.images.cardBackgroundTeuCard
      }
      style={{ opacity: number ? 1 : 0.6 }}
    >
      <Content
        temaCard={temaDoCartao === "LUDICO" && themes.components.colors.font}
      >
        <h6>{number || "●●●● ●●●● ●●●● ●●●●"}</h6>
        {userName && <p>{userName}</p>}
      </Content>
    </Container>
  );
};

export default CardClient;
