import styled from 'styled-components';

export const Container = styled.div`
  background: #f1f0f0; //#E71037
  padding: 25px;
  margin-bottom: 25px;
  width: 50%;
  position: relative;
  right: 0;

  p {
    margin: 0;
  }
`;
