import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import {
  Container,
  DateStyle,
  TransactionsDate,
  Transaction,
  TransactionDescription,
  TransactionValue,
  LineHorizontal,
  DivAlign,
  TransactionMoeda,
} from "./styles";
import themes from "~/themes";
import { GlobalContext } from "~/contexts/GlobalState";
import { convertBalance, convertDate } from "~/utils/converters";

function montaTipo(parcela_atual, quantidade_parcelas) {
  if (quantidade_parcelas > 1) {
    return `parcelas ${parcela_atual}/${quantidade_parcelas} `;
  } else {
    return "";
  }
}

const DigitalWalletExtrato = ({ transactions = [], displayMore = true }) => {
  const history = useHistory();
  const { addIdTransacao } = useContext(GlobalContext);

  const paymentHandler = (idTransacao) => {
    addIdTransacao(idTransacao);
    history.push("/carteira-digital-payment-voucher");
  };
  return (
    <Container>
      <TransactionsDate>
        {transactions?.map((transacao) => {
          const newDate = convertDate(transacao.dataCriacao);
          const extorno = transacao?.valor < 0;
          return (
            <div key={transacao.idTransacao}>
              <DateStyle style={{ borderBottom: "1.08006px solid #727272" }}>
                {newDate.mounted_date}
              </DateStyle>
              <div>
                <Transaction
                  onClick={() => paymentHandler(transacao.idTransacao)}
                >
                  {extorno ? (
                    <span>{themes.components.icons.plusIcon}</span>
                  ) : (
                    <span>{themes.components.icons.minusIcon}</span>
                  )}
                  <TransactionDescription>
                    {extorno ? (
                      <strong>ESTORNO NO CARTÃO DE CRÉDITO</strong>
                    ) : (
                      <strong>
                        COMPRA NO{" "}
                        {transacao.tipo === "CARTAO_CREDITO" &&
                          "CARTÃO DE CRÉDITO"}
                      </strong>
                    )}
                    <span>às {newDate.hour}h</span>
                  </TransactionDescription>
                  {extorno ? (
                    <TransactionMoeda>
                      <TransactionValue green>
                        <strong>
                          {convertBalance(transacao.valor).split(",")[0]}
                        </strong>
                        <span>
                          {convertBalance(transacao.valor)
                            .split(",")[1]
                            .replace("-R$", "")}
                        </span>
                        {/* <KnowMore  style={{color: 'black'}}>Saber mais</KnowMore> */}
                      </TransactionValue>
                      {/* <span>
                  {montaTipo(
                    operation.parcela_atual,
                    operation.quantidade_parcelas
                  )}
                </span> */}
                    </TransactionMoeda>
                  ) : (
                    <TransactionMoeda>
                      <TransactionValue>
                        <strong>
                          {convertBalance(transacao.valor).split(",")[0]}
                        </strong>
                        <span>
                          {convertBalance(transacao.valor)
                            .split(",")[1]
                            .replace("R$", "")}
                        </span>
                        {/* <KnowMore  style={{color: 'black'}}>Saber mais</KnowMore> */}
                      </TransactionValue>
                      {/* <span>
                  {montaTipo(
                    operation.parcela_atual,
                    operation.quantidade_parcelas
                  )}
                </span> */}
                    </TransactionMoeda>
                  )}
                </Transaction>
                <LineHorizontal>
                  <span />
                </LineHorizontal>
              </div>
            </div>
          );
        })}
      </TransactionsDate>
      <DivAlign displayMore={displayMore}>
        <Link to="/transacoes">Ver todas as transações</Link>
      </DivAlign>
      {transactions.length === 0 && (
        <DivAlign>
          <p>Não existe extratos</p>
        </DivAlign>
      )}
    </Container>
  );
};

export default DigitalWalletExtrato;
