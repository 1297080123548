import styled, { css } from "styled-components";
import themes from "~/themes";

export const Container = styled.div`
  flex: 0 1;
`;

const outlinedButton = css`
  background: transparent;
  border: 1px solid ${themes.components.colors.button};
  color: ${themes.components.colors.button};
`;

const defaultButton = css`
  background: ${(props) => (props.color ? "#7FA3BF" : themes.components.colors.button)};
  border: 0;
  color: ${themes.components.colors.buttonText};
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  font-size: 18px;

  margin-top: auto;
  height: 50px;
  border-radius: 8px;
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};

  ${(props) => (props.type === "outlined" ? outlinedButton : defaultButton)}
`;
