/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Loader from "~/components/Loader";
import InputBanking from "~/views/Lending/components/InputBanking";
import InputSelectSimple from "~/views/Lending/components/InputSelectSimple";
import InputOnboarding from "~/components/InputOnboarding";
import Button from "~/components/Button";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import Dropdown from "~/views/LendingBySearch/components/SelectDropdown";

import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import {
  cpfCnpjMask,
  phoneMask,
  cpfUnmask,
  cpfMask,
  phoneUnmask,
  accountBankMask,
} from "~/utils/masksNew";
import {
  phoneValidator,
  cpfCnpjValidator,
  emailValidator,
  randomValidator,
  accountBankValidator,
  documentCPFValidator,
  selectValidator,
} from "~/utils/validators";
import { GlobalContext } from "~/contexts/GlobalState";

import * as St from "../../styles";
import themes from "~/themes";

const INPUT_NAMES = {
  inputSearch: "inputSearch",
  typeAccount: "typeAccount",
  agency: "agency",
  account: "account",
  digit: "digit",
  phone: "phone",
  key: "key",
  email: "email",
  random: "random",
  cpfcnpj: "cpfcnpj",
};

const BankingWithdraw = () => {
  const [loading, setLoading] = useState(false);
  const [bankings, setBankings] = useState([]);
  const [allBankings, setAllBankings] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [messageErrorPopup, setMessageErrorPopup] = useState("");
  const [errorsInputs, setErrorsInputs] = useState({});
  const [openSelect, setOpenSelect] = useState(false);
  const [openSelectTypeAccount, setOpenSelectTypeAccount] = useState(false);
  const [openSelectKey, setOpenSelectKey] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [pixKey, setPixKey] = useState("");
  const [input, setInput] = useState({
    key: "",
  });
  const [form, setForm] = useState({
    account: "",
    agency: "",
    email: "",
    phone: "",
    cpfCnpj: "",
    random: "",
    digit: "",
  });
  const { lending, addLending } = useContext(GlobalContext);

  const GetBanksList = useApi(Endpoints.lendings.getBanks, ApiMethod.GET);

  const postContractedWithdraw = useApi(
    Endpoints.lendings.postContractWithdraw.replace(
      "PARAM_ID",
      lending?.idSimulacao
    ),
    ApiMethod.POST
  );

  const putUpdateBankingData = useApi(
    Endpoints.lendings.putUpdateBankingDataWithdraw.replace(
      "PARAM_ID",
      lending?.idSimulacao
    ),
    ApiMethod.PUT
  );

  const history = useHistory();
  const FetchBankingList = async () => {
    setLoading(true);

    try {
      const response = await GetBanksList.callApi();
      const { data } = response;

      if (data) {
        setBankings(response.data?.bancos);
        setAllBankings(response.data?.bancos);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBankingCode = (value) => {
    updateFormField(value, INPUT_NAMES.inputSearch);

    if (value?.length > 0) {
      setOpenSelect(true);
      const filter = allBankings.filter((banking) => {
        const r = `${banking.name}-${banking.code}`;
        return r.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });
      setBankings(filter);
    } else {
      setBankings(allBankings);
      setOpenSelect(false);
    }
  };

  const requestContractedLending = async () => {
    setButtonIsLoading(true);
    const payload = {
      origem: lending?.origem,
    };

    const body = {
      agencia: form?.agency,
      chavePix:
        input?.key?.code === "CHAVE_ALEATORIA"
          ? form.random
          : form?.phone.unmasked || form?.cpfCnpj.unmasked || form?.email,
      codigoBanco: parseInt(input?.inputSearch).toString(),
      contaDigito: form?.digit,
      contaSemDigito: form?.account,
      tipoChavePix: input?.key?.code,
      tipoConta: input?.typeAccount?.code,
    };

    try {
      const responseData = await putUpdateBankingData.callApi(body);

      if (responseData.status >= 200 && responseData.status < 300) {
        history.push("/emprestimo/validacao-cliente");
      }

      if (responseData.status > 400) {
        if (responseData.status >= 400) {
          setShowPopup(true);
          setMessageErrorPopup(
            responseData.mensagem
              ? responseData.mensagem
              : "Erro ao atualizar dados bancários!"
          );
          setButtonIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const activateButton = useMemo(
    () =>
      !form.account ||
      !form.agency ||
      !input?.key?.code ||
      !input?.typeAccount?.value ||
      !input?.inputSearch ||
      !pixKey,
    [form.agency, form.account, input, pixKey]
  );

  const handleValidateFields = () => {
    try {
      const fieldsForm = {
        agency: !form.agency && form.agency < 4 ? "Digite a agência" : "",
        account: !form.account ? "Digite uma conta" : "",
        digit: !form.digit ? "Informe o dígito" : "",
        bank: !input.inputSearch ? "Selecione o banco" : "",
        typeAccount: !input.typeAccount?.code
          ? "Selecione o tipo da conta"
          : "",
        key: !input.key.code ? "Selecione e chave PIX" : "",
        cpfCnpj:
          input.key?.code === "CPF"
            ? documentCPFValidator(form?.cpfCnpj?.masked)?.message || ""
            : null,
        phone:
          input?.key?.code === "CELULAR"
            ? phoneValidator(form?.phone.masked)?.message || ""
            : null,
        email:
          input?.key?.code === "EMAIL"
            ? emailValidator(form?.email)?.message || ""
            : null,
        random:
          input?.key?.code === "CHAVE_ALEATORIA"
            ? randomValidator(form?.random)?.message || ""
            : null,
      };

      setErrorsInputs(fieldsForm);

      if (
        (fieldsForm.agency === "" &&
          fieldsForm.account === "" &&
          fieldsForm.bank === "" &&
          fieldsForm.typeAccount === "" &&
          fieldsForm.key === "" &&
          fieldsForm.cpfCnpj === "") ||
        fieldsForm.email === "" ||
        fieldsForm.random === "" ||
        fieldsForm.phone === ""
      ) {
        addLending({
          conta: form.account,
          agencia: form.agency,
          tipoChavePix: input.key?.code,
          chavePix:
            form.cpfCnpj.unmasked ||
            form.email ||
            form.random ||
            form.phone.unmasked,
          codeBank: input.inputSearch,
        });
        requestContractedLending();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = () => {
    handleValidateFields();
  };

  const updateFormField = (value, id) => {
    setInput((prev) => ({ ...prev, [id]: value }));
  };

  const updateFormSelectType = (code, id, value) => {
    setInput((prev) => ({ ...prev, [id]: { code, value } }));
    setPixKey("");
    if (code === "CPF") {
      setForm({ ...form, cpfCnpj: cpfMask(lending?.cpf) });
      setPixKey(lending?.cpf);
    } else if (
      code === "CELULAR" ||
      code === "EMAIL" ||
      code === "CHAVE_ALEATORIA"
    ) {
      setForm((prev) => ({ ...prev, [id.toLowerCase()]: "" }));
    }
  };

  const updateFormSelect = (code, id, value) => {
    setInput((prev) => ({ ...prev, [id]: { code, value } }));
    if (id === "typeAccount") {
      setForm((prev) => ({ ...prev, typeAccount: value }));
    }
  };

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/");
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, [verifyReloadPage]);

  useEffect(() => {
    FetchBankingList();
  }, []);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={messageErrorPopup}
        buttonText="Entendi"
        visibility={showPopup}
        setVisibility={setShowPopup}
      />
      <St.Content>
        <St.BoxContent>
          {loading ? (
            <St.WrapperLoader>
              <Loader size="large" />
            </St.WrapperLoader>
          ) : (
            <>
              <St.Title>Dados bancários</St.Title>
              <St.Subtitle>
                Agora precisamos de informaçoes bancárias (para onde o teu
                dinheiro vai!):
              </St.Subtitle>
              <St.Steps>Passo 5 de 9</St.Steps>
              <St.Form>
                {/* <InputBanking
                  label="Código e Descrição do Banco*"
                  placeholder="Selecione o banco"
                  onChange={(e) => handleBankingCode(e.target.value)}
                  open={openSelect}
                  setOpen={setOpenSelect}
                  options={bankings}
                  id={INPUT_NAMES.inputSearch}
                  defaultValue={input?.inputSearch}
                  onClickSelect={updateFormField}
                  inputTextValue={input?.inputSearch}
                  error={errorsInputs.bank}
                /> */}
                <Dropdown
                  label="Código e Descrição do Banco*"
                  placeholder="Selecione o banco"
                  options={bankings}
                  onChange={(name) =>
                    updateFormField(name, INPUT_NAMES.inputSearch)
                  }
                  id={INPUT_NAMES.inputSearch}
                  open={openSelect}
                  setOpen={setOpenSelect}
                  selectedKey={input?.inputSearch}
                  error={errorsInputs.inputSearch}
                />

                {/* <InputSelectSimple
                  label="Tipo de conta*"
                  largeLabel
                  placeholder="Selecione o tipo de conta"
                  open={openSelectTypeAccount}
                  setOpen={setOpenSelectTypeAccount}
                  options={[
                    { code: "Conta Corrente", value: "CONTA_CORRENTE" },
                    { code: "Conta Poupança", value: "CONTA_POUPANCA" },
                  ]}
                  inputId={INPUT_NAMES.typeAccount}
                  onClickSelect={updateFormSelectType}
                  inputTextValue={input?.typeAccount?.code}
                  type="account"
                  error={errorsInputs.typeAccount}
                /> */}

                <Dropdown
                  label="Tipo de conta*"
                  placeholder="Selecione o tipo de conta"
                  options={[
                    { name: "Conta Corrente", code: "CONTA_CORRENTE" },
                    { name: "Conta Poupança", code: "CONTA_POUPANCA" },
                  ]}
                  onChange={(text, name) =>
                    updateFormSelect(text, INPUT_NAMES.typeAccount, name)
                  }
                  selectedKey={form?.typeAccount?.code}
                  open={openSelectTypeAccount}
                  setOpen={setOpenSelectTypeAccount}
                  id={INPUT_NAMES.typeAccount}
                  error={errorsInputs.typeAccount}
                />

                <InputOnboarding
                  type="text"
                  label="Agência*"
                  largeLabel
                  id={INPUT_NAMES.agency}
                  maxLength={4}
                  pattern="[0-9]*"
                  placeholder="0000"
                  value={form?.agency}
                  onChange={(e) => setForm({ ...form, agency: e.target.value })}
                  error={errorsInputs.agency}
                />
                <div style={{ display: "flex" }}>
                  <InputOnboarding
                    type="text"
                    label="Conta*"
                    largeLabel
                    id={INPUT_NAMES.account}
                    maxLength={20}
                    pattern="[0-9]*"
                    placeholder="00000000"
                    value={form?.account}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        account: e.target.value,
                      })
                    }
                    error={errorsInputs.account}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <InputOnboarding
                      type="text"
                      label="Dígito*"
                      largeLabel
                      id={INPUT_NAMES.digit}
                      placeholder={"0"}
                      pattern="[0-9]*"
                      maxLength={1}
                      value={form?.digit}
                      error={errorsInputs.digit}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          digit: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                {/* <InputSelectSimple
                  label="Tipo de chave PIX*"
                  largeLabel
                  placeholder="Selecione o tipo de chave"
                  open={openSelectKey}
                  options={[
                    { code: "CPF", value: "CPF" },
                    { code: "Celular", value: "CELULAR" },
                    { code: "E-mail", value: "EMAIL" },
                    { code: "Aleatória", value: "CHAVE_ALEATORIA" },
                  ]}
                  inputId={INPUT_NAMES.key}
                  setOpen={setOpenSelectKey}
                  onClickSelect={updateFormSelectType}
                  inputTextValue={input?.key?.code}
                  error={errorsInputs.key}
                /> */}
                <Dropdown
                  label="Tipo de chave PIX*"
                  placeholder="Selecione o tipo de chave"
                  open={openSelectKey}
                  setOpen={setOpenSelectKey}
                  options={[
                    { name: "CPF", code: "CPF" },
                    { name: "Celular", code: "CELULAR" },
                    { name: "E-mail", code: "EMAIL" },
                    { name: "Aleatória", code: "CHAVE_ALEATORIA" },
                  ]}
                  onChange={(text, name) =>
                    updateFormSelectType(text, INPUT_NAMES.key, name)
                  }
                />
                {input?.key?.code === "CPF" ? (
                  <InputOnboarding
                    label="CPF*"
                    largeLabel
                    maxLength={14}
                    value={form?.cpfCnpj.masked}
                    onChange={(e) => {
                      setForm({ ...form, cpfCnpj: cpfMask(e.target.value) });
                      setPixKey(e);
                    }}
                    error={errorsInputs.cpfCnpj}
                  />
                ) : null}
                {input?.key?.code === "CELULAR" ? (
                  <InputOnboarding
                    label="Celular*"
                    largeLabel
                    id={INPUT_NAMES.phone}
                    maxLength={15}
                    value={form?.phone?.masked}
                    onChange={(e) => {
                      setForm({ ...form, phone: phoneMask(e.target.value) });
                      setPixKey(e);
                    }}
                    error={errorsInputs.phone}
                  />
                ) : null}
                {input?.key?.code === "EMAIL" ? (
                  <InputOnboarding
                    label="E-mail*"
                    largeLabel
                    id={INPUT_NAMES.email}
                    value={form.email}
                    onChange={(e) => {
                      setForm({ ...form, email: e.target.value });
                      setPixKey(e);
                    }}
                    error={errorsInputs.email}
                  />
                ) : null}
                {input?.key?.code === "CHAVE_ALEATORIA" ? (
                  <InputOnboarding
                    label="Aleatória*"
                    largeLabel
                    value={form?.random}
                    onChange={(e) => {
                      setForm({ ...form, random: e.target.value });
                      setPixKey(e);
                    }}
                    error={errorsInputs.random}
                  />
                ) : null}
              </St.Form>
              <St.Footer align="right">
                <div>
                  <Button
                    onClick={onSubmit}
                    disabled={
                      activateButton ||
                      postContractedWithdraw.loading ||
                      putUpdateBankingData.loading
                    }
                    isLoading={buttonIsLoading}
                  >
                    Avançar
                  </Button>
                </div>
              </St.Footer>
            </>
          )}
        </St.BoxContent>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default BankingWithdraw;
