import styled from 'styled-components';

export const Wrapper = styled.div`
 display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 55%;
  max-width: 800px;
  margin: 0 auto;

   header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  h4 {
    display: flex;
    flex-direction: row;
    color: #4a4b4a;
    font-size: 20px;
    font-weight: bold;
    margin-left: -30%;
  }


  h6 {
    display: flex;
    color: #4a4b4a;
    font-size: 20px;
    font-weight: 600;
    margin-top: -3%;
  }

  
`;

export const InvoiceDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #EEEEEE;
  cursor:pointer;
  
  strong {
    color: #444444;
    font-size: 16px;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 40px;
  }

  > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    display: flex;
    height: 20px;
    
  }

   p {
    display: flex;
    flex-direction: row;
    color: #4A4B4A;
    margin-left: 250px;
    margin-top: -40px;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
  }

    svg {      
      margin-right: 2%;
      margin-bottom: -3px;
    }
  

  a {
    display: flex;
    flex-direction: row;
    color: #4A4B4A;
    margin-left: 500px;
    margin-top: -55px;
    font-weight: 500;
    font-size: 16px;
  }
`;


export const WrapperLoader = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  min-height: 300px;
`

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
export const CardClientInfo = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  height: auto;
  margin-bottom: 7%;
`;