import React from "react";
import { Link } from "react-router-dom";

import {
  Container,
  Date,
  TransactionsDate,
  Transaction,
  TransactionDescription,
  TransactionValue,
  LineHorizontal,
  DivAlign,
  TransactionMoeda,
} from "./styles";
import themes from "~/themes";

function montaTipo(parcela_atual, quantidade_parcelas) {
  if (quantidade_parcelas > 1) {
    return `parcelas ${parcela_atual}/${quantidade_parcelas} `;
  } else {
    return "";
  }
}

const TransactionsClient = ({ transactions = [], displayMore = true }) => (
  <Container>
    <TransactionsDate >
      {transactions?.map((transacao) => (
        <div key={Math.random()}>
          <Date style={{ borderBottom: '1.08006px solid #727272' }}>{transacao.dataTransacao}</Date>

          {transacao?.data?.map((operation) => {
            const isCredit = operation.debitoCredito === "CREDITO";
            const isPayment = operation.tipo === "PAGAMENTO_FATURA";
            const tipo = operation.tipo;
            const estabelecimento = operation.estabelecimento;
            const descricao = operation.descricao;
            const formattedText = "Pagamento de fatura"
            return (
              <div key={Math.random()}>
                <Transaction >
                  {isPayment === true ?
                    <span>{themes.components.icons.plusIcon}</span>
                    : <span>{themes.components.icons.minusIcon}</span>}
                  <TransactionDescription
                    isPayment={isPayment}
                    decoration={!!operation.dataEstorno}
                  >
                    <strong>
                      {tipo === "PAGAMENTO_FATURA"
                        ? formattedText
                        : tipo === "COMPRA"
                          ? estabelecimento
                          : tipo === "TARIFA"
                            ? descricao.toUpperCase()
                            : tipo}
                    </strong>
                    {tipo === "DESCONTO" && <span>CRÉDITO</span>}
                    {tipo === "COMPRA" && <span>{tipo}</span>}
                    {tipo === "ENCARGOS" && <span>{descricao}</span>}
                    
                  </TransactionDescription>
                  <TransactionMoeda>
                    <TransactionValue
                      decoration={!!operation.dataEstorno}
                      green={isCredit}
                    >
                      <span>{isCredit && "-"}R$</span>
                      <strong>{operation.valor.split(".")[0]}</strong>
                      <span>
                        {operation.valor.split(".")[1]}
                      </span>
                    </TransactionValue>
                    <span>
                      {tipo !== "TARIFA" ? montaTipo(
                        operation.parcela_atual,
                        operation.quantidade_parcelas
                      ) : null}
                    </span>
                  </TransactionMoeda>
                </Transaction>
                <LineHorizontal>
                  <span />
                </LineHorizontal>
              </div>
            );
          })}
          {
            
          }
        </ div>
      ))}
    </TransactionsDate>
    <DivAlign displayMore={displayMore}>
      <Link to="/transacoes">Ver todas as transações</Link>
    </DivAlign>
  </Container>
);

export default TransactionsClient;
