/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import TypeLending from "~/views/Lending/components/TypeLending";
import Button, { BUTTON_TYPES } from "~/components/Button";

import { GlobalContext } from "~/contexts/GlobalState";

import * as S from "../../styles";
import themes from "~/themes";

const SimulationType = () => {
  const history = useHistory();

  const handleClick = (type) => {
    history.push("/emprestimo/simulacao", {
      type: type,
    });
  };

  const goBack = () => {
    history.goBack();
  };

  const { addLending, lending } = useContext(GlobalContext);

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/home-produtos");
    }
  };

  const options = [
    {
      id: 1,
      title: "Valor do limite disponível",
      subtitle:
        "Escolher o quanto quero usar do meu limite no cartão de crédito",
      icon: themes.components.images.limite,
      type: "LIMITE",
    },
    {
      id: 2,
      title: "Valor da parcela",
      subtitle: "Escolher o quanto quer pagar por mês",
      icon: themes.components.images.parcela,
      type: "PARCELA",
    },
    {
      id: 3,
      title: "Valor do Saque",
      subtitle: "Escolher o quanto quero sacar           ",
      icon: themes.components.images.saque,
      type: "SAQUE",
    },
  ];

  useEffect(() => {
    verifyReloadPage();
  }, []);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <S.Content>
        <S.BoxData>
          <S.TitlePersonalData>Escolha o tipo de simulação</S.TitlePersonalData>
          <S.Subtitle>
            Agora é hora de escolher o melhor tipo de simulação para você:
          </S.Subtitle>
          <S.TypeContainer>
            {options.map((option, index) => {
              return (
                <TypeLending
                  title={option.title}
                  subtitle={option.subtitle}
                  icon={option.icon}
                  key={index}
                  onClick={() => handleClick(option?.type)}
                />
              );
            })}
          </S.TypeContainer>
          <S.FooterData>
            <div>
              <Button type={BUTTON_TYPES.outlined} onClick={goBack}>
                Voltar
              </Button>
            </div>
          </S.FooterData>
        </S.BoxData>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default SimulationType;
