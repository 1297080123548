import React from "react";

import InputOnboarding from "~/components/InputOnboarding";

import { FiChevronDown } from "react-icons/fi";

import * as S from "./styles";

const InputSelectSimple = ({
  label,
  inputId,
  placeholder,
  onBlur,
  inputTextValue,
  onChange,
  error,
  onClickSelect,
  setOpen,
  open,
  options,
  type,
}) => {
  const onClickSelectOption = (text, id) => {
    onClickSelect(text, inputId, id);
    setOpen(false);
  };

  return (
    <S.Container>
      <S.InputContainer>
        <InputOnboarding
          label={label}
          id={inputId}
          largeLabel
          placeholder={placeholder}
          onBlur={onBlur}
          value={inputTextValue}
          onChange={(text, name) => onChange(text, name)}
          onClick={() => setOpen(!open)}
        />

        <S.IconContainer onClick={() => setOpen(!open)}>
          <FiChevronDown
            color="#E4003A"
            size={26}
            style={{
              transform: [
                {
                  rotate: open ? "180deg" : "0deg",
                },
              ],
            }}
          />
        </S.IconContainer>
      </S.InputContainer>
      {open && (
        <S.ContainerOptions type={type}>
          <div className="option">
            {options?.map((option, index) => (
              <>
                <S.Option
                  key={index}
                  onClick={() => onClickSelectOption(option.code, option.value)}
                >
                  <S.ContainerItems key={index}>
                    <S.OptionLabel>{option?.code}</S.OptionLabel>
                  </S.ContainerItems>
                </S.Option>
                {index !== options?.length - 1 && <S.Divider />}
              </>
            ))}
          </div>
        </S.ContainerOptions>
      )}
      {!!error && <S.ErrorText>{error}</S.ErrorText>}
    </S.Container>
  );
};

export default InputSelectSimple;
