import React, { createContext, useEffect, useReducer } from "react";
import AppReducer from "./reducers/GlobalStateReducer";

// Initial state
const initialState = {
  user: {},
  loggedUser: {},
  transactions: [],
  walletDigital: {
    cardNumberSelected: "",
    idTransacao: "",
    cardId: "",
  },
  errorMessage: "",
  onboarding: {
    tipoProduto: "",
    cpf: "",
    nome: "",
    phone: "",
    email: "",
    idProduto: "",
    verificationCode: "",
    cep: "",
    cidade: "",
    endereco: "",
    numero: "",
    complemento: "",
    bairro: "",
    uf: "",
    dataNascimento: {},
    genero: "",
  },
  lending: {
    idCliente: "",
    cpf: "",
    nome: "",
    phone: "",
    email: "",
    dataNascimento: "",
    cep: "",
    city: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    uf: "",
    document: "",
    mother: "",
    father: "",
    codeBank: "",
    agency: "",
    account: "",
    value: "",
    installments: "",
    credit_limit: "",
    typeSimulation: "",
    value_iof: "",
    value_installment: "",
    taxaJuros: "",
    statusCartao: "",
    limiteSaqueDisponivel: "",
    valorSaqueMaximo: "",
    valorSaqueMinimo: "",
    payment_amount: "",
    requested_amount: "",
    iof_amount: "",
    last_payment_date: "",
    financed_amount: "",
    num_periods: "",
    idSimulacao: "",
    origem: "",
    tipoChavePix: "",
    chavePix: "",
    disbursement_date: "",
    financeFee: 0,
    first_payment_date: "",
    monthly_effective_interest_rate: "",
    tac_amount: "",
    dataEmissaoDocumento: "",
    issuingAgency: "",
    typeDoc: "",
    statusSignature: "",
    selfieAgent: "",
    geolocation: "",
    xCode: "",
    occupation: "",
    profession: "",
    currentIncome: "",
    dayOfPayment: "",
    civilStatus: "",
    schooling: "",
    gender: "",
    creditReason: "",
    emissionDocumentUf: "",
    typeResidential: "",
    timeResidential: "",
    typeAccount: "",
    idEasyCredito: "",
  },
  documentImage: { file: "", type: "", idProposta: "" },
};

// Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
    addUser(userInfo);
  }, []);

  // Actions
  function addUser(user) {
    dispatch({
      type: "ADD_USER",
      payload: user,
    });
  }

  function addLoggedUser(user) {
    dispatch({
      type: "ADD_LOGGED_USER",
      payload: user,
    });
  }

  function addTransaction(transaction) {
    dispatch({
      type: "ADD_TRANSACTION",
      payload: transaction,
    });
  }

  function addIdTransacao(idTransacao) {
    dispatch({
      type: "ADD_ID_TRANSACAO",
      payload: idTransacao,
    });
  }

  function cardNumberSelectedHandler(cardNumber) {
    dispatch({
      type: "CARD_NUMBER_SELECTED_HANDLER",
      payload: cardNumber,
    });
  }

  function addErrorMessage(message) {
    dispatch({
      type: "ADD_ERROR_MESSAGE",
      payload: message,
    });
  }

  function addOnboarding(onboarding) {
    dispatch({
      type: "ADD_ONBOARDING",
      payload: onboarding,
    });
  }

  function addLending(lending) {
    dispatch({
      type: "ADD_LENDING",
      payload: lending,
    });
  }

  function addDocumentImage(documentImage) {
    dispatch({
      type: "ADD_DOCUMENT_IMAGE",
      payload: documentImage,
    });
  }

  function cardIdSelectedHandler(cardId) {
    dispatch({
      type: "CARD_ID_SELECTED_HANDLER",
      payload: cardId,
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        user: state.user,
        loggedUser: state.loggedUser,
        transactions: state.transactions,
        walletDigital: state.walletDigital,
        errorMessage: state.errorMessage,
        onboarding: state.onboarding,
        lending: state.lending,
        documentImage: state.documentImage,
        addDocumentImage,
        addOnboarding,
        addLending,
        addUser,
        addLoggedUser,
        addTransaction,
        cardNumberSelectedHandler,
        addIdTransacao,
        addErrorMessage,
        cardIdSelectedHandler,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
