import styled from "styled-components";

import themes from "~/themes";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerInput = styled.div`
  width: 300px;
`;

export const ImageUser = styled.div`
  margin: 20px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;

  img {
    height: 110px;
    width: 110px;
    border-radius: 55px;
  }

  span {
    margin-top: 8px;
    color: ${themes.components.colors.link};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.52px;
    line-height: 17px;
    text-decoration: none;
    cursor: pointer;
  }

  #input-image {
    display: none;
  }
`;

export const RowInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  padding: 11px 4px;
  border-bottom: 1px solid #eaeaea;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      color: ${themes.components.colors.label};
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
    }

    p {
      color: ${themes.components.colors.input};
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      margin: 4px 0 0;

      width: 700px;
      height: 35px;
      border-radius: 10px;
      padding-left: 10px;
      display: flex;
      align-items: center; 
    }
  }

  a {
    text-decoration: none;
    background: transparent;
    border: 0;
    margin-left: auto;

    svg {
      height: 15px;
      width: auto;
    }
  }
`;

export const ButtonNextPage = styled.button`
  display: flex;
  justify-content: center;

  font-size: 16px;
  color: ${themes.components.colors.buttonText};

  margin-top: auto;
  padding: 16px 0;
  border: 0;
  border-radius: 8px;
  background: ${themes.components.colors.button};
`;

export const WrapperLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

export const Info = styled.span`
  width: min(700px, 100%);
  margin-top: 20px;
  color: ${themes.colors.orange.n2};
  font-size: 1rem;
`;
