import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import Button from "~/components/Button";

import { GlobalContext } from "~/contexts/GlobalState";

import * as St from "../../styles";
import themes from "~/themes";
const SuccessWithdraw = () => {
  const history = useHistory();

  const { state } = useLocation();

  const { lending } = useContext(GlobalContext);

  const verifyReloadPage = () => {
    if (lending.idSimulacao === "") {
      history.push("/");
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, [verifyReloadPage]);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <St.Content>
        <St.Box>
        <St.Steps>Passo 11 de 11</St.Steps>
          <St.BoxSuccess>
            <St.ImageLogo>
              <img
                id="sucess-validation"
                src={themes.components.images.successValidation}
                alt="sucesso"
              />
            </St.ImageLogo>
            <St.Title>
              {state?.onCancelSuccess
                ? "Cancelamento realizado com sucesso!"
                : "Contratação realizada com sucesso."}
            </St.Title>
            <St.Description>
              {state?.onCancelSuccess === "CANCELADO" ? (
                <span>O cancelamento do teu saque TeuCard foi aprovada!</span>
              ) : (
                <span>
                  Parabéns! Em breve o dinheiro estará na tua conta. Acompanhe o
                  status e fique atento ! O prazo máximo para pagamento é de até
                  24 horas!
                </span>
              )}
            </St.Description>
          </St.BoxSuccess>
          <St.Footer align="right">
            <div>
              <Button onClick={() => history.push("/home")}>
                Voltar à página inicial
              </Button>
            </div>
          </St.Footer>
        </St.Box>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default SuccessWithdraw;
