import React, { memo, useEffect } from 'react';
import { usePagination } from '~/hooks/usePagination';

import * as St from './styles';

const Pagination = ({
  totalPages,
  onCurrentPageChange,
}) => {
  const {
    currentPage,
    pages,
    onClickPage,
    goBack,
    goNext,
    goToFirstPage,
    goToLastPage,
    firstRender,
  } = usePagination(totalPages);

  useEffect(() => {
    if (!firstRender) onCurrentPageChange(currentPage);
  }, [currentPage]);

  return (
    <div style={{ display: 'flex' }}>
      <St.CustomTabs onClick={goToFirstPage}>Início</St.CustomTabs>
      <St.CustomTabs onClick={goBack}>{'<'}</St.CustomTabs>

      {pages.map((page) => (
        <St.Tabs
          onClick={() => onClickPage(page)}
          selected={currentPage === page}
        >
          {page}
        </St.Tabs>
      ))}

      <St.CustomTabs onClick={goNext}>{'>'}</St.CustomTabs>
      <St.CustomTabs onClick={goToLastPage}>Último</St.CustomTabs>
    </div>
  );
};

export default memo(Pagination);
