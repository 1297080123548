import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import Layout from "~/components/Layout";
import ContainerBox from "~/components/ContainerBox";

import * as St from "../../styles";
import themes from "~/themes";
const LoanVoucherAndContracted = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { state } = useLocation();

  console.log("state", state);

  return (
    <Layout showBackGroundBar={false}>
      <ContainerBox title="Documentos">
        <St.RowInfo>
          <div>
            <p>Quero visualizar o contrato</p>
          </div>
          <Link
            to={{
              pathname: "/view-contract",
              state: {
                idEmprestimo: state.idEmprestimo,
                type: "contract",
              },
            }}
          >
            {themes.components.icons.chevronRight}
          </Link>
        </St.RowInfo>
        <St.RowInfo>
          <div>
            <p>Quero visualizar o comprovante</p>
          </div>
          <Link
            to={{
              pathname: "/view-contract",
              state: {
                idEmprestimo: state.idEmprestimo,
                type: "voucher",
              },
            }}
          >
            {themes.components.icons.chevronRight}
          </Link>
        </St.RowInfo>
      </ContainerBox>
    </Layout>
  );
};

export default LoanVoucherAndContracted;
