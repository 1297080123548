import React from "react";
import * as St from './styles'
import themes from "~/themes";

const DownloadFatura = () => {
    return (
        <St.Container>
            Baixar fatura
            <St.Icons>
                {themes.components.icons.setaViradoParaBaixo}
                {themes.components.icons.tracoCurvo}
            </St.Icons>
        </St.Container>
    )
}

export default DownloadFatura