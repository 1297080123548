import styled from 'styled-components';

export const Container = styled.div`
  width: 340px;
  margin-right: 20px;
  margin-bottom: 20px;
`;

export const ProductImage = styled.div`
  display: flex;
  width: 340px;
  height: 150px;
  background-size: cover;  
  flex-direction: column;
  border-radius: 2.1613px;
  background-image: url('${(p) => p.imageUrl}');
`;

export const ProductFuncoes = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 15.6%, rgba(0, 0, 0, 0.52) 53.45%);
  width: 340px;
  height: 150px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 100px;
  padding-left: 10px;
  padding-right: 10px;
`;
export const ProductInformation = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 18px;
  color: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const ProductButton = styled.button`
  height: 20.56px;
  width: 110px;
  background: #E40A3B;
  border: 0.801541px solid #FFFFFF;
  border-radius: 24.8478px;
  border-style: none;
  font-weight: 700;
  margin-top: 22px;
`;
