export const cpfMask = (value) =>
  value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

export const phoneMask = (value) => {
  const newValue = value.replace(/\D/g, "").replace(/^(\d{2})(\d)/g, "($1) $2");
  // Formata os caracteres após o hífen do telefone
  if (newValue.length > 6) return newValue.replace(/(\d{5})(\d{1,4})/, "$1-$2");

  return newValue;
};

export const rgUnmask = (value) => value.replace(/\D/gim, "");

export const rgMask = (value) => {
  const maskedValue = value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{1})/,
    "$1.$2.$3-$4"
  );

  return defaultReturn(maskedValue, value.replace(/\D/g, ""));
};

export const dateMask = (value) => {
  // Formata os campos dia(dd) e mês(MM) da string
  const newValue = value.replace(/\D/g, "").replace(/^(\d{2})(\d)/g, "$1/$2");
  // Formata a parte final da string, o campo ano(YYYY)
  if (newValue.length > 4) return newValue.replace(/(\d{2})(\d{1,2})/, "$1/$2");

  return newValue;
};

export const cepUnmask = (value = "") => value.replace(/\D/gim, "");

export const cepMask = (value = "") => {
  const maskedValue = value
    .replace(/\D/g, "")
    .replace(/^([\d]{2})\.*([\d]{3})-*([\d]{3})/, "$1$2-$3");

  return maskedValue;
};

export const cardNumberMask = (value) => {
  // Remove os espaços em branco
  const newValue = value.replace(/\D/g, "");
  const sizeValue = value.length;
  // Formata Grupo 1: Até o caractere 9 '#### ####'
  if (sizeValue <= 9) return newValue.replace(/(\d{4})(\d{1,4})/g, "$1 $2");
  // Formata Grupo 2: Até o caractere 12 '#### #### ####'
  else if (sizeValue <= 14)
    return newValue.replace(/(\d{4})(\d{4})(\d{1,4})/g, "$1 $2 $3");
  // Formata Grupo 3: Formatação completa '#### #### #### ####'
  return newValue.replace(/(\d{4})(\d{4})(\d{4})(\d{1,4})/g, "$1 $2 $3 $4");
};

// Due Date Mask
export const dueDateUnmask = (value) => value.replace(/\D/g, "");

export const dueDateMask = (value = "") => {
  value = value
    .replace(/\D/g, "")
    .replace(/(\d{2})/g, "$1/")
    .replace(/\/$/, "");

  if (value.substring(0, 2) <= 31)
    return defaultReturn(value, dueDateUnmask(value));
  else if (value.substring(0, 2) > 31) {
    value = value.replace(/\D/g, "");
    return defaultReturn("");
  }

  return;
};
const defaultReturn = (masked, unmasked) => ({
  masked: masked,
  unmasked: unmasked,
});

export const verificationCodeMask = (value) => {
  return value.replace(/\D/g, "").replace(/^(\d{3})(\d{1,3})/g, "$1 $2");
};

export const verificationCodeUnmask = (value) => value.replace(" ", "");

export const formatCardNumber = (num) =>
  `${num.slice(0, 4)} ${num.slice(4, 8)} ${num.slice(8, 12)} ${num.slice(12)}`;

export const numericMask = (value) => value.replace(/\D/g, "");

export const hideEmailMask = (value = "") => {
  const [prefix, provider] = value.split("@");

  const prefixToHide = prefix.slice(2);

  return value.replace(prefixToHide, "*".repeat(prefixToHide.length));
};

export const moneyMask = (value = 0.0) =>
  new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
  }).format(value);

export const moneyBrazilian = (value) => {
  console.log(value)
  
  const maskedValue = value
    ?.replace(/\D/g, "")
    ?.replace(/^0+/, "")
    ?.replace(/(\d{1,})(\d{2})$/, "$1,$2")
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return defaultReturn("R$ " + maskedValue, value?.replace(/\D/g, ""));
};

export const moneyFormatReal = (e) => {
const onlyDigits = e.target.value
    .split("")
    .filter(s => /\d/.test(s))
    .join("")
    .padStart(3, "0")
  const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
  e.target.value = maskCurrency(digitsFloat)
}

const maskCurrency = (valor, locale = 'pt-BR', currency = 'BRL') => {
  const formattedValue = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  }).format(valor)

  return defaultReturn(formattedValue, valor?.replace(/\D/g, ""));
}

export const currencyMask = (param) => {
  if (!param) return "0,00";

  let value = "";

  if (typeof param === "number") {
    param = param.toFixed(2);
  }

  if (String(param).includes(".") || String(param).includes(",")) {
    value = String(param);
  } else {
    value = `${String(param)}.00`;
  }

  value = String(value).replace(".", "").replace(",", "");

  const v = `${(value / 100).toFixed(2)}`.split(".");

  const m = v[0]
    .split("")
    .reverse()
    .join("")
    .match(/.{1,3}/g);

  for (let i = 0; i < m.length; i += 1)
    m[i] = `${m[i].split("").reverse().join("")}.`;

  const r = m.reverse().join("");

  const masked = `${r.substring(0, r.lastIndexOf("."))},${v[1]}`;

  return masked;
};

export const hidePhone = (value = "") => {
  let phoneFormat = phoneMask(value);
  let hidePhone = [];
  for (let i = 0; i < phoneFormat.length; i++) {
    if (i <= 2) {
      hidePhone.push(phoneFormat[i]);
    } else if (i === 4) {
      hidePhone.push(phoneFormat[i]);
    } else if (i >= 8 && i < phoneFormat.length - 5) {
      hidePhone.push("*");
    } else if (i >= 11 && i < phoneFormat.length - 2) {
      hidePhone.push("*");
    } else {
      hidePhone.push(phoneFormat[i]);
    }
  }
  return hidePhone.join("");
};

export const convertDateMode3 = (originalDate) => {
  const date = new Date(originalDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
};

