import styled from "styled-components";
import { MdClose, MdSwitchCamera } from "react-icons/md";

export const IconClose = styled(MdClose)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10000;
  cursor: pointer;

  path {
    color: #fff;
  }
`;

export const Chronometer = styled.div`
  position: absolute;
  top: 3vh;
  left: 7vw;
  z-index: 10000;
  font-size: 5rem;
  font-weight: bold;
  color: red;
`;

export const CertifaceMessageSteps = styled.img`
  max-height: 80px;
  position: absolute;
  z-index: 100000;
  left: 0;
  right: 0;
  top: 0px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
`;

export const ChangeCamera = styled.span`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 20px;
  display: flex;
  justify-content: center;
  z-index: 10000;
`;

export const IconChangeCamera = styled(MdSwitchCamera)`
  cursor: pointer;

  path {
    color: #fff;
  }
`;

export const Camera = styled.video`
  position: absolute;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  object-fit: fill;
  ${({ mirroring }) => mirroring && "transform: rotateY(180deg);"}
`;

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;

export const Button = styled.div`
  z-index: 10000;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: white;
  border: 10px solid grey;
  bottom: 20px;
`;
