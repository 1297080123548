import React, { useContext, useState, useEffect } from "react";

import Button from "~/components/Button";
import { OneButtonPopup } from "~/components/Modals/OneButtonPopup";

import * as S from "../styles";
import { numericMask } from "~/utils/mask";
import themes from "~/themes";
import LayoutOnboarding from "~/components/LayoutOnboarding";
import { useHistory } from "react-router-dom";
import InputOnboarding from "~/components/InputOnboarding";
import { propostaAPI } from "~/services/api";
import { GlobalContext } from "~/contexts/GlobalState";
import {
  passwordConfirmationValidator,
  passwordValidator,
} from "~/utils/validators";

const CreatePassword = () => {
  const history = useHistory();

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [confirmationPopupVisibility, setOneButtonPopupVisibility] =
    useState(false);
  const [textPopup, setTextPopup] = useState("");
  const [errorsInputs, setErrorsInputs] = useState({});

  const gctx = useContext(GlobalContext);
  const { onboarding } = useContext(GlobalContext);

  const cpf = gctx.onboarding.cpf;
  const nome = gctx.onboarding.nome;
  const phone = gctx.onboarding.phone;
  const email = gctx.onboarding.email;
  const endereco = gctx.onboarding.endereco;
  const bairro = gctx.onboarding.bairro;
  const cep = gctx.onboarding.cep;
  const cidade = gctx.onboarding.cidade;
  const complemento = gctx.onboarding.complemento;
  const numero = gctx.onboarding.numero;
  const uf = gctx.onboarding.uf;
  const genero = gctx.onboarding.genero;
  const idProduto = gctx.onboarding.idProduto;
  const dataNascimento = gctx.onboarding.dataNascimento;

  const handleDisabledButton = () => !password || !passwordConfirmation;

  const createUser = async () => {
    const fieldsForm = {
      password: passwordValidator(password).message || "",
      passwordConfirmation:
        passwordConfirmationValidator(password, passwordConfirmation).message ||
        "",
    };
    setErrorsInputs(fieldsForm);
    if (!fieldsForm.password && !fieldsForm.passwordConfirmation) {
      try {
        const response = await propostaAPI.post(
          "/v1/ficha-proposta/noauth/criar-proposta-carteira",
          {
            celular: phone,
            checkWhatsApp: true,
            cpf: cpf,
            dataNascimento: dataNascimento,
            email: email,
            endereco: {
              bairro: bairro,
              cep: cep,
              cidade: cidade,
              complemento: complemento,
              logradouro: endereco,
              numero: numero,
              pais: "BRASIL",
              uf: uf,
            },
            genero: genero,
            idProduto: idProduto,
            nome: nome,
            optinTermoDeUso: true,
            senha: password,
          }
        );
        if (response.status != 200) {
          setTextPopup(
            response.mensagem
              ? response.mensagem
              : "Erro ao criar um novo usuário, tente novamente."
          );
          setOneButtonPopupVisibility(true);
        } else {
          sessionStorage.removeItem("tipoProdutoSession");
          sessionStorage.removeItem("verificationCodeSession");
          history.push("/onboarding-validacao-sucesso");
        }
        console.log("response ------" + JSON.stringify(response));
      } catch (err) {}
    }
  };

  const onClickPopup = () => {
    window.location = "/login";
  };

  const verifyReloadPage = () => {
    if (onboarding.cpf === "") {
      history.push("/home-produtos");
    }
  };

  useEffect(() => {
    verifyReloadPage();
  }, [verifyReloadPage]);

  return (
    <LayoutOnboarding showBackGroundBar={false}>
      <S.Content>
        <S.MainText>
          <p>Criar carteira digital</p>
        </S.MainText>
        <S.MiddleText style={{ marginBottom: 30 }}>
          <a>
            A senha deve ter 6 dígitos para acessar a tua conta e realizar
            transações.
          </a>
          <a>
            A senha não poderá conter números iguais, números sequenciais ou tua
            data de aniversário.
          </a>
        </S.MiddleText>
        <S.Input>
          <InputOnboarding
            label="Senha"
            icon
            placeholder="******"
            maxLength={6}
            value={password}
            onChange={(e) => setPassword(numericMask(e.target.value))}
            error={errorsInputs.password}
          />
          <InputOnboarding
            label="Confirme a senha"
            icon
            placeholder="******"
            maxLength={6}
            value={passwordConfirmation}
            onChange={(e) =>
              setPasswordConfirmation(numericMask(e.target.value))
            }
            error={errorsInputs.passwordConfirmation}
          />
        </S.Input>
        <S.Footer>
          <Button
            disabled={handleDisabledButton()}
            onClick={() => createUser()}
          >
            Salvar senha
          </Button>
        </S.Footer>
        <OneButtonPopup
          onClick={onClickPopup}
          icon={themes.components.icons.cardBlocked}
          text={textPopup}
          buttonText="Entendi"
          visibility={confirmationPopupVisibility}
          setVisibility={setOneButtonPopupVisibility}
        />
      </S.Content>
    </LayoutOnboarding>
  );
};

export default CreatePassword;
