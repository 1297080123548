import React, { useEffect, useState } from "react";

import Input from "~/components/Input";
import Button from "~/components/Button";
import Layout from "~/components/Layout";
import ContainerBox from "~/components/ContainerBox";
import themes from "~/themes";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";

import * as S from "./styles";
import Loader, { LOADER_TYPES } from "~/components/Loader";

import { useLocation } from "react-router";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";

const QrCodePix = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [base64QrCode, setBase64QrCode] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const { state } = useLocation();


  const GetQrCode = useApi(
    Endpoints.charge.getInvoiceQrCode.replace("PARAM_ID", invoiceId),
    ApiMethod.GET
  );

  useEffect(() => {
    if(state?.id)
    setInvoiceId(state?.id);
  }, [state?.id]);

  useEffect(() => {
    if(invoiceId)
    getQrCode();
  }, [invoiceId]);

  const getQrCode = async () => {
    try{
        const response = await GetQrCode.callApi();
        if(response.status >= 200 && response.status <= 300){
            setBase64QrCode(response.data);
        }
        else{
            setShowPopup(true);
            setErrorMessage(response.mensagem || "Não conseguimos carregar seu QrCode");
        }

    }catch (err){
        setShowPopup(true);
        setErrorMessage(err.message || "Não conseguimos carregar seu QrCode");
    }

  }

  return (
    <Layout showBackGroundBar={false}>
      <ContainerBox>
        {GetQrCode.loading ? (
          <S.WrapperLoader>
            <Loader size={LOADER_TYPES.size.medium} />
          </S.WrapperLoader>
        ) : (
          <S.Wrapper>
            {!errorMessage ? (
              <>
              <h6>Pagamento da fatura</h6>
                {base64QrCode?.base64 ? <img src={"data:image/jpeg;base64," + base64QrCode.base64}/> : <h6>{"Não conseguimos carregar seu QrCode"}</h6>}
              <S.Key>
              <h6>Chave: </h6>
              <p>{base64QrCode.emv}</p>
              </S.Key>
              <S.NavBtnLink onClick={() => {navigator.clipboard.writeText(base64QrCode.emv)}}>
              <S.CopyIcon/>
              </S.NavBtnLink>
                <OneButtonPopup
                  icon={themes.components.icons.warning}
                  text={errorMessage}
                  buttonText="Entendi"
                  visibility={showPopup}
                  setVisibility={setShowPopup}
                />
              </>
            ) : (
              <h6>{errorMessage}</h6>
            )}
          </S.Wrapper>
        )}
      </ContainerBox>
    </Layout>
  );
};

export default QrCodePix;
