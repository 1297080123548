import styled from 'styled-components';

import themes from '~/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const BackgroundBar = styled.div`
  position: absolute;
  /* top: 87px; */
  left: 0;
  background: ${themes.colors.orange.n2};
  width: 100%;
  height: 118px;
`;

export const Content = styled.div`
  z-index: 2;
  display: flex;

  flex-direction: row;
  width: 100%;
  height: auto;

  margin-top: 48px;

  > main {
    flex: 1;
    margin: 0 11px;
  }
`;

export const SimpleContent = styled.div`
  z-index: 2;
  display: flex;

  width: auto;
  max-width: auto;

  height: 100vh;


  padding: 48px 10px;

  background: #FFF;

  > main {
    margin: 0 auto;
    width: 448px;
  }
`;

