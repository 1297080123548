/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Button from "~/components/Button";
import { GlobalContext } from "~/contexts/GlobalState";

import * as St from "../../styles";
import themes from "~/themes";
const WelcomeLending = () => {
  const history = useHistory();

  const { lending } = useContext(GlobalContext);

  const handleClick = () => {
    history.push("/emprestimo/confirmar-dados");
  };

  useEffect(() => {
    if (lending.statusCartao !== "ATIVO") {
      history.push("/emprestimo/erro", {
        error: "noTeuCardActive",
      });
    }
  }, []);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <St.Content>
        <St.Box>
          <St.Header>
            <St.Banner
              src={themes.components.images.banner_card}
              alt="banner"
              onResize="cover"
            />
          </St.Header>

          <St.Title align="center">
            Bem-vindo ao Empréstimo Saque Rápido com cartão TeuCard
          </St.Title>
          <St.MiddleText>
            <img src={themes.components.images.orangeCircle} alt="Indicador" />
            <p>
              É essencial que você possua o <b>Limite de Saque</b> disponível!
            </p>
          </St.MiddleText>

          <St.Footer align="center">
            <div>
              <Button onClick={handleClick}>Avançar</Button>
            </div>
          </St.Footer>
        </St.Box>
      </St.Content>
    </LayoutOnboarding>
  );
};

export default WelcomeLending;
