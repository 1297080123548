import styled from "styled-components";
import Spinner from "~/components/Spinner";

import themes from "~/themes";

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 670px;
  background-color: #fff;
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
  border-radius: 4px;
  margin: 60px auto 60px;
  min-height: 600px;
  max-width: 1210px;
  width: 100%;
  margin-top: 10px;
  height: auto;

  @media (max-width: 960px) {
    grid-template-columns: 100%;
  }
`;

export const ContentMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  > header {
    display: flex;
    flex-direction: row;
    align-items: center;

    h6 {
      flex: 1;
      color: #4a4b4a;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.86px;
      line-height: 27px;
    }

    button {
      background: transparent;
      border: 0;
      height: 21px;
      width: 21px;

      svg {
        height: 21px;
        width: 21px;
      }
    }
  }

  @media (max-width: 960px) {
    > #footer-button {
      margin-left: 0px;
    }
  }
`;

export const Content = styled.div`
  z-index: 2;
  display: flex;
  flex: 1;
  flex-direction: column;

  background-color: #fff;
  box-shadow: rgb(201 201 201 / 50%) 1px 3px 8px 1px;
  border-radius: 4px;
  padding: 48px 10px;
  margin: 60px auto 60px;
  min-height: 600px;
  max-width: 1210px;
  width: 100%;
  margin-top: 1%;

  p {
    color: ${themes.components.colors.font};
    letter-spacing: 0;
    line-height: 20px;
    margin-left: 5%;
    /* margin-bottom: min(10%,auto); */
    text-align: left;
  }

  a {
    color: ${themes.components.colors.font};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-left: 5%;
    text-align: left;
  }

  @media (max-width: 960px) {
    min-height: 100%;
    height: 100%;
    padding: 48px 20px;

    p,
    a {
      margin-left: 0px;
    }
  }
`;

export const MainText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 22px;
  font-weight: 700;
  color: #4a4b4a;

  @media (max-width: 960px) {
    font-size: 18px;
  }
`;

export const MiddleText = styled.span`
  display: flex;
  flex-direction: column;

  font-weight: 400;
  font-size: 20px;
  color: #4a4b4a;

  h6 {
    color: #4a4b4a;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
  }

  .instructions {
    margin: 50px 0;
  }

  .help-message {
    margin-bottom: 70px;

    span {
      color: #e71037;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media (max-width: 960px) {
    p {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
`;

export const Input = styled.span`
  display: flex;
  flex-direction: column;
  margin-left: 5%;

  color: #444444;
  font-weight: bold;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;

  width: 50%;
  height: 5%;

  @media (max-width: 960px) {
    width: 100%;
    margin-left: 0px;
    height: 45%;
  }
`;

export const ViewTerms = styled.span`
  color: #000;
  margin-right: 3px;
  text-decoration: underline;
  padding-left: 5px;
`;

export const InputMain = styled.span`
  display: flex;
  flex-direction: column;
  margin-top: 2%;

  color: #444444;
  font-weight: bold;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;

  width: 85%;
  height: 5%;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const InputSideBySide = styled.span`
  display: flex;
  flex-direction: row;

  color: #444444;
  font-weight: bold;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  gap: 10px;

  p {
    margin-left: 80px;
    color: #444444;
    font-weight: bold;
    font-weight: 700;
    font-size: 14px;
  }

  a {
    margin-left: 180px;
    color: #444444;
    font-weight: bold;
    font-weight: 700;
    font-size: 14px;
  }

  img {
    margin-top: -3%;
    height: 90%;
    width: 45%;
  }

  @media (max-width: 960px) {
    gap: initial;
    flex-direction: column;

    select {
      min-width: 100% !important;
      position: relative !important;
    }

    p,
    a {
      margin-left: 0px;
      width: 50%;
      text-align: center;
    }
  }
`;

export const Footer = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  align-self: center;

  width: 40%;
  height: 20%;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const SelfieButton = styled.span`
  flex: 1;
  display: flex;

  margin-bottom: 10px;
  width: 30%;
  height: 20%;

  img {
    width: 10%;
    height: 25%;
    margin-left: 10px;
  }
`;

export const Alert = styled.span`
  display: flex;
  flex-direction: column;

  width: 85%;
  margin-left: 5%;
  font-weight: 400;
  font-size: 14px;
`;

export const InputDrop = styled.span`
  display: flex;
  flex-direction: column;

  .select {
    font-size: 16px;
    padding: 6px;
    border-color: ${(props) =>
      props.isFocus ? themes.colors.orange.n2 : "#444444"};
    color: ${themes.components.colors.input};
    height: 40px;
    min-width: 258px;
    background: ${themes.colors.white.n1};
    border-radius: 5px;
  }

  span {
    font-size: 12px;
    color: #444444;
  }
`;

export const Checkbox = styled.span`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-size: 18px;
  color: ${themes.components.colors.font};
  align-self: left;
  margin-left: 5%;
  margin-top: 1%;

  @media (max-width: 960px) {
    margin: 0px;
  }
`;

export const WrapperCamera = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10000;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
`;

export const Picture = styled.div`
  grid-area: camera;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  #picture-first-modal {
    margin-left: 20px;
  }

  #picture-second-modal {
    margin-left: 62px;
  }

  button {
    display: flex;
    align-items: center;
    width: 180px !important;
    height: 40px !important;
    margin: 0 20px 0 0 !important;
    svg {
      margin-right: 10px;
    }
  }

  @media (max-width: 960px) {
    > div {
      width: ${(p) => (p.wrapperCamera ? "100%" : "49%")};
    }

    #picture-first-modal,
    #picture-second-modal {
      margin-left: 0px;
      width: 100%;

      img {
        width: 100%;
        height: 200px;
      }
    }
  }

  @media (max-width: 560px) {
    #picture-first-modal,
    #picture-second-modal {
      img {
        width: 100%;
        height: 110px;
      }
    }
  }
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  text-align: center;
`;

export const SuccessMessage = styled.span`
  color: green;
  font-size: 12px;
  text-align: center;
`;

export const SpinnerCusmotized = styled(Spinner).attrs(() => ({
  color: themes.components.colors.n2,
}))``;

export const WrapperSpinner = styled.div`
  width: 200px;
  height: 200px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResendCode = styled.p`
  color: ${themes.colors.black.n2};
  font-size: 15px;
  font-weight: 600;

  > span {
    margin-left: 3px;

    color: ${(props) =>
      props.disabled ? themes.colors.secondary : themes.colors.primary};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-size: 15px;
    font-weight: 600;
    text-decoration: underline;
  }

  p {
    color: ${themes.components.colors.font};
    letter-spacing: 0;
    line-height: 20px;
    margin-left: 0px;
    /* margin-bottom: min(10%,auto); */
    text-align: left;
  }
`;
