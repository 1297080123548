import styled from 'styled-components';

import themes from '~/themes';

export const Content = styled.div`
  width: 100%;
  height: 100%; 
  display: flex;
  flex-direction: column;
  justify-content:flex-start;

  img {
    width: 60%;
    margin-bottom: 15%;
    align-self:left;
  }

  p {
    color: ${themes.components.colors.font};
    font-size: 30px;
    letter-spacing: 0;
    line-height: 0px;
    font-weight: bold;
    top: 50px;
    /* margin-bottom: min(10%,auto); */
    text-align: left;
    margin-left: 1%;
  }
`;

export const BackgroundLogin = styled.div`
  z-index: -1;
  background-image: url(${themes.components.images.emojisLogin});
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  position: absolute;
  padding-left: 85%;
  top: 50px;
  width: 80%;
  height: 80%;

  @media (max-width: 1360px) {
    width: 60%;
    height: 60%;
    top: 100px;
  }

  @media (max-width: 1200px) {
    padding-left: 88%;
  }

  @media (max-width: 1024px) {
    padding-left: 95%;
  }

  @media (max-width: 960px) {
    display: none;
  }
`;

export const SectionForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ButtonLogin = styled.button`
  display: flex;
  justify-content: center;

  font-size: 16px;
  color: ${themes.components.colors.buttonText};

  opacity: ${(props) => (props.active ? 1 : 0.7)};
  margin-top: auto;
  padding: 16px 0;
  border: 0;
  border-radius: 8px;
  background: ${themes.components.colors.button};
`;

export const WrapperButton = styled.div`
  flex: 0 1;
  margin-top: 20px;
  & button:nth-child(2) {
    margin-top: 10px;
  }
`
export const Main = styled.div`
  flex:1;
  display:flex;
  flex-flow:column;
  align-items:stretch;
  justify-content:left;

  @media (max-width: 960px) {
    justify-content: center;

    img {
      align-self: center;
    }

    #title {
      text-align: center;
      font-size: 22px;
    }
  }
`
export const HiperlinkPass = styled.button`
  margin-bottom: 30px;
  border: 0;
  background: transparent;
  padding-left: 3px;
  align-self: flex-end;
  color: #727272; 
  font-size: 14px;
  font-weight: 600;

  @media (max-width: 960px) {
    margin: 30px 0px;
  }
`;

export const ContentVersion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:center;
  margin-top: 22px;
`;

export const ContentVersionNew = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  margin-top: 22px;
`;

export const Version = styled.p`
  color: red;
  font-size: 12px;
  text-align: center;
`;

export const VersionNew = styled.p`
  color: red !important;
  font-size: 12px !important;
  font-weight: normal !important;
`;