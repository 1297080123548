import React, { useEffect } from "react";

import themes from "~/themes";
import { Container, NavItem } from "./styles";

const NavBarDigitalWallet = (props) => {
  const navItens = [
    {
      id: 0,
      name: "Início",
      link: "/home",
      icon: themes.components.icons.home,
    },
    {
      id: 1,
      name: "Transações",
      link: "/emprestimo/extrato",
      icon: themes.components.icons.transition,
    },
  ];

  let navItemExibition = navItens.map((navItem) => (
    <NavItem to={navItem.link} key={navItem.id} exact>
      <div>{navItem.icon}</div>
    </NavItem>
  ));

  if (props.onlyHomeItemInNavBar) {
    navItemExibition = navItens.map(
      (navItem) =>
        navItem.link === "/home" && (
          <NavItem to={navItem.link} key={navItem.id} exact>
            <div style={{ alignContent: "center" }}>{navItem.icon}</div>
          </NavItem>
        )
    );
  }

  return (
    <Container>
      <nav>{navItemExibition}</nav>
    </Container>
  );
};

export default NavBarDigitalWallet;
