import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import themes from '~/themes'
import * as S from './styles';

const Input = ({ marginBottom, error, label, icon = false, ...rest }) => {
  const [focusInput, setFocusInput] = useState(false);
  const [showText, setShowText] = useState(false);

  return (
    <S.Container marginBottom={marginBottom} isFocus={focusInput} >
      <label>{label}</label>

      <input
        type={icon ? (showText ? 'text' : 'password') : 'text'}
        onFocus={() => setFocusInput(true)}
        onBlur={() => setFocusInput(false)}
        {...rest}
      />
      {icon ? (
        showText ? (
          <img 
            src={themes.components.images.visible}  
            onClick={() => setShowText(!showText)} 
            className='icon'
            style={{marginTop:14}}

          />
        ) : (
          <img 
            src={themes.components.images.invisible}  
            onClick={() => setShowText(!showText)} 
            className='icon'
            style={{marginTop:12}}
          />
        )
      ) : null}

      {!!error && <S.ErrorText>{error}</S.ErrorText>}
    </S.Container>
  );
};

export default Input;
