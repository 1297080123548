import styled from "styled-components";
import themes from "~/themes";

export const Container = styled.div`
  grid-area: ${({ name }) => name};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 15px;

  label {
    font-size: ${(props) => (props.largeLabel ? "18px" : "12px")};
    font-weight: ${(props) => (props.largeLabel ? 700 : 400)};
    color: ${(props) => (props.isFocus ? "#E71037" : "#444444")};
  }

  .select {
    font-size: 16px;
    padding: 6px;
    border-color: ${(props) =>
      props.isFocus ? themes.colors.orange.n2 : "#444444"};
    color: ${themes.components.colors.input};
    height: 40px;
    min-width: ${(props) => (props.large ? "100%" : "238px")};
    background: ${themes.colors.white.n1};
    border-radius: 5px;
  }
`;

export const ErrorText = styled.span`
  font-size: 12px;
  color: #ed4848;
  margin-top: 2px;
`;
