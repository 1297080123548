/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Loader from "~/components/Loader";
import InputOnboarding from "~/components/InputOnboarding";
import InputSelect from "~/components/InputSelect";
import Checkbox from "~/components/Checkbox";
import Button from "~/components/Button";
import InputBanking from "../../components/InputBanking";
import InputSelectSimple from "../../components/InputSelectSimple";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";

import {
  agencyBankValidator,
  cpfCnpjValidator,
  phoneValidator,
  accountBankValidator,
  selectValidator,
  randomValidator,
  emailValidator,
} from "~/utils/validators";
import { phoneMask } from "~/utils/mask";
import {
  cpfCnpjMask,
  cpfUnmask,
  phoneUnmask,
  agencyBankMask,
  accountBankMask,
} from "~/utils/masksNew";

import Endpoints from "~/services/endpoints";
import useApi, { ApiMethod } from "~/hooks/useApi";
import { GlobalContext } from "~/contexts/GlobalState";

import * as S from "../../styles";
import themes from "~/themes";

const INPUT_NAMES = {
  inputSearch: "inputSearch",
  typeAccount: "typeAccount",
  agency: "agency",
  account: "account",
  phone: "phone",
  key: "key",
  email: "email",
  random: "random",
  cpfcnpj: "cpfcnpj",
};

const BankingData = () => {
  const [form, setForm] = useState({
    key: "",
  });
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [account, setAccount] = useState("");
  const [agency, setAgency] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [random, setRandom] = useState("");
  const [openSelectKey, setOpenSelectKey] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [openSelectTypeAccount, setOpenSelectTypeAccount] = useState(false);
  const [bankings, setBankings] = useState([]);
  const [allBankings, setAllBankings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [errorsInputs, setErrorsInputs] = useState({});
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  const [messageErrorPopup, setMessageErrorPopup] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const history = useHistory();

  const updateFormField = (value, id) => {
    setForm((prev) => ({ ...prev, [id]: value }));
  };

  const updateFormSelectType = (code, id, value) => {
    setForm((prev) => ({ ...prev, [id]: { code, value } }));
  };

  const GetBanksList = useApi(Endpoints.lendings.getBanks, ApiMethod.GET);

  const { addLending, lending } = useContext(GlobalContext);

  const PostContractedLending = useApi(
    Endpoints.lendings.postContractedLending,
    ApiMethod.POST
  );

  const FetchListBankings = async () => {
    setLoading(true);
    try {
      const response = await GetBanksList.callApi();

      if (response.data) {
        setBankings(response.data?.bancos);
        setAllBankings(response.data?.bancos);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeBankingCode = (value) => {
    updateFormField(value, INPUT_NAMES.inputSearch);

    if (value?.length > 0) {
      setOpenSelect(true);
      const filter = allBankings.filter((banking) => {
        const r = `${banking.name}-${banking.code}`;
        return r.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });

      setBankings(filter);
    } else {
      setBankings(allBankings);
      setOpenSelect(false);
    }
  };

  // useEffect(() => {
  //   const { inputSearch, key, agency, typeAccount, account, email, random } =
  //     form;
  //   if (inputSearch && agency && account && typeAccount) {
  //     if (key) {
  //       console.log("TESTEEEE");
  //       if (phone || email || random || cpfCnpj) {
  //         setButtonIsDisabled(true);
  //       } else {
  //         setButtonIsDisabled(false);
  //       }
  //     } else {
  //       setButtonIsDisabled(true);
  //     }
  //   }
  // }, [form, phone, cpfCnpj]);

  const verifyReloadPage = () => {
    if (lending.cpf === "") {
      history.push("/home");
    }
  };

  const requestLending = async () => {
    setButtonIsLoading(true);
    const payload = {
      atendimento: {
        card_limit: Number(lending.credit_limit),
        cliente: {
          celular: lending.phone,
          cpf: lending.cpf,
          dataNascimento: lending.dataNascimento,
          email: lending.email,
          endereco: {
            bairro: lending.neighborhood,
            cep: lending.cep,
            cidade: lending.city,
            complemento: lending.complement,
            logradouro: lending.street,
            numero: lending.number,
            pais: "Brasil",
            uf: lending.uf,
          },
          idCliente: lending.idCliente,
          nome: lending.nome,
          nomeMae: lending.mother,
          nomePai: lending.father,
          rg: lending.document,
        },
        installments: 12,
        interest_rate: Number(lending.taxaJuros),
        released_amount: Number(lending.value),
        value_installment: Number(lending.value_installment),
        value_iof: Number(lending.value_iof),
      },
      banco: {
        accountDigit: account.unmasked.substring(
          account.unmasked.length - 1,
          account.unmasked.length
        ),
        agencyAccount: agency,
        agencyDigit: "",
        kind: form?.typeAccount.value ? form?.typeAccount.value : null,
        kindPix: form?.key.value ? form?.key.value : undefined,
        nameBank: form?.inputSearch,
        numberAccount: account.unmasked.substring(
          0,
          account.unmasked.length - 1
        ),
        numberBank: parseInt(form?.inputSearch).toString(),
        pix:
          email ||
          phoneUnmask(phone) ||
          random ||
          cpfCnpj.unmasked ||
          lending?.cpf,
      },
    };

    try {
      const response = await PostContractedLending.callApi(payload);
      if (response.status >= 200 && response.status < 300) {
        setButtonIsLoading(false);
        history.push("/emprestimo/successo");
      }

      if (response.status >= 400) {
        setShowPopup(true);
        setMessageErrorPopup(
          response.mensagem ? response.mensagem : "Erro ao simular empréstimo"
        );
        setButtonIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleValidateFields = () => {
    try {
      const fieldsForm = {
        agency: agencyBankValidator(agency)?.message || "",
        account: accountBankValidator(account.masked)?.message || "",
        typeAccount: selectValidator(form?.typeAccount?.code)?.message || "",
        cpfCnpj:
          form?.key.code === "CPF/CNPJ"
            ? cpfCnpjValidator(cpfCnpj.masked || cpfCnpjMask(lending.cpf))
                ?.message || ""
            : null,
        bank: selectValidator(form?.inputSearch)?.message || "",
        phone:
          form?.key?.code === "Celular"
            ? phoneValidator(phone)?.message || ""
            : null,
        email:
          form?.key?.code === "E-mail"
            ? emailValidator(form?.email)?.message || ""
            : null,
        random:
          form?.key?.code === "Aleatória"
            ? randomValidator(form?.random)?.message || ""
            : null,
      };

      setErrorsInputs(fieldsForm);

      if (
        !fieldsForm.typeAccount &&
        !fieldsForm.account &&
        !fieldsForm.agency &&
        !fieldsForm.bank
      ) {
        requestLending();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = () => {
    handleValidateFields();
  };

  useEffect(() => {
    FetchListBankings();
    verifyReloadPage();
  }, []);

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <OneButtonPopup
        icon={themes.components.icons.warning}
        text={messageErrorPopup}
        buttonText="Entendi"
        visibility={showPopup}
        setVisibility={setShowPopup}
      />
      <S.Content>
        <S.BoxData>
          {loading ? (
            <S.WrapperLoader>
              <Loader size="large" />
            </S.WrapperLoader>
          ) : (
            <>
              <S.TitlePersonalData>Dados bancários</S.TitlePersonalData>
              <S.Subtitle>
                Agora precisamos de informações bancárias (pra onde o Teu
                empréstimo vai!) :
              </S.Subtitle>
              <S.Form>
                <InputBanking
                  label="Código e Descrição do Banco*"
                  placeholder="Selecione o banco"
                  onChange={(e) => onChangeBankingCode(e.target.value)}
                  open={openSelect}
                  options={bankings}
                  id={INPUT_NAMES.inputSearch}
                  inputTextValue={form?.inputSearch}
                  setOpen={setOpenSelect}
                  onClickSelect={updateFormField}
                  defaultValue={form?.inputSearch}
                  error={errorsInputs.bank}
                />

                <InputSelectSimple
                  label="Tipo de conta*"
                  largeLabel
                  placeholder="Selecione o tipo de conta"
                  open={openSelectTypeAccount}
                  options={[
                    { code: "Conta Corrente", value: "CONTA_CORRENTE" },
                    { code: "Conta Poupança", value: "CONTA_POUPANCA" },
                  ]}
                  inputId={INPUT_NAMES.typeAccount}
                  setOpen={setOpenSelectTypeAccount}
                  onClickSelect={updateFormSelectType}
                  inputTextValue={form?.typeAccount?.code}
                  type="account"
                  error={errorsInputs.typeAccount}
                />

                <InputOnboarding
                  type="text"
                  label="Agência*"
                  largeLabel
                  maxLength={4}
                  id={INPUT_NAMES.agency}
                  pattern="[0-9]*"
                  placeholder={"0000"}
                  value={agency.masked}
                  error={errorsInputs.agency}
                  onChange={(e) => setAgency(e.target.value)}
                />
                <InputOnboarding
                  type="text"
                  label="Conta*"
                  largeLabel
                  id={INPUT_NAMES.account}
                  placeholder={"0000000-0"}
                  pattern="[0-9]*"
                  maxLength={9}
                  value={account.masked}
                  error={errorsInputs.account}
                  onChange={(e) => setAccount(accountBankMask(e.target.value))}
                />

                <Checkbox
                  label="Não possuo chave PIX"
                  checked={enabled}
                  onChange={(e) => setEnabled(e.target.checked)}
                />

                {!enabled && (
                  <InputSelectSimple
                    label="Tipo de chave PIX*"
                    largeLabel
                    placeholder="Selecione o tipo de chave"
                    open={openSelectKey}
                    options={[
                      { code: "CPF/CNPJ", value: "CPF_CNPJ" },
                      { code: "Celular", value: "PHONE" },
                      { code: "E-mail", value: "EMAIL" },
                      { code: "Aleatória", value: "RANDOM" },
                    ]}
                    inputId={INPUT_NAMES.key}
                    setOpen={setOpenSelectKey}
                    onClickSelect={updateFormSelectType}
                    inputTextValue={form?.key?.code}
                    error={errorsInputs.typeKey}
                  />
                )}

                {!enabled && form?.key?.code === "Celular" && (
                  <InputOnboarding
                    type="text"
                    label="Celular*"
                    largeLabel
                    id={INPUT_NAMES.phone}
                    maxLength={15}
                    value={phone}
                    onChange={(e) => setPhone(phoneMask(e.target.value))}
                    error={errorsInputs.phone}
                  />
                )}
                {!enabled && form?.key?.code === "E-mail" && (
                  <InputOnboarding
                    label="E-mail*"
                    largeLabel
                    id={INPUT_NAMES.email}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={errorsInputs.email}
                  />
                )}
                {!enabled && form?.key?.code === "CPF/CNPJ" && (
                  <InputOnboarding
                    label="CPF/CNPJ*"
                    largeLabel
                    maxLength={18}
                    value={
                      lending.cpf ? cpfCnpjMask(lending.cpf) : cpfCnpj.masked
                    }
                    onChange={(e) => setCpfCnpj(cpfCnpjMask(e.target.value))}
                    error={errorsInputs.cpfCnpj}
                  />
                )}
                {!enabled && form?.key.code === "Aleatória" && (
                  <InputOnboarding
                    label="Aleatória*"
                    largeLabel
                    maxLenght={30}
                    value={random}
                    onChange={(e) => setRandom(e.target.value)}
                    error={errorsInputs.random}
                  />
                )}
              </S.Form>
              <S.FooterData>
                <div>
                  <Button
                    onClick={onSubmit}
                    isLoading={buttonIsLoading}
                    disabled={buttonIsDisabled}
                  >
                    Contratar
                  </Button>
                </div>
              </S.FooterData>
            </>
          )}
        </S.BoxData>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default BankingData;
