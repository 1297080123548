import styled from "styled-components";
import { NavLink } from "react-router-dom";
import themes from "~/themes";

export const Container = styled.div`
  width: 80%;
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 50px;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
`;

export const Linked = styled.div`
  padding-right: 30px;
`;

export const NavItem = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  color: #4a4b4a;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: 0;
  line-height: 19px;

  &:hover,
  &:visited {
    color: ${themes.components.colors.font};
  }

  div {
    display: flex;
    width: 38px;
  }

  > svg {
    margin-left: auto;
  }
`;

export const RowInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  padding: 11px 4px;
  border-bottom: 1px solid #eaeaea;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      color: ${themes.components.colors.label};
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
    }

    p {
      color: ${themes.components.colors.input};
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      margin: 4px 0 0;
    }
  }

  a {
    text-decoration: none;
    background: transparent;
    border: 0;
    margin-left: auto;

    svg {
      height: 15px;
      width: auto;
    }
  }
`;

export const Span = styled.span`
  font-size: 16px;
  color: #d4313e;
  font-weight: 700;
`;

export const WrapperLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;
