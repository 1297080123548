import React, { useContext, useCallback, useState } from "react";

import { GlobalContext } from "~/contexts/GlobalState";
import Input from "~/components/Input";
import CardClient from "~/components/CardClient";
import Button from "~/components/Button";
import Modal from "~/components/Modals/OneButtonPopup";
import { cardNumberMask } from "~/utils/mask";
import Checkbox from "~/components/Checkbox";
import Loader, { LOADER_TYPES } from "~/components/Loader";

import Termos from "~/services/terms";
import * as S from "../styles";
import themes from "~/themes";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { CustomizedError } from "~/utils/errors";

const CardNumber = ({ handleNextSection }) => {
  const { addUser } = useContext(GlobalContext);

  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [errorCardNumber, setErrorCardNumber] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [terms, setTerms] = useState(false);
  const [whatsApp, setWhatsApp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [popupcharge, setPopupCharge] = useState(false);

  const numeroCartao = cardNumber.replace(/\D/g, "");

  const ResponseToken = useApi(
    Endpoints.autorizador.validateCard,
    ApiMethod.POST
  );

  const RequestGetMotivoBloqueio = useApi(
    Endpoints.cliente.getMotivoBloqueio.replace("PARAM_NUMERO", numeroCartao),
    ApiMethod.GET
  );

  const RequestPostValidateCard = useApi(
    Endpoints.autorizador.postCartao,
    ApiMethod.POST
  );

  const formattedCardNumber = useCallback((value) => {
    setCardNumber(cardNumberMask(value));
  }, []);

  const ValidateCard = useCallback(async () => {
    const responseToken = await ResponseToken.callApi({ numeroCartao });

    const getMotivoBloqueio = await RequestGetMotivoBloqueio.callApi();

    const motivoBloqueio = getMotivoBloqueio.data.status;

    if (responseToken.status === 404) {
      throw CustomizedError({
        message: responseToken.data.mensagem,
        status: responseToken.status,
      });
    } else if (responseToken.status !== 201) {
      throw CustomizedError({ message: responseToken.status });
    }

    const { tokenCartao, token } = responseToken.data;
    localStorage.setItem("@NovaEra:token", token);

    const responseCard = await RequestPostValidateCard.callApi({ tokenCartao });

    switch (responseCard.status) {
      case 200:
        const { statusCliente, bloqueado, permiteLogin } = responseCard.data;

        if (statusCliente === "INADIMPLENTE") {
          sessionStorage.setItem("@NovaEra:statusCliente", statusCliente);
        }

        if (
          statusCliente !== "CANCELADO" &&
          statusCliente !== "COBRANCA" &&
          motivoBloqueio !== "CANCELAMENTO_SOLICITACAO" &&
          motivoBloqueio !== "CANCELAMENTO_EXPERIENCIA" &&
          motivoBloqueio !== "PERDA_EXTRAVIO" &&
          motivoBloqueio !== "FURTO_ROUBO" &&
          motivoBloqueio !== "ERRO_IMPRESSAO" &&
          motivoBloqueio !== "CANCELADO"
        ) {
          localStorage.setItem("@NovaEra:tokenCartao", tokenCartao);
        } else {
          sessionStorage.setItem("@NovaEra:tokenCartao", tokenCartao);
        }

        return {
          status: 200,
          data: responseCard.data,
          motivoBloqueio: motivoBloqueio,
        };
      default:
        throw CustomizedError({
          status: responseCard.status,
          message: responseCard.data.mensagem || responseCard.status,
        });
    }
  }, [
    RequestGetMotivoBloqueio,
    RequestPostValidateCard,
    ResponseToken,
    numeroCartao,
  ]);

  const handleValidadeCardNumber = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setButtonIsLoading(true);

        const {
          motivoBloqueio,
          data: { statusCliente, bloqueado, permiteLogin, ...others },
        } = await ValidateCard();

        if (
          motivoBloqueio === "CANCELAMENTO_SOLICITACAO" ||
          motivoBloqueio === "CANCELAMENTO_EXPERIENCIA"
        ) {
          setTextModal("Cartão Cancelado! Favor realizar nova proposta.");
          setModalIsOpen(true);
          setButtonIsLoading(false);
          return;
        }

        if (
          motivoBloqueio === "PERDA_EXTRAVIO" ||
          motivoBloqueio === "FURTO_ROUBO" ||
          motivoBloqueio === "ERRO_IMPRESSAO" ||
          motivoBloqueio === "CANCELADO"
        ) {
          setTextModal(
            "Cartão Bloqueado! Favor solicitar outra via no atendimento."
          );
          setModalIsOpen(true);
          setButtonIsLoading(false);
          return;
        }

        switch (statusCliente) {
          case "CANCELADO":
            setTextModal(
              "Favor solicitar um novo cartão em uma das lojas TeuCard!"
            );
            setModalIsOpen(true);
            setButtonIsLoading(false);
            break;
          case "COBRANCA":
            setTextModal(
              "Temos uma excelente proposta para regularizar seus débitos, clique no botão abaixo e acesse nosso portal de negociações. Se preferir entre em contato pelo nr. 4003-3203 ou pelo WhatsApp (92) 4003-3203"
            );
            setPopupCharge(true);
            setButtonIsLoading(false);
            break;
          case "INATIVO":
            addUser(others);
            handleNextSection({
              card: { number: cardNumber },
              validateApi: others,
            });
            break;
          default:
            window.location = "/login";
            break;
        }
      } catch (err) {
        setErrorCardNumber(err.message);
      } finally {
        localStorage.setItem("@NovaEra:optionWhatsApp", whatsApp);
        localStorage.setItem("@NovaEra:optionTermos", terms);
      }
      setButtonIsLoading(false);
    },
    [ValidateCard, addUser, handleNextSection, cardNumber, whatsApp, terms]
  );

  const handleDisabledButton = () =>
    cardNumber.length !== 19 || !terms || !whatsApp;

  const handleTermsConditions = async () => {
    const filePath = Termos.termosCondicoes;

    window.open(filePath);
  };

  const handleTermsPrivacy = async () => {
    const filePath = Termos.termosPrivacidade;

    window.open(filePath);
  };

  return (
    <>
      {isLoading ? (
        <S.WrapperLoader>
          <Loader size={LOADER_TYPES.size.medium} />
        </S.WrapperLoader>
      ) : (
        <S.SectionForm onSubmit={handleValidadeCardNumber}>
          <S.Main>
          <img src={themes.components.images.logoTeuCard} alt="Logo TeuCard" />
          <p> Já é cliente TeuCard? </p>
          <Modal
            imgIcon
            icon={themes.components.images.warning}
            text={textModal}
            visibility={modalIsOpen}
            buttonText="Ok"
            // onClick={() => {
            //   setModalIsOpen(false);
            //   window.location = "/";
            // }}
            slug="/"
            setVisibility={setModalIsOpen}
          />
          <Modal
            defaultButton
            icon={themes.components.icons.cardBlockedtitle}
            text={textModal}
            buttonText="negocie.useteucard.com.br"
            visibility={popupcharge}
            setVisibility={() => {
              window.open("https://negocie.useteucard.com.br", "_blank");
              setPopupCharge(false);
            }}
          />
          <S.WrapperCard>
            <CardClient number={cardNumber} />
          </S.WrapperCard>
          <S.MiddleText>
          <p>Preencha o campo abaixo com o número do teu cartão</p>
          </S.MiddleText> 
          <Input
            type="text"
            placeholder="Número do cartão"
            value={cardNumber}
            maxLength={19}
            onChange={(e) => formattedCardNumber(e.target.value)}
            error={errorCardNumber}
          />
          </S.Main>
          <S.Checkbox>
          <Checkbox
            label={
              <>
                Li e concordo com os
                <S.ViewTerms onClick={() => handleTermsConditions()}>
                  Termos e condições de uso
                </S.ViewTerms>
                e a
                <S.ViewTerms onClick={() => handleTermsPrivacy()}>
                  Política de privacidade
                </S.ViewTerms>
              </>
            }
            checked={terms}
            onChange={(e) => setTerms(e.target.checked)}
            disabled={cardNumber.length !== 19}
          />
          
          <Checkbox
            label={
              <>Desejo receber comunicação do meu interesse por Whatsapp</>
            }
            checked={whatsApp}
            onChange={(e) => setWhatsApp(e.target.checked)}
            disabled={cardNumber.length !== 19}
          />
          </S.Checkbox>
          <S.ButtonSubmit>
          <Button
            type="submit"
            disabled={handleDisabledButton()}
            isLoading={buttonIsLoading}
          >
            Continuar
          </Button>
          </S.ButtonSubmit>
        </S.SectionForm>
      )}
    </>
  );
};

export default CardNumber;
