import styled from 'styled-components';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import themes from '../../themes';

export const ExclamationIcon = styled(AiOutlineExclamationCircle)`
  font-size: 1.8em;
  position: absolute;
  right: 20px;
  bottom: 28px;
  color: #E71037;
  cursor: pointer;
  z-index: 2;
`;

export const Container = styled.div`
  display: grid;

  grid-template-columns: auto 550px;
  grid-template-rows: auto auto;
  gap: 22px 0;
  grid-template-areas:
    'BALANCE ICONS'
    'ACTIVITIES CARD';

  height: auto;
`;

export const Balance = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  height: 38px;
  margin-top: 10px;

  h5 {
    color: #4a4b4a;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;

    strong {
      color: #727272;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 23px;
    }
  }

  span {
    color: #727272;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
    align-self: flex-start;
    margin-left: 1px;
  }

  button {
    position: absolute;
    background: transparent;
    border: 0;
    top: 10px;
    left: 130px;
  }
`;

export const HeaderIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

export const HeaderIcon = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 109px;
  width: 109px;
  margin: 0 11px;

  border: 0;
  border-radius: 4px;
  background: #fff;
  box-shadow: 1px 3px 8px 0 rgba(201, 201, 201, 0.5);

  p {
    color: ${themes.components.colors.link};
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0;
    line-height: 13px;
    margin-top: 20px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  svg {
    width: 80px;
    height: auto;
    padding-left: 22px;
    padding-top: 22px;
  }

  p {
    color: #4a4b4a;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    padding-left: 22px;
    margin: 20px 0 50px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 470px;

    padding: 16px 22px;
    border-top: 1px solid #e0e0e0;

    button {
      height: 50px;
      width: 202px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      border-radius: 4px;
    }
    button:nth-child(1) {
      color: #ff822f;
      border: 1px solid #ff822f;
      font-size: 16px;
      font-weight: 600;
      background: transparent;
    }
    button:nth-child(2) {
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      background: #ff822f;
    }
  }
`;

export const CardClientInfo = styled.div`
  display: flex;
  flex-direction: column;

  height: auto;
  margin-top: 22px;
`;

export const LimitBar = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  margin-top: 35px;

  background: ${themes.components.colors.limitBar};
  border-radius: 3px;

  width: 100%;
  height: 9px;

  > span {
    display: flex;
    width: ${(props) => props.percentage}%;
    height: 9px;
    background: ${themes.components.colors.button};
    border-radius: 2px;
  }
`


export const WrapperLoader = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height: 100px;
`
