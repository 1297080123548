import React, { useEffect, useState } from "react";
import Button from "~/components/Button";
import ContainerBox from "~/components/ContainerBox";
import Layout from "~/components/Layout";
import themes from "~/themes";
import { getpayments } from "~/services/walletServices";

import * as St from './styles';
import { useContext } from "react";
import { GlobalContext } from "~/contexts/GlobalState";
import Loader from "~/components/Loader";
import { useHistory } from "react-router-dom";
import { convertBalance, convertDate } from "~/utils/converters";
import { cpfMask } from "~/utils/mask";

const PaymentVoucher = () => {
    const [payment, setPayment] = useState()
    const [paymentData, setPaymentData] = useState();
    const [cpf, setCpf] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { walletDigital } = useContext(GlobalContext);
    const { idTransacao } = walletDigital;
    const history = useHistory()

    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            try {
                if (idTransacao) {
                    const responseData = await getpayments(idTransacao);
                    setPayment(responseData.data)
                    setPaymentData(convertDate(responseData.data.dataCriacao))
                    handlerCpf(responseData.data.pagador.cpf)
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                    history.push('/carteira-digital-transacoes')
                }

            } catch (err) {
                console.error(err.message);
            }
        };
        fetchData();
    }, [])

    const handlerCpf = (cpf) => {
        const cpfMas = cpfMask(cpf);
        setCpf(cpfMas.substring(0, 4) + '***.***' + cpfMas.substring(11, 14))
    }

    return (
        <Layout
            showBackGroundBar={false}
            productType='Carteira digital'
        >
            <ContainerBox title='Comprovante'>
                {isLoading && !payment &&
                    <St.WrapperLoader>
                        <Loader />
                    </St.WrapperLoader>
                }
                {payment &&
                    <St.Content>
                        <St.TeuCardLogo>
                            <img src={themes.components.images.logoTeuCard} />
                        </St.TeuCardLogo>
                        <St.Header>
                            <h3>Comprovante de pagamento</h3>
                            <span>{`${paymentData?.day}/${paymentData?.month}/${paymentData?.year} às ${paymentData?.hour}:${paymentData?.minutes}:${paymentData?.seconds}`} </span>
                        </St.Header>
                        <St.BodyItems>
                            <St.BodyItem>
                                <p>Valor da compra</p>
                                <span>{convertBalance(payment.valor)}</span>
                            </St.BodyItem>
                            <St.BodyItem>
                                <p>Parcelas</p>
                                <span>{payment.detalhes.parcelas}</span>
                            </St.BodyItem>
                            <St.BodyItem>
                                <p>Cartão</p>
                                <span>Cartão terminado em {payment.detalhes.cartao.numero.slice(payment.detalhes.cartao.numero.length - 4)}</span>
                            </St.BodyItem>

                            <hr />
                            <h3>Pagador</h3>
                            <St.BodyItem>
                                <p>Nome</p>
                                <span>{payment.pagador.nome}</span>
                            </St.BodyItem>
                            <St.BodyItem>
                                <p>CPF/CNPJ</p>
                                <span>{cpf}</span>
                            </St.BodyItem>

                            <hr />
                            <h3>Recebedor</h3>
                            <St.BodyItem>
                                <p>Nome</p>
                                <span>{payment.recebedor.nome}</span>
                            </St.BodyItem>
                            <St.BodyItem>
                                <p>CPF/CNPJ</p>
                                <span>{cpfMask(payment.recebedor.cpfCnpj)}</span>
                            </St.BodyItem>
                        </St.BodyItems>
                        <St.Footer>
                            <p>Código de autenticação</p>
                            <span>{payment.idAutorizador}</span>
                            <Button onClick={() => history.goBack()}>VOLTAR</Button>
                        </St.Footer>
                    </St.Content>
                }
            </ContainerBox>
        </Layout>
    )
}

export default PaymentVoucher