import React, { useState } from 'react';

import { Container, ErrorText } from './styles';

const Select = ({ error, values, label, ...rest }) => {
  const [focusSelect, setFocusSelect] = useState(false);

  return (
    <Container isFocus={focusSelect}>
      <label>{label}</label>
      <select
        onFocus={() => setFocusSelect(true)}
        onBlur={() => setFocusSelect(false)}
        {...rest}
      >
        {values?.map((value) => (
          <option key={value.idMotivo} value={value.idMotivo}>
            {value.descricao}
          </option>
        ))}
      </select>
      {!!error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

export default Select;
