import React, {useCallback, useMemo, useState} from 'react';
import {FaCheck} from 'react-icons/fa';

import Input from '~/components/Input';
import Button from '~/components/Button'
import themes from '~/themes';
import { passwordValidator, passwordConfirmationValidator, isSequencialNumbers } from '~/utils/validators';

import {SectionForm, ButtonNextPage} from '../styles';
import { ButtonShowPassword, CheckForm } from './styles';

const AplicationPassword = ({ handleNextSection }) => {

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [passwordInputActive, setPasswordInputActive] = useState(true);
  const [passwordConfirmationInputActive, setPasswordConfirmationInputActive] = useState(true);

  const [errorsInputs, setErrorsInputs] = useState({});
  
  const handleValidadeFields = useCallback((event) => {
    try {
      event.preventDefault();

      const fieldsForm = {
        password: passwordValidator(password).message || '',
        passwordConfirmation: passwordConfirmationValidator(password, passwordConfirmation).message || '',
      };
      
      setErrorsInputs(fieldsForm);
      if(!fieldsForm.password && !fieldsForm.passwordConfirmation){
        handleNextSection({ password, passwordConfirmation })
      }
    } catch (err) {
      console.log(err);
    }
  }, [handleNextSection, password, passwordConfirmation]);

  const checkFormSequencialNumber = useMemo(() => password.length ? !isSequencialNumbers(password) : false,
  [password]);

  return (
    <SectionForm onSubmit={handleValidadeFields}>
      <p>Preencha os campos abaixo com a senha que será utilizada para entrar no app.</p>

      <div style={{position: 'relative'}}>
        <Input 
          type={passwordInputActive ? 'password' : 'text'} 
          label="Senha"
          placeholder="******"
          maxLength={6}
          value={password}
          onChange={e => setPassword(e.target.value)}
          error={errorsInputs.password}
        />
          <ButtonShowPassword type="button" onClick={() => setPasswordInputActive(state => !state)}>
          <img src={themes.components.icons.eye} alt="Icone para mudança de estado do input"/>
        </ButtonShowPassword>
      </div>

      <div style={{position: 'relative'}}>
        <Input 
          type={passwordConfirmationInputActive ? 'password' : 'text'} 
          label="Confirmar senha"
          placeholder="******"
          maxLength={6}
          value={passwordConfirmation}
          onChange={e => setPasswordConfirmation(e.target.value)}
          error={errorsInputs.passwordConfirmation}
        />
        <ButtonShowPassword type="button" onClick={() => setPasswordConfirmationInputActive(state => !state)}>
          <img src={themes.components.icons.eye} alt="Icone para mudança de estado do input"/>
        </ButtonShowPassword>
      </div>

      <CheckForm active={password.length === 6}>
        <div>
          <FaCheck />
        </div>
        <p>Senha de até 6 dígitos</p>
      </CheckForm>

      <CheckForm active={checkFormSequencialNumber}>
        <div>
          <FaCheck />
        </div>
        <p>Números não sequenciais</p>
      </CheckForm>

      <Button
        type='submit'
        disabled={!checkFormSequencialNumber || password.length !== 6}
      >
        Entrar
      </Button>
    </SectionForm>
  );
}

export default AplicationPassword;
