import React, { useState } from "react";

import Layout from "~/components/Layout";
import ContainerBox from "~/components/ContainerBox";
import TwoButtonPopup from "~/components/Modals/TwoButtonPopup";
import PasswordPopup from "~/components/Modals/PasswordPopup";
import { Link, useHistory } from "react-router-dom";

import * as S from "./styles";
import themes from "~/themes";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { CustomizedError } from "~/utils/errors";

const Security = () => {
  const history = useHistory();

  const [blockPopupVisibility, setTwoButtonPopupVisibility] = useState(false);
  const [passwordPopupVisibility, setPasswordPopupVisibility] = useState(false);
  const [passwordSlug, setPasswordSlug] = useState("");

  const RequestPostValidatePassword = useApi(
    Endpoints.autorizador.postValidatePassword,
    ApiMethod.POST
  );

  const contentSecurity = [
    {
      id: 0,
      title: "",
      value: "Alterar a senha",
      route: "/cartao-form-redefinir-senha",
    },
  ];

  function handleClick(click) {
    if (click === "block") {
      setTwoButtonPopupVisibility(!blockPopupVisibility);
    }
  }

  const handleConfirmBlockModal = () => {
    setTwoButtonPopupVisibility(false);
    setPasswordPopupVisibility(true);
    setPasswordSlug("/cartao-motivo-bloqueio");
  };

  const ValidatePassword = async (senha) => {
    let tokenCartao = sessionStorage.getItem("@NovaEra:tokenCartao");
    if (!tokenCartao)
      tokenCartao = localStorage.getItem("@NovaEra:tokenCartao");

    const response = await RequestPostValidatePassword.callApi({
      senha: senha,
      tokenCartao: tokenCartao,
    });

    switch (response.status) {
      case 204:
        return { status: 204 };
      default:
        throw CustomizedError({
          message: response.data.mensagem || response.status,
        });
    }
  };

  const handlePasswordPopup = async (password) => {
    await ValidatePassword(password);
    const parentPath = history.location.pathname;
    history.push(passwordSlug, { password, parentPath });
  };

  return (
    <Layout
      onlyHomeItem={true}
      showBackGroundBar={false}
    >
      <ContainerBox title="Segurança">
        {contentSecurity.map((itemData) => (
          <S.RowInfo key={itemData.id}>
            <div>
              <span>{itemData.title}</span>
              <p>{itemData.value}</p>
            </div>
            {itemData.route && (
              <Link to={itemData.route}>
                {themes.components.icons.chevronRight}
              </Link>
            )}
            {itemData.click && (
              <Link onClick={() => handleClick(itemData.click)}>
                {themes.components.icons.chevronRight}
              </Link>
            )}
          </S.RowInfo>
        ))}
      </ContainerBox>

      <TwoButtonPopup
        bodyText="Você quer bloquear seu cartão?"
        leftButtonText="Cancelar"
        rightButtonText="Bloquear"
        visibility={blockPopupVisibility}
        setVisibility={setTwoButtonPopupVisibility}
        confirm={handleConfirmBlockModal}
      />
      <PasswordPopup
        onClick={handlePasswordPopup}
        text="Continuar"
        slug={passwordSlug}
        visibility={passwordPopupVisibility}
        setVisibility={setPasswordPopupVisibility}
      />
    </Layout>
  );
};

export default Security;
