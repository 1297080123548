import styled from "styled-components";

import themes from "~/themes";

export const Content = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  
  h3 {
    display: none;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    color: ${themes.components.colors.font};
    margin-bottom: 10px;
  }
`;

export const SectionForm = styled.form`
  width: 170%;
  height: 100vh;
  flex:1;

  padding-top: 3vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  >p{
    font-size: 18px;
    color: ${themes.components.colors.font};
    align-self:center;
  }

  aside {
    margin: 0 auto 10px;
  }
`;

export const Container = styled.div`
  width: 170%;
  height: 100vh;
  flex:1;

  padding-top: 3vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  >p{
    font-size: 18px;
    color: ${themes.components.colors.font};
    align-self:center;
  }

  aside {
    margin: 0 auto 10px;
  }
`;

export const ButtonNextPage = styled.button`
  display: flex;
  justify-content: center;

  font-size: 16px;
  color: ${themes.components.colors.buttonText};

  margin-top: auto;
  padding: 16px 0;
  border: 0;
  border-radius: 8px;
  background: ${themes.components.colors.button};
`;

export const WrapperCard = styled.div`
  padding-bottom: 30px;
`;
export const WrapperCheckbox = styled.div`
  margin-bottom: 20px;
`;

export const ViewTerms = styled.span`
  color: #000;
  margin-right: 3px;
  text-decoration: underline;
  padding-left: 5px;
`;

export const WrapperLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

export const MiddleText = styled.span`
  // flex:1;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction: column;
  // margin-top: 20px;
  // margin-bottom: -35px;
  

  >p{
    font-size: 18px;
    color: ${themes.components.colors.font};
    align-self:center;
  }
`;

export const AlternativeMiddleText = styled.span`
  flex:1;
  display:flex;
  align-items:flex-start;
  justify-content:center;
  margin-top: 20px;
  margin-bottom: -35px;
  

  >p{
    font-size: 18px;
    color: ${themes.components.colors.font};
    align-self:center;
  }
`;

export const ButtonSubmit = styled.span`
  width: 60%;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 40%;
`;

export const Checkbox = styled.span`
  flex:1;
  display:flex;
  flex-direction: column;
  justify-content:left;
  font-size: 18px;
  color: ${themes.components.colors.font};
  align-self:left;

`;

export const AlternativeMain = styled.div`
  flex:1;
  display:flex;
  flex-flow:column;
  align-items:stretch;
  justify-content:left;
  margin-top: 40;

  >img {
    width: 32%;
    margin-bottom: 2%;
    align-self:center;
    margin-top: 40px;

    
  }

  >p {
    font-size: 18px;
    color: ${themes.components.colors.font};
    font-weight: bold;
    align-self:center;
    margin-bottom: 2%;


  }

`;


export const Main = styled.div`
  flex:1;
  display:flex;
  flex-flow:column;
  align-items:stretch;
  justify-content:left;
  margin-top: -40px;

  >img {
    width: 32%;
    margin-bottom: 2%;
    align-self:center;
    
  }

  >p {
    font-size: 20px;
    color: ${themes.components.colors.font};
    font-weight: bold;
    align-self:center;


  }

  
`;

