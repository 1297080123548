import styled from "styled-components";

import themes from "~/themes";

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 520px;
  height: 320px;
  /* margin: 0 auto 100px; */
  box-shadow: 1px 3px 8px 0 rgba(201, 201, 201, 0.5);

  background-image: url(${(props) =>
    props.temaCard
      ? props.temaCard
      : themes.components.images.cardBackgroundTeuCard});
  background-size: cover;
  background-position: center;
  border-radius: 10px;

  img {
    top: 20px;
    right: 15px;
    width: auto;
    height: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 23px;

  h6,
  p {
    color: ${(props) =>
      props.temaCard ? props.temaCard : themes.components.colors.cardText};
    font-family: "Bai Jamjuree", sans-serif;
    font-weight: 600;
  }

  h6 {
    margin-top: 50px;
    font-size: 18px;
    letter-spacing: 1.13px;
    line-height: 22px;
  }

  p {
    font-size: 14px;
    letter-spacing: 0.88px;
    line-height: 22px;
    padding-top: 20px;
  }
`;
