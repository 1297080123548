import React from "react";
import { useHistory } from "react-router-dom";

import LayoutOnboarding from "~/components/LayoutOnboarding";
import Button from "~/components/Button";

import * as S from "../../styles";
import themes from "~/themes";
const Success = () => {
  const history = useHistory();

  return (
    <LayoutOnboarding showBackGroundBar={false} auth teuCred>
      <S.Content>
        <S.BoxData>
          <S.BoxSuccess>
            <S.ImageLogo>
              <img
                id="sucess-validation"
                src={themes.components.images.successValidation}
              />
            </S.ImageLogo>
            <S.TitlePersonalData>
              Simulação realizada com sucesso
            </S.TitlePersonalData>
            <S.Description>
              <span>
                Teus dados estão sendo processados e analisados. Assim que
                estiver tudo certo, nós te avisaremos! Fique atento ao teu
                celular. Podemos te ligar ou enviar um SMS.
              </span>
            </S.Description>
          </S.BoxSuccess>
          <S.FooterData>
            <div>
              <Button onClick={() => history.push("/home")}>
                Voltar à página inicial
              </Button>
            </div>
          </S.FooterData>
        </S.BoxData>
      </S.Content>
    </LayoutOnboarding>
  );
};

export default Success;
