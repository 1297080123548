import React, { useMemo, useState } from "react";
import Input from "~/components/Input";
import Button from "~/components/Button";
import Layout from "~/components/Layout";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import Checkbox from "~/components/Checkbox";

import {
  passwordValidator,
  passwordConfirmationValidator,
  isSequencialNumbers,
} from "~/utils/validators";

import themes from "~/themes";
import * as S from "./styles";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { CustomizedError } from "~/utils/errors";

function NewPassword(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const idUsuario = queryParams.get('idUser');
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenFeedback, setModalIsOpenFeedback] = useState(false);
  const [msgFeedback, setMsgFeedback] = useState("");
  const [errorsInputs, setErrorsInputs] = useState({});
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [buttonIsEnable, setButtonIsEnable] = useState(false);

  const handleValidadeFields = (event) => {
    setButtonIsLoading(true);
    try {
      event.preventDefault();

      const fieldsForm = {
        password: passwordValidator(password).message || null,
        passwordConfirmation:
          passwordConfirmationValidator(password, passwordConfirmation)
            .message || null,
      };

      setErrorsInputs(fieldsForm);
      if (!fieldsForm.password && !fieldsForm.passwordConfirmation) {
        handleSubmit();
      }
    } catch (err) {
      console.log(err);
      setButtonIsLoading(false);
    } finally {
      setButtonIsLoading(false);
    }
  };

  const checkFormSequencialNumber = useMemo(
    () => (password.length ? !isSequencialNumbers(password) : false),
    [password]
  );

  const RequestPostDefineNewPassword = useApi(
    Endpoints.usuario.postDefineNewPassword,
    ApiMethod.POST
  );

  const DefineNewPassword = async (novaSenha) => {
    const response = await RequestPostDefineNewPassword.callApi({
      novaSenha: novaSenha,
      idUsuario: idUsuario,
    });

    if (response.status >= 200 && response.status < 300) {
        return { data: response.data };
    }
    else{
        throw CustomizedError({
          message: response.data.mensagem || response.status,
        });
    }
  };

  function handleSubmit() {
    console.log("handleSubmit");
    setButtonIsLoading(true);
    setButtonIsEnable(true);

    DefineNewPassword(password)
      .then(() => {
        setModalIsOpen(true);
      })
      .catch((err) => {
        setButtonIsLoading(false);
        setMsgFeedback(err.message);
        setModalIsOpenFeedback(true);
      })
      .finally(() => {
        setButtonIsLoading(false);
      });
  }

  return (
    <Layout auth={false}>
      <S.Content>
        <h3>Esqueci minha senha</h3>
        <S.SectionForm>
          <p>Por favor, crie uma nova senha.</p>

          <div>
            <Input
              icon
              label="Nova senha"
              maxLength={6}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={errorsInputs.password}
            />
          </div>

          <div>
            <Input
              icon
              label="Confirme nova senha"
              maxLength={6}
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              error={errorsInputs.passwordConfirmation}
            />
          </div>

          <Checkbox
            checked={password.length === 6}
            label="Senha de até 6 dígitos"
          />
          <Checkbox
            checked={checkFormSequencialNumber}
            label="Números não sequenciais"
          />

          <Button
            type="submit"
            onClick={handleValidadeFields}
            disabled={buttonIsEnable}
            isLoading={buttonIsLoading}
          >
            Continuar
          </Button>

          <OneButtonPopup
            slug="/login"
            icon={themes.components.icons.tick}
            text="Nova senha atualizada sucesso!"
            buttonText="Entendi"
            visibility={modalIsOpen}
            setVisibility={setModalIsOpen}
          />
          <OneButtonPopup
            slug="/"
            icon={themes.components.icons.warning}
            text={msgFeedback}
            buttonText="Entendi"
            visibility={modalIsOpenFeedback}
            setVisibility={setModalIsOpenFeedback}
          />
        </S.SectionForm>
      </S.Content>
    </Layout>
  );
}

export default NewPassword;
