import * as session from "./session";
import * as storage from "./storage";
import keys from "./keys";

const Repository = {
  keys,
  session,
  storage,
};

export default Repository;
